import React, { Fragment } from 'react';
import { Container, Row, Col, Card} from 'reactstrap';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import LearningFilter from '../Common/learning-filter';
import SubMateriBox from './SubMateriBox';
import { resetState } from '../../../Features/Learning/SubMateri/addSubMaterialSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Btn } from '../../../AbstractElements';


const SubMateriContain = () => {
  const dispatch = useDispatch();
  const cards = useSelector(state => state.subMaterial.data.data)

  const handleAdd = (e) => {
    dispatch(resetState());
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Col md="12" className="project-list">
          {cards && (
            <Card>
              <Row>
                <Col>
                  <Link to={`${process.env.PUBLIC_URL}/learning/sub-materi-add`} onClick={handleAdd}>
                    <Btn attrBtn={{ color: 'primary'}}>
                      <i className="fa fa-plus-circle fa-lg"></i> Tambah Submateri Baru
                    </Btn>
                  </Link>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
        <Row className='learning-block'>
          <Col xl="9" className='xl-80'>
            <Row>
              <SubMateriBox />
            </Row>
          </Col>
          {cards && (
          <LearningFilter />
          )}
        </Row>
      </Container>
    </Fragment>
  );
};

export default SubMateriContain;
import React, { Fragment, useEffect, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Card, Col, Row, CardBody, CardHeader, CardFooter, Table, Label, FormGroup, Input } from 'reactstrap';
import { H1, H2, H3, H4, H5, H6, Image, Btn, LI, P, UL, Spinner } from '../../../AbstractElements';
import AttachModal from '../../../Component/Learning/Common/Modal/ModalButton';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../Features/Learning/Ticket/ticketSlice';
import {
    fetchTicket,
    handleBackPagination,
    handleNextPagination,
    handleClickPagination
} from './TicketUtils';
import { markAsSolved } from "../../../Features/Learning/Ticket/Utils/TicketUtils";
import PaginationComponent from '../Common/Pagination';
import Swal from "sweetalert2";
import Moment from "moment";

const TicketBox = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const filters = useSelector(state => state.Ticket.filters)
    const isLoading = useSelector(state => state.Ticket.isLoading)
    const cards = useSelector(state => state.Ticket.data.data)
    const pagination = useSelector(state => state.Ticket.data.pagination)
    const [refatch, setRefatch] = useState(false)
    const [modal, setModal] = useState(false);
    const [data, setData] = useState({
        id: '',
        feedback: ''
    });

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleToggle = (id) => {
        setModal(!modal);
        setData({ id: id, feedback: '' });
    };

    const handleSubmit = async (data) => {
        try {
            const result = await Swal.fire({
                title: 'Apakah anda yakin?',
                text: "Pastikan anda telah memperbaiki masalah soal yang dilaporkan!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ya, laporan soal telah terselesaikan!',
                cancelButtonColor: '#d33'
            });

            if (result.isConfirmed) {
                const response = await dispatch(markAsSolved(data));
                console.log(response);
                if (response.payload.message = 'success') {
                    setRefatch(prevRefetch => !prevRefetch);
                    Swal.fire(
                        'Terselesaikan!',
                        'Laporan soal telah diselesaikan.',
                        'success'
                    )
                    setModal(!modal);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Permintaan gagal. Mohon coba lagi.',
                    });
                    setModal(!modal);
                }
            }
        } catch (error) {
            console.error("Delete error:", error);
        }
    };

    useEffect(() => {
        fetchTicket(dispatch, filters, refatch)
        window.scrollTo(0, 0);
    }, [refatch])    

    return (
        <Fragment>
            {isLoading ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
                </Col>
            ) : (
                cards && cards.length > 0 && (
                    <Col sm="12 order-2">
                        <Card>
                            <CardBody>
                                <Table className="table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="bg-primary text-light">{'#'}</th>
                                            <th scope="col" className="bg-primary text-light">{'Laporan'}</th>
                                            <th scope="col" className="bg-primary text-light">{'Dibuat'}</th>
                                            <th scope="col" className="bg-primary text-light">{'Diupdate'}</th>
                                            <th scope="col" className="bg-primary text-light">{'Status'}</th> 
                                            <th scope="col" className="bg-primary text-light text-center">{'Aksi'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cards && cards.map((item, index) =>
                                                <tr key={`user-${index}`} className="table-light">
                                                    <th>{pagination.from + index}</th>
                                                    <td>{item.title}</td>
                                                    <td>
                                                        {Moment(item.created_at).format("D MMM")} -{" "}
                                                        {Moment(item.created_at).format("HH:mm")}
                                                    </td>
                                                    <td>
                                                        {item.created_at === item.updated_at ? '-' : <>
                                                            {Moment(item.updated_at).format("D MMM")} -{" "}
                                                            {Moment(item.updated_at).format("HH:mm")}
                                                        </>}
                                                    </td>
                                                    <td>{item.is_solved === 0 ? 'Belum Selesai' : 'Selesai'}</td>
                                                    <td className='text-center'>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Link to={`${process.env.PUBLIC_URL}/user-tickets/detail/${item.id}`} title="Detail Laporan" className="p-1">
                                                                <i className="fa fa-info-circle mx-auto fa-lg"></i>
                                                            </Link>
                                                            <Link to={`${process.env.PUBLIC_URL}/learning/soal-detail/${item.question.id}`} title="Detail Soal" className="p-1">
                                                                <i className="fa fa-question mx-auto fa-lg"></i>
                                                            </Link>
                                                            {item.is_solved === 0 && (
                                                                <div title="Perbarui status laporan">
                                                                    <AttachModal 
                                                                        icon={'fa fa-check-square-o mx-auto fa-lg'}
                                                                        colorBtn={'light'} 
                                                                        toggle={() => handleToggle(item.id)} 
                                                                        modalLabel={'Perbarui status laporan'}
                                                                        modal={modal} 
                                                                        handleSubmit={() => {
                                                                            handleSubmit(data);
                                                                        }} 
                                                                    >
                                                                        <FormGroup className='mb-3'>
                                                                            <Label for="validationFeedback">Umpan Balik Laporan :</Label>
                                                                            <Input className="form-control" id="validationFeedback" type="textarea" name="feedback" placeholder="Opsional..."
                                                                                value={data.feedback}
                                                                                onChange={handleChanges} 
                                                                            />
                                                                        </FormGroup>
                                                                    </AttachModal>
                                                                </div>
                                                            )}
                                                        </div >
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                            <CardFooter>
                                {pagination && (
                                    <PaginationComponent
                                        pagination={pagination}
                                        handleBackPagination={() => handleBackPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                        handleClickPagination={(page) => handleClickPagination(page, filters, dispatch, setFilters, setRefatch)}
                                        handleNextPagination={() => handleNextPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                    />
                                )}
                            </CardFooter>
                        </Card>
                    </Col>
                )
            )}
            
            {!cards || cards.length === 0 && (
                <Col lg="12">
                    <H3 attrH3={{ className: 'sub-title mb-5 text-center' }}>{!cards ? 'Sumber Data Tidak Tersedia' : 'Data Tidak Ditemukan'}</H3>
                </Col>
            )}

        </Fragment>
    );
};

export default TicketBox;
import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { H6, Spinner } from '../../../AbstractElements';
import QuestionDetail from './QuestionDetail';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetDetailQuestion } from '../../../Component/Learning/BankQuestion/QuestionsUtils';
import { setDetail } from '../../../Features/Learning/BankQuestions/QuestionSlice';

const DetailTryout = () => {
  const dispatch = useDispatch();
  const dataQuestionDetail = useSelector(state => state.Question.data.data);
  const isLoading = useSelector(state => state.Question.isLoading);
  
  // useEffect(() => {
  //   if (dataQuestionDetail && dataQuestionDetail.length > 0 ) {
  //     handleGetDetailQuestion(dispatch, dataQuestionDetail[0], setDetail)
  //   }
  // }, [dataQuestionDetail])
  return (
    <Fragment>
        <Container fluid={true}>
            <div>
              {isLoading ? (
                <Col lg="12">
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-30' }} />
                  </div>
                  <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
                </Col>
              ) : (
                <Row className="product-page-main p-0">
                    <QuestionDetail />
                </Row>
              )}
            </div>
        </Container>
    </Fragment>
  );
};

export default DetailTryout;
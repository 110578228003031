import { fetchCourses, deleteCourses } from '../../../Features/Learning/Courses/Utils/CoursesUtils'
import Swal from 'sweetalert2';

/* **************************************************** */
/* CRUD Courses                                       */
/* **************************************************** */

export const fetchCourse = (dispatch, filters, refetch) => {
    dispatch(fetchCourses(filters));
}

export const handleDeleteCourse = async (dispatch, id, setRefetch) => {
    try {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(deleteCourses(id));
                setRefetch(prevRefetch => !prevRefetch);
                Swal.fire(
                    'Terhapus!',
                    'Data telah dihapus.',
                    'success'
                )
            }
        })
    } catch (error) {
        console.error("Delete error:", error);
    }
}

export const handleEditCourse = async (dispatch, item, setField) => {
    dispatch(setField({ field: 'id', value: item.id }));
    dispatch(setField({ field: 'isUpdate', value: true }));
    const fieldsToUpdate = ['title', 'description'];
    fieldsToUpdate.forEach(field => {
        dispatch(setField({ field, value: item[field] }));
    });
    dispatch(setField({ field: 'description_videoLink', value: item.description_video }));
    dispatch(setField({ field: 'description_imageLink', value: item.description_image }));
    dispatch(setField({ field: 'bannerLink', value: item.banner }));
}

export const handleGetDetailCourse = async (dispatch, item, setDetail) => {
    dispatch(setDetail(item));
    // console.log(item);
}

export const handleDetach = async (detail, data, dispatch, attachDetachCourses, setDetail, selectedMaterials) => {
  console.log('detail detach', detail);
  console.log('data detach', data);
  console.log('selectedMaterials detach', selectedMaterials);
  
  const newDetail = { ...detail };
  try {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const success = await dispatch(attachDetachCourses(data))
        if (success.payload === true) {
          newDetail.materials = newDetail.materials.filter(
            (material) => !selectedMaterials.includes(material.id)
          );
          Swal.fire(
            'Terhapus!',
            'Materi telah dihapus dari course.',
            'success'
          )
          dispatch(setDetail(newDetail));
        } else if (success.payload === false) {
          Swal.fire(
            'Gagal!',
            'Materi gagal dihapus.',
            'error'
          )
        }
        
      }
    })
  } catch (error) {
    console.error("Delete error:", error);
  }
};

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page > 1) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page - 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page < pagination.last_page) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page + 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
  console.log(page);
  dispatch(setFilters({
    ...filters,
    page: page
  }));
  setRefetch(prevRefetch => !prevRefetch);
}
import { createSlice } from '@reduxjs/toolkit';
import { fetchSchedules } from './Utils/ScheduleUtils';

const ScheduleSlice = createSlice({
    name : 'Schedules',
    initialState : {
        filters: {
            "filter[id]": "",
            "filter[name]": "",
            "filter[class_id]": "",
            "filter[tentor_id]": "",
            "filter[place]": "",
            "sort": "",
            "page": "",
            "include": "tentor,classes,attendance,course,material,material.submaterials"
        },
        data: [],
        detail: {},
        isLoading: false,
    },
    reducers : {
        setFilters : (state, action) => {
            state.filters = action.payload;
        },
        setDetail : (state, action) => {
            state.detail = action.payload;
        },
    },
    extraReducers : {
        [fetchSchedules.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchSchedules.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        [fetchSchedules.rejected]: (state) => {
            state.isLoading = false;
        },
    }
});

export const { setFilters, setDetail } = ScheduleSlice.actions;

export default ScheduleSlice.reducer;
import React, { Fragment, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import FindReferralMutation from './FindReferralMutation';
import LearningContext from '../../../../../_helper/Leatning';

const HistoryReferralMutationFilter = () => {
    const { isOpen, setIsOpen } = useContext(LearningContext);
    const onClickeHandle = () => {
        setIsOpen(!isOpen);
    };
    return (
        <Fragment>
            <Col xl="3 xl-20">
                <div className="job-sidebar md-sidebar"><a className="btn btn-primary md-sidebar-toggle" href="#javascript" onClick={onClickeHandle}>Filter History</a>
                    <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen ? 'open' : ''}`}>
                        <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                            <Row>
                                <FindReferralMutation />
                            </Row>
                        </div>
                    </div>
                </div>
            </Col>
        </Fragment>
    );
};

export default HistoryReferralMutationFilter;
import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Label, Media, Row } from 'reactstrap';
import { Btn, H3, H4, H6, Image, P } from '../../../../AbstractElements';
import { MyProfile, Bio, Password, Website, Save, EmailAddress } from '../../../../Constant';
import user from '../../../../assets/images/user/7.jpg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import 'moment/locale/id';
import { handleLoginAs } from '../UsersUtils';

const UserProfile = () => {
    const userDetail = useSelector(state => state.User.detail);
    const [detail, setDetail] = useState({});
    useEffect(() => {
        setDetail(userDetail);
    }, [userDetail]);
    const validImageExtensions = [".jpg", ".jpeg", ".png"];
    const isValidImage = (url) => {
        const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
        return validImageExtensions.includes(extension);
    }

    return (
        <Fragment>
            <Card>
                <CardHeader className="pb-0">
                    {/* <H4 attrH4={{ className: 'card-title mb-0' }}>{MyProfile}</H4> */}
                    <div className="card-options">
                        <a className="card-options-collapse" href="#javascript">
                            <i className="fe fe-chevron-up"></i>
                        </a>
                        <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                        </a>
                    </div>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row className="mb-2">
                            <div className="profile-title">
                                <Media>
                                    <Image attrImage={{ className: 'img-70 rounded-circle', alt: '', src: detail.photo_profile ? !isValidImage(detail.photo_profile) ? user : detail.photo_profile : user }} />
                                    <Media body>
                                        <H3 attrH3={{ className: 'mb-1 f-20 txt-primary' }}>
                                            {detail.name || 'User Name'}
                                        </H3>
                                    </Media>
                                </Media>
                            </div>
                        </Row>
                        <FormGroup className="mb-3">
                            <Label className="form-label"><b>Jenis Kelamin</b></Label>
                            <Input readOnly className="form-control" value={detail.gender == 'Male' ? 'Pria' : detail.gender == 'Female' ? 'Wanita' : '-'} />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label className="form-label"><b>Tanggal Lahir</b></Label>
                            <Input readOnly className="form-control" defaultValue={detail.birthday} />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label className="form-label"><b>No. HP</b></Label>
                            <Input readOnly className="form-control" defaultValue={detail.phone} />
                        </FormGroup>
                        {detail.parent_user && (
                        <>
                            <FormGroup className="mb-3">
                                <Label className="form-label"><b>Nama Wali</b></Label>
                                <Input readOnly className="form-control" defaultValue={detail.parent_user ? detail.parent_user.name : '-'} />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Label className="form-label"><b>No. HP Wali</b></Label>
                                <Input readOnly className="form-control" defaultValue={detail.parent_user ? detail.parent_user.phone : '-'} />
                            </FormGroup>
                        </>
                        )}
                        <FormGroup className="mb-0">
                            <Label><b>Dibuat pada :</b></Label>
                            <Label>{Moment(detail.created_at).format('dddd, D MMMM YYYY')} - {Moment(detail.created_at).format('HH:mm:ss')} WIB</Label>
                        </FormGroup>
                    </Form>
                </CardBody>
                <CardFooter className="d-flex justify-content-between">
                    <Btn
                        attrBtn={{
                            title: 'Login As',
                            color: 'link',
                            outline: true,
                            className: 'btn btn-primary m-r-10',
                            onClick: () => handleLoginAs(detail.id, detail.name)
                        }}
                    >
                        Login As <i className="fa fa-sign-in mx-auto fa-lg"></i>
                    </Btn>
                    <Link to={`${process.env.PUBLIC_URL}/users/user-list`}>
                        <Btn attrBtn={{ color: 'warning', className: 'btn btn-primary m-r-10' }}>
                            <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                        </Btn>
                    </Link>
                </CardFooter>
            </Card>
        </Fragment>
    );
};
export default UserProfile;
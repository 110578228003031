import React, { Fragment } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Btn } from "../../AbstractElements";
import BlogBox from "./BlogBox";
import { useDispatch, useSelector } from "react-redux";
import BlogFilter from "./Filter/BlogFilter";
import { resetState } from "../../Features/Learning/Blog/addBlogSlice";

const BlogContain = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const cards = useSelector((state) => state.Blog.data.data);

	const handleAdd = (e) => {
		dispatch(resetState());
	};

	return (
		<Fragment>
			<Container fluid={true}>
				<Col md="12" className="project-list">
					{cards && (
						<Card>
							<Row>
								<Col>
									<Link
										to={`${process.env.PUBLIC_URL}/blog-add`}
										onClick={handleAdd}>
										<Btn attrBtn={{ color: "primary" }}>
											<i className="fa fa-plus-circle fa-lg"></i> Buat Blog Baru
										</Btn>
									</Link>
								</Col>
							</Row>
						</Card>
					)}
				</Col>
				<Row className="learning-block">
					<Col xl="9" className="xl-80">
						<Row>
							<BlogBox />
						</Row>
					</Col>
					{cards && <BlogFilter />}
				</Row>
			</Container>
		</Fragment>
	);
};
export default BlogContain;

import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import { PlusCircle } from 'react-feather';
import { Btn } from '../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ClassListTable from '../../../Component/Classes/Class/ClassListTable';
import { resetState } from '../../../Features/Learning/Vouchers/addVoucherSlice';
import ClassFilter from '../../../Component/Classes/Class/Filter';


const ClassContain = () => {
    const cards = [useSelector(state => state.Class.data.data)]
    const dispatch = useDispatch();
    const handleAdd = (e) => {
        dispatch(resetState());
    };

    return (
        <Fragment>
            <Container fluid={true}>
                <Col md="12" className="project-list">
                    <Card>
                        <Row>
                            <Col>
                                <Link to={`${process.env.PUBLIC_URL}/classes/class-add`} onClick={handleAdd}>
                                    <Btn attrBtn={{ color: 'primary' }}>
                                        <i className="fa fa-plus-circle fa-lg"></i> Tambah Kelas Baru
                                    </Btn>
                                </Link>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Row className='learning-block'>
                    <Col xl="9" className='xl-80'>
                        <Row>
                            <ClassListTable />
                        </Row>
                    </Col>
                    {cards && cards.length > 0 && (
                        <ClassFilter />
                    )}
                </Row>
            </Container>
        </Fragment>
    );
};
export default ClassContain;
import { createSlice } from '@reduxjs/toolkit';
import { fetchDetailMemberClass } from './Utils/ClassUtils';

const detailMemberClassSlice = createSlice({
    name : 'DetailMemberClass',
    initialState : {
        data: [],
        isLoading: false,
    },
    reducers : {
        resetDetailMemberClass : (state) => {
            state.data = [];
        },
    },
    extraReducers : {
        [fetchDetailMemberClass.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchDetailMemberClass.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        [fetchDetailMemberClass.rejected]: (state) => {
            state.isLoading = false;
        },
    }
});

export const { resetDetailMemberClass } = detailMemberClassSlice.actions;

export default detailMemberClassSlice.reducer;
import { fetchMaterial, deleteMaterial } from '../../../Features/Learning/Materi/Utils/MateriUtils';
import Swal from 'sweetalert2';


/* **************************************************** */
/* CRUD Materi                                       */
/* **************************************************** */

export const fetchMateri = (dispatch, filters, refetch) => {
  dispatch(fetchMaterial(filters));
};

export const handleDeleteMateri = async (dispatch, id, setRefetch) => {
  try {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteMaterial(id));
        setRefetch(prevRefetch => !prevRefetch);
        Swal.fire(
          'Terhapus!',
          'Data telah dihapus.',
          'success'
        )
      }
    })
  } catch (error) {
    console.error("Delete error:", error);
  }
};

export const handleEditMateri = async (dispatch, item, setField) => {
  dispatch(setField({ field: 'id', value: item.id }));
  dispatch(setField({ field: 'isUpdate', value: true }));
  const fieldsToUpdate = ['name', 'description'];
  fieldsToUpdate.forEach(field => {
    dispatch(setField({ field, value: item[field] }));
  });
  const selectedSubMaterials = item.submaterials
      ? item.submaterials.map(subMaterial => ({
        value: subMaterial.id,
        label: subMaterial.name,
      }))
      : [];
  dispatch(setField({ field: 'subMaterials', value: selectedSubMaterials }));
  dispatch(setField({ field: 'imageLink', value: item.image }));
};

export const handleGetDetailMaterial = async (dispatch, item, setDetail) => {
  dispatch(setDetail(item));
};

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page > 1) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page - 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page < pagination.last_page) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page + 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
  console.log(page);
  dispatch(setFilters({
    ...filters,
    page: page
  }));
  setRefetch(prevRefetch => !prevRefetch);
}
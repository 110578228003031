import React from 'react';

function CurrencyFormatter({ amount }) {
  const formattedAmount = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(amount);

  return <span>{formattedAmount}</span>;
}

export default CurrencyFormatter;

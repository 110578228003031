import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row, CardBody} from 'reactstrap';
import { H1, H2, H3, H4, H5, H6, Image, Btn, LI, P, UL, Spinner } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDetail, setFilters } from '../../../Features/Learning/Materi/materialSlice';
import { setField } from '../../../Features/Learning/Materi/addMaterialSlice';
import { 
  fetchMateri, 
  handleDeleteMateri,
  handleEditMateri,
  handleGetDetailMaterial,
  handleBackPagination,
  handleNextPagination,
  handleClickPagination,
} from './MateriUtils';
import PaginationComponent from '../Common/Pagination';
import Moment from 'moment';
import { fetchSubMaterials } from '../../../Component/Learning/SubMateri/SubMateriUtils';


const SubMateriBox = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const filters = useSelector(state => state.Material.filters)
  const filtersSubMateri = useSelector(state => state.subMaterial.filters)
  const cards = useSelector(state => state.Material.data.data)
  const isLoading = useSelector(state => state.Material.isLoading)
  const pagination = useSelector(state => state.Material.data.pagination)
  const [refatch, setRefatch] = useState(false)

  useEffect(() => {
    fetchMateri(dispatch, filters, refatch)
    window.scrollTo(0, 0);
  }, [refatch])

  return (
    <Fragment>
      {isLoading ? (
        <Col lg="12">
          <div className="loader-box">
            <Spinner attrSpinner={{ className: 'loader-30' }} />
          </div>
          <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
        </Col>
      ) : (
        cards && cards.length > 0 ? (
        cards && cards.map((item) => {
          return (
            <Col xl="4" sm="4" lg="4" key={`tryout-${item.id}`} className='box-col-4'>
              <Card>
                <div className="product-box learning-box">
                  <div className="product-img">
                    <Image attrImage={{ className: 'img-fluid top-radius-blog', src: item.image, alt: '' }} />
                    <div className="product-hover">
                      <UL attrUL={{ className: 'simple-list' }}>
                        <li title="delete" onClick={() => handleDeleteMateri(dispatch, item.id, setRefatch)}>
                          <i className="icofont icofont-trash text-danger"></i>
                        </li>
                        <Link to={`${process.env.PUBLIC_URL}/learning/materi-add/${item.id}`} onClick={() => handleEditMateri(dispatch, item, setField)}>
                          <li title="edit" style={{ marginBottom: '10px', marginTop: '10px' }}>
                            <i className="icofont icofont-edit"></i>
                          </li>
                        </Link>
                        <Link to={`${process.env.PUBLIC_URL}/learning/materi-detail/${item.id}`} onClick={() => handleGetDetailMaterial(dispatch, item, setDetail)}>
                          <li title="detail">
                            <i className="icofont icofont-info-circle"></i>
                          </li>
                        </Link>
                      </UL>
                    </div>
                  </div>
                  <div className="details-main">
                    <Link to={`${process.env.PUBLIC_URL}/learning/materi-detail/${item.id}`}
                      onClick={() => handleGetDetailMaterial(dispatch, item, setDetail)}
                    >
                      <div className='bottom-details'>
                        <H6>{item.name}</H6>
                      </div>
                    </Link>
                    <P>Created at : {Moment(item.created_at).format('ddd, DD/MM/YYYY')} - {Moment(item.created_at).format('HH:mm:ss')} WIB</P>
                  </div>
                </div>
              </Card>
            </Col>
          );
        })
        ) : (
          <Col lg="12">
            <H3 attrH3={{ className: 'sub-title mb-5 text-center' }}>{!cards ? 'Sumber Data Tidak Tersedia' : 'Data Tidak Ditemukan'}</H3>
          </Col>
        )
      )}
      
      {isLoading === false && cards && cards.length > 0 && pagination && (
        <CardBody>
            <PaginationComponent 
            pagination={pagination}
            handleBackPagination={() => handleBackPagination(pagination, filters, dispatch, setFilters, setRefatch)}
            handleClickPagination={(page) => handleClickPagination(page, filters, dispatch, setFilters, setRefatch)}
            handleNextPagination={() => handleNextPagination(pagination, filters, dispatch, setFilters, setRefatch)}
            />
          </CardBody>
        )}
    </Fragment>
  );
};

export default SubMateriBox;
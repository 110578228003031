import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs } from '../../../AbstractElements';
import DetailScheduleClass from '../../../Component/Classes/Class/DetailScheduleClass';
import { handleGetDetailClass } from '../../../Component/Classes/Class/ClassUtils';
import { setDetail } from '../../../Features/Classes/Class/ClassSlice';
import { fetchClasses } from '../../../Features/Classes/Class/Utils/ClassUtils';


const DetailScheduleClassPage = () => {
    const { classId } = useParams();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.Class.filters);
    const dataField = useSelector(state => state.Class.detail);

    useEffect(() => {
        if (classId && !dataField.id) {
            const newFilters = {
                ...filters,
                "filter[id]": classId,
                "page": 1,
            };
            dispatch(fetchClasses(newFilters))
                .then((response) => {
                    // Kode yang akan dijalankan setelah fetch sukses
                    handleGetDetailClass(dispatch, response.payload.data[0], setDetail)
                    // Kode lain yang perlu dijalankan setelah fetch sukses
                })
                .catch((error) => {
                    // Tangani error jika fetch gagal
                    console.error('Error during fetchClasses:', error);
                });
        }
    } , [classId]);

    return (
        <Fragment>
            <Breadcrumbs parent="Kelas" title="Detail Sesi Kelas" />
            <DetailScheduleClass />
        </Fragment>
    );
};
export default DetailScheduleClassPage;
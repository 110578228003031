import React from "react";
import {
	CardBody,
	Table,
	Button,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";
import { Search } from "react-feather";
import Swal from "sweetalert2";
import {
	attachDetachTryoutMemberships,
	fetchMemberships,
} from "../../../../Features/Learning/Memberships/Utils/MembershipUtils";
import { setDetail as setDetailMembership } from "../../../../Features/Learning/Memberships/membershipSlice";
import {
	fetchTryouts,
	handleBackPagination,
	handleNextPagination,
	handleClickPagination,
} from "../../../../Component/Learning/Tryout/TryoutUtils";
import { setFilters } from "../../../../Features/Learning/Tryout/tryoutSlice";

import PaginationComponent from "../../Common/Pagination";
import { useSelector, useDispatch } from "react-redux";

const AttachTryout = ({ items, membershipId }) => {
	const dispatch = useDispatch();
	const filters = useSelector((state) => state.Tryout.filters);
	const filter = useSelector((state) => state.Membership.filters);
	const [modal, setModal] = React.useState(false);
	const [selectedItems, setSelectedItems] = React.useState([]);
	const [selectedIds, setSelectedIds] = React.useState([]);
	const tryoutSearchResult = useSelector((state) => state.Tryout.data.data);
	const isLoading = useSelector((state) => state.Tryout.isLoading);
	const pagination = useSelector((state) => state.Tryout.data.pagination);
	const [refatch, setRefatch] = React.useState(false);

	React.useEffect(() => {
		fetchTryouts(dispatch, filters);
	}, [refatch]);

	const toggle = () => setModal(!modal);

	const handleCheckboxChange = (item) => {
		if (selectedIds.includes(item.id)) {
			const index = selectedIds.indexOf(item.id);
			if (index > -1) {
				selectedItems.splice(index, 1);
				selectedIds.splice(index, 1);
			}
			setSelectedItems([...selectedItems]);
			setSelectedIds([...selectedIds]);
		} else {
			setSelectedItems([...selectedItems, item]);
			setSelectedIds([...selectedIds, item.id]);
		}
	};

	const handleClickSearch = () => {
		setRefatch(!refatch);
	};

	const handleSubmit = async () => {
		const newItems = items.filter(
			(item) =>
				!selectedItems.some((selectedItem) => selectedItem.id === item.id)
		);
		const tryouts = newItems.concat(selectedItems);
		const newTryouts = tryouts
			.filter(
				(item, index, self) => self.findIndex((t) => t.id === item.id) === index
			)
			.map((item) => {
				return {
					value: item.id,
				};
			});
		const data = {
			tryouts: newTryouts,
			id: membershipId,
		};
		try {
			const response = await dispatch(attachDetachTryoutMemberships(data));

			if (response.payload === true) {
				toggle();
				const getMembership = await dispatch(
					fetchMemberships({
						...filter,
						"filter[id]": membershipId,
						sort: "",
						page: "",
					})
				);

				dispatch(setDetailMembership(getMembership.payload.data[0]));
				Swal.fire("Berhasil!", "Tryout Berhasil Ditambahkan", "success");
				setSelectedItems([]);
				setSelectedIds([]);
			}
		} catch (error) {
			console.error("Error:", error);
			// Modal tidak akan ditutup jika terjadi kesalahan
		}
	};

	return (
		<>
			<Button color="primary" onClick={toggle}>
				Tambah Tryout (new)
			</Button>{" "}
			<Modal isOpen={modal}>
				<ModalHeader onClick={toggle}>
					Tambahkan Tryout ke Membership
				</ModalHeader>
				<ModalBody>
					<ul>
						{selectedItems.length > 0 &&
							selectedItems.map((item, index) => (
								<li key={`item-${index}`}>
									{item.id} - {item.title}
								</li>
							))}
					</ul>
					<div className="faq-form mt-3">
						<Input
							className="form-control"
							type="text"
							placeholder="Search.."
							value={filters["filter[title]"]}
							onKeyUp={(e) => (e.key === "Enter" ? handleClickSearch(e) : null)}
							onChange={(e) => {
								dispatch(
									setFilters({
										...filters,
										"filter[title]": e.target.value,
									})
								);
							}}
						/>
						<Search className="search-icon" />
					</div>
					<Table className="table-responsive table-bordered my-3">
						<thead className="table-light">
							<tr>
								<th scope="col">{"#"}</th>
								<th scope="col">{"ID"}</th>
								<th scope="col">Nama Try out</th>
								<th scope="col" className="text-center" colSpan="2">
									{"Aksi"}
								</th>
							</tr>
						</thead>
						<tbody>
							{tryoutSearchResult &&
								tryoutSearchResult.map((item, index) => (
									<tr key={`item-${index}`}>
										<th>{index + 1}</th>
										<th>{item.id}</th>
										<td>{item.title}</td>

										<td className="text-center">
											<Input
												className="checkbox_animated"
												id={`detach-${item.id}`}
												type="checkbox"
												checked={selectedIds.includes(item.id)}
												onChange={() => handleCheckboxChange(item)}
											/>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
					<CardBody>
						{isLoading === false && pagination && (
							<PaginationComponent
								pagination={pagination}
								handleBackPagination={() =>
									handleBackPagination(
										pagination,
										filters,
										dispatch,
										setFilters,
										setRefatch
									)
								}
								handleClickPagination={(page) =>
									handleClickPagination(
										page,
										filters,
										dispatch,
										setFilters,
										setRefatch
									)
								}
								handleNextPagination={() =>
									handleNextPagination(
										pagination,
										filters,
										dispatch,
										setFilters,
										setRefatch
									)
								}
							/>
						)}
					</CardBody>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
					<Button color="primary" onClick={handleSubmit}>
						Simpan
					</Button>{" "}
				</ModalFooter>
			</Modal>
		</>
	);
};

export default AttachTryout;

import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Form, FormGroup, Label, Input } from 'reactstrap';
import { Post, Discard } from '../../../Constant';
import { Btn, H5, H6, Image, Spinner } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Trash2 } from 'react-feather';
import RichEditor from '../../../Component/Editor/RichEditor/RichEditor';
import PostCommon from '../../../Component/Learning/Common/Form/Post';
import DropzoneCommon from '../../../Component/Learning/Common/Upload/Dropzone';
import AsyncSelectCommon from '../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { submitCourses } from '../../../Features/Learning/Courses/Utils/CoursesUtils';
import { setField, resetState } from '../../../Features/Learning/Courses/addCourseSlice';
import VideoPlayer from '../../../Component/Learning/DetailSubMateri/VideoPlayer';
import Swal from 'sweetalert2';

const CoursePostContain = (props) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const data = useSelector(state => state.addCourse)
    const startUpload = useSelector(state => state.addCourse.isLoading);

    const handleSuccess = () => {
        dispatch(resetState());
        history(`${process.env.PUBLIC_URL}/membership/course-list`);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(data, 'data');
        let errorMessage = '';
        //for checking empty description or only contains <p></p> and space only
        const isEmptyDescription = /^<p>\s*<\/p>$/.test(data.description);
        
        if (data.title === '') {
            errorMessage = 'Nama Course belum diisi.';
        } else if (data.description === '' || data.description === '<p><br></p>' || isEmptyDescription) {
            errorMessage = 'Deskripsi Course belum diisi.';
        } else if (data.image === '' || data.image === null) {
            errorMessage = 'Banner Course tidak boleh kosong.';
        } 

        if (data.title === '' || data.description === '' || data.description === '<p><br></p>' || isEmptyDescription || (data.image === '' || data.image === null) && !data.isUpdate) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errorMessage,
            });
        } else {
            dispatch(submitCourses({ ...data, successCallback: handleSuccess }))
        }
    };
    const handleDiscard = (e) => {
        e.preventDefault();
        dispatch(resetState());
        history(`${process.env.PUBLIC_URL}/membership/course-list`);
    };
    const handleChanges = (e) => {
        const { name, value } = e.target;
        dispatch(setField({ field: name, value: value }));
    }
    const handleChangeDescription = (e) => {
        dispatch(setField({ field: 'description', value: e }));
    }

    const handleChangeStatus = ({ meta, file }, status) => {
        if (status === 'done') {
            dispatch(setField({ field: 'image', value: file }));
        } else if (status === 'removed') {
            dispatch(setField({ field: 'image', value: null }));
        }
    };

    const handleDescriptionImage = ({ meta, file }, status) => {
        if (status === 'done') {
            dispatch(setField({ field: 'description_image', value: file }));
        } else if (status === 'removed') {
            dispatch(setField({ field: 'description_image', value: null }));
        }
    }

    const handleDescriptionVideo = ({ meta, file }, status) => {
        if (status === 'done') {
            dispatch(setField({ field: 'description_video', value: file }));
        } else if (status === 'removed') {
            dispatch(setField({ field: 'description_video', value: null }));
        }
    }

    const handleDeleteFile = (e, type) => {
        e.preventDefault();
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda tidak dapat mengembalikan ${type === "description_image" ? 'Banner iklan' : 'Video iklan'} yang telah dihapus!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(setField({ field: type, value: 'delete' }));
                if (type === "description_image") {
                    dispatch(setField({ field: 'description_imageLink', value: '' }));
                } else if (type === "description_video") {
                    dispatch(setField({ field: 'description_videoLink', value: '' }));
                }
            }
        })
    }

    return (
        <PostCommon title={props.label} handleSubmit={handleSubmit} handleDiscard={handleDiscard} >
            {startUpload ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses unggah...'}</H6>
                </Col>
            ) : (
            <div>
                <Col sm="12">
                    <FormGroup className='mb-3'>
                        <Label for="validationName" style={{ fontWeight: 'bold' }}>{'Judul Course'}:</Label>
                        <Input className="form-control" id="validationName" type="text" name="title" placeholder="Masukkan nama course..." required=""
                            value={data.title}
                            onChange={handleChanges} />
                        <div className="valid-feedback">{'Looks good!'}</div>
                    </FormGroup>
                    <FormGroup className='mb-3'>
                        <Label for="validationDescription" style={{ fontWeight: 'bold' }}>{'Description'}:</Label>
                        <RichEditor id="validationDescription" handleChangeDescription={handleChangeDescription} description={data.description} />
                    </FormGroup>
                    {data.bannerLink && (
                        <FormGroup className='mb-3'>
                            <Label for="validationImage" style={{ fontWeight: 'bold' }}>Preview Banner Course:</Label>
                            <div className="blog-page blog-box mb-3">
                                <img style={{ width: "30%" }} src={data.bannerLink} alt={data.title} />
                            </div>
                        </FormGroup>
                    )}
                </Col>
                <DropzoneCommon label={'Banner Course'} inputContent={data.image ? 'banner course tersedia' : 'Silahkan upload banner course'} handleChangeStatus={handleChangeStatus} accept="image/*" />
                {data.description_imageLink && (
                    <FormGroup className='mb-3'>
                        <Label for="validationImage" style={{ fontWeight: 'bold', marginBottom: '15px', marginRight: '10px' }}>
                            Preview Banner Iklan :
                        </Label>
                        <Btn attrBtn={{ className: 'btn btn-danger', onClick: (e) => handleDeleteFile(e, "description_image") }}>
                            <i className="fa fa-trash fa-lg"></i>
                        </Btn>
                        <div>
                            <img style={{ width: "30%" }} src={data.description_imageLink} alt={data.title} />
                        </div>
                    </FormGroup>
                )}
                <DropzoneCommon label={'Banner Iklan'} inputContent={data.description_imageLink ? 'banner iklan tersedia' : 'Silahkan upload banner iklan'} handleChangeStatus={handleDescriptionImage} accept="image/*" />
                {(data.description_videoLink) && (
                    <FormGroup className='mb-3'>
                        <Label for="validationImage" style={{ fontWeight: 'bold', marginBottom: '15px', marginRight: '10px' }}>
                                Preview Video Iklan : 
                        </Label>
                        <Btn attrBtn={{ className: 'btn btn-danger', onClick: (e) => handleDeleteFile(e, "description_video") }}>
                            <i className="fa fa-trash fa-lg"></i>
                        </Btn>
                        <VideoPlayer link={data.description_videoLink} width={720} />
                    </FormGroup>
                )}  
                <DropzoneCommon label={'Video Iklan'} inputContent={data.description_videoLink ? 'video iklan tersedia' : 'Silahkan upload video iklan'} handleChangeStatus={handleDescriptionVideo} accept=".mp4" />
            </div>
            )}
        </PostCommon>
    );
};

export default CoursePostContain;
import React, { Fragment } from 'react';
import { Col, Card } from 'reactstrap';
import SubMateriDetail from './SubMateriDetail';

const LearningDeatil = () => {
  return (
    <Fragment>
      <Col>
        <div className="blog-single">
          <SubMateriDetail />
        </div>
      </Col>
    </Fragment>
  );
};

export default LearningDeatil;
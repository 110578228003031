import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClassServices } from '../../../../Services/axios/Class.Service';
import { checkFilter } from '../../../../Features/Common/CommonUtils';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

export const fetchClasses = createAsyncThunk(
    'Classes/fetchClasses',
    async (filters) => {
        try {
            let filter = checkFilter(filters);
            const response = await ClassServices.getAll(filter);
            return response.data;
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data. Mohon periksa koneksi internet Anda.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server.';
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                });

            }
            else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
        }
    }
);

export const submitClasses = createAsyncThunk(
    'Classes/submitClasses',
    async (data) => {
        try {
            const formData = new FormData();
            formData.append('name', data.name);
            formData.append('description', data.description);
            data.banner && formData.append('banner', data.banner);

            let response;
            if (data.id) {
                response = await ClassServices.update(data.id, formData);
            } else {
                response = await ClassServices.create(formData);
            }
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Kelas berhasil' + (data.id ? ' diubah' : ' dibuat'),
            });
            data.successCallback && data.successCallback();
            return response.data.data;
        } catch (error) {
            for (const errorField in error.response.data.errors) {
                if (error.response.data.errors.hasOwnProperty(errorField)) {
                    const errorMessages = error.response.data.errors[errorField];
                    let errorMessage = errorMessages[0];
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
            if (error.code === "ERR_NETWORK") {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else if (error.response.status === 500) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan sistem. Mohon hubungi pengembang.',
                });
            }
        }
    }
);

export const deleteClasses = createAsyncThunk(
    'Classes/deleteClasses',
    async (id) => {
        try {
            const response = await ClassServices.delete(id);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
            });
            return id;
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }
);

export const attachDetachUserToClass = createAsyncThunk(
    'Class/attachDetachUserToClass',
    async (data) => {
        try {
            const formData = new FormData();
            formData.append('membershipId', data.membershipId);
            data.userId.forEach((user, index) => {
                formData.append(`userId[${index}]`, user);
            });
            let response;
            response = await ClassServices.attachDetachUserToClass(data.class_id, formData);
            return true;
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            return false;
        }
    }
);

export const fetchDetailMemberClass = createAsyncThunk(
    'Class/fetchDetailMemberClass',
    async (id) => {
        try {
            const response = await ClassServices.getDetailMemberClass(id);
            return response.data;
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data. Mohon periksa koneksi internet Anda.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server.';
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                });

            }
            else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
        }
    }
);
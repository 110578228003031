import { axiosInstance } from "./axiosInstance";


export const CategoriesService = {
    getAll: async (filter) => {
        return await axiosInstance.get('/api/feature/category', { params: filter });
    },
    create: async (data) => {
        return await axiosInstance.post('/api/feature/category', data);
    },
    update: async (id, data) => {
        return await axiosInstance.post(`/api/feature/category/update/${id}`, data);
    },
    delete: async (id) => {
        return await axiosInstance.delete(`/api/feature/category/delete/${id}`);
    },
    getMainProgram: async () => {
        return await axiosInstance.get(`/api/feature/category/get-main-program-category`);
    },
}
import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import QuestionContain from '../../../Component/Learning/BankQuestion/QuestionsPost';
import { useSelector, useDispatch } from 'react-redux';

const QuestionPost = () => {
    const isUpdate = useSelector(state => state.addQuestion.isUpdate);

    return (
        <Fragment>
            <Breadcrumbs grandparent="Learning" parent="Bank Soal" title={isUpdate ? 'Update Soal' : 'Tambah Soal'} />
            <QuestionContain />
        </Fragment>
    );
};

export default QuestionPost;
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, CardHeader, CardFooter, Media, Table, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Image, H6, H5, H4, LI, UL, P, Btn, Spinner, Badges } from '../../../../AbstractElements';
import Moment from 'moment';
import 'moment/locale/id';
import Swal from 'sweetalert2';
import RawHTMLComponent from '../../../../Component/Learning/Common/RawHTML';
import avatarDummy from '../../../../assets/images/avtar/3.jpg';


const DetailScheduleComponent = (props) => {
    const { classId } = useParams();
    const dispatch = useDispatch();
    const detail = useSelector(state => state.Schedule.detail);
    const data = useSelector(state => state.attachDetachUserToClass);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
                <Card className="social-widget-card">
                    <CardHeader>
                        <Col>
                            <Row>
                                <Col>
                                    <h5>{'Sesi ' + detail.name || 'Nama Sesi'}</h5>
                                </Col>
                            </Row>
                        </Col>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm="2" xl="2" lg="2">
                                <img src={detail.tentor && detail.tentor.photo ? detail.tentor.photo : avatarDummy} alt="banner" className="img-fluid rounded" style={{ width: '150px', height: 'auto' }} />
                            </Col>
                            <Col sm="10" xl="10" lg="10">
                                <FormGroup className="mb-3">
                                    <Label for="validationName" style={{ fontWeight: 'bold' }}>Tentor :
                                        <Link to={`${process.env.PUBLIC_URL}/classes/tentor/detail/${detail.tentor_id}`}><i className="fa fa-info-circle fa-lg" title="Detail Tentor" style={{ marginLeft: '5px' }}></i></Link>
                                    </Label>
                                    <Input readOnly className="form-control" defaultValue={detail.tentor ? detail.tentor.name : 'Tentor'} />
                                </FormGroup>
                                <Row>
                                    <Col>
                                        <FormGroup className="mb-3">
                                            <Label className="form-label"><b>Ruang :</b></Label>
                                            <Input readOnly className="form-control" defaultValue={detail.place} />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="mb-3">
                                            <Label className="form-label"><b>Jumlah Siswa :</b></Label>
                                            <h5><Badges attrBadge={{ color: 'primary' }} > {detail.attendance && detail.attendance.length || 0} </Badges></h5>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label className="form-label"><b>Waktu Sesi Mulai :</b></Label>
                                    <Input readOnly className="form-control" defaultValue={Moment(detail.session_start_at).format('dddd, D MMMM YYYY - HH:mm:ss')} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label className="form-label"><b>Waktu Sesi Berakhir :</b></Label>
                                    <Input readOnly className="form-control" defaultValue={Moment(detail.session_end_at).format('dddd, D MMMM YYYY - HH:mm:ss')} />
                                </FormGroup>
                            </Col>
                            <Col> 
                                <FormGroup className="mb-3">
                                    <Label className="form-label"><b>Waktu Scan QR :</b></Label>
                                    <Input readOnly className="form-control" defaultValue={Moment(detail.QR_start_at).format('dddd, D MMMM YYYY - HH:mm:ss')} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup className="mb-3">
                            <Label className="form-label"><b>Materi Prasesi :</b></Label>
                            {detail.material && detail.material.id ?
                                <Link to={`${process.env.PUBLIC_URL}/learning/materi-detail/${detail.material.id}`} title="detail materi" style={{ marginLeft: '5px' }}>
                                    <h6><Badges attrBadge={{ color: 'primary' }} >{detail.material.name}</Badges></h6>
                                </Link>
                                : <h6><Badges attrBadge={{ color: 'primary' }} >Tidak ada</Badges></h6>
                            }
                            {detail.material && detail.material.description && (
                                <div className="mt-1">
                                    <RawHTMLComponent htmlContent={detail.material.description} />
                                </div>
                            )}
                        </FormGroup>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col>
                                <P attrP={{ className: 'font-roboto' }}>Created at: {Moment(detail.created_at).format('dddd, D MMMM YYYY')} - {Moment(detail.created_at).format('HH:mm:ss')} WIB</P>
                                {/* <P attrP={{ className: 'font-roboto' }}><b>Updated at: {Moment(detail.updated_at).format('dddd, D MMMM YYYY')} - {Moment(detail.updated_at).format('HH:mm:ss')} WIB</b></P> */}
                            </Col>
                            <Col className='text-end'>
                                <Link to={`${process.env.PUBLIC_URL}/classes/class-schedule-detail/${classId}`}>
                                    <Btn attrBtn={{ color: 'warning', className: 'btn btn-primary m-r-10' }}>
                                        <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                                    </Btn>
                                </Link>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Col>
        </Fragment >
    );
};
export default DetailScheduleComponent;
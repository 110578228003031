import { axiosInstance } from './axiosInstance';

export const VoucherServices = {
    getAll: async (filter) => {
        return await axiosInstance.get('/api/feature/voucher', { params: filter });
    },
    create: async (data) => {
        return await axiosInstance.post('/api/feature/voucher', data);
    },
    update: async (id, data) => {
        return await axiosInstance.post(`/api/feature/voucher/update/${id}`, data);
    },
    delete: async (id) => {
        return await axiosInstance.delete(`/api/feature/voucher/delete/${id}`);
    },
}
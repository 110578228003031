import React, { Fragment, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import FindFilter from '../../../../Component/Learning/Common/Filter/FindFilter';
import LearningContext from '../../../../_helper/Leatning';

const Filter = (props) => {
  const { isOpen, setIsOpen } = useContext(LearningContext);
  const onClickeHandel = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Fragment>
      <Col xl={props.xlClass}>
        <div className="job-sidebar md-sidebar"><a className="btn btn-primary md-sidebar-toggle" href="#javascript" onClick={onClickeHandel}>{props.label}</a>
          <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen ? 'open' : ''}`}>
            <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
              <Row>
                <FindFilter search={props.search} label={props.label} labelButton={props.labelButton} labelReset={props.labelReset} handleClickFilter={props.handleClickFilter} handleResetFilter={props.handleResetFilter}>
                  {props.children}
                </FindFilter>
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default Filter;
import { createSlice } from '@reduxjs/toolkit';
import { attachTryout } from './Utils/TryoutUtils';

const initialState = {
    attachments: {
        'question_id': '',
        'category_id': '',
        'order': '',
        'score': []
    },
    type_question: '',
    id: '',
    isLoading: false,
};

const attachTryoutSlice = createSlice({
  name: 'attachTryout',
  initialState: initialState,
  reducers: {
    setAttachments: (state, action) => {
        state.attachments = action.payload;
        },
    setIdAttach: (state, action) => {
        state.id = action.payload;
        },
    setTypeQuestion: (state, action) => {
        state.type_question = action.payload;
        },
    setScore : (state, action) => {
        state.attachments.score = action.payload;
        },
    resetState: (state) => {
        return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(attachTryout.pending, (state) => {
            // Handle loading state
            state.isLoading = true;
        }
        )
        .addCase(attachTryout.fulfilled, (state, action) => {
            // Handle success state
            // console.log('Sukses:', action.payload);
            state.isLoading = false;
        }
        )
        .addCase(attachTryout.rejected, (state, action) => {
            console.log(action.error.message, "error action")
            state.isLoading = false;
        }
        );
  }
});

export const { setAttachments, setIdAttach, setTypeQuestion, setScore, resetState } = attachTryoutSlice.actions;

export default attachTryoutSlice.reducer;

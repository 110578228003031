import React, { Fragment, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs } from '../../../../AbstractElements';
import DetailScheduleContainPage from '../../../../Component/Classes/Schedule/DetailSchedule';
import { handleGetDetailSchedule } from '../../../../Component/Classes/Schedule/ScheduleUtils';
import { setDetail } from '../../../../Features/Classes/Schedule/ScheduleSlice';
import { fetchSchedules } from '../../../../Features/Classes/Schedule/Utils/ScheduleUtils';

const ScheduleDetailPage = () => {
    const { classId, scheduleId } = useParams();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.Schedule.filters)
    const detailSchedule = useSelector(state => state.Schedule.detail)

    useEffect(() => {
        if (!detailSchedule.id && scheduleId) {
            const newFilters = {
                ...filters,
                "filter[id]": scheduleId,
                "page": 1,
            };
            dispatch(fetchSchedules(newFilters))
                .then((response) => {
                    handleGetDetailSchedule(dispatch, response.payload.data[0], setDetail)
                })
                .catch((error) => {
                    console.error('Error during fetchClasses:', error);
                });
        }
    } , [scheduleId])
    return (
        <Fragment>
            <Breadcrumbs grandparent="Kelas" parent={<Link to={`${process.env.PUBLIC_URL}/classes/class-schedule-detail/${classId}`}>{detailSchedule.id ? `Detail Kelas ${detailSchedule.classes.name}` : ""}</Link>} title={detailSchedule.id ? `Detail Sesi ${detailSchedule.name}` : ""} />
            <DetailScheduleContainPage />
        </Fragment>
    );
};
export default ScheduleDetailPage;
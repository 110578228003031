import {
	fetchBlog,
	deleteBlog,
} from "../../Features/Learning/Blog/Utils/BlogUtils";
import Swal from "sweetalert2";

/* **************************************************** */
/* CRUD Blog                                       */
/* **************************************************** */

export const fetchBlogs = (dispatch, filters, refetch) => {
	dispatch(fetchBlog(filters));
};

export const handleDeleteBlog = async (dispatch, id, setRefetch) => {
	try {
		Swal.fire({
			title: "Apakah anda yakin?",
			text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
		}).then(async (result) => {
			if (result.isConfirmed) {
				await dispatch(deleteBlog(id));
				setRefetch((prevRefetch) => !prevRefetch);
				Swal.fire("Terhapus!", "Data telah dihapus.", "success");
			}
		});
	} catch (error) {
		console.error("Delete error:", error);
	}
};

export const handleEditBlog = async (dispatch, item, setField) => {
	dispatch(setField({ field: "id", value: item.id }));
	dispatch(setField({ field: "isUpdate", value: true }));
	const fieldsToUpdate = [
		"title",
		"slug",
		"description",
		"link",
		"category",
		"is_hide",
	];
	fieldsToUpdate.forEach((field) => {
		dispatch(setField({ field, value: item[field] }));
	});
	// covert tags array to string and join with comma
	if (item.tags && item.tags !== "null") {
		console.log(item.tags, typeof item.tags, "item tags");
		// remove [, ], spacing after comma and "" from string
		const tags = item.tags.replace(/[\[\]"]+/g, "").replace(/,\s*$/, "");
		dispatch(setField({ field: "tags", value: tags }));
	}
	dispatch(setField({ field: "imageLink", value: item.thumbnail }));
	dispatch(setField({ field: "old_slug", value: item.slug }));
};

export const handleGetDetailBlog = async (dispatch, item, setDetail) => {
	dispatch(setDetail(item));
};

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (
	pagination,
	filters,
	dispatch,
	setFilters,
	setRefetch
) => {
	if (pagination.current_page > 1) {
		dispatch(
			setFilters({
				...filters,
				page: pagination.current_page - 1,
			})
		);
		setRefetch((prevRefetch) => !prevRefetch);
	}
};

export const handleNextPagination = (
	pagination,
	filters,
	dispatch,
	setFilters,
	setRefetch
) => {
	if (pagination.current_page < pagination.last_page) {
		dispatch(
			setFilters({
				...filters,
				page: pagination.current_page + 1,
			})
		);
		setRefetch((prevRefetch) => !prevRefetch);
	}
};

export const handleClickPagination = (
	page,
	filters,
	dispatch,
	setFilters,
	setRefetch
) => {
	console.log(page);
	dispatch(
		setFilters({
			...filters,
			page: page,
		})
	);
	setRefetch((prevRefetch) => !prevRefetch);
};

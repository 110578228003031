import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import { Btn, H4, H5, Badges } from '../../../../AbstractElements';
import CurrencyFormat from '../../Common/GenerateContent/CurrencyFormatter';
import { setField } from '../../../../Features/Learning/MainPrograms/attachDetachMembershipSlice';
import { setDetail } from '../../../../Features/Learning/MainPrograms/mainProgramSlice';
import { fetchMainProgram } from '../../../../Features/Learning/MainPrograms/Utils/MainProgramUtils';
import { handleGetDetailMainProgram } from '../../../../Component/Learning/MainProgram/MainProgramUtils';
import { 
    handleGetDetailMembership,
    handleEditMembership
} from '../../../../Component/Learning/Membership/MembershipsUtils';
import { setField as setFieldMembership } from '../../../../Features/Learning/Memberships/addMembershipSlice';
import { setDetail as setDetailMembership, setFilters as setFiltersMembership } from '../../../../Features/Learning/Memberships/membershipSlice';
import { attachDetachMainProgram } from '../../../../Features/Learning/MainPrograms/Utils/MainProgramUtils';
import Swal from 'sweetalert2';


const MembershipMainProgram = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onEditSubmit = (data) => {
        alert('Form is Submitted');
    };
    const { id } = useParams();
    const dispatch = useDispatch();
    const detail = useSelector(state => state.MainProgram.detail)
    const filters = useSelector(state => state.MainProgram.filters)
    const dataAttachDetach = useSelector(state => state.attachDetachMainProgram)


    const handleDetach = async () => {
        try {
            Swal.fire({
                title: 'Apakah anda yakin?',
                text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const success = await dispatch(attachDetachMainProgram(dataAttachDetach))
                    if (success.payload === true) {
                        const newFilter = {
                            ...filters,
                            'filter[id]': id,
                            'page': 1,
                        };
                        dispatch(fetchMainProgram(newFilter))
                            .then((response) => {
                                // Kode yang akan dijalankan setelah fetch sukses
                                handleGetDetailMainProgram(dispatch, response.payload.data[0], setDetail)
                            })
                            .catch((error) => {
                                // Tangani error jika fetch gagal
                                console.error('Error during fetchMainProgram:', error);
                            });
                        Swal.fire(
                            'Berhasil!',
                            'Membership berhasil dihapus dari program.',
                            'success'
                        )
                        dispatch(setField({ field: 'membership_id', value: [] }));
                    } else if (success.payload === false) {
                        Swal.fire(
                            'Gagal!',
                            'Membership gagal dihapus. Silahkan coba lagi.',
                            'error'
                        )
                    }

                }
            })
        } catch (error) {
            console.error("Delete error:", error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Terjadi kesalahan saat menghapus membership dari program!',
            });
        }
    };


  return (
    <Fragment>
        <div>
            <Card>
                <CardHeader className="pb-0">
                    <H4 attrH4={{ className: 'card-title mb-0 text-center' }}>{detail.membership && detail.membership.length > 0 ? 'Daftar Membership' : 'Belum ada membership terdaftar'}</H4>
                </CardHeader>
                <CardBody>
                    {detail.membership && detail.membership.length > 0 && (
                    <Col sm="12 order-2">
                        <Card>
                        <div className="table-responsive">
                            <Table>
                            <thead className="table-light">
                                <tr>
                                <th scope="col">#</th>
                                    <th scope="col">Nama Membership</th>
                                    <th scope="col">Harga</th>
                                    <th scope="col">Durasi</th>
                                    <th scope="col" className="text-center">Status</th>
                                    <th scope="col" className="text-center">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                detail.membership && detail.membership.map((membership, index) =>
                                    <tr key={`membership-${index}`}>
                                    <td>{index + 1}</td>
                                    <td>{membership.title}</td>
                                    <td><CurrencyFormat amount={membership.price} /></td>
                                    <td>{membership.duration_days} hari</td>
                                    <td className='text-center'>
                                        <Badges attrBadge={{ color: membership.is_online === true ? 'primary' : 'danger' }} > {membership.is_online === true ? 'Online' : 'Offline'} </Badges>
                                        {membership.is_to_only === true ? <Badges attrBadge={{ color: 'warning' }} > Tryout only </Badges> : ''}
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <Link to={`${process.env.PUBLIC_URL}/membership/membership-users/${membership.id}`} title="Users Membership" className="p-1"
                                            target="_blank"
                                            >
                                                <i className="icofont icofont-users-social fa-lg"></i>
                                            </Link>
                                            <Link to={`${process.env.PUBLIC_URL}/membership/membership-detail/${membership.id}`} title="Detail Membership" className="p-1" 
                                            target="_blank"
                                            >
                                                <i className="fa fa-info-circle mx-auto fa-lg"></i>
                                            </Link>
                                            <Link to={`${process.env.PUBLIC_URL}/membership/membership-add/${membership.id}`} title="Edit Membership" className="p-1" 
                                            target="_blank"
                                            >
                                                <i className="fa fa-edit mx-auto text-secondary fa-lg"></i>
                                            </Link>
                                            <Btn
                                                attrBtn={{
                                                    color: 'link',
                                                    outline: true,
                                                    className: 'p-1',
                                                    onFocus: () => {
                                                        // dispatch(setField({ ...dataAttachDetach, 'id': 9, 'type': 'detach', 'membership_id': {value:membership.id} }))
                                                        dispatch(setField({ field: 'type', value: 'detach' }));
                                                        const membershipId = [{value:membership.id}];
                                                        dispatch(setField({ field: 'membership_id', value: membershipId }));
                                                    },
                                                    onClick: () => {
                                                        handleDetach()
                                                    }
                                                }}
                                            >
                                                <i className="fa fa-trash-o mx-auto text-danger fa-lg"></i>
                                            </Btn>
                                        </div >
                                    </td>
                                    </tr>
                                )
                                }
                            </tbody>
                            </Table>
                        </div>
                        </Card>
                    </Col>
                    )}
                </CardBody>
            </Card>
        </div>
    </Fragment>
  );
};
export default MembershipMainProgram;
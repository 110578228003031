import React, { Fragment } from 'react';
import { Input, Label } from 'reactstrap';
import { handleFilterType, isCheckedType } from './CatCheckBoxUtils';
import { useSelector, useDispatch } from 'react-redux';

const CatCheckBox = () => {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.subMaterial.filters)

  return (
    <Fragment>
      <div className="checkbox-animated">
        <div className="learning-header"><span className="f-w-600">Tipe Submater:</span></div>
        <Label className="d-block" htmlFor="chk-image">
          <Input className="checkbox_animated" id="chk-image" type="checkbox"
          name="filter[type]" value="image"
          checked={isCheckedType(filters, 'image')}
          onChange={handleFilterType(filters, dispatch)}
          />
          {'Image'}
        </Label>
        <Label className="d-block" htmlFor="chk-youtube">
          <Input className="checkbox_animated" id="chk-youtube" type="checkbox" 
          name="filter[type]" value="youtube" 
          checked={isCheckedType(filters, 'youtube')}
          onChange={handleFilterType(filters, dispatch)}
          />
          {'Youtube'}
        </Label>
        <Label className="d-block" htmlFor="chk-video">
          <Input className="checkbox_animated" id="chk-video" type="checkbox"
          name="filter[type]" value="video"
          checked={isCheckedType(filters, 'video')}
          onChange={handleFilterType(filters, dispatch)}
          />
          {'Video'}
        </Label>
        <Label className="d-block" htmlFor="chk-ebook">
          <Input className="checkbox_animated" id="chk-ebook" type="checkbox" 
          name="filter[type]" value="ebook"
          checked={isCheckedType(filters, 'ebook')}
          onChange={handleFilterType(filters, dispatch)}
          />
          {'E-book'}
        </Label>
        <Label className="d-block" htmlFor="chk-audio">
          <Input className="checkbox_animated" id="chk-audio" type="checkbox" 
          name="filter[type]" value="audio"
          checked={isCheckedType(filters, 'audio')}
          onChange={handleFilterType(filters, dispatch)}
          />
          {'Audio'}
        </Label>
      </div>
    </Fragment>
  );
};

export default CatCheckBox;
import { createSlice } from '@reduxjs/toolkit';
import { submitTryout } from './Utils/TryoutUtils';

const initialState = {
    title: '',
    description: '',
    time: '',
    start_at: '',
    end_at: '',
    quotas: '',
    banner: '',
    category: '',
    is_quiz: '',
    is_report_show: '',
    instruction: '',
    ads_video: '',
    tutorial_video: '',
    isUpdate: false,
    id: '',
    isLoading: false,
};

const addTryout = createSlice(
    {
        name: 'addTryout',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(submitTryout.pending, (state) => {
                    // Handle loading state
                    state.isLoading = true;
                })
                .addCase(submitTryout.fulfilled, (state, action) => {
                    // Handle success state
                    state.isLoading = false;
                    // console.log('Sukses:', action.payload);
                })
                .addCase(submitTryout.rejected, (state, action) => {
                   console.log(action.error.message, "error action")
                   state.isLoading = false;
                });
        }
    }
);


export const { setField, resetState } = addTryout.actions;

export default addTryout.reducer;
import React, { Fragment } from "react";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import { Image } from "../../AbstractElements";
import { useSelector, useDispatch } from "react-redux";
import { setField } from "../../Features/Learning/Blog/addBlogSlice";

const BlogForm = () => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.addBlog);
	const handleChanges = (e) => {
		const { name, value } = e.target;
		dispatch(setField({ field: name, value: value }));
		console.log(name, value);
	};

	return (
		<Fragment>
			<Form className="row needs-validation">
				<Col sm="12">
					<FormGroup className="mb-3">
						<Label for="validationName" style={{ fontWeight: "bold" }}>
							{"Title"}:
						</Label>
						<Input
							className="form-control"
							id="validationName"
							type="text"
							name="title"
							placeholder="Masukkan Judul Blog..."
							required=""
							value={data.title}
							onChange={handleChanges}
						/>
						<div className="valid-feedback">{"Looks good!"}</div>
					</FormGroup>
					<FormGroup className="mb-3">
						<Label for="validationName" style={{ fontWeight: "bold" }}>
							{"Slug"}:
						</Label>
						<Input
							className="form-control"
							id="validationName"
							type="text"
							name="slug"
							placeholder="Masukkan slug. Contoh: judul-blog"
							required=""
							value={data.slug}
							onChange={handleChanges}
						/>
						<div className="valid-feedback">{"Looks good!"}</div>
					</FormGroup>
					<FormGroup className="mb-3">
						<Label for="validationDescription" style={{ fontWeight: "bold" }}>
							{"Description"}:
						</Label>
						<Input
							className="form-control"
							id="validationDescription"
							type="textarea"
							name="description"
							placeholder="Masukkan Deskripsi..."
							required=""
							value={data.description}
							onChange={handleChanges}
						/>
					</FormGroup>
					<FormGroup className="mb-3">
						<Label for="validationName" style={{ fontWeight: "bold" }}>
							{"Link"}:
						</Label>
						<Input
							className="form-control"
							id="validationName"
							type="text"
							name="link"
							placeholder="Masukkan link blog..."
							required=""
							value={data.link}
							onChange={handleChanges}
						/>
						<div className="valid-feedback">{"Looks good!"}</div>
					</FormGroup>
					<FormGroup className="mb-3">
						<Label for="validationDescription" style={{ fontWeight: "bold" }}>
							{"Type"}:
						</Label>
						<Input
							className="form-control"
							id="validationType"
							type="select"
							name="category"
							required=""
							value={data.category}
							onChange={handleChanges}>
							<option value="">{"Pilih Kategori"}</option>
							<option value="Artikel">Artikel</option>
							<option value="Promo">Promo</option>
							<option value="Lowongan Kerja">Lowongan Kerja</option>
							<option value="Event">Event</option>
						</Input>
					</FormGroup>
					<FormGroup className="mb-3">
						<Label for="validationName" style={{ fontWeight: "bold" }}>
							{"Tags"}:
						</Label>
						<Input
							className="form-control"
							id="validationName"
							type="text"
							name="tags"
							placeholder="Masukkan Tags: pisahkan dengan koma, contoh: cpns,lowongan kerja,event, ..."
							required=""
							value={data.tags}
							onChange={handleChanges}
						/>
						<div className="valid-feedback">{"Looks good!"}</div>
					</FormGroup>
					<FormGroup className="mb-3">
						<Label style={{ fontWeight: "bold" }}>
							Sembunyikan di halaman News
						</Label>
						<div className="m-checkbox-inline">
							<Label for="is_hide_false">
								<Input
									className="radio_animated"
									id="is_hide_false"
									type="radio"
									name="is_hide"
									value={0}
									checked={data.is_hide == 0}
									onChange={handleChanges}
								/>
								TIDAK
							</Label>
							<Label for="is_hide_true">
								<Input
									className="radio_animated"
									id="is_hide_true"
									type="radio"
									name="is_hide"
									value={1}
									checked={data.is_hide == 1}
									onChange={handleChanges}
								/>
								YA
							</Label>
						</div>
					</FormGroup>
					{data.imageLink && (
						<FormGroup className="mb-3">
							<Image
								attrImage={{
									className: "img-fluid",
									width: "500px",
									src: data.imageLink,
									alt: data.name,
								}}
							/>
						</FormGroup>
					)}
				</Col>
			</Form>
		</Fragment>
	);
};
export default BlogForm;

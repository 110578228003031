import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Previous, Next } from '../../../../Constant';

const PaginationComponent = ({ pagination, handleBackPagination, handleClickPagination, handleNextPagination, maxPagesToShow = 5 }) => {
    const startPage = Math.max(pagination.current_page - Math.floor(maxPagesToShow / 2), 1);
    const endPage = Math.min(startPage + maxPagesToShow - 1, pagination.last_page);

    return (
        <Pagination className="pagination justify-content-center pagination-primary" aria-label="Page navigation example">
            <PaginationItem disabled={pagination.current_page === 1}>
                <PaginationLink onClick={() => handleBackPagination()}>{Previous}</PaginationLink>
            </PaginationItem>
            {startPage > 1 && (
                <PaginationItem>
                    <PaginationLink onClick={() => handleClickPagination(1)}>1</PaginationLink>
                </PaginationItem>
            )}
            {startPage > 2 && (
                <PaginationItem>
                    <PaginationLink disabled>...</PaginationLink>
                </PaginationItem>
            )}
            {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
                <PaginationItem key={startPage + i} active={pagination.current_page === startPage + i}>
                    <PaginationLink onClick={() => handleClickPagination(startPage + i)}>{startPage + i}</PaginationLink>
                </PaginationItem>
            ))}
            {endPage < pagination.last_page && (
                <PaginationItem>
                    <PaginationLink disabled>...</PaginationLink>
                </PaginationItem>
            )}
            {endPage < pagination.last_page && (
                <PaginationItem>
                    <PaginationLink onClick={() => handleClickPagination(pagination.last_page)}>{pagination.last_page}</PaginationLink>
                </PaginationItem>
            )}
            <PaginationItem disabled={pagination.current_page === pagination.last_page}>
                <PaginationLink onClick={() => handleNextPagination()}>{Next}</PaginationLink>
            </PaginationItem>
        </Pagination>
    );
};

export default PaginationComponent;
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, CardHeader, CardFooter, Media, Table, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Image, H6, H5, H4, LI, UL, P, Btn, Spinner } from '../../../../AbstractElements';
import AttachModal from '../../../../Component/Learning/Common/Modal/ModalButton';
import { setFieldAttachUser, resetStateAttachUser } from '../../../../Features/Learning/Memberships/attachUserSlice';
import { attachUserMemberships, fetchMemberships } from '../../../../Features/Learning/Memberships/Utils/MembershipUtils';
import { setDetail as setDetailMembership } from '../../../../Features/Learning/Memberships/membershipSlice';
import { fetchUser, fetchUserFiltered } from '../../../../Component/Learning/Users/UsersUtils';
import { tryParseJSON } from '../../../../Component/Learning/Membership/MembershipsUtils';
import { fetchUsers } from '../../../../Features/Learning/Users/Utils/UserUtils';
import CleanLimitHTML from '../../../../Component/Learning/Common/GenerateContent/CleanLimitHtml';
import GenerateUniqueContain from '../../../../Component/Learning/Common/GenerateUnique';
import AsyncSelectCommon from '../../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { setFilterUsers } from '../../../../Features/Learning/Users/userFilteredSlice';
import Moment from 'moment';
import 'moment/locale/id';
import Swal from 'sweetalert2';
import CurrencyFormat from '../../Common/GenerateContent/CurrencyFormatter';
import UserContain from './UsersContain';

const BoxListMembershipDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const detail = useSelector(state => state.Membership.detail);
    const filter = useSelector(state => state.User.filters);
    const filteredUsers = useSelector(state => state.userFiltered.filters);
    const [refatch, setRefatch] = useState(false)
    const [modal, setModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const dataOptions = useSelector(state => state.User.data.data);
    const data = useSelector(state => state.attachUsersMembership);
    // const dataUsersRegistered = useSelector(state => state.userFiltered.data.data);
    // const statusUsersRegistered = useSelector(state => state.userFiltered.isLoading);
    // console.log(detail.id, 'detail.id')
    // console.log(dataUsersRegistered, 'dataUsersRegistered')
    const usersFilter = {
        ...filteredUsers,
        'filter[membership.id]': id,
        'filter[name]': '',
        'filter[email]': '',
        'filter[phone]': '',
    }    

    useEffect(() => {
        dispatch(setFieldAttachUser({ field: 'id', value: id }));
    }, [id])

    useEffect(() => {
        dispatch(setFilterUsers(usersFilter))
        fetchUserFiltered(dispatch, usersFilter)
        window.scrollTo(0, 0);
    }, [refatch])

    const handleToggle = () => {
        setModal(!modal);
        setSelectedValue(null);
    };

    const promiseOptions = (inputValue, callback) => {
    // console.log('Input Value = ', inputValue)
        if(inputValue !== '') {
            dispatch(fetchUsers({
            'filter[name]': inputValue,
            'sort': 'name',
            'page': 1,
        }))
        .then((response) => {
            const options = response.payload.data.map(item => ({
            value: item.id,
            label: item.name,
            }));
            callback(options);
        })
        .catch((error) => {
            console.error('Error fetching options:', error);
            callback([]);
        });
        }
  };

    const handleSelectChange = (selectedOption) => {
        setSelectedValue(selectedOption);
        if (selectedOption) {
            dispatch(setFieldAttachUser({ field: 'user_id', value: selectedOption.value }));
        } else {
            dispatch(setFieldAttachUser({ field: 'user_id', value: null }));
        }
    };

    const handleSubmit = async () => {
        try {
            const response = await dispatch(attachUserMemberships(data));
            if (response.payload === true) {
                fetchUserFiltered(dispatch, usersFilter)
                dispatch(setFilterUsers(usersFilter))
                setModal(!modal);
                Swal.fire('Berhasil!', 'User berhasil terdaftar ke membership', 'success');
            }
        } catch (error) {
            console.error('Error:', error);
            // Modal tidak akan ditutup jika terjadi kesalahan
        }
    };

    return (
        <Fragment>
            <Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
                <Card className="social-widget-card">
                    <CardHeader>
                        <Col>
                            <Row>
                                <Col>
                                    <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>{detail.title || 'Title Membership'}</H5>
                                </Col>
                                <Col className='text-end'>
                                    <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>ID Membership : {GenerateUniqueContain('MBS', detail.id, 5) || 'ID Membership'}</H5>
                                </Col>
                            </Row>
                        </Col>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mt-2' }}>{'Harga'}</H5>
                                            <H6 attrH6={{ className: 'font-roboto mb-0 font-primary' }}><CurrencyFormat amount={detail.price} /></H6>
                                        </Col>
                                        <Col>
                                            <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mt-2' }}>{'Harga Coret'}</H5>
                                            <H6 attrH6={{ className: 'font-roboto mb-0 font-primary' }}>{detail.gimmick_price ? <CurrencyFormat amount={detail.gimmick_price} /> : '-'}</H6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mt-2' }}>{'Masa Aktif Membership'}</H5>
                                    <H6 attrH6={{ className: 'font-roboto mb-0 font-primary' }}>{detail.duration_days} Hari</H6>
                                </Col>
                                <Col>
                                    <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mt-2' }}>{'Jenis Membership'}</H5>
                                    <H6 attrH6={{ className: 'font-roboto mb-0 font-primary' }}>
                                        {detail.is_online === true ? 'Online ' : 'Offline '}
                                        {detail.is_to_only === true ? '(Tryout Only)' : ''}
                                    </H6>
                                </Col>
                                <Col>
                                    <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mt-2' }}>{'Kategori Program Utama'}</H5>
                                    <H6 attrH6={{ className: 'font-roboto mb-0 font-primary' }}>{detail.main_program ? detail.main_program.title : '-'}</H6>
                                </Col>
                            </Col>
                            <Col>
                                <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mb-2' }}>{'Fasilitas Membership'}</H5>
                                {detail.points && detail.points.length > 0 ? (
                                    <UL attrUl={{ className: 'list-unstyled mt-2 mb-0' }}>
                                        {detail.points.map((item, index) => {
                                            const isDataJSON = tryParseJSON(item); // Panggil fungsi isDataJSON di sini
                                            const pointName = isDataJSON ? JSON.parse(item).name : item;
                                            const pointStatus = isDataJSON ? JSON.parse(item).status : true;

                                        return (
                                            <LI key={`points-${index}`} attrLI={{ className: 'd-flex mb-2' }}>
                                                <i className={`fa fa-${pointStatus ? 'check' : 'times'} fa-lg me-2`} style={{ color: pointStatus ? 'green' : 'red' }}></i>
                                                <P attrP={{ className: 'mb-0 font-roboto' }}><b>{pointName}</b></P>
                                            </LI>
                                            )
                                        })}
                                    </UL>
                                ) : (
                                    <P attrP={{ className: 'mb-0 font-roboto' }}><b>Tidak ada fasilitas.</b></P>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col>
                                <P attrP={{ className: 'font-roboto' }}>Created at: {Moment(detail.created_at).format('dddd, D MMMM YYYY')} - {Moment(detail.created_at).format('HH:mm:ss')} WIB</P>
                                <P attrP={{ className: 'font-roboto' }}><b>Updated at: {Moment(detail.updated_at).format('dddd, D MMMM YYYY')} - {Moment(detail.updated_at).format('HH:mm:ss')} WIB</b></P>
                            </Col>
                            <Col className='text-end'>
                                <Link to={`${process.env.PUBLIC_URL}/membership/membership-list`}>
                                    <Btn attrBtn={{ color: 'warning', className: 'btn btn-primary m-r-10' }}>
                                        <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                                    </Btn>
                                </Link>
                                <AttachModal colorBtn={'primary'} buttonLabel={'Tambah User'} toggle={() => handleToggle()} modalLabel={'Daftarkan user ke membership'} modal={modal} handleSubmit={() => {
                                    handleSubmit();
                                }} >
                                    <AsyncSelectCommon label={'Users'} placeholder={'Silahkan pilih User...'}
                                        isMulti={false}
                                        promiseOptions={promiseOptions}
                                        handleSelectChange={(selectedOption) => handleSelectChange(selectedOption)}
                                        selectedValue={selectedValue}
                                    />
                                    {data.isLoading && (
                                        <Col lg="12">
                                            <div className="loader-box">
                                                <Spinner attrSpinner={{ className: 'loader-30' }} />
                                            </div>
                                            <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses penambahan tryout...'}</H6>
                                        </Col>
                                    )}
                                </AttachModal>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Col>
            <UserContain setRefatch={setRefatch} />
        </Fragment >
    );
};
export default BoxListMembershipDetail;
import { createSlice } from '@reduxjs/toolkit';
import { submitMaterial } from './Utils/MateriUtils';

const initialState = {
    name: '',
    description: '',
    image: '',
    subMaterials: [],
    isUpdate: false,
    id: '',
    isLoading: false,
};

const addMaterial = createSlice(
    {
        name: 'addMaterial',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(submitMaterial.pending, (state) => {
                    // Handle loading state
                    state.isLoading = true;
                })
                .addCase(submitMaterial.fulfilled, (state, action) => {
                    // Handle success state
                    console.log("fulfilled action")
                    state.isLoading = false;
                })
                .addCase(submitMaterial.rejected, (state, action) => {
                    // Handle error state
                   console.log(action.error.message, "error action")
                    state.isLoading = false;
                });
        }
    }
);


export const { setField, resetState } = addMaterial.actions;

export default addMaterial.reducer;
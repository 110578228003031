import ReactQuill, { Quill } from 'react-quill';
import React, { useEffect, useRef, useState } from 'react';
import ImageResize from 'quill-image-resize-module-react';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import * as Emoji from "quill-emoji";
import 'react-quill/dist/quill.snow.css';
import "quill-emoji/dist/quill-emoji.css";
import "quill-emoji/dist/quill-emoji.css";

window.katex = katex;

Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/emoji", Emoji);

const module = {
    'toolbar': [
        [{ 'font': [] }, { 'size': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'super' }, { 'script': 'sub' }],
        [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        ['direction', { 'align': [] }],
        ['link', 'image', 'video', 'formula'],
        ['clean'],
        ['emoji'],
    ],
    handlers: { 'emoji': function () { } }
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'color', 'background',
    'font', 'size',
    'script', 'direction', 'align',
    'code-block',
    'indent',
    'list', 'bullet',
    'link', 'image', 'video',
    'formula', 'emoji'
];

const RichEditor = ({ handleChangeDescription, description, index }) => {
    const quillRef = useRef(null);
    // const [localDescription, setLocalDescription] = useState(description);
    
    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();

            const handleChange = (delta, source, editor) => {
                if (editor === 'user') {
                    const editorContent = quill.root.innerHTML;
                    // setLocalDescription(editorContent);
                    if (index !== undefined) {
                        handleChangeDescription(index, editorContent);
                    } else {
                        handleChangeDescription(editorContent);
                    }
                }
            }

            quill.on('text-change', handleChange);

            return () => {
                quill.off('text-change', handleChange); // Membersihkan pemantauan setelah komponen di-unmount
            };
        }
    }, [description, index]);

    // useEffect(() => {
    //     // Menggunakan localDescription untuk menghindari infinite loop pada handleChangeDescription
    //     if (index !== undefined) {
    //         handleChangeDescription(index, localDescription);
    //     } else {
    //         handleChangeDescription(localDescription);
    //     }
    // }, [localDescription]);

    return (
        <ReactQuill
            theme="snow"
            modules={{
                toolbar : module.toolbar,
                imageResize: { parchment: Quill.import('parchment') },
                "emoji-toolbar": true,
                "emoji-textarea": true,
                "emoji-shortname": true,
            }}
            formats={formats}
            value={description}
            ref={quillRef}            
            type="textarea"
        />
    )
}

export default RichEditor;
import { createSlice } from '@reduxjs/toolkit';
import { fetchTransactions } from './Utils/TransactionUtils';

const TransactionSlice = createSlice({
    name : 'Transactions',
    initialState : {
        filters: {
            "filter[id]": "",
            "filter[user.name]": "",
            "filter[user.email]": "",
            "filter[user.phone]": "",
            "filter[membership.title]": "",
            "filter[status]": "",
            "filter[payment_method]": "",
            "sort": "",
            "page": "",
        },
        data: [],
        isLoading: false,
    },
    reducers : {
        setFilters : (state, action) => {
            state.filters = action.payload;
        }
    },
    extraReducers : (builder) => {
        builder
        .addCase(fetchTransactions.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchTransactions.fulfilled, (state, action) => {
            state.isLoading = false;
            // console.log('status fulfilled', action.payload)
            state.data = action.payload;
            // console.log('data', state.data)
        })
        .addCase(fetchTransactions.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export const { setFilters } = TransactionSlice.actions;

export default TransactionSlice.reducer;
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CoursesService } from '../../../../Services/axios/Courses.Service';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";

export const fetchCourses = createAsyncThunk(
    'Courses/fetchCourses',
    async (filters) => {
        try {
            const response = await CoursesService.getAll(filters);
            return response.data;
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server.';
                } else if (statusCode === 0) {
                    errorMessage = 'Tidak ada koneksi internet.';
                }

                Swal.fire({
                    icon: 'error',
                    title: statusCode === 0 ? 'Error' : `Error ${statusCode}`,
                    text: errorMessage,
                });
            } else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
        }
    }
    );

export const submitCourses = createAsyncThunk(
    'addCourses/submitCourses',
    async (data) => {
        try {
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('description', data.description);
        data.description_video && formData.append('description_video', data.description_video);
        data.description_image && formData.append('description_image', data.description_image);
        data.image && formData.append('banner', data.image);
        
        let response;
        if (data.isUpdate) {
            response = await CoursesService.update(data.id, formData);
            toast.success('Course berhasil diperbarui', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            response = await CoursesService.create(formData);
            toast.success('Course berhasil ditambahkan', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        data.successCallback && data.successCallback();
        return response.data.data;
        } catch (error) {
            for (const errorField in error.response.data.errors) {
                if (error.response.data.errors.hasOwnProperty(errorField)) {
                    const errorMessages = error.response.data.errors[errorField];
                    let errorMessage = errorMessages[0];
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
            if (error.code === "ERR_NETWORK") {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else if (error.response.status === 500) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan sistem. Mohon hubungi pengembang.',
                });
            }
        }
    }
    );

export const deleteCourses = createAsyncThunk(
    'deleteCourses/deleteCourses',
    async (id) => {
        try {
        const response = await CoursesService.delete(id);
        toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
        });
        return id;
        } catch (error) {
        if (error.response && error.response.data) {
            toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
        }
    }
    );

export const attachDetachCourses = createAsyncThunk(
    'attachDetachCourses/attachDetachCourses',
    async (data) => {
        try {
        const formData = new FormData();
        data.materials.forEach((material, index) => {
          formData.append(`material[${index}][id]`, material.value);
        });
        let response;
        response = await CoursesService.attachDetach(data.id, formData);
        // toast.success('Berhasil tambah materi ke course', {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return true;
        } catch (error) {
        if (error.response && error.response.data) {
            toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
        return false;
        }
    }
    );
    
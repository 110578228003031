import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { Search } from 'react-feather';
import { Filter } from '../../../../Constant';
import { H5, Btn } from '../../../../AbstractElements';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../../Features/Learning/BankQuestions/QuestionSlice';
import { fetchQuestions } from '../QuestionsUtils';


const FindQuestion = () => {
  const [isFilter, setIsFilter] = useState(true);
  const dispatch = useDispatch()
  const filters = useSelector(state => state.Question.filters)
  const [selectedValue, setSelectedValue] = useState(null)

  useEffect(() => {
    const selectedCategory = filters['filter[type]']
      ? [{
        value: filters['filter[type]'],
        label: filters['filter[type]'] === 'multiple_choice' ? 'Multiple Choice' : filters['filter[type]'] === 'sentence_arrangement' ? 'Sentence Arrangement' : 'Fill in the Blanks',
      }]
      : selectedValue;
    setSelectedValue(selectedCategory);
  }, []);

  const typeOptions = [
    { value: 'multiple_choice', label: 'Pilihan Ganda' },
    { value: 'sentence_arrangement', label: 'Susunan Kalimat' },
    { value: 'fill_in_the_blanks', label: 'Isian Singkat' },
  ];

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    if (selectedOption === null) {
      dispatch(setFilters({
        ...filters,
        'filter[type]': '',
      }));
    }
    else {
      dispatch(setFilters({
        ...filters,
        'filter[type]': selectedOption.value,
      }));
    }
  }

  const handleClickSearch = (e) => {
    e.preventDefault()
    const newFilters = {
      ...filters,
      'page': 1,
    };
    dispatch(setFilters(newFilters));
    fetchQuestions(dispatch, newFilters);
  }


  const handleResetFilter = () => {
    const newFilters = {
      ...filters,
        'filter[description]': '',
        'filter[title]': '',
        'filter[type]': '',
        'filter[id]': '',
        'sort': '',
        'include[0]': 'answer',
        'include[1]': 'category',
        'page': 1,
    };
    setSelectedValue(null);
    dispatch(setFilters(newFilters));
    fetchQuestions(dispatch, newFilters);
  }

  const updateFilters = (filters, dispatch, field, value) => {
    dispatch(setFilters({
      ...filters,
      [field]: value,
    }));
  };

  const handleSortBy = (filters, dispatch) => (e) => {
    const filterValue = e.target.value;
    updateFilters(filters, dispatch, 'sort', filterValue);
  };


  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: 'mb-0' }} >
              <Btn attrBtn={{ className: 'btn btn-link ps-0', onClick: () => setIsFilter(!isFilter), color: 'transperant', datastoggle: 'collapse', databstarget: '#collapseicon', ariaexpanded: 'true', ariacontrols: 'collapseicon' }} >{'Temukan Soal'}</Btn>
            </H5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
              <CardBody className="filter-cards-view animate-chk">
                <div className="job-filter">
                  <div className="faq-form">
                    <Input className="form-control" type="text" placeholder="Search.." 
                      value={filters['filter[title]']}
                      onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e): null}
                      onChange={(e) => {
                        dispatch(setFilters({
                          ...filters,
                          'filter[title]': e.target.value
                        }));
                      }}
                    />
                    <Search className="search-icon" />
                  </div>
                </div>
                <div className="checkbox-animated mt-0">
                  <div className="learning-header"><span className="f-w-600">Sort by:</span></div>
                  <Label className="d-block" htmlFor="sort-new">
                    <Input className="radio_animated" id="sort-new" type="radio" name="sort"
                      checked={filters.sort === '-id'}
                      value="-id"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'Terbaru'}
                  </Label>
                  <Label className="d-block" htmlFor="sort-old">
                    <Input className="radio_animated" id="sort-old" type="radio" name="sort"
                      checked={filters.sort === 'id'}
                      value="id"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'Terlama'}
                  </Label>
                  <Label className="d-block" htmlFor="sort-az">
                    <Input className="radio_animated" id="sort-az" type="radio" name="sort"
                      checked={filters.sort === 'title'}
                      value="title"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'A-Z'}
                  </Label>
                  <Label className="d-block" htmlFor="sort-za">
                    <Input className="radio_animated" id="sort-za" type="radio" name="sort"
                      checked={filters.sort === '-title'}
                      value="-title"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'Z-A'}
                  </Label>
                </div>
                <div className="checkbox-animated mt-0">
                  <div className="learning-header"><span className="f-w-600">Tipe Soal:</span></div>
                    <Select
                        name="type"
                        options={typeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Silahkan Pilih..."
                        isClearable={true}
                        onChange={handleSelectChange}
                        value={selectedValue}
                    />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Btn attrBtn={{ color: 'primary text-center p-1', onClick: handleClickSearch }}>{Filter}</Btn>
                  </div>
                  <div className="col-md-6">
                    <Btn attrBtn={{ color: 'warning text-center p-1', onClick: handleResetFilter }}>{'Reset'}</Btn>
                  </div>
                </div>
              </CardBody>
            </div>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default FindQuestion;
import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row, CardBody} from 'reactstrap';
import { H1, H2, H3, H4, H5, H6, Image, Btn, LI, P, UL, Spinner } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDetail, setFilters } from '../../../Features/Learning/MainPrograms/mainProgramSlice';
import { setField } from '../../../Features/Learning/MainPrograms/addMainProgramSlice';
import { 
    fetchMainPrograms,
    handleDeleteMainProgram,
    handleEditMainProgram,
    handleGetDetailMainProgram,
    handleNextPagination,
    handleBackPagination,
    handleClickPagination
} from './MainProgramUtils';
import PaginationComponent from '../Common/Pagination';
import Moment from 'moment';

const MainProgramBox = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const filters = useSelector(state => state.MainProgram.filters)
  const isLoading = useSelector(state => state.MainProgram.isLoading)
  const cards = useSelector(state => state.MainProgram.data.data)
  const pagination = useSelector(state => state.MainProgram.data.pagination)
  const [refatch, setRefatch] = useState(false)

  const handleClickEdit = (item) => {
    handleEditMainProgram(dispatch, item, setField)
    history(`${process.env.PUBLIC_URL}/main-program/add/${item.id}`);
  };

  const handleClickDetail = (item) => {
      handleGetDetailMainProgram(dispatch, item, setDetail)
      history(`${process.env.PUBLIC_URL}/main-program/detail/${item.id}`);
    };

  useEffect(() => {
    fetchMainPrograms(dispatch, filters, refatch)
    window.scrollTo(0, 0);
  }, [refatch])

  return (
      <Fragment>
      { isLoading ? (
        <Col lg="12">
            <div className="loader-box">
                <Spinner attrSpinner={{ className: 'loader-30' }} />
            </div>
            <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
        </Col>
      ) : (
        cards && cards.map((item) => {
          return (
            <Col xl="4" sm="4" lg="4" key={`tryout-${item.id}`} className='box-col-4'>
                <Card>
                    <div className="product-box learning-box">
                        <div className="product-img">
                            <Image attrImage={{ className: 'img-fluid top-radius-blog', src: item.image, alt: '' }} />
                            <div className="product-hover">
                                <UL attrUL={{ className: 'simple-list' }}>
                                    <li title="delete" onClick={() => handleDeleteMainProgram(dispatch, item.id, setRefatch)}>
                                        <i className="icofont icofont-trash text-danger"></i>
                                    </li>
                                    <Link to={`${process.env.PUBLIC_URL}/main-program/add/${item.id}`} onClick={() => handleEditMainProgram(dispatch, item, setField)}>
                                      <li title="edit" style={{ marginBottom: '10px', marginTop: '10px' }}>
                                          <i className="icofont icofont-edit"></i>
                                      </li>
                                    </Link>
                                    <Link to={`${process.env.PUBLIC_URL}/main-program/detail/${item.id}`} onClick={() => handleGetDetailMainProgram(dispatch, item, setDetail)}>
                                      <li title="detail">
                                          <i className="icofont icofont-info-circle"></i>
                                      </li>
                                    </Link>
                                </UL>
                            </div>
                        </div>
                        <div className="details-main">
                            <Link to={`${process.env.PUBLIC_URL}/main-program/detail/${item.id}`}
                              onClick={() => handleGetDetailMainProgram(dispatch, item, setDetail)}
                            >
                                <div className='bottom-details'>
                                    <H6>{item.title}</H6>
                                </div>
                            </Link>
                            <P>Created at : {Moment(item.created_at).format('ddd, DD/MM/YYYY')} - {Moment(item.created_at).format('HH:mm:ss')} WIB</P>
                        </div>
                    </div>
                </Card>
            </Col>
          );
        })
      )}

      {!cards || cards.length === 0 && (
        <Col lg="12">
          <H3 attrH3={{ className: 'sub-title mb-5 text-center' }}>{!cards ? 'Sumber Data Tidak Tersedia' : 'Data Tidak Ditemukan'}</H3>
        </Col>
      )}
        
      <CardBody>
      {isLoading === false && cards && cards.length > 0 && pagination && (
          <PaginationComponent 
          pagination={pagination}
          handleBackPagination={() => handleBackPagination(pagination, filters, dispatch, setFilters, setRefatch)}
          handleClickPagination={(page) => handleClickPagination(page, filters, dispatch, setFilters, setRefatch)}
          handleNextPagination={() => handleNextPagination(pagination, filters, dispatch, setFilters, setRefatch)}
          />
      )}
      </CardBody>

    </Fragment>
  );
};

export default MainProgramBox;
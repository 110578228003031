import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Btn } from '../../../../AbstractElements';
import { NewMessage } from '../../../../Constant/index';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import FormModal from '../TryoutModal/FormModal';
import CommonModal from './ModalPost';
import { setIdAttach, setAttachments } from '../../../../Features/Learning/Tryout/attachTryoutSlice';


const AttachModalButton = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.attachTryout)
  // console.log(data, 'data')
//   const [modal, setModal] = useState(false);
//   const questionArray = (item && item.questions) || [];
//   const orderArray = (item && questionArray.length + 1) || '';
//   const toggle = () => {
//     setModal(!modal);
//     dispatch(setIdAttach(item.id))
//     dispatch(setAttachments({ ...data.attachments, 'order': orderArray }))
//   }
  
  return (
    <Fragment>
      <Btn attrBtn={{ color: props.colorBtn, onClick: props.toggle, className: 'p-1 m-r-10', }}><i className={props.icon ? props.icon : "fa fa-plus-square fa-lg me-2"}></i>{props.buttonLabel}</Btn>
        <CommonModal isOpen={props.modal} title={props.modalLabel} toggler={props.toggle} handleSubmit={props.handleSubmit}>
            {props.children}
        </CommonModal>
    </Fragment>
  );
};

export default AttachModalButton;
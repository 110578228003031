import React from 'react';

const VideoPlayer = (props) => {
    const { link } = props;
  return (
    <div>
      <video controls width={props.width}>
        <source src={link} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;

import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, Input, Label } from 'reactstrap';
import { PlusCircle } from 'react-feather';
import { Btn } from '../../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import MembershipBox from './MembershipBox';
import MembershipFilter from './Filter/MembershipFilter';
import { resetState } from '../../../Features/Learning/Memberships/addMembershipSlice';


const MembershipContain = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const cards = useSelector(state => state.Membership.data.data)
  
  const handleAdd = (e) => {
    dispatch(resetState());
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Col md="12" className="project-list">
          {cards && (
            <Card>
              <Row>
                <Col>
                  <Link to={`${process.env.PUBLIC_URL}/membership/membership-add`} onClick={handleAdd}>
                    <Btn attrBtn={{ color: 'primary' }}>
                      <i className="fa fa-plus-circle fa-lg"></i> Tambah Membership Baru
                    </Btn>
                  </Link>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
        <Row className='learning-block'>
          <Col xl="9" className='xl-80'>
            <Row>
              <MembershipBox />
            </Row>
          </Col>
          {cards && (
          <MembershipFilter />
          )}
        </Row>
      </Container>
    </Fragment>
  );
};
export default MembershipContain;
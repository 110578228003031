import React, { Fragment, useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import AsyncSelectCommon from '../../../Component/Learning/Common/SelectOption/AsyncSelect';

const FormSelectOption = ({ type, selectedValueCategory, setSelectedValueCategory, setState, fieldState }) => {
  const dispatch = useDispatch()
  const dataCategoryOptions = useSelector(state => state.Categories.data.data);
  const data = useSelector(state => state.addTryout)
  const filtersTryout = useSelector(state => state.Tryout.filters);

  useEffect(() => {
      const selectedCategory = data.category.id
          ? [{
              value: data.category.id,
              label: data.category.title.replace(/\d/g, '')
          }]
          : selectedValueCategory;
      setSelectedValueCategory(selectedCategory);
    if (type === 'Filter') {
      if (filtersTryout['filter[category_id]'] !== '') {
        const filteredOptions = dataCategoryOptions
          .filter((item) => item.id === filtersTryout['filter[category_id]'])
          .map((item) => ({
            value: item.id,
            label: item.title.replace(/\d/g, ''),
          }))
          setSelectedValueCategory(filteredOptions);
      } else {
        setSelectedValueCategory(null);
      }
    }
  }, [data.category.id]);

  const promiseCategoryOptions = (inputValue, callback) => {
    const filteredOptions = filteredDataCategory(inputValue);
    callback(filteredOptions);
  };

  const filteredDataCategory = (inputValue) => {
    if (!dataCategoryOptions || dataCategoryOptions.length === 0) {
      return [];
    }
    return dataCategoryOptions.map((item) => ({
      value: item.id,
      label: item.title.replace(/\d/g, ''),
    })).filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };


  const handleSelectChangeCategory = (selectedOption) => {
    setSelectedValueCategory(selectedOption);
    if (type === 'Filter') {
      if (selectedOption === null) {
        dispatch(setState({
          ...fieldState,
          'filter[category_id]': '', // Kosongkan category_id
        }));
      } else {
        dispatch(setState({
          ...fieldState,
          'filter[category_id]': selectedOption.value, // Atur category_id sesuai dengan nilai yang dipilih
        }));
      }
    } else {
      if (selectedOption === null) {
        console.log('Selected Value:', selectedOption);
        dispatch(setState({ field: 'category', value: '' })); // Atur category ke null
      } else {
        console.log('Selected Value:', selectedOption);
        dispatch(setState({ field: 'category', value: selectedOption.value })); // Atur category ke nilai yang dipilih
      }
    }
  };


  return (
  
      <Form>
        <FormGroup>
        {/* {dataCategoryOptions && ( */}
          <AsyncSelectCommon label={'Pilih Kategori'} placeholder={'Silahkan pilih Kategori...'}
            promiseOptions={promiseCategoryOptions}
            handleSelectChange={handleSelectChangeCategory}
            selectedValue={selectedValueCategory}
          />
        {/* )} */}
        </FormGroup>
      </Form>

  );
};

export default FormSelectOption;
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Btn } from '../../../../AbstractElements';
import { Close, SaveChanges } from '../../../../Constant/index';
import { detachTryout, attachTryout } from '../../../../Features/Learning/Tryout/Utils/TryoutUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAttachments } from '../../../../Features/Learning/Tryout/attachTryoutSlice';

const CommonModal = (props) => {
  // const dispatch = useDispatch();
  // const history = useNavigate();
  // const data = useSelector(state => state.attachTryout)
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = dispatch(attachTryout(data));
  //     if (response.payload === true) {
  //       props.toggler();
  //     } 
  //   } catch (error) {
  //     // Tanggapan error
  //     console.error('Error:', error);
  //     // Modal tidak akan ditutup jika terjadi kesalahan
  //   }
  // };

  // console.log(data);
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} onOpened={props.onOpened} backdrop="static" centered>
      <ModalHeader toggle={props.toggler}>
        {props.title}
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.children}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'warning', onClick: props.toggler }} >{Close}</Btn>
        <Btn attrBtn={{ color: 'primary', onClick: props.handleSubmit }} >{'Submit'}</Btn>
      </ModalFooter>
    </Modal>
  );
};

export default CommonModal;
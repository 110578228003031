import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, FormGroup, Label } from 'reactstrap';
import { Post, Discard } from '../../../Constant';
import { Btn, H5, H6, Spinner } from '../../../AbstractElements';
import TryoutForm from './TryoutForm';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { submitTryout } from '../../../Features/Learning/Tryout/Utils/TryoutUtils';
import { resetState, setField } from '../../../Features/Learning/Tryout/addTryoutSlice';
import DropzoneCommon from '../../../Component/Learning/Common/Upload/Dropzone';
import AsyncSelectCommon from '../../../Component/Learning/Common/SelectOption/AsyncSelect';
import VideoPlayer from '../../../Component/Learning/DetailSubMateri/VideoPlayer';
import { fetchCategories } from '../../../Features/Learning/Categories/Utils/CategoriesUtils';
import Swal from 'sweetalert2';


const TryoutPostContain = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const data = useSelector(state => state.addTryout)
  const [selectedValue, setSelectedValue] = useState(null);
  const filtersCategory = useSelector(state => state.Categories.filters)
  const startUpload = useSelector(state => state.addTryout.isLoading);

  useEffect(() => {
    const selectedCategory = data.category && data.category.id
      ? [{
        value: data.category.id,
        label: data.category.title,
      }]
      : selectedValue;
    setSelectedValue(selectedCategory);
  }, [data.id]);

  const handleSuccess = () => {
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/learning/tryout-list`);
  };

  const showError = (errorMessage) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: errorMessage,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data);
    let errorMessage = '';
    if (!data.isUpdate) {
      if (data.title === '' || data.description === '' || data.description === '<p><br></p>' || data.time === '' || data.start_at === '' || data.end_at === '' || data.quotas === '' || data.banner === '' || data.category === '' || data.is_quiz === '') {
        if (data.title === '') {
          errorMessage = 'Judul belum diisi.';
        } else if (data.is_quiz === '') {
          errorMessage = 'Jenis Tryout belum dipilih.';
        } else if (data.time === '') {
          errorMessage = 'Durasi belum diisi.';
        } else if (data.start_at === '') {
          errorMessage = 'Waktu Mulai belum diisi.';
        } else if (data.end_at === '') {
          errorMessage = 'Waktu Berakhir belum diisi.';
        } else if (data.quotas === '') {
          errorMessage = 'Kuota Peserta belum diisi.';
        } else if (data.description === '' || data.description === '<p><br></p>') {
          errorMessage = 'Deskripsi belum diisi.';
        } else if (data.banner === '') {
          errorMessage = 'Banner belum dipilih.';
        } else if (data.category === '') {
          errorMessage = 'Kategori belum dipilih.';
        }
        showError(errorMessage);
      } else {
        dispatch(submitTryout({ ...data, successCallback: handleSuccess }))
      }
    } else if (data.isUpdate) {
      if (data.title === '' || data.description === '' || data.description === '<p><br></p>' || data.time === '' || data.start_at === '' || data.end_at === '' || data.quotas === '' || data.category === '' || data.is_quiz === '') {
        if (data.title === '') {
          errorMessage = 'Judul belum diisi.';
        } else if (data.is_quiz === '') {
          errorMessage = 'Jenis Tryout belum dipilih.';
        } else if (data.time === '') {
          errorMessage = 'Durasi belum diisi.';
        } else if (data.start_at === '') {
          errorMessage = 'Waktu Mulai belum diisi.';
        } else if (data.end_at === '') {
          errorMessage = 'Waktu Berakhir belum diisi.';
        } else if (data.quotas === '') {
          errorMessage = 'Kuota Peserta belum diisi.';
        } else if (data.description === '' || data.description === '<p><br></p>') {
          errorMessage = 'Deskripsi belum diisi.';
        } else if (data.banner === '') {
          errorMessage = 'Banner belum dipilih.';
        } else if (data.category === '') {
          errorMessage = 'Kategori belum dipilih.';
        }
        showError(errorMessage);
      } else {
        dispatch(submitTryout({ ...data, successCallback: handleSuccess }))
      }
    }
  };


  const handleDiscard = (e) => {
    e.preventDefault();
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/learning/tryout-list`);
  };

  const handleChangeBanner = ({ meta, file }, status) => {
    if (status === 'removed') {
      dispatch(setField({ field: 'banner', value: '' }));
    } else if (status === 'done') {
      dispatch(setField({ field: 'banner', value: file }));
    }
  };

  const handleChangeAds = ({ meta, file }, status) => {
    if (status === 'removed') {
      dispatch(setField({ field: 'ads_video', value: '' }));
    } else if (status === 'done') {
      dispatch(setField({ field: 'ads_video', value: file }));
    }
  };

  const handleChangeTutorial = ({ meta, file }, status) => {
    if (status === 'removed') {
      dispatch(setField({ field: 'tutorial_video', value: '' }));
    } else if (status === 'done') {
      dispatch(setField({ field: 'tutorial_video', value: file }));
    }
  };
  
  const promiseOptions = (inputValue, callback) => {
    dispatch(fetchCategories({
      ...filtersCategory,
      'filter[is_parent]': '1',
      'filter[title]': inputValue,
    }))
      .then((response) => {
        const options = response.payload.data.map(item => ({
          value: item.id,
          label: item.title,
        }));
        callback(options);
      })
      .catch((error) => {
        console.error('Error fetching options:', error);
        callback([]);
      });
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    if (selectedOption === null) {
      dispatch(setField({ field: 'category', value: '' }));
      setSelectedValue(null);
    } else {
      dispatch(setField({ field: 'category', value: selectedOption.value }));
      setSelectedValue(selectedOption);
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        {startUpload ? (
          <Col lg="12">
            <div className="loader-box">
              <Spinner attrSpinner={{ className: 'loader-30' }} />
            </div>
            <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses unggah...'}</H6>
          </Col>
        ) : (
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className='pb-0'><H5>{data.isUpdate ? '' : 'Tryout Baru'}</H5></CardHeader>
              <CardBody className="add-post">
                <TryoutForm />
                <DropzoneCommon label={'Banner Tryout'} inputContent={data.bannerLink ? 'Banner tryout tersedia' : 'Silahkan upload gambar.'} handleChangeStatus={handleChangeBanner} accept="image/*" />
                <AsyncSelectCommon label={'Pilih Kategori'} placeholder={'Silahkan pilih Kategori...'}
                  isMulti={false}
                  promiseOptions={promiseOptions}
                  handleSelectChange={handleSelectChange}
                  selectedValue={selectedValue}
                />
                {(data.isUpdate === true && data.tutorial_videoLink) && (
                  <FormGroup className='mb-3'>
                    <Label for="validationImage" style={{ fontWeight: 'bold' }}>Preview Video Tutorial:</Label>
                    <VideoPlayer link={data.tutorial_videoLink} width={720} />
                  </FormGroup>
                )}  
                <DropzoneCommon label={'Video Tutorial'} inputContent={data.ads_videoLink ? 'video tutorial tersedia' : 'Silahkan upload video tutorial'} handleChangeStatus={handleChangeTutorial} accept=".mp4" />
                {(data.isUpdate === true && data.ads_videoLink) && (
                  <FormGroup className='mb-3'>
                    <Label for="validationImage" style={{ fontWeight: 'bold' }}>Preview Video Iklan:</Label>
                    <VideoPlayer link={data.ads_videoLink} width={720} />
                  </FormGroup>
                )}                
                <DropzoneCommon label={'Video Iklan'} inputContent={data.tutorial_videoLink ? 'video iklan tersedia' : 'Silahkan upload video iklan'} handleChangeStatus={handleChangeAds} accept=".mp4" />
                <div className="btn-showcase text-end">
                  <Btn attrBtn={{ color: 'primary', type: 'submit', onClick: handleSubmit }}>{Post}</Btn>
                  <Btn attrBtn={{ color: 'light', type: 'reset', onClick: handleDiscard }}>{Discard}</Btn>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        )}
      </Container>
    </Fragment>
  );
};

export default TryoutPostContain;
import { createSlice } from '@reduxjs/toolkit';
import { attachDetachSubMaterial } from './Utils/SubMateriUtils';

const initialState = {
    method: '',
    type: 'posttest',
    idSubmateri: '',
    idTryout: '',
    isLoading: false,
};

const attachDetachSubMaterialSlice = createSlice(
    {
        name: 'attachDetachSubMaterial',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(attachDetachSubMaterial.pending, (state) => {
                    // Handle loading state
                    state.isLoading = true;
                })
                .addCase(attachDetachSubMaterial.fulfilled, (state, action) => {
                    state.isLoading = false;
                })
                .addCase(attachDetachSubMaterial.rejected, (state, action) => {
                    state.isLoading = false;
                });
        }
    }
);


export const { setField, resetState } = attachDetachSubMaterialSlice.actions;

export default attachDetachSubMaterialSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";
import { fetchCourses } from "./Utils/CoursesUtils";

const CourseSlice = createSlice({
    name: "Course",
    initialState: {
        filters: {
        "filter[id]": "",
        "filter[title]": "",
        "sort": "",
        "page": 1,
        },
        data: [],
        detail: {},
        isLoading: false,
    },
    reducers: {
        setFilters: (state, action) => {
        state.filters = action.payload;
        },
        setData: (state, action) => {
        state.data = action.payload;
        },
        setDetail: (state, action) => {
        state.detail = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchCourses.pending, (state) => {
            state.isLoading = true;
            // Handle loading state
        })
        .addCase(fetchCourses.fulfilled, (state, action) => {
            state.isLoading = false;
            // Handle success state
            state.data = action.payload;
        })
        .addCase(fetchCourses.rejected, (state, action) => {
            state.isLoading = false;
            // Handle error state
        });
    }
    });

export const { setFilters, setData, setDetail } = CourseSlice.actions;

export default CourseSlice.reducer;



import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label, Collapse } from 'reactstrap';
import { Search } from 'react-feather';
import Select from 'react-select';
import { Filter } from '../../../../Constant';
import { H5, Btn } from '../../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../../Features/Learning/Transactions/transactionSlice';
import { fetchTransaction } from '../../Transaction/TransactionUtils';

const FindTransaction = () => {
    const [isFilter, setIsFilter] = useState(true);
    const dispatch = useDispatch()
    const filters = useSelector(state => state.Transaction.filters)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

    const dataOptions = [
        { value: 'bank_transfer,echannel', label: 'Bank Transfer' },
        { value: 'free', label: 'FREE'},
        { value: 'qris', label: 'QRIS' },
        { value: 'gopay', label: 'Gopay' },
    ];

    useEffect(() => {
        if (filters['filter[payment_method]'] !== '') {
            const selected = dataOptions.find((item) => item.value === filters['filter[payment_method]']);
            setSelectedPaymentMethod(selected);
        } else {
            setSelectedPaymentMethod(null);
        }
    }, [filters['filter[payment_method]']]);

    const handleSelectChange = (selectedOption) => {
        if (selectedOption !== null) {
            setSelectedPaymentMethod(selectedOption);
            const newFilters = {
                ...filters,
                'filter[payment_method]': selectedOption.value
            };
            dispatch(setFilters(newFilters));
            fetchTransaction(dispatch, newFilters);
        } else {
            setSelectedPaymentMethod(null);
            const newFilters = {
                ...filters,
                'filter[payment_method]': ''
            };
            dispatch(setFilters(newFilters));
            fetchTransaction(dispatch, newFilters);
        }
    };

    const handleClickSearch = (e) => {
        e.preventDefault()
        const newFilters = {
            ...filters,
            'page': 1,
        };
        dispatch(setFilters(newFilters));
        fetchTransaction(dispatch, newFilters);
    }


    const handleResetFilter = () => {
        const newFilters = {
            ...filters,
            "filter[user.name]": "",
            "filter[user.email]": "",
            "filter[user.phone]": "",
            "filter[membership.title]": "",
            "filter[status]": "",
            "filter[payment_method]": "",
            "sort": "",
            "page": "",
        };

        dispatch(setFilters(newFilters));
        fetchTransaction(dispatch, newFilters);
        setSelectedPaymentMethod(null);
    }

    const updateFilters = (filters, dispatch, field, value) => {
        dispatch(setFilters({
            ...filters,
            [field]: value,
        }));
    };

    const handleSortBy = (filters, dispatch) => (e) => {
        const filterValue = e.target.value;
        updateFilters(filters, dispatch, e.target.name, filterValue);
    };


    return (
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardHeader>
                        <H5 attrH5={{ className: 'mb-0' }} >
                            <Btn attrBtn={{ className: 'btn btn-link ps-0', onClick: () => setIsFilter(!isFilter), color: 'transperant', datastoggle: 'collapse', databstarget: '#collapseicon', ariaexpanded: 'true', ariacontrols: 'collapseicon' }} >{'Temukan Transaksi'}</Btn>
                        </H5>
                    </CardHeader>
                    <Collapse isOpen={isFilter}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                            <CardBody className="filter-cards-view animate-chk">
                                <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <Input className="form-control" type="text" placeholder="Cari transaksi id.."
                                            value={filters['filter[id]']}
                                            onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                                            onChange={(e) => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[id]': e.target.value
                                                }));
                                            }}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div>
                                <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <Input className="form-control" type="text" placeholder="Cari nama.."
                                            value={filters['filter[user.name]']}
                                            onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                                            onChange={(e) => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[user.name]': e.target.value
                                                }));
                                            }}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div>
                                <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <Input className="form-control" type="text" placeholder="Cari email.."
                                            value={filters['filter[user.email]']}
                                            onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                                            onChange={(e) => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[user.email]': e.target.value
                                                }));
                                            }}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div>
                                <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <Input className="form-control" type="number" placeholder="Cari nomer telp.."
                                            value={filters['filter[user.phone]']}
                                            onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                                            onChange={(e) => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[user.phone]': e.target.value
                                                }));
                                            }}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div>
                                <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <Input className="form-control" type="text" placeholder="Cari membership.."
                                            value={filters['filter[membership.title]']}
                                            onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                                            onChange={(e) => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[membership.title]': e.target.value
                                                }));
                                            }}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div>
                                <div className="checkbox-animated mt-0">
                                    <div className="learning-header"><span className="f-w-600">Status:</span></div>
                                    <Label className="d-block" htmlFor="is_statusPending">
                                        <Input className="radio_animated" id="is_statusPending" type="radio" name="filter[status]"
                                            value="pending"
                                            checked={filters['filter[status]'] === 'pending'}
                                            onChange={handleSortBy(filters, dispatch)}
                                        />
                                        {'Menunggu Pembayaran'}
                                    </Label>
                                    <Label className="d-block" htmlFor="is_statusPaid">
                                        <Input className="radio_animated" id="is_statusPaid" type="radio" name="filter[status]"
                                            value="paid"
                                            checked={filters['filter[status]'] === 'paid'}
                                            onChange={handleSortBy(filters, dispatch)}
                                        />
                                        {'Sudah Dibayar'}
                                    </Label>
                                    <Label className="d-block" htmlFor="is_statusCancelled">
                                        <Input className="radio_animated" id="is_statusCancelled" type="radio" name="filter[status]"
                                            value="cancelled"
                                            checked={filters['filter[status]'] === 'cancelled'}
                                            onChange={handleSortBy(filters, dispatch)}
                                        />
                                        {'Sudah Dibayar'}
                                    </Label>
                                </div>
                                <div className="checkbox-animated mt-0">
                                    <div className="learning-header"><span className="f-w-600">Metode Pembayaran:</span></div>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Pilihlah metode"
                                        isClearable
                                        isSearchable
                                        name="color"
                                        options={dataOptions}
                                        onChange={handleSelectChange}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.value}
                                        value={selectedPaymentMethod}
                                    />
                                </div>
                                <div className="checkbox-animated mt-0">
                                    <div className="learning-header"><span className="f-w-600">Sort by:</span></div>
                                    <Label className="d-block" htmlFor="sort-new">
                                        <Input className="radio_animated" id="sort-new" type="radio" name="sort"
                                            checked={filters.sort === '-created_at'}
                                            value="-created_at"
                                            onChange={handleSortBy(filters, dispatch)}
                                        />
                                        {'Terbaru'}
                                    </Label>
                                    <Label className="d-block" htmlFor="sort-old">
                                        <Input className="radio_animated" id="sort-old" type="radio" name="sort"
                                            checked={filters.sort === 'created_at'}
                                            value="created_at"
                                            onChange={handleSortBy(filters, dispatch)}
                                        />
                                        {'Terlama'}
                                    </Label>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Btn attrBtn={{ color: 'primary text-center p-1', onClick: handleClickSearch }}>{Filter}</Btn>
                                    </div>
                                    <div className="col-md-6">
                                        <Btn attrBtn={{ color: 'warning text-center p-1', onClick: handleResetFilter }}>{'Reset'}</Btn>
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default FindTransaction;
import React, { Fragment, useState } from 'react';
import { Col, Card, CardHeader, CardBody, Input, Collapse, Label } from 'reactstrap';
import { Search } from 'react-feather';
import { Filter } from '../../../Constant';
import { H5, Btn } from '../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../Features/Learning/Tryout/tryoutSlice';
import { fetchTryout } from '../../../Features/Learning/Tryout/Utils/TryoutUtils';
import TryoutCheckBox from './TryoutCheckBox/TryoutCheckSort';
import TryoutCheckDate from './TryoutCheckBox/TryoutCheckonGoing';
import { fetchTryouts } from '../Tryout/TryoutUtils';
// import TryoutCheckSelect from '../../../Component/Learning/Tryout/TryoutSelectForm';
import RadioFilter from '../../../Component/Learning/Common/Filter/RadioFilter';
import TryoutCheckSelect from '../../../Component/Learning/Tryout/SelectOption';


const FindTryout = () => {
  const [isFilter, setIsFilter] = useState(true);
  const dispatch = useDispatch()
  const filters = useSelector(state => state.Tryout.filters)
  const dataCategoryOptions = useSelector(state => state.Categories.data);
  const [inputValue, setInputValue] = useState('');
  const [inputRadioValue, setInputRadioValue] = useState('');
  const [selectedValueCategory, setSelectedValueCategory] = useState(null);
  const [inputDateValue, setInputDateValue] = useState(null);

  const handleSortBy = (filters, dispatch) => (e) => {
    const filterValue = e.target.value;
    dispatch(setFilters({
      ...filters,
      [e.target.name]: filterValue,
    }));
  };

  const handleClickSearch = (e) => {
    e.preventDefault()
    const newFilters = {
      ...filters,
      'page': 1,
    };
    dispatch(setFilters(newFilters));
    fetchTryouts(dispatch, newFilters);
  }

  const handleResetFilter = (e) => {
    e.preventDefault()
    setInputValue('');
    setInputRadioValue('');
    setInputDateValue(null);
    setSelectedValueCategory(null)
    const newFilters = {
      ...filters,
      'filter[category_id]': '',
      'filter[title]': '',
      'filter[id]': '',
      'filter[onGoing]': '',
      'filter[is_quiz]': '',
      'sort': '',
      'page': 1,
      'include[0]': 'category',
      'include[1]': 'questions',
    }
    fetchTryouts(dispatch, newFilters);
    dispatch(setFilters(newFilters));
  }

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: 'mb-0' }} >
              <Btn attrBtn={{ className: 'btn btn-link ps-0', onClick: () => setIsFilter(!isFilter), color: 'transperant', datastoggle: 'collapse', databstarget: '#collapseicon', ariaexpanded: 'true', ariacontrols: 'collapseicon' }} >{'Temukan Tryout'}</Btn>
            </H5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
              <CardBody className="filter-cards-view animate-chk">
                <div className="job-filter">
                  <div className="faq-form">
                    <Input className="form-control" type="text" placeholder="Search.." 
                      value={filters['filter[title]']}
                      onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e): null}
                      onChange={(e) => {
                        dispatch(setFilters({
                          ...filters,
                          'filter[title]': e.target.value
                        }));
                        setInputValue(e.target.value);
                      }}
                    />
                    <Search className="search-icon" />
                  </div>
                </div>
                <RadioFilter sort={'Tipe :'}>
                  <Label className="d-block" htmlFor="is_quizFalse">
                    <Input className="radio_animated" id="is_quizFalse" type="radio" name="filter[is_quiz]"
                      value="0"
                      checked={filters['filter[is_quiz]'] === '0'}
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    Tryout
                  </Label>
                  <Label className="d-block" htmlFor="is_quizTrue">
                    <Input className="radio_animated" id="is_quizTrue" type="radio" name="filter[is_quiz]"
                      value="1"
                      checked={filters['filter[is_quiz]'] === '1'}
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    Kuis
                  </Label>
                </RadioFilter>
                <TryoutCheckBox inputRadioValue={inputRadioValue} setInputRadioValue={setInputRadioValue} />
                <TryoutCheckSelect type={'Filter'} selectedValueCategory={selectedValueCategory} setSelectedValueCategory={setSelectedValueCategory} setState={setFilters} fieldState={filters}/>
                <TryoutCheckDate inputDateValue={inputDateValue} setInputDateValue={setInputDateValue} />
                <div className="row">
                  <div className="col-md-6">
                    <Btn attrBtn={{ color: 'primary text-center', onClick: handleClickSearch }}>{Filter}</Btn>
                  </div>
                  <div className="col-md-6">
                    <Btn attrBtn={{ color: 'warning text-center', onClick: handleResetFilter }}>{'Reset'}</Btn>
                  </div>
                </div>
              </CardBody>
            </div>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default FindTryout;
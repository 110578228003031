import { createSlice } from '@reduxjs/toolkit';
import { fetchCategories } from './Utils/CategoriesUtils';


const initialState = {
    filters: {
        'filter[id]': '',
        'filter[title]': '',
        'filter[is_parent]': '',
        'sort': '',
        'page': '',
    },
    data: [],
    isLoading: false,
};

const CategoriesSlice = createSlice({
    name: 'Categories',
    initialState: initialState,
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        
    },
    extraReducers: (builder) => { // Handle  action creators from createAsyncThunk
        builder
            .addCase(fetchCategories.pending, (state) => {
                // Handle loading state
                state.isLoading = true;
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                // Handle success state
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                // Handle error state
                console.log('rejected',action.error);
                state.isLoading = false;
            });
    }
});

export const { setFilters, setData } = CategoriesSlice.actions;

export default CategoriesSlice.reducer;

import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import DetailsUserContain from '../../../Component/Learning/Users/Detail';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDetailUser } from '../../../Component/Learning/Users/UsersUtils';
import { fetchUsers } from '../../../Features/Learning/Users/Utils/UserUtils';
import { setDetail } from '../../../Features/Learning/Users/userSlice';

const DetailUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const filters = useSelector(state => state.User.filters)
  const dataField = useSelector(state => state.User.detail);

  useEffect(() => {
    if (id) {
      const newFilters = {
        ...filters,
        "filter[id]": id,
        "page": 1,
      };
      dispatch(fetchUsers(newFilters))
        .then((response) => {
          // Kode yang akan dijalankan setelah fetch sukses
          getDetailUser(dispatch, response.payload.data[0], setDetail)
        })
        .catch((error) => {
          // Tangani error jika fetch gagal
          console.error('Error during fetchUsers:', error);
        });
    }
  } , [id]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Detail User" parent="Users" title="Detail User" />
          <DetailsUserContain />
    </Fragment>
  );
};
export default DetailUser;
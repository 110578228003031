import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import TryoutDetail from './TryoutDetail';

const DetailTryout = () => {

  return (
    <Fragment>
      <Container fluid={true}>
        <Row className='learning-block'>
          <TryoutDetail />
        </Row>
      </Container>
    </Fragment>
  );
};

export default DetailTryout;
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import AsyncSelectCommon from '../../Learning/Common/SelectOption/AsyncSelect.jsx';
import { fetchMemberships } from "../../../Features/Learning/Memberships/Utils/MembershipUtils.jsx";

const AsynchSelectMembership = ({ handleSelectChange }) => {
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState(null);
    const promiseOption = (inputValue, callback) => {
        dispatch(fetchMemberships({
            'filter[title]': inputValue,
            'page': 1,
        }))
            .then((response) => {
                const options = response.payload.data.map(item => ({
                    value: item.id,
                    label: item.title,
                }));
                callback(options);
            })
            .catch((error) => {
                console.error('Error fetching options:', error);
                callback([]);
            });
    };

    return (
        <Fragment>
            <AsyncSelectCommon 
                label={'Pilih Main Membership'} 
                placeholder={'Silahkan pilih Main Membership...'}
                isMulti={false}
                promiseOptions={promiseOption}
                handleSelectChange={(selectedOption) => {
                    setSelectedValue(selectedOption === null ? '' : selectedOption)
                    handleSelectChange(selectedOption === null ? '' : selectedOption.value);
                }}
                selectedValue={selectedValue}
            />
        </Fragment>
    );
}

export default AsynchSelectMembership;
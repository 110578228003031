import React, { Fragment, useEffect, useState } from "react";
import {
	Col,
	Card,
	CardFooter,
	Row,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Btn } from "../../../../AbstractElements";
import {
	fetchMemberships,
	upgradeUserMembership,
} from "../../../../Features/Learning/Memberships/Utils/MembershipUtils";
import AsyncSelectCommon from "../../../../Component/Learning/Common/SelectOption/AsyncSelect";
import "moment/locale/id";
import Swal from "sweetalert2";

const MembershipUpgrade = ({ upgradeData, open, setOpen }) => {
	const dispatch = useDispatch();
	const filterMembership = useSelector((state) => state.Membership.filters);
	const [modal, setModal] = useState(open || false);
	const [selectedValueMembership, setSelectedValueMembership] = useState(null);

	useEffect(() => {
		if (open) {
			setModal(true);
		}
	}, [open]);

	const handleToggle = () => {
		setModal(!modal);
		setOpen(!modal);
	};

	const promiseOptionMemberships = (inputValue, callback) => {
		dispatch(
			fetchMemberships({
				...filterMembership,
				"filter[title]": inputValue,
				sort: "-id",
				page: 1,
				include: "",
			})
		)
			.then((response) => {
				const options = response.payload.data.map((item) => ({
					value: item.id,
					label: item.title,
				}));
				callback(options);
			})
			.catch((error) => {
				console.error("Error fetching options:", error);
				callback([]);
			});
	};

	const handleSelectChangeMembership = (selectedOption) => {
		setSelectedValueMembership(selectedOption);
	};

	const handleSubmit = async () => {
		Swal.fire({
			title: "Proses tidak dapat dikembalikan! Apakah anda yakin?",
			text:
				upgradeData.userName +
				" upgrade dari " +
				upgradeData.oldMembershipName +
				" ke " +
				selectedValueMembership.label,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Ya, Upgrade!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				const data = {
					user_id: upgradeData.userId,
					membership_id_old: upgradeData.oldMembershipId,
					membership_id_new: selectedValueMembership.value,
				};
				const response = await dispatch(upgradeUserMembership(data));
				if (response.payload === true) {
					Swal.fire("Berhasil!", "User berhasil diupgrade", "success");
					setModal(false);
					setOpen(false);
				} else {
					Swal.fire("Gagal!", "User gagal diupgrade", "error");
				}
			}
		});
	};

	return (
		<Fragment>
			<Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1 mb-3">
				<Card className="social-widget-card">
					<CardFooter>
						<Row>
							<Col className="text-end">
								<Modal
									isOpen={modal}
									toggle={handleToggle}
									backdrop="static"
									keyboard={false}
									centered>
									<ModalHeader toggle={handleToggle}>
										Upgrade Membership dari {upgradeData.oldMembershipName}
									</ModalHeader>
									<ModalBody>
										<AsyncSelectCommon
											label={"Membership"}
											placeholder={"Silahkan pilih membership..."}
											isMulti={false}
											promiseOptions={promiseOptionMemberships}
											handleSelectChange={(selectedOption) =>
												handleSelectChangeMembership(selectedOption)
											}
											selectedValue={selectedValueMembership}
										/>
										<p style={{ marginBottom: 0 }}>Catatan upgrade:</p>
										<p style={{ marginBottom: 0 }}>
											# Upgrade tidak akan mengubah masa aktif membership,
											mengikuti membership lama.
										</p>
										<p>
											# Apabila user memiliki 2 membership yang sama (misal 1
											kadaluarsa dan 1 aktif), maka keduanya akan diupgrade.
										</p>
									</ModalBody>
									<ModalFooter>
										<Btn attrBtn={{ color: "warning", onClick: handleToggle }}>
											Batal
										</Btn>
										<Btn attrBtn={{ color: "primary", onClick: handleSubmit }}>
											Upgrade
										</Btn>
									</ModalFooter>
								</Modal>
							</Col>
						</Row>
					</CardFooter>
				</Card>
			</Col>
		</Fragment>
	);
};
export default MembershipUpgrade;

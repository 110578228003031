import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
	Breadcrumbs,
	Image,
	H6,
	H5,
	H4,
	LI,
	UL,
	P,
	Btn,
	Spinner,
} from "../../../../AbstractElements";
import {
	Col,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Table,
	Row,
	Input,
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";
import { fetchDetailMemberClass } from "../../../../Features/Classes/Class/Utils/ClassUtils";
import {
	fetchSchedules,
	attachDetachUserToSchedule,
} from "../../../../Features/Classes/Schedule/Utils/ScheduleUtils";
import {
	setField,
	resetState,
} from "../../../../Features/Classes/Schedule/attachDetachUserToScheduleSlice";
import { handleGetDetailSchedule } from "../../../../Component/Classes/Schedule/ScheduleUtils";
import { setDetail } from "../../../../Features/Classes/Schedule/ScheduleSlice";
import Swal from "sweetalert2";

const DetailUserSchedule = () => {
	const { classId, scheduleId } = useParams();
	const dispatch = useDispatch();
	// const [selectedItems, setSelectedItems] = useState([]); // State untuk menyimpan ID-item yang dipilih
	const detail = useSelector((state) => state.Schedule.detail);
	const filterSchedule = useSelector((state) => state.Schedule.filters);
	const dataMember = useSelector((state) => state.detailMemberClass.data.data);
	const [userAttendance, setUserAttendance] = useState([]); // State untuk menyimpan data user yang sudah terdaftar di sesi
	const selectedItems = useSelector(
		(state) => state.attachDetachUserToSchedule.userId
	);
	const data = useSelector((state) => state.attachDetachUserToSchedule);
	const [refatch, setRefatch] = useState(false);
	const [open, setOpen] = useState('');

	useEffect(() => {
		dispatch(fetchDetailMemberClass(classId));
		dispatch(setField({ field: "scheduleId", value: parseInt(scheduleId) }));
	}, [refatch]);

	useEffect(() => {
		// Memeriksa apakah detail.attendance tidak kosong
		if (detail.attendance && detail.attendance.length > 0) {
			// Mengambil hanya id dari detail.attendance
			const selectedIds = detail.attendance.map((item) => item.user.id);
			// Mengatur selectedItems ke Redux state userId
			setUserAttendance(selectedIds);
			dispatch(setField({ field: "userId", value: selectedIds }));
		} else {
			// Jika detail.attendance kosong, mengatur userId di Redux state menjadi kosong
			dispatch(setField({ field: "userId", value: [] }));
		}
	}, [detail.attendance]);

	const handleCheckboxChange = (id) => {
		let updatedSelectedItems = [...selectedItems];
		if (updatedSelectedItems.includes(id)) {
			updatedSelectedItems = updatedSelectedItems.filter((item) => item !== id);
		} else {
			updatedSelectedItems.push(id);
		}
		dispatch(setField({ field: "userId", value: updatedSelectedItems }));
	};

	const handleSelectAll = (index) => {
		const selectedIds = dataMember[index].members.map((member) => member.id);
		const isAllSelected = selectedIds.every((id) => selectedItems.includes(id));

		if (isAllSelected) {
			const updatedSelectedItems = selectedItems.filter(
				(id) => !selectedIds.includes(id)
			);
			dispatch(setField({ field: "userId", value: updatedSelectedItems }));
		} else {
			const newSelectedItems = selectedItems.concat(
				selectedIds.filter((id) => !selectedItems.includes(id))
			);
			dispatch(setField({ field: "userId", value: newSelectedItems }));
		}
	};

	const handleAttachDetachUserToSchedule = async () => {
		try {
			if (selectedItems.length === 0) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Pilih peserta terlebih dahulu!",
				});
			} else {
				const confirmResult = await Swal.fire({
					title: "Apakah Anda yakin?",
					text: "Anda akan mengubah peserta sesi ini.",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Ya, Lanjutkan!",
					cancelButtonText: "Batal",
				});

				if (confirmResult.isConfirmed) {
					console.log(data, "data attach");
					const response = await dispatch(attachDetachUserToSchedule(data));
					if (response.payload) {
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Peserta sesi berhasil diubah.",
						});
						dispatch(resetState());
						setRefatch(!refatch);
						const newFilters = {
							...filterSchedule,
							"filter[id]": scheduleId,
							page: 1,
						};
						dispatch(fetchSchedules(newFilters))
							.then((response) => {
								handleGetDetailSchedule(
									dispatch,
									response.payload.data[0],
									setDetail
								);
							})
							.catch((error) => {
								console.error("Error during fetchSchedule:", error);
							});
					} else {
						Swal.fire({
							title: "Gagal",
							text: "Ups, sepertinya ada kesalahan. Silahkan coba lagi",
							icon: "error",
							confirmButtonText: "OK",
						});
					}
				}
			}
		} catch (error) {
			console.error("Error during attachDetachUserToSchedule:", error);
		}
	};

	const toggle = (id) => {
		if (open === id) {
			setOpen('');
		} else {
			setOpen(id);
		}
	};


	return (
		<Fragment>
			<Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
				<Card className="social-widget-card" style={{ background: "none" }}>
					<CardHeader>
						<div className="d-flex justify-content-between align-items-center">
							<h5>Peserta Kelas {detail.name}</h5>
							<Btn
								attrBtn={{
									color: "primary",
									className: "float-right",
									onClick: handleAttachDetachUserToSchedule,
								}}>
								<i className="fa fa-check-square-o fa-lg mx-1"></i> Set Peserta
								Sesi
							</Btn>
						</div>
					</CardHeader>
					<CardBody>
						{dataMember && dataMember.length > 0 ? (
							<Row className="justify-content-center">
								{dataMember.map((item, index) => {
									return (
										<Col key={index} lg="6" className="mb-3">
											<Accordion key={index} open={open} toggle={() => toggle(`${index}`)}>
												<AccordionItem>
													<CardHeader
														style={{
															backgroundColor: "#2f707f",
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														}}
														className="py-3">
														<h6 className="text-white">{item.title} ({item.members.length})</h6>
														<AccordionHeader
															targetId={`${index}`}
															style={{
																background: "transparent",
															}}></AccordionHeader>
													</CardHeader>
													<AccordionBody accordionId={`${index}`}>
														<Table className="table-responsive table-bordered">
															<thead className="table-light">
																<tr>
																	<th
																		scope="col"
																		className="text-center"
																		style={{ width: "15px" }}>
																		#
																	</th>
																	<th scope="col" colSpan="2">
																		Nama
																	</th>
																	<th
																		scope="col"
																		className="text-center"
																		style={{ width: "15%" }}>
																		{/* Checkbox "Select All" di sini */}
																		<Input
																			className="checkbox_animated"
																			type="checkbox"
																			checked={dataMember[index].members.every(
																				(member) =>
																					selectedItems.includes(member.id)
																			)}
																			onChange={() => handleSelectAll(index)}
																		/>
																	</th>
																</tr>
															</thead>
															<tbody>
																{item.members &&
																	item.members.map((student, i) => (
																		<tr key={`student-${i}`}>
																			<th>{i + 1}</th>
																			<td>{student.name}</td>
																			<td
																				className="text-center"
																				style={{ width: "10%" }}>
																				<Link
																					to={`${process.env.PUBLIC_URL}/users/user-detail/${student.id}`}
																					title="Detail User"
																					className="p-1 float-right">
																					<i className="fa fa-info-circle mx-auto fa-lg"></i>
																				</Link>
																			</td>
																			{/* <td>{student.id}</td> */}
																			<td className="text-center">
																				{/* Checkbox untuk setiap item */}
																				<Input
																					className="checkbox_animated"
																					id={`select-${student.id}`}
																					type="checkbox"
																					checked={selectedItems.includes(
																						student.id
																					)}
																					onChange={() =>
																						handleCheckboxChange(student.id)
																					}
																				/>
																			</td>
																		</tr>
																	))}
															</tbody>
														</Table>
													</AccordionBody>
												</AccordionItem>
											</Accordion>
										</Col>
									);
								})}
							</Row>
						) : (
							<H6 attrH6={{ className: "text-center" }}>Belum ada peserta</H6>
						)}
					</CardBody>
					<CardFooter>
						<Col className="text-center">
							<Btn
								attrBtn={{
									color: "primary",
									className: "float-right",
									onClick: handleAttachDetachUserToSchedule,
								}}>
								<i className="fa fa-check-square-o fa-lg mx-1"></i> Set Peserta
								Sesi
							</Btn>
						</Col>
					</CardFooter>
				</Card>
			</Col>
		</Fragment>
	);
};
export default DetailUserSchedule;

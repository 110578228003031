import React, { Fragment, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Container, Row, Col, Card, Input, Label } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Btn } from '../../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import MainProgramContain from './MainProgramContain';
import MainProgramFilter from './Filter/MainProgramFilter';
import Filter from '../Common/Filter';
import RadioFilter from '../../../Component/Learning/Common/Filter/RadioFilter';
import { resetState } from '../../../Features/Learning/MainPrograms/addMainProgramSlice';
import { setFilters } from '../../../Features/Learning/MainPrograms/mainProgramSlice';
import { fetchMainProgram } from '../../../Features/Learning/MainPrograms/Utils/MainProgramUtils';
import { fetchMainPrograms } from './MainProgramUtils';


const MainProgramContainer = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const filters = useSelector(state => state.MainProgram.filters)
  const cards = useSelector(state => state.MainProgram.data.data)
  const [selectedOption, setSelectedOption] = useState(null)
  const [inputValue, setInputValue] = useState('');
  
  const handleAdd = (e) => {
    dispatch(resetState());
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Col md="12" className="project-list">
          {cards && (
          <Card>
            <Row>
              <Col>
              <Link to={`${process.env.PUBLIC_URL}/main-program/add`} onClick={handleAdd}>
                <Btn attrBtn={{ color: 'primary' }}>
                 <i className="fa fa-plus-circle fa-lg"></i> Tambah Program Utama
                </Btn>
              </Link>
              </Col>
            </Row>
          </Card>
          )}
        </Col>
        <Row className='learning-block'>
          <Col xl="9" className='xl-80'>
            <Row>
              <MainProgramContain />
            </Row>
          </Col>
          {cards && (
            <MainProgramFilter />
          )}
        </Row>
      </Container>
    </Fragment>
  );
};
export default MainProgramContainer;
import { createSlice } from '@reduxjs/toolkit';
import { submitSchedules } from './Utils/ScheduleUtils';

const initialState = {
    class_id: '',
    tentor_id: '',
    course_id: '',
    materials: [],
    material_id: '',
    name: '',
    QR_start_at: '',
    session_start_at: '',
    session_end_at: '',
    place: '',
    id: '',
    isLoading: false,
};

const addSchedule = createSlice(
    {
        name: 'addSchedule',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(submitSchedules.pending, (state) => {
                    // Handle loading state
                    state.isLoading = true;
                })
                .addCase(submitSchedules.fulfilled, (state, action) => {
                    // Handle success state
                    state.isLoading = false;
                })
                .addCase(submitSchedules.rejected, (state, action) => {
                    // Handle error state
                    state.isLoading = false;
                });
        }
    }
);

export const { setField, resetState } = addSchedule.actions;

export default addSchedule.reducer;
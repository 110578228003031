import React, { Fragment, useEffect, useState } from "react";
import { PlusCircle } from "react-feather";
import {
	Card,
	Col,
	Row,
	CardBody,
	CardHeader,
	CardFooter,
	Table,
	Label,
} from "reactstrap";
import {
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
	Image,
	Btn,
	LI,
	P,
	UL,
	Spinner,
	Badges,
} from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	setFilters,
	setDetail,
} from "../../../Features/Classes/Tentor/TentorSlice";
import {
	fetchTentor,
	handleDeleteTentor,
	handleEditTentor,
	handleGetDetailTentor,
	handleBackPagination,
	handleNextPagination,
	handleClickPagination,
} from "../../../Component/Classes/Tentor/TentorUtils";
import {
	setField,
	resetState,
} from "../../../Features/Classes/Tentor/addTentorSlice";
import PaginationComponent from "../../../Component/Learning/Common/Pagination";
import formatPhoneNumber from "../../../Component/Learning/Common/GenerateContent/FormatPhoneNumber";
import Moment from "moment";
import "moment/locale/id";

const TentorContain = () => {
	const dispatch = useDispatch();
	const filters = useSelector((state) => state.Tentor.filters);
	const isLoading = useSelector((state) => state.Tentor.isLoading);
	const cards = useSelector((state) => state.Tentor.data.data);
	const pagination = useSelector((state) => state.Tentor.data.pagination);
	const [refatch, setRefatch] = useState(false);

	useEffect(() => {
		fetchTentor(dispatch, filters, refatch);
		window.scrollTo(0, 0);
	}, [refatch]);

	return (
		<Fragment>
			{isLoading ? (
				<Col lg="12">
					<div className="loader-box">
						<Spinner attrSpinner={{ className: "loader-30" }} />
					</div>
					<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
						{"Data Loading..."}
					</H6>
				</Col>
			) : (
				cards &&
				cards.length > 0 && (
					<Col sm="12 order-2">
						<Card>
							<CardBody>
								<Table className="table-bordered table-striped table-hover">
									<thead>
										<tr>
											<th
												scope="col"
												className="bg-primary text-light"
												style={{ verticalAlign: "middle", width: "5%" }}>
												No
											</th>
											<th
												scope="col"
												className="bg-primary text-light"
												style={{ verticalAlign: "middle" }}>
												Nama Tentor
											</th>
											<th
												scope="col"
												className="bg-primary text-light"
												style={{ verticalAlign: "middle" }}>
												Materi Ajar
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Nomer Telepon
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ width: "15%", verticalAlign: "middle" }}>
												Aksi
											</th>
										</tr>
									</thead>
									<tbody>
										{cards &&
											cards.map((item, index) => (
												<tr key={`tentor-${index}`} className="table-light">
													<td>{pagination.from + index}</td>
													<td>
														<Image
															attrImage={{
																style: {
																	width: "40px",
																	height: "40px",
																	borderRadius: "50%",
																	border: "1px solid #eee",
																	marginRight: "10px",
																},
																src: item.photo,
																alt: "",
															}}
														/>
														{item.name}
													</td>
													<td>{item.mastered_fields}</td>
													<td>
														<a
															href={`https://wa.me/62${item.phone}?text=Halo%20${item.name},%20Kami%20dari%20Brofesional,`}
															target="_blank"
															rel="noreferrer"
															title="Hubungi via whatsapp">
															{formatPhoneNumber(item.phone)}
														</a>
													</td>
													<td className="text-center">
														<div>
															<Link
																to={`${process.env.PUBLIC_URL}/classes/tentor/detail/${item.id}`}
																title="Detail Tentor"
																className="p-1"
																onClick={() =>
																	handleGetDetailTentor(
																		dispatch,
																		item,
																		setDetail
																	)
																}>
																<i className="fa fa-info-circle mx-auto fa-lg"></i>
															</Link>
															<Link
																to={`${process.env.PUBLIC_URL}/classes/tentor/edit/${item.id}`}
																title="Edit"
																className="p-1"
																onClick={() =>
																	handleEditTentor(dispatch, item, setField)
																}>
																<i className="fa fa-edit mx-auto text-secondary fa-lg"></i>
															</Link>
															<Btn
																attrBtn={{
																	title: "Delete",
																	color: "link",
																	outline: true,
																	className: "p-1",
																	onClick: () =>
																		handleDeleteTentor(
																			dispatch,
																			item.id,
																			setRefatch
																		),
																}}>
																<i className="fa fa-trash-o mx-auto text-danger fa-lg"></i>
															</Btn>
														</div>
													</td>
												</tr>
											))}
									</tbody>
								</Table>
							</CardBody>
							<CardFooter>
								{pagination && (
									<PaginationComponent
										pagination={pagination}
										handleBackPagination={() =>
											handleBackPagination(
												pagination,
												filters,
												dispatch,
												setFilters,
												setRefatch
											)
										}
										handleClickPagination={(page) =>
											handleClickPagination(
												page,
												filters,
												dispatch,
												setFilters,
												setRefatch
											)
										}
										handleNextPagination={() =>
											handleNextPagination(
												pagination,
												filters,
												dispatch,
												setFilters,
												setRefatch
											)
										}
									/>
								)}
							</CardFooter>
						</Card>
					</Col>
				)
			)}

			{isLoading === false && !cards ? (
				<Col lg="12">
					<H3 attrH3={{ className: "sub-title mb-5 text-center" }}>
						Sumber Data Tidak Tersedia
					</H3>
				</Col>
			) : (
				isLoading === false &&
				cards.length === 0 && (
					<Col lg="12">
						<H3 attrH3={{ className: "sub-title mb-5 text-center" }}>
							Data Tidak Ditemukan
						</H3>
					</Col>
				)
			)}
		</Fragment>
	);
};

export default TentorContain;

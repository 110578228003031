import React, { Fragment, useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
	setAttachments,
	setScore,
} from "../../../../Features/Learning/Tryout/attachTryoutSlice";
import CommonModal from "../../Common/TryoutModal/Modal";
import { H6, Spinner } from "../../../../AbstractElements";
import RawHTMLComponent from "../../../../Component/Learning/Common/RawHTML";
import GenerateUntagHtml from "../../../../Component/Learning/Common/GenerateUntagHtml";
import AsyncSelectCommon from "../../../../Component/Learning/Common/SelectOption/AsyncSelect";
import { fetchMaterial } from "../../../../Features/Learning/Materi/Utils/MateriUtils";
import { fetchCategories } from "../../../../Features/Learning/Categories/Utils/CategoriesUtils";
import { fetchQuestion } from "../../../../Features/Learning/BankQuestions/Utils/QuestionsUtils";
import { setTypeQuestion } from "../../../../Features/Learning/Tryout/attachTryoutSlice";
import Swal from "sweetalert2";

const FormModal = ({ modal, NewMessage, toggle, handleSubmit, data }) => {
	const dispatch = useDispatch();
	const [selectedValueCategory, setSelectedValueCategory] = useState(null);
	const [selectedValueQuestion, setSelectedValueQuestion] = useState(null);
	const dataAttach = useSelector((state) => state.attachTryout);
	const dataDetailTryout = useSelector((state) => state.Tryout.detail);
	console.log(dataDetailTryout, "dataDetailTryout");
	const isLoading = useSelector((state) => state.attachTryout.isLoading);
	const dataAttachScore = useSelector(
		(state) => state.attachTryout.attachments.score
	);
	const dataQuestionOptions = useSelector((state) => state.Question.data);
	const isLoadingQuestion = useSelector((state) => state.Question.isLoading);
	// console.log('Data Attach Score = ', dataAttachScore)
	const filtersCategory = useSelector((state) => state.Categories.filters);
	const filtersQuestion = useSelector((state) => state.Question.filters);
	// console.log('Data Question Options = ', dataQuestionOptions)

	const [inputData, setInputData] = useState({ choice_id: "", point: "" });
	const [newScores, setNewScores] = useState([]);
	const [choiceData, setChoiceData] = useState([]);
	const [answerChoiceData, setAnswerChoiceData] = useState([]);
	const [answerData, setAnswerData] = useState([]);
	const [isAnswerDataReady, setIsAnswerDataReady] = useState(false);
	const [categoryOptions, setCategoryOptions] = useState([]);

	useEffect(() => {
		const categoryOptions = dataDetailTryout?.category?.children?.map(
			(item) => ({
				value: item.id,
				label: item.title.replace(/\d/g, ""),
			})
		);
		setCategoryOptions(categoryOptions);
	}, [dataDetailTryout]);

	const processAnswerData = (item) => {
		const answerChoice = item[0].answer[0].answer;
		const parsedAnswerData = JSON.parse(answerChoice);

		if (item[0].type === "multiple_choice") {
			setAnswerChoiceData(parsedAnswerData);
			const choicesMatchingAnswer = item[0].choices.filter((choice) =>
				parsedAnswerData.includes(choice.id.toString())
			);
			const questionChoicesContent = choicesMatchingAnswer.map(
				(choice) => choice.choice
			);
			setChoiceData(questionChoicesContent);
			if (NewMessage === "Update Pengaturan Soal") {
				// console.log(data.pivot.score, 'choice')
				dispatch(setScore(JSON.parse(data.pivot.score)));
			}
		} else if (item[0].type === "sentence_arrangement") {
			setAnswerChoiceData([]);
			const questionChoicesIdContent = item[0].choices.map(
				(choice) => choice.id
			);
			questionChoicesIdContent.forEach((item, index) => {
				dispatch(
					setScore([
						...dataAttachScore,
						{ ...dataAttachScore[index], choice_id: item, point: index },
					])
				);
			});
		} else if (item[0].type === "fill_in_the_blanks") {
			setAnswerChoiceData([]);
			dispatch(
				setScore([
					...dataAttachScore,
					{ ...dataAttachScore[0], choice_id: 2, point: 0 },
				])
			); // 2 adalah id dari jawaban benar
		}
	};

	const handleSelectChangeQuestion = (selectedOption) => {
		setSelectedValueQuestion(selectedOption);
		console.log("selectedOption", selectedOption);
		// dispatch(setScore(resetScore(dataAttach)))
		// validasi supaya ketika ganti select quiz score otomatis kosong
		if (selectedOption === null) {
			dispatch(
				setAttachments({
					...dataAttach.attachments,
					question_id: "",
					score: [],
				})
			);
			setNewScores([]);
			setChoiceData([]);
			setAnswerChoiceData([]);
			dispatch(setTypeQuestion(""));
		} else {
			dispatch(setAttachments({ ...dataAttach.attachments, score: [] }));
			dispatch(
				setAttachments({
					...dataAttach.attachments,
					question_id: selectedOption.value,
				})
			);
			dispatch(setTypeQuestion(selectedOption.type));
			// console.log('Selected Option = ', selectedOption)
			// console.log('dataDetailTryout', dataDetailTryout)
			const checkQuestionExist = dataDetailTryout.question.find(
				(item) => item.id === selectedOption.value
			);
			if (checkQuestionExist) {
				setSelectedValueQuestion(null);
				dispatch(
					setAttachments({ ...dataAttach.attachments, question_id: "" })
				);
				Swal.fire({
					icon: "error",
					title: "Soal sudah ada di Tryout!",
					text: "Mohon pilih soal lain!",
				});
			} else {
				// console.log(dataQuestionOptions.data, 'dataQuestionOptions.data')
				//ganti dataQuestionOptions.data dengan fetch question filter berdasarkan id yang dipilih
				dispatch(
					fetchQuestion({
						...filtersQuestion,
						"filter[id]": selectedOption.value,
					})
				)
					.then((response) => {
						const responFetchQuestion = response.payload.data;
						processAnswerData(responFetchQuestion);
						setAnswerData(responFetchQuestion);
						setIsAnswerDataReady(true);
						// console.log('responFetchQuestion = ', responFetchQuestion);
					})
					.catch((error) => {
						console.error("Error setAnswerData:", error);
						setAnswerData([
							{
								value: "",
								label: "Error...",
							},
						]);
						setIsAnswerDataReady(false);
					});
			}
		}
	};

	const promiseQuestionOptions = async (inputValue, callback) => {
		const fetchDataFromPage = async (page) => {
			try {
				const response = await dispatch(
					fetchQuestion({
						...filtersQuestion,
						"filter[title]": inputValue,
						sort: "title",
						page: page,
					})
				);
				return response.payload;
			} catch (error) {
				console.error("Error fetching options:", error);
				return [];
			}
		};

		const fetchDataRecursively = async (page) => {
			const responsePayload = await fetchDataFromPage(page);
			// console.log('responsePayload', responsePayload);
			const filteredPayload = responsePayload.data.filter(
				(item) =>
					!dataDetailTryout.question.some((dataItem) => dataItem.id === item.id)
			);

			if (filteredPayload.length > 0) {
				const options = filteredPayload.map((item) => ({
					value: item.id,
					type: item.type,
					label: (
						<Fragment>
							<GenerateUntagHtml
								htmlContent={item.description}
								characterLimit={100}
							/>
							<span className="text-muted">
								{item.title}
								{"("}
								{item.type === "multiple_choice"
									? "Pilihan Ganda"
									: item.type === "sentence_arrangement"
									? "Susunan Kalimat"
									: item.type === "fill_in_the_blanks"
									? "Isian Singkat"
									: "Tidak diketahui"}
								{")"}
							</span>
						</Fragment>
					),
				}));
				callback(options);
			} else {
				// console.log(responsePayload);
				const { pagination } = responsePayload;
				// console.log('pagination', pagination);
				const totalPages = pagination.last_page;
				// console.log('totalPages', totalPages);
				if (page < totalPages) {
					await fetchDataRecursively(page + 1);
				} else {
					callback([]);
				}
			}
		};

		// Mulai dengan halaman pertama
		await fetchDataRecursively(1);
	};

	// const promiseCategoryOptions = (inputValue, callback) => {
	// 	dispatch(
	// 		fetchCategories({
	// 			...filtersCategory,
	// 			"filter[title]": inputValue,
	// 			"filter[is_question]": "1",
	// 			"filter[is_parent]": "1",
	// 		})
	// 	)
	// 		.then((response) => {
	// 			const options = response.payload.data.map((item) => ({
	// 				value: item.id,
	// 				label: item.title.replace(/\d/g, ""),
	// 			}));
	// 			callback(options);
	// 		})
	// 		.catch((error) => {
	// 			console.error("Error fetching options:", error);
	// 			callback([]);
	// 		});
	// };

	const handleSelectChangeCategory = (selectedOption) => {
		setSelectedValueCategory(selectedOption);
		if (selectedOption === null) {
			dispatch(setAttachments({ ...dataAttach.attachments, category_id: "" }));
		} else {
			dispatch(
				setAttachments({
					...dataAttach.attachments,
					category_id: selectedOption.value,
				})
			);
		}
	};

	const handleModalOpen = () => {
		setNewScores([]);
		setChoiceData([]);
		setAnswerChoiceData([]);
		setSelectedValueQuestion(null);
		setSelectedValueCategory(null);
		dispatch(
			setAttachments({
				...dataAttach.attachments,
				question_id: "",
				category_id: "",
				score: [],
			})
		);
		if (NewMessage === "Update Pengaturan Soal") {
			// console.log('data = ', data.pivot.score)
			dispatch(
				setAttachments({
					...dataAttach.attachments,
					question_id: data.id,
					category_id: data.pivot.category_id,
					order: data.pivot.order,
				})
			);
			// dispatch(setAttachments({ ...dataAttach.attachments, 'order': data.pivot.order }))
			const category = {
				value: data.pivot.category_id,
				label: data.pivot.category.title.replace(/\d/g, ""),
			};
			setSelectedValueCategory(category);
			// dispatch(setAttachments({ ...data.attachments, 'order': data.pivot.order }))
			dispatch(
				fetchQuestion({
					...filtersQuestion,
					"filter[id]": data.id,
				})
			)
				.then((response) => {
					const responFetchQuestion = response.payload.data;
					processAnswerData(responFetchQuestion);
					setAnswerData(responFetchQuestion);
					setIsAnswerDataReady(true);
					// console.log('responFetchQuestion = ', responFetchQuestion);
				})
				.catch((error) => {
					console.error("Error setAnswerData on update score:", error);
					setAnswerData([
						{
							value: "",
							label: "Error...",
						},
					]);
					setIsAnswerDataReady(false);
				});
		}
	};

	const handleScore = (name, value, item) => {
		// console.log('name', name)
		// console.log('value', value)
		// console.log('item', item)
		const existingIndex = dataAttachScore.findIndex(
			(itemScore) => itemScore.choice_id === item
		);
		// console.log('data', dataAttachScore)

		if (existingIndex !== -1) {
			// Jika sudah ada, perbarui data yang ada
			const updatedDataAttachScore = [...dataAttachScore];
			updatedDataAttachScore[existingIndex] = {
				...updatedDataAttachScore[existingIndex],
				[name]: value,
			};
			dispatch(setScore(updatedDataAttachScore));
		} else {
			// Jika belum ada, tambahkan data baru
			dispatch(
				setScore([...dataAttachScore, { choice_id: item, [name]: value }])
			);
		}
	};

	const handleKeyDown = (e) => {
		// Hanya izinkan input angka (0-9) dan backspace
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput && e.key !== "Backspace") {
			e.preventDefault();
		}
	};

	return (
		<CommonModal
			isOpen={modal}
			title={NewMessage}
			toggler={toggle}
			onOpened={handleModalOpen}
			size="lg"
			handleSubmit={handleSubmit}>
			{isLoading ? (
				<Col lg="12">
					<div className="loader-box">
						<Spinner attrSpinner={{ className: "loader-30" }} />
					</div>
					<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
						{"Tambah Soal sedang diproses..."}
					</H6>
				</Col>
			) : (
				<Form>
					{NewMessage === "Tambah Soal ke Tryout" && (
						<FormGroup>
							<AsyncSelectCommon
								label={"Pilih Soal"}
								placeholder={"Silahkan pilih Soal..."}
								promiseOptions={promiseQuestionOptions}
								handleSelectChange={handleSelectChangeQuestion}
								selectedValue={selectedValueQuestion}
							/>
						</FormGroup>
					)}
					{NewMessage === "Update Pengaturan Soal" && (
						<FormGroup>
							<Label className="col-form-label" style={{ fontWeight: "bold" }}>
								Judul Soal:{" "}
							</Label>
							<h6>{data.title}</h6>
						</FormGroup>
					)}
					{NewMessage === "Update Pengaturan Soal" && isLoadingQuestion ? (
						<Col lg="12">
							<div className="loader-box">
								<Spinner attrSpinner={{ className: "loader-30" }} />
							</div>
							<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
								{"Data Loading..."}
							</H6>
						</Col>
					) : (
						answerChoiceData &&
						answerChoiceData.map((item, index) => {
							return (
								<FormGroup key={index}>
									<Row>
										<Col className="col-9">
											<FormGroup className="mb-3">
												<Label
													className="col-form-label"
													style={{ fontWeight: "bold" }}>
													{choiceData.length > 1
														? "Pilihan Jawaban Benar ke-" + (index + 1)
														: "Pilihan Jawaban Benar"}
													:{" "}
												</Label>
												<h4>
													<RawHTMLComponent htmlContent={choiceData[index]} />
												</h4>
											</FormGroup>
										</Col>
										<Col className="col-3">
											<FormGroup className="mb-3">
												<Label
													className="col-form-label"
													for="validationScore"
													style={{ fontWeight: "bold" }}>
													{"Skor"}:{" "}
												</Label>
												<Input
													className="form-control"
													id="validationScore"
													type="text"
													name="point"
													placeholder="Masukkan angka..."
													required=""
													onBlur={(e) => {
														const { name, value } = e.target;
														handleScore(name, value, item);
														// dispatch(setScore([...dataAttachScore, { ...dataAttachScore[index], 'choice_id': item, [name]: value }]))
													}}
													onKeyDown={handleKeyDown}
													defaultValue={
														dataAttachScore[index]
															? dataAttachScore[index].point
															: ""
													}
												/>
											</FormGroup>
										</Col>
									</Row>
								</FormGroup>
							);
						})
					)}
					<FormGroup>
						<Label className="col-form-label" style={{ fontWeight: "bold" }}>
							Kategori Soal:{" "}
						</Label>
						<Select
							options={categoryOptions}
							className="js-example-basic-single"
							isMulti={false}
							isClearable={true}
							placeholder="Pilih Kategori"
							onChange={handleSelectChangeCategory}
							value={selectedValueCategory}
						/>
					</FormGroup>
				</Form>
			)}
		</CommonModal>
	);
};

export default FormModal;

import React, { Fragment, useEffect, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Card, Col, Row, CardBody, CardHeader, CardFooter, Table, ListGroup, ListGroupItem } from 'reactstrap';
import { H1, H2, H3, H4, H5, H6, Image, Btn, LI, P, UL, Spinner } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomPagination from '../Common/Pagination/CustomPagination';
import {
    fetchQuestions,
    handleEditQuestion,
    handleBackPagination,
    handleNextPagination,
    handleClickPagination,
} from './QuestionsUtils';
import { setDetail, setFilters } from '../../../Features/Learning/BankQuestions/QuestionSlice';
import { setField } from '../../../Features/Learning/BankQuestions/addQuestionSlice';



const MiniBarQuestionContain = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.Question.isLoading)
    const cards = useSelector(state => state.Question.data.data)
    const pagination = useSelector(state => state.Question.data.pagination)
    const filters = useSelector(state => state.Question.filters)
    const [refatch, setRefatch] = useState(false)
    const { page, index } = useParams();
    const decodedParams = atob(new URLSearchParams(window.location.search).get('params'));
    const title = decodedParams.split("&")[0].split("=")[1];
    const type = decodedParams.split("&")[1].split("=")[1];
    const sort = decodedParams.split("&")[2].split("=")[1];

    useEffect(() => { 
        const newFilters = {
            ...filters,
            'filter[title]': title,
            'filter[type]': type,
            'sort': sort,
        };
        fetchQuestions(dispatch, newFilters, refatch)
    }, [refatch])

    const getEncryptedParams = () => {
        const encryptedParams = btoa(
            `title=${title}&type=${type}&sort=${sort}`
        );
        return encryptedParams;
    }

    return (
        <Fragment>
            <Col sm="12 order-2">
                <Card>
                    <CardHeader className="pb-0">
                        <H5>Daftar Soal</H5>
                    </CardHeader>
                    <CardBody>
                        <ListGroup className="list-group">
                            {cards && cards.length > 0 ? (
                                cards && cards.map((item, indexMap) => {
                                    return (
                                        <ListGroupItem key={`question-${item.id}`} className="list-group-item-action" disabled={String(indexMap) === String(index) && String(page) === String(pagination.current_page) ? true : false}>
                                            <Link to={`${process.env.PUBLIC_URL}/learning/soal-add/${pagination.current_page}/${indexMap}?params=${getEncryptedParams()}`}
                                            onClick={() => handleEditQuestion(dispatch, item, setField) }
                                            style={{ fontWeight: String(indexMap) === String(index) && String(page) === String(pagination.current_page) ? 'bold' : 'normal' }}>
                                                {item.title}
                                            </Link>
                                        </ListGroupItem>
                                    )
                                })
                            ) : (
                                <ListGroupItem className="list-group-item">
                                    <Row>
                                        <Col sm="12">
                                            <H6 className="mb-0">Belum ada soal</H6>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            )}
                        </ListGroup>
                    </CardBody>
                    <CardFooter>
                        {pagination && (
                            <CustomPagination
                                pagination={pagination}
                                handleBackPagination={() => handleBackPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                handleClickPagination={(page) => handleClickPagination(page, filters, dispatch, setFilters, setRefatch)}
                                handleNextPagination={() => handleNextPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                            />
                        )}
                    </CardFooter>
                </Card>
            </Col>
        </Fragment>
    );
};

export default MiniBarQuestionContain;
import { fetchHistoryReferral } from '../../../../Features/Learning/Users/Utils/UserUtils';

/* **************************************************** */
/* CRUD History Referral                                */
/* **************************************************** */

export const fetchHistoryReferrals = (dispatch, filters, refetch) => {
    dispatch(fetchHistoryReferral(filters));
    }

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
    if (pagination.current_page > 1) {
        dispatch(setFilters({
            ...filters,
            page: pagination.current_page - 1,
        }));
        setRefetch(prevRefetch => !prevRefetch);
    }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
    if (pagination.current_page < pagination.total_pages) {
        dispatch(setFilters({
            ...filters,
            page: pagination.current_page + 1,
        }));
        setRefetch(prevRefetch => !prevRefetch);
    }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
    dispatch(setFilters({
        ...filters,
        page: page,
    }));
    setRefetch(prevRefetch => !prevRefetch);
}
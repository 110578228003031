import { fetchMemberships, deleteMemberships, attachDetachCourseMemberships, attachDetachTryoutMemberships } from '../../../Features/Learning/Memberships/Utils/MembershipUtils'
import Swal from 'sweetalert2';

/* **************************************************** */
/* CRUD Membership                                       */
/* **************************************************** */

export const fetchMembership = (dispatch, filters, refetch) => {
    dispatch(fetchMemberships(filters));    
}

export const handleDeleteMembership = async (dispatch, id, setRefetch) => {
    try {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(deleteMemberships(id));
                setRefetch(prevRefetch => !prevRefetch);
                Swal.fire(
                    'Terhapus!',
                    'Data telah dihapus.',
                    'success'
                )
            }
        })
    } catch (error) {
        console.error("Delete error:", error);
    }
}

export const tryParseJSON = (jsonString) => {
  try {
    const parsed = JSON.parse(jsonString);
    return parsed;
  } catch (error) {
    return null; // Jika gagal parsing, kembalikan null
  }
}

export const handleEditMembership = async (dispatch, item, setField, setDetail) => {
  dispatch(setDetail(item));
  // console.log(item, 'item')
  const fieldsToUpdate = ['title', 'price', 'gimmick_price', 'duration_days'];
    fieldsToUpdate.forEach(field => {
        dispatch(setField({ field, value: item[field] }));
    });
    const isDataJSON = item.points.every(item => {
      try {
        JSON.parse(item);
        return true;
      } catch (error) {
        return false;
      }
    });
    // format data points diubah menjadi array of object karena ada perubahan permintaan tiap point harus set status
    const newArrayPoints = item.points.map(item => ({
      name: isDataJSON ? JSON.parse(item).name : item,
      status: isDataJSON ? JSON.parse(item).status : true,
    }));
    dispatch(setField({ field: 'points', value: newArrayPoints }));

    if (item.subheader_desc !== null) {
        dispatch(setField({ field: 'subheader_desc', value: item.subheader_desc }));
    } else {
        dispatch(setField({ field: 'subheader_desc', value: null }));
    }
    
    if (item.is_online === true) {
        dispatch(setField({ field: 'is_online', value: '1' }));
    } else {
        dispatch(setField({ field: 'is_online', value: '0' }));
    }

    if (item.is_price_bottom === true) {
        dispatch(setField({ field: 'is_price_bottom', value: '1' }));
    } else {
        dispatch(setField({ field: 'is_price_bottom', value: '0' }));
    }

    if (item.is_to_only === true) {
        dispatch(setField({ field: 'is_to_only', value: '1' }));
    } else {
        dispatch(setField({ field: 'is_to_only', value: '0' }));
    }
    
    if (item.is_guaranteed === true) {
        dispatch(setField({ field: 'is_guaranteed', value: '1' }));
    } else {
        dispatch(setField({ field: 'is_guaranteed', value: '0' }));
    }

    if (item.link) {
        dispatch(setField({ field: 'is_campus_event', value: '1' }));
        dispatch(setField({ field: 'link', value: item.link }));
    } else {
        dispatch(setField({ field: 'is_campus_event', value: '0' }));
        dispatch(setField({ field: 'link', value: '' }));
    }

    if (item.main_program_id !== null) {
        dispatch(setField({ field: 'main_program_id', value: item.main_program_id }));
    } else {
        dispatch(setField({ field: 'main_program_id', value: '' }));
    }
    // dispatch(setField({ field: 'courses', value: item.courses }));
    // dispatch(setField({ field: 'tryouts', value: item.tryouts }));
    dispatch(setField({ field: 'isUpdate', value: true }));
    dispatch(setField({ field: 'id', value: item.id }));
}

export const handleGetDetailMembership = async (dispatch, item, setDetail) => {
    dispatch(setDetail(item));
    // console.log(item);
}

export const handleDetachCourse = async (detail, data, dispatch, setDetailMembership, selectedMaterials) => {
  // console.log('detail detach', detail);
  // console.log('data detach', data);
  // console.log('selectedMaterials detach', selectedMaterials);
  
  const newDetail = { ...detail };
  try {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const success = await dispatch(attachDetachCourseMemberships(data))
        if (success.payload === true) {
          newDetail.courses = newDetail.courses.filter(
            (course) => !selectedMaterials.includes(course.id)
          );
          Swal.fire(
            'Terhapus!',
            'Course telah dihapus dari membership.',
            'success'
          )
          dispatch(setDetailMembership(newDetail));
        } else if (success.payload === false) {
          Swal.fire(
            'Gagal!',
            'Course gagal dihapus.',
            'error'
          )
        }
        
      }
    })
  } catch (error) {
    console.error("Delete error:", error);
  }
};

export const handleDetachTryout = async (detail, data, dispatch, setDetailMembership, selectedMaterials) => {
  // console.log('detail detach', detail);
  // console.log('data detach', data);
  // console.log('selectedMaterials detach', selectedMaterials);
  
  const newDetail = { ...detail };
  try {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        const success = await dispatch(attachDetachTryoutMemberships(data))
        if (success.payload === true) {
          newDetail.tryouts = newDetail.tryouts.filter(
            (tryout) => !selectedMaterials.includes(tryout.id)
          );
          Swal.fire(
            'Terhapus!',
            'Tryout telah dihapus dari membership.',
            'success'
          )
          dispatch(setDetailMembership(newDetail));
        } else if (success.payload === false) {
          Swal.fire(
            'Gagal!',
            'Tryout gagal dihapus.',
            'error'
          )
        }
        
      }
    })
  } catch (error) {
    console.error("Delete error:", error);
  }
};
/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page > 1) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page - 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page < pagination.last_page) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page + 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
  // console.log(page);
  dispatch(setFilters({
    ...filters,
    page: page
  }));
  setRefetch(prevRefetch => !prevRefetch);
}
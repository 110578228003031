import React, { Fragment, useContext } from "react";
import { Row, Col } from "reactstrap";
import FindBlog from "./FindBlog";
import LearningContext from "../../../_helper/Leatning";

const BlogFilter = () => {
	const { isOpen, setIsOpen } = useContext(LearningContext);
	const onClickeHandel = () => {
		setIsOpen(!isOpen);
	};
	return (
		<Fragment>
			<Col xl="3 xl-20">
				<div className="job-sidebar md-sidebar">
					<a
						className="btn btn-primary md-sidebar-toggle"
						href="#javascript"
						onClick={onClickeHandel}>
						Filter Blog
					</a>
					<div
						className={`md-sidebar-aside job-left-aside custom-scrollbar ${
							isOpen ? "open" : ""
						}`}>
						<div
							className="default-according style-1 faq-accordion job-accordion"
							id="accordionoc">
							<Row>
								<FindBlog />
							</Row>
						</div>
					</div>
				</div>
			</Col>
		</Fragment>
	);
};

export default BlogFilter;

import { fetchHistoryReferralMutation, postWithdrawReferral } from '../../../../Features/Learning/Users/Utils/UserUtils';
import { toast } from 'react-toastify';
import { UserServices } from '../../../../Services/axios/User.Service';

/* **************************************************** */
/* CRUD History Daftar Referrer & Withdraw                    */
/* **************************************************** */

export const fetchHistoryReferralMutations = (dispatch, filters, refetch) => {
    dispatch(fetchHistoryReferralMutation(filters));
}

export const handleWithdrawReferral = async (dispatch, id, amount) => {
    try {
        const response = await UserServices.postWithdrawReferral(id, amount);
        toast.success('Penarikan berhasil diajukan', {
            position: toast.POSITION.TOP_RIGHT
        });
        return response.data.data;
    } catch (error) {
        if (error.response && error.response.data) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
}

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
    if (pagination.current_page > 1) {
        dispatch(setFilters({
            ...filters,
            page: pagination.current_page - 1,
        }));
        setRefetch(prevRefetch => !prevRefetch);
    }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
    if (pagination.current_page < pagination.total_pages) {
        dispatch(setFilters({
            ...filters,
            page: pagination.current_page + 1,
        }));
        setRefetch(prevRefetch => !prevRefetch);
    }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
    dispatch(setFilters({
        ...filters,
        page: page,
    }));
    setRefetch(prevRefetch => !prevRefetch);
}
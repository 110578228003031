import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row, CardBody, CardHeader, CardFooter, Table } from 'reactstrap';
import { H1, H2, H3, H4, H5, H6, Image, Btn, LI, P, UL, Spinner } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDetail, setFilters } from '../../../Features/Learning/SubMateri/subMaterialSlice';
import { setField, resetState } from '../../../Features/Learning/SubMateri/addSubMaterialSlice';
import { 
  fetchSubMaterials, 
  handleDeleteSubMaterial,
  handleEditSubMaterial,
  handleGetDetailSubMaterial,
  handleBackPagination,
  handleNextPagination,
  handleClickPagination,
} from './SubMateriUtils';
import PaginationComponent from '../Common/Pagination';
import Moment from 'moment';

const SubMateriBox = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const filters = useSelector(state => state.subMaterial.filters)
  const cards = useSelector(state => state.subMaterial.data.data)
  const pagination = useSelector(state => state.subMaterial.data.pagination)
  const isLoading = useSelector(state => state.subMaterial.isLoading)
  const [refatch, setRefatch] = useState(false)

  useEffect(() => {
    fetchSubMaterials(dispatch, filters, setRefatch)
    window.scrollTo(0, 0);
  }, [refatch])


  return (
    <Fragment>
      {isLoading ? (
        <Col lg="12">
          <div className="loader-box">
            <Spinner attrSpinner={{ className: 'loader-30' }} />
          </div>
          <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
        </Col>
      ) : (
        cards && cards.length > 0 && (
          <Col sm="12 order-2">
            <Card>
              <CardBody>
                <Table className="table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th scope="col" className="bg-primary text-light">{'#'}</th>
                      <th scope="col" className="bg-primary text-light">{'Judul Submateri'}</th>
                      <th scope="col" className="bg-primary text-light">{'Tipe'}</th>
                      <th scope="col" className="bg-primary text-light text-center">{'Aksi'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      cards && cards.map((item, index) =>
                        <tr key={`course-${index}`} className="table-light">
                          <th>{pagination.from + index}</th>
                          <td>{item.name}</td>
                          <td>
                            {item.type === 'audio' ? 'Audio' : item.type === 'video' ? 'Video' : item.type === 'ebook' ? 'Ebook' : item.type === 'youtube' ? 'Youtube' : item.type === 'image' ? 'Image' : 'Unknown'}
                          </td>
                          <td className='text-center'>
                            <div>
                              <Link to={`${process.env.PUBLIC_URL}/learning/sub-materi-detail/${item.id}`} className="p-1" onClick={() => handleGetDetailSubMaterial(dispatch, item, setDetail)}>
                                <i className="fa fa-info-circle mx-auto fa-lg"></i>
                              </Link>
                              <Link to={`${process.env.PUBLIC_URL}/learning/sub-materi-add/${item.id}`} className="p-1" onClick={() => handleEditSubMaterial(dispatch, item, setField)}>
                                <i className="fa fa-edit mx-auto text-secondary fa-lg"></i>
                              </Link>
                              <Btn
                                attrBtn={{
                                  color: 'link',
                                  outline: true,
                                  className: 'p-1',
                                  onClick: () => handleDeleteSubMaterial(dispatch, item.id, setRefatch)
                                }}
                              >
                                <i className="fa fa-trash-o mx-auto text-danger fa-lg"></i>
                              </Btn>
                            </div >
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                {pagination && (
                  <PaginationComponent
                    pagination={pagination}
                    handleBackPagination={() => handleBackPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                    handleClickPagination={(page) => handleClickPagination(page, filters, dispatch, setFilters, setRefatch)}
                    handleNextPagination={() => handleNextPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                  />
                )}
              </CardFooter>
            </Card>
          </Col>
        )
      )}

      {!cards || cards.length === 0 && (
        <Col lg="12">
          <H4 attrH4={{ className: 'sub-title mb-5 text-center' }}>{!cards ? 'Sumber Data Tidak Tersedia' : 'Data Tidak Ditemukan'}</H4>
        </Col>
      )}

    </Fragment>
  );
};

export default SubMateriBox;
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import TicketsContain from '../../../Component/Learning/Ticket';

const TicketList = () => {
    return (
        <Fragment>
            <Breadcrumbs title="Daftar Laporan Soal" />
            <TicketsContain />
        </Fragment>
    );
};
export default TicketList;
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../../AbstractElements';
import TestimonialContain from './TestimonialContain';

const TestimonialList = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Daftar Testimonial" parent="Users" title="Testimonial" />
            <TestimonialContain />
        </Fragment>
    );
};
export default TestimonialList;
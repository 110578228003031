import React, { Fragment } from 'react';
import { Input, Label } from 'reactstrap';
import { handleSortBy } from '../CheckBox/CatCheckBoxUtils';
import { useSelector, useDispatch } from 'react-redux';

const RadioFilter = (props) => {

  return (
    <Fragment>
      <div className="checkbox-animated mt-0">
        <div className="learning-header"><span className="f-w-600">{props.sort}</span></div>
        {props.children}
      </div>
    </Fragment>
  );
};

export default RadioFilter;
import { fetchMainProgram, deleteMainProgram } from '../../../Features/Learning/MainPrograms/Utils/MainProgramUtils';
import Swal from 'sweetalert2';

/* **************************************************** */
/* CRUD Main Program                                    */
/* **************************************************** */

export const fetchMainPrograms = (dispatch, filters, refetch) => {
    dispatch(fetchMainProgram(filters));
}

export const handleDeleteMainProgram = async (dispatch, id, setRefetch) => {
    try {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(deleteMainProgram(id));
                setRefetch(prevRefetch => !prevRefetch);
                Swal.fire(
                    'Terhapus!',
                    'Data telah dihapus.',
                    'success'
                )
            }
        })
    } catch (error) {
        console.error("Delete error:", error);
    }
}

export const handleEditMainProgram = async (dispatch, item, setField) => {
    const fieldsToUpdate = ['title', 'is_tryout_only', 'is_private', 'header_desc', 'content_desc']; 
    
    fieldsToUpdate.forEach(field => {
        dispatch(setField({ field, value: item[field] }));
    });

    dispatch(setField({ field: 'id', value: item.id }));
    dispatch(setField({ field: 'isUpdate', value: true }));
    dispatch(setField({ field: 'membership', value: item.membership }));
    dispatch(setField({ field: 'category_id', value: item.category }));
    dispatch(setField({ field: 'image', value: '' }));
    dispatch(setField({ field: 'bannerLink', value: item.image }));
    dispatch(setField({ field: 'attachmentLink', value: item.attachment }));

    if (item.batch !== null) {
        dispatch(setField({ field: 'batch', value: item.batch }));
    } else {
        dispatch(setField({ field: 'batch', value: '' }));
    }

    if (item.video_promo !== null) {
        dispatch(setField({ field: 'video_promo', value: item.video_promo }));
    } else {
        dispatch(setField({ field: 'video_promo', value: '' }));
    }

    if(item.slug !== null) {
        dispatch(setField({ field: 'slug', value: item.slug }));
        dispatch(setField({ field: 'is_campus_event', value: 1 }));
        dispatch(setField({ field: 'old_slug', value: item.slug }));
    } else {
        dispatch(setField({ field: 'slug', value: '' }));
        dispatch(setField({ field: 'is_campus_event', value: 0 }));
    }

    if (item.point !== null && JSON.parse(item.point).length > 0) {
        dispatch(setField({ field: 'point', value: JSON.parse(item.point) }));
        dispatch(setField({ field: 'isPoint', value: true }));
    } else if (item.point === null || JSON.parse(item.point).length === 0) {
        dispatch(setField({ field: 'point', value: '' }));
        dispatch(setField({ field: 'isPoint', value: false }));
    }
}

export const handleGetDetailMainProgram = async (dispatch, item, setDetail) => {
    dispatch(setDetail(item));
}

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page > 1) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page - 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page < pagination.last_page) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page + 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
  dispatch(setFilters({
    ...filters,
    page: page
  }));
  setRefetch(prevRefetch => !prevRefetch);
}
import React, { Fragment, useState } from 'react';
import { Search, PlusCircle } from 'react-feather';
import { Container, Row, Col, Card, Input, Label } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Btn, Spinner } from '../../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import UserBox from './UserBox';
import UserFilter from './Filter/UserFilter';
import { resetState } from '../../../Features/Learning/Users/addUserSlice';
import { exportUsers } from '../../../Features/Learning/Users/Utils/UserUtils';


const UsersContain = () => {
    const cards = useSelector(state => state.User.data.data)
    const filter = useSelector(state => state.User.filters)
    const history = useNavigate();
    const dispatch = useDispatch();
    const isLoadingExport = useSelector(state => state.User.isExport);
    const handleAdd = (e) => {
        dispatch(resetState());
    };
    const handleExport = (e) => {
        e.preventDefault();
        dispatch(exportUsers(filter));
    }
    return (
        <Fragment>
            <Container fluid={true}>
                <Col md="12" className="project-list">
                    {cards && (
                        <Card>
                            <Row className='justify-content-between align-items-center mb-3'>
                                <Col className="d-flex justify-content-start">
                                    <Link to={`${process.env.PUBLIC_URL}/users/user-add`} onClick={handleAdd}>
                                        <Btn attrBtn={{ color: 'primary'}}>
                                            <i className="fa fa-plus-circle fa-lg"></i> Tambah User Baru
                                        </Btn>
                                    </Link>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <Btn attrBtn={{ color: 'warning', onClick: handleExport }}>
                                        <i className="fa fa-upload fa-lg"></i> Export Data User
                                    </Btn>
                                </Col>
                            </Row>
                        </Card>
                    )}
                </Col>
                { isLoadingExport && (
                    <Col lg="12 mb-5">
                        <div className="loader-box">
                            <Spinner attrSpinner={{ className: 'loader-30' }} />
                        </div>
                        <h6 className="sub-title mb-0 text-center">{'Exporting Data...'}</h6>
                        <p className="text-muted text-center">{'Pastikan anda mengijinkan pop-up pada browser anda.'}</p>
                    </Col>
                )}
                <Row className='learning-block'>
                    <Col xl="9" className='xl-80'>
                        <Row>
                            <UserBox />
                        </Row>
                    </Col>
                    {cards && (
                    <UserFilter />
                    )}
                </Row>
            </Container>
        </Fragment>
    );
};
export default UsersContain;
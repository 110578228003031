import { axiosInstance } from './axiosInstance';

export const ClassServices = {
    getAll: async (filter) => {
        return await axiosInstance.get('/api/feature/class/admin', { params: filter });
    },
    create: async (data) => {
        return await axiosInstance.post('/api/feature/class/create', data);
    },
    update: async (id, data) => {
        return await axiosInstance.post(`/api/feature/class/update/${id}`, data);
    },
    delete: async (id) => {
        return await axiosInstance.delete(`/api/feature/class/delete/${id}`);
    },
    attachDetachUserToClass: async (id, data) => {
        return await axiosInstance.post(`/api/feature/class/attach-detach/${id}`, data);
    },
    getDetailMemberClass: async (id) => {
        return await axiosInstance.get(`/api/feature/class/admin/detail-member/${id}`);
    }
}
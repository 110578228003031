import { createSlice } from '@reduxjs/toolkit';
import { fetchMemberships } from './Utils/MembershipUtils';

const MembershipSlice = createSlice({
    name: 'Memberships',
    initialState: {
        filters: {
            "filter[id]": "",
            "filter[title]": "",
            "filter[price]": "",
            "filter[duration_days]": "",
            "filter[is_online]": "",
            "filter[is_not_attached_to_main_program]": "",
            "sort": "",
            "page": 1,
            "include": "courses,tryouts,mainProgram,tryouts.category"
        },
        data: [],
        detail: {},
        isLoading: false,
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        setDetail: (state, action) => {
            state.detail = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchMemberships.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchMemberships.fulfilled, (state, action) => {
            state.isLoading = false;
            // console.log('status fulfilled', action.payload)
            state.data = action.payload;
            // console.log('data', state.data)
        })
        .addCase(fetchMemberships.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export const { setFilters, setData, setDetail } = MembershipSlice.actions;

export default MembershipSlice.reducer;
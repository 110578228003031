import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PlusCircle } from "react-feather";
import {
	Card,
	Col,
	Row,
	CardBody,
	CardHeader,
	CardFooter,
	Table,
	Label,
} from "reactstrap";
import {
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
	Image,
	Btn,
	LI,
	P,
	UL,
	Spinner,
	Badges,
} from "../../../../AbstractElements";
import {
	setFilters,
	setDetail,
} from "../../../../Features/Learning/Users/historyUserReferralMutationSlice";
import {
	fetchHistoryReferralMutations,
	handleBackPagination,
	handleNextPagination,
	handleClickPagination,
} from "./ReferralMutationUtils";
import CurrencyFormat from "../../Common/GenerateContent/CurrencyFormatter";
import PaginationComponent from "../../Common/Pagination";
import ReferralWithdrawPopup from "./ReferralWithdrawPopup";
import Moment from "moment";
import "moment/locale/id";

const ReferralMutationTable = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const filters = useSelector(
		(state) => state.historyUserReferralMutation.filters
	);
	const isLoading = useSelector(
		(state) => state.historyUserReferralMutation.isLoading
	);
	const cards = useSelector(
		(state) => state.historyUserReferralMutation.data.data
	);
	const pagination = useSelector(
		(state) => state.historyUserReferralMutation.data.pagination
	);
	const [refatch, setRefatch] = useState(false);

	useEffect(() => {
		const newFilter = {
			...filters,
			referee_id: id,
			page: 1,
		};
		fetchHistoryReferralMutations(dispatch, newFilter, refatch);
		window.scrollTo(0, 0);
	}, [refatch]);

	return (
		<Fragment>
			{isLoading ? (
				<Col lg="12">
					<div className="loader-box">
						<Spinner attrSpinner={{ className: "loader-30" }} />
					</div>
					<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
						{"Data Loading..."}
					</H6>
				</Col>
			) : (
				cards &&
				cards.length > 0 && (
					<>
                        <Col lg="4">
						    <ReferralWithdrawPopup id={id} setRefatch={setRefatch} refatch={refatch} />
                        </Col>
						<Col sm="12 order-2">
							<Card>
								<CardBody>
									<Table className="table-bordered table-striped table-hover">
										<thead>
											<tr>
												<th
													scope="col"
													className="bg-primary text-light"
													style={{ verticalAlign: "middle" }}>
													#
												</th>
												<th
													scope="col"
													className="bg-primary text-light"
													style={{ verticalAlign: "middle" }}>
													Membership
												</th>
												<th
													scope="col"
													className="bg-primary text-light text-center"
													style={{ width: "100px", verticalAlign: "middle" }}>
													Tanggal
												</th>
												<th
													scope="col"
													className="bg-primary text-light text-center"
													style={{ width: "50px", verticalAlign: "middle" }}>
													Jenis Transaksi
												</th>
												<th
													scope="col"
													className="bg-primary text-light text-center"
													style={{ width: "150px", verticalAlign: "middle" }}>
													Jumlah
												</th>
												<th
													scope="col"
													className="bg-primary text-light text-center"
													style={{ width: "150px", verticalAlign: "middle" }}>
													Saldo
												</th>
											</tr>
										</thead>
										<tbody>
											{cards.map((item, index) => (
												<tr key={index}>
													<td>{index + 1}</td>
													<td>{item.membership?.title ?? "-"}</td>
													<td className="text-center">
														{Moment(item.created_at).format("DD/MM/YYYY")}
													</td>
													<td
														className={`text-center ${
															item.type === "debit"
																? "text-success"
																: "text-danger"
														}`}>
														{item.type === "debit" ? "Masuk" : "Keluar"}
													</td>
													<td
														className={`text-center ${
															item.type === "debit"
																? "text-success"
																: "text-danger"
														}`}>
														<CurrencyFormat amount={item.amount} />
													</td>
													<td className="text-center">
														<b>
															<CurrencyFormat amount={item.balance} />
														</b>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
								</CardBody>
								<CardFooter>
									<PaginationComponent
										pagination={pagination}
										handleBackPagination={() =>
											handleBackPagination(
												pagination,
												filters,
												dispatch,
												setFilters,
												setRefatch
											)
										}
										handleNextPagination={() =>
											handleNextPagination(
												pagination,
												filters,
												dispatch,
												setFilters,
												setRefatch
											)
										}
										handleClickPagination={(page) =>
											handleClickPagination(
												page,
												filters,
												dispatch,
												setFilters,
												setRefatch
											)
										}
									/>
								</CardFooter>
							</Card>
						</Col>
					</>
				)
			)}

			{(!cards || cards.length === 0) && isLoading === false && (
				<Col lg="12">
					<H3 attrH3={{ className: "sub-title mb-5 text-center" }}>
						{!cards ? "Sumber Data Tidak Tersedia" : "Data Tidak Ditemukan"}
					</H3>
				</Col>
			)}
		</Fragment>
	);
};

export default ReferralMutationTable;

import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import MainProgramPostContain from '../../../Component/Learning/MainProgram/MainProgramPostContain';
import { useParams } from 'react-router-dom';
import { handleEditMainProgram } from '../../../Component/Learning/MainProgram/MainProgramUtils';
import { setField, resetState } from '../../../Features/Learning/MainPrograms/addMainProgramSlice';
import { fetchMainProgram } from '../../../Features/Learning/MainPrograms/Utils/MainProgramUtils';


const MainProgramAddPage = () => {
    const addField = useSelector(state => state.addMainProgram)
    const dispatch = useDispatch();
    const filters = useSelector(state => state.MainProgram.filters)
    const { id } = useParams();

    useEffect(() => {
        if (!addField.id && id) {
            const newFilters = {
                ...filters,
                "filter[id]": id,
                "page": 1,
            };
            dispatch(fetchMainProgram(newFilters))
            .then((response) => {
                // Kode yang akan dijalankan setelah fetch sukses
                handleEditMainProgram(dispatch, response.payload.data[0], setField)
                // Kode lain yang perlu dijalankan setelah fetch sukses
            })
            .catch((error) => {
                // Tangani error jika fetch gagal
                console.error('Error during fetchMainProgram:', error);
            });
        } else if (!id) {
            dispatch(resetState());
            console.log(addField, "addField");
        }
    }, [id]);

    return (
        <Fragment>
            <Breadcrumbs parent="Program" title={id ? "Update Program" : "Tambah Program"} />
            <MainProgramPostContain label={id ? "" : "Program Baru"} />
        </Fragment>
    );
};

export default MainProgramAddPage;

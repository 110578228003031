import React, { Fragment, useEffect, useState } from "react";
import {
	Col,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Media,
	Table,
	Row,
	Form,
	FormGroup,
	Label,
	Input,
} from "reactstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	Image,
	H6,
	H5,
	H4,
	LI,
	UL,
	P,
	Btn,
	Spinner,
	Badges,
} from "../../../AbstractElements";
import AttachModal from "../../../Component/Learning/Common/Modal/ModalButton";
import { fetchUsers } from "../../../Features/Learning/Users/Utils/UserUtils";
import { fetchMemberships } from "../../../Features/Learning/Memberships/Utils/MembershipUtils";
import {
	attachDetachUserToClass,
	fetchClasses,
} from "../../../Features/Classes/Class/Utils/ClassUtils";
import {
	setField as setFieldAttachDetach,
	resetState as resetAttachDetach,
} from "../../../Features/Classes/Class/attachDetachUserToClassSlice";
import { handleGetDetailClass } from "../../../Component/Classes/Class/ClassUtils";
import { setDetail } from "../../../Features/Classes/Class/ClassSlice";
import { fetchDetailMemberClass } from "../../../Features/Classes/Class/Utils/ClassUtils";
import AsyncSelectCommon from "../../../Component/Learning/Common/SelectOption/AsyncSelect";
import RawHTMLComponent from "../../../Component/Learning/Common/RawHTML";
import Moment from "moment";
import "moment/locale/id";
import Swal from "sweetalert2";
import Select from "react-select";

const ClassDetailGeneral = (props) => {
	const { classId } = useParams();
	const dispatch = useDispatch();
	const detail = useSelector((state) => state.Class.detail);
	const dataMember = useSelector((state) => state.detailMemberClass.data.data);
	const filterClass = useSelector((state) => state.Class.filters);
	const filterUser = useSelector((state) => state.User.filters);
	const filterMembership = useSelector((state) => state.Membership.filters);
	const [modal, setModal] = useState(false);
	const [selectedValueUser, setSelectedValueUser] = useState(null);
	const [selectedValueMembership, setSelectedValueMembership] = useState(null);
	const [userOptions, setUserOptions] = useState([]);
	const data = useSelector((state) => state.attachDetachUserToClass);
	const isLoading = useSelector((state) => state.Class.isLoading);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleToggle = () => {
		setModal(!modal);
		setSelectedValueUser(null);
		setSelectedValueMembership(null);
		dispatch(resetAttachDetach());
	};

	const promiseOptionMemberships = (inputValue, callback) => {
		// console.log('Input Value = ', inputValue)
		dispatch(
			fetchMemberships({
				...filterMembership,
				"filter[title]": inputValue,
				sort: "-id",
				page: 1,
				include: "",
			})
		)
			.then((response) => {
				const options = response.payload.data.map((item) => ({
					value: item.id,
					label: item.title,
				}));
				callback(options);
			})
			.catch((error) => {
				console.error("Error fetching options:", error);
				callback([]);
			});
	};

	const handleSelectChangeMembership = (selectedOption) => {
		// validation membership already exist
		if (
			selectedOption &&
			dataMember.some((item) => item.id === selectedOption.value)
		) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Membership ini sudah terdaftar pada kelas ini!",
			});
		} else {
			setSelectedValueMembership(selectedOption);
			setSelectedValueUser(null);
			dispatch(setFieldAttachDetach({ field: "userId", value: null }));
			dispatch(
				setFieldAttachDetach({ field: "class_id", value: parseInt(classId) })
			);
			dispatch(
				setFieldAttachDetach({
					field: "membershipId",
					value: selectedOption === null ? "" : selectedOption.value,
				})
			);
			// get user by membership & set to state
			dispatch(
				fetchUsers({
					// ...filterUser,
					// 'filter[name]': inputValue,
					sort: "name",
					page: 1,
					include: "",
					"filter[membership.id]":
						selectedOption === null ? "" : selectedOption.value,
				})
			)
				.then((response) => {
					const options = response.payload.data.map((item) => ({
						value: item.id,
						label: item.name,
					}));
					setUserOptions(options);
					if (response.payload.data.length === 0) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Belum ada user yang terdaftar pada membership ini! Pilih membership lain atau tambahkan user ke membership ini.",
						});
					}
				})
				.catch((error) => {
					console.error("Error fetching options:", error);
					setUserOptions([]);
				});
		}
	};

	const handleSelectChangeUsers = (selectedOption) => {
		setSelectedValueUser(selectedOption);
		if (selectedOption) {
			dispatch(
				setFieldAttachDetach({
					field: "userId",
					value: selectedOption.map((item) => item.value),
				})
			);
		} else {
			dispatch(setFieldAttachDetach({ field: "userId", value: null }));
		}
	};

	const handleSubmit = async () => {
		console.log(userOptions, "userOptions");
		try {
			let errorMessage = "";
			if (!data.membershipId) {
				errorMessage = "Silahkan pilih membership terlebih dahulu";
			} else if (data.membershipId && !data.userId) {
				errorMessage = "Silahkan pilih user yang akan ditambahkan";
			}
			// validation
			if (!data.membershipId || (data.membershipId && !data.userId)) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: errorMessage,
				});
			} else {
				const response = await dispatch(attachDetachUserToClass(data));
				if (response.payload === true) {
					setModal(!modal);
					Swal.fire({
						title: "Berhasil!",
						text: "User berhasil ditambahkan ke kelas",
						icon: "success",
						confirmButtonText: "Oke",
					});
					dispatch(resetAttachDetach());
					dispatch(fetchDetailMemberClass(classId));
					const newFilters = {
						...filterClass,
						"filter[id]": classId,
						page: 1,
					};
					dispatch(fetchClasses(newFilters))
						.then((response) => {
							// Kode yang akan dijalankan setelah fetch sukses
							handleGetDetailClass(
								dispatch,
								response.payload.data[0],
								setDetail
							);
							// Kode lain yang perlu dijalankan setelah fetch sukses
						})
						.catch((error) => {
							// Tangani error jika fetch gagal
							console.error("Error during fetchClasses:", error);
						});
				} else {
					Swal.fire({
						title: "Gagal!",
						text: "User gagal ditambahkan ke kelas",
						icon: "error",
						confirmButtonText: "Oke",
					});
				}
			}
		} catch (error) {
			console.error("Error:", error);
			// Modal tidak akan ditutup jika terjadi kesalahan
		}
	};

	const promiseOptionUsers = (inputValue, callback) => {
		fetchData(dispatch, inputValue, callback);
	};

	const fetchData = async (dispatch, inputValue, callback) => {
		const fetchDataFromPage = async (page) => {
			try {
				const response = await dispatch(fetchUsers({
					...filterUser,
					'filter[name]': inputValue,
					'sort': 'name',
					'page': page,
					'include': '',
					'filter[membership.id]': data.membershipId,
				}));
				return response;
			} catch (error) {
				console.error('Error fetching options:', error);
				return [];
			}
		}

		const fetchDataRecursively = async (page) => {
			const responsePayload = await fetchDataFromPage(page);
			console.log('responsePayload', data);
			const filteredPayload = responsePayload.payload.data;
			// console.log('filteredPayloadLength', filteredPayload.length);
			if (filteredPayload.length > 0) {
				// console.log('set options')
				const options = filteredPayload.map(item => ({
					value: item.id,
					label: item.name,
				}));
				callback(options);
			} else {
				// console.log('fetch next page')
				const { pagination } = responsePayload.payload;
				const totalPages = pagination.last_page;
				if (page < totalPages) {
					await fetchDataRecursively(page + 1);
				} else {
					callback([]);
				}
			}
		}

		await fetchDataRecursively(1);
	};

	return (
		<Fragment>
			<Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1 mb-3">
				<Card className="social-widget-card">
					<CardHeader>
						<Col>
							<Row>
								<Col>
									<h5>{isLoading ? "Nama Kelas" : "Kelas " + detail.name}</h5>
								</Col>
							</Row>
						</Col>
					</CardHeader>
					<CardBody>
						{isLoading ? (
							<Col lg="12">
								<div className="loader-box">
									<Spinner attrSpinner={{ className: "loader-30" }} />
								</div>
								<H6 attrH6={{ className: "mb-0 text-center" }}>
									{"Data Loading..."}
								</H6>
							</Col>
						) : (
							<Row>
								<Col sm="6" xl="6" lg="6">
									<h5>Deskripsi</h5>
									<h6>
										{detail.description ? (
											<RawHTMLComponent htmlContent={detail.description} />
										) : (
											"Deskripsi Kelas"
										)}{" "}
									</h6>
								</Col>
								<Col>
									<h5 className="text-center">Jumlah Siswa</h5>
									<h5 className="text-center">
										<Badges attrBadge={{ color: "warning" }}>
											{" "}
											{(detail.members && detail.members.length) || 0}{" "}
										</Badges>
									</h5>
								</Col>
								<Col>
									<h5 className="text-center">Banner</h5>
									<img
										src={detail.banner}
										alt="banner"
										className="img-fluid rounded mx-auto d-block"
										style={{ width: "150px", height: "auto" }}
									/>
								</Col>
							</Row>
						)}
					</CardBody>
					<CardFooter>
						<Row>
							<Col>
								<P attrP={{ className: "font-roboto" }}>
									Created at:{" "}
									{Moment(detail.created_at).format("dddd, D MMMM YYYY")} -{" "}
									{Moment(detail.created_at).format("HH:mm:ss")} WIB
								</P>
							</Col>
							<Col className="text-end">
								<Link to={`${process.env.PUBLIC_URL}/classes/class-list`}>
									<Btn
										attrBtn={{
											color: "warning",
											className: "btn btn-primary m-r-10",
										}}>
										<i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
									</Btn>
								</Link>
								{props.type === "user" ? (
									<AttachModal
										colorBtn={"primary"}
										buttonLabel={"Tambah Peserta"}
										toggle={() => handleToggle()}
										modalLabel={"Tambahkan Peserta ke Kelas"}
										modal={modal}
										handleSubmit={() => {
											handleSubmit();
										}}>
										<AsyncSelectCommon
											label={"Membership"}
											placeholder={"Silahkan pilih membership..."}
											isMulti={false}
											promiseOptions={promiseOptionMemberships}
											handleSelectChange={(selectedOption) =>
												handleSelectChangeMembership(selectedOption)
											}
											selectedValue={selectedValueMembership}
										/>
										{data.membershipId && (
											<AsyncSelectCommon label={'Users'} placeholder={'Silahkan pilih User...'}
											    isMulti={true}
											    promiseOptions={promiseOptionUsers}
											    handleSelectChange={(selectedOption) => handleSelectChangeUsers(selectedOption)}
											    selectedValue={selectedValueUser}
											/>
											// <FormGroup className="mb-3">
											// 	<Label style={{ fontWeight: "bold" }}>Users :</Label>
											// 	<Select
											// 		options={userOptions}
											// 		className="js-example-basic-single"
											// 		isMulti={true}
											// 		isClearable={true}
											// 		placeholder="Silahkan pilih User..."
											// 		onChange={handleSelectChangeUsers}
											// 		value={selectedValueUser}
											// 	/>
											// </FormGroup>
										)}
										{data.isLoading && (
											<Col lg="12">
												<div className="loader-box">
													<Spinner attrSpinner={{ className: "loader-30" }} />
												</div>
												<H6
													attrH6={{ className: "sub-title mb-0 text-center" }}>
													{"Sedang proses penambahan peserta..."}
												</H6>
											</Col>
										)}
									</AttachModal>
								) : (
									<Link
										to={`${process.env.PUBLIC_URL}/classes/schedule-class-add/${classId}`}>
										<Btn
											attrBtn={{
												color: "primary",
												className: "btn btn-primary m-r-10",
											}}>
											<i className="fa fa-plus-square fa-lg me-2"></i>Tambah
											Sesi
										</Btn>
									</Link>
								)}
							</Col>
						</Row>
					</CardFooter>
				</Card>
			</Col>
		</Fragment>
	);
};
export default ClassDetailGeneral;

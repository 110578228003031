import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import UsersContain from '../../../Component/Learning/Users';

const UserList = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Users List" parent="Users" title="Daftar User" />
            <UsersContain />
        </Fragment>
    );
};
export default UserList;
import { axiosInstance } from "./axiosInstance";

export const AuthService = {
    login: async (email, password) => {
        return await axiosInstance.post('/api/auth/admin/login', {
            email : email,
            password : password
        })
    },
    logout: async () => {
        return await axiosInstance.post('/api/auth/logout')
    },
    loginAs: async (id) => {
        return await axiosInstance.get(`/api/auth/admin/login-as/${id}`)
    }
}
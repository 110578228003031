import { createSlice } from '@reduxjs/toolkit';
import { fetchHistoryReferralMutation } from './Utils/UserUtils';

const historyReferralMutationSlice = createSlice({
    name: 'historyReferralMutation',
    initialState: {
        filters: {
            "referee_id": "",
            "filter[membership.title]": "",
            "sort": "",
            "page": "",
        },
        data: [],
        detail: {},
        isLoading: false,
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        setDetail: (state, action) => {
            state.detail = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHistoryReferralMutation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchHistoryReferralMutation.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchHistoryReferralMutation.rejected, (state) => {
                state.isLoading = false;
            })
    }
});

export const { setFilters, setData, setDetail } = historyReferralMutationSlice.actions;

export default historyReferralMutationSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';
import { submitMainProgram } from './Utils/MainProgramUtils';

const initialState = {
    category_id: '',
    title: '',
    batch: '',
    image: '',
    video_promo: '',
    header_desc: '',
    content_desc: '',
    point: [],
    is_tryout_only: 0,
    is_private: 0,
    is_campus_event: 0,
    slug: '',
    old_slug: '',
    attachment: '',
    isUpdate: false,
    isPoint: false,
    id: '',
    isLoading: false,
};

const addMainProgram = createSlice(
    {
        name: 'addMainProgram',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(submitMainProgram.pending, (state) => {
                    // Handle loading state
                    state.isLoading = true;
                })
                .addCase(submitMainProgram.fulfilled, (state, action) => {
                    // Handle success state
                    state.isLoading = false;
                })
                .addCase(submitMainProgram.rejected, (state, action) => {
                    // Handle error state
                   console.log(action.error.message, "error action")
                    state.isLoading = false;
                });
        }
    }
);

export const { setField, resetState } = addMainProgram.actions;

export default addMainProgram.reducer;
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import { Btn, H4, H5, Badges } from '../../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import CurrencyFormat from '../../Common/GenerateContent/CurrencyFormatter';
import { setFilters } from '../../../../Features/Learning/Memberships/membershipSlice';


const VoucherMembership = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onEditSubmit = (data) => {
    alert('Form is Submitted');
  };
//   const detail = useSelector(state => state.Voucher.detail);
  const item = useSelector(state => state.Voucher.detail)
  const filters = useSelector(state => state.Membership.filters)
  const dispatch = useDispatch()
  const history = useNavigate()


  return (
    <Fragment>
        <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
            <CardHeader className="pb-0">
                <H4 attrH4={{ className: 'card-title mb-0 text-center' }}>{item.membership && item.membership.length > 0 ? 'Daftar Membership' : 'Belum ada membership terdaftar'}</H4>
            </CardHeader>
            <CardBody>
                {item.membership && item.membership.length > 0 && (
                <Col sm="12 order-2">
                    <Card>
                    <div className="table-responsive">
                        <Table>
                        <thead className="table-light">
                            <tr>
                            <th scope="col">#</th>
                                <th scope="col">Nama Membership</th>
                                <th scope="col">Harga</th>
                                <th scope="col">Durasi</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            item.membership && item.membership.map((membership, index) =>
                                <tr key={`membership-${index}`}>
                                <td>{index + 1}</td>
                                <td>
                                    {membership.title}
                                    <Btn
                                        title="Lihat membership"
                                        attrBtn={{
                                            color: 'link',
                                            outline: true,
                                            className: 'p-1 ms-2',
                                            onClick: () => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[title]': membership.title
                                                }))
                                                history(`${process.env.PUBLIC_URL}/membership/membership-list`)
                                            }
                                        }}
                                    >
                                        <i className="fa fa-share-square-o mx-auto fa-lg" style={{ color: '#376673' }}></i>
                                    </Btn>
                                </td>
                                <td><CurrencyFormat amount={membership.price} /></td>
                                <td>{membership.duration_days} hari</td>
                                <td>
                                    <Badges attrBadge={{ color: membership.is_online === true ? 'primary' : 'danger' }} > {membership.is_online === true ? 'Online' : 'Offline'} </Badges>
                                    {membership.is_to_only === true ? <Badges attrBadge={{ color: 'warning' }} > Tryout only </Badges> : ''}
                                </td>
                                </tr>
                            )
                            }
                        </tbody>
                        </Table>
                    </div>
                    </Card>
                </Col>
                )}
            </CardBody>
        </Form>
    </Fragment>
  );
};
export default VoucherMembership;
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, CardHeader, CardFooter, Media, Table, Row, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Image, H6, H5, H4, H3, LI, UL, P, Btn, Spinner, Badges } from '../../../../AbstractElements';
import RawComponent from '../../../../Component/Learning/Common/RawHTML';
import AttachModal from '../../../../Component/Learning/Common/Modal/ModalButton';
import AsyncSelectCommon from '../../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { setField } from '../../../../Features/Learning/MainPrograms/attachDetachMembershipSlice';
import { setDetail } from '../../../../Features/Learning/MainPrograms/mainProgramSlice';
import { attachDetachMainProgram } from '../../../../Features/Learning/MainPrograms/Utils/MainProgramUtils';
import { fetchMemberships } from '../../../../Features/Learning/Memberships/Utils/MembershipUtils'
import { fetchMainProgram } from '../../../../Features/Learning/MainPrograms/Utils/MainProgramUtils';
import { handleGetDetailMainProgram } from '../../../../Component/Learning/MainProgram/MainProgramUtils';
import Moment from 'moment';
import 'moment/locale/id';
import Swal from 'sweetalert2';

const DetailProgramContain = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const detail = useSelector(state => state.MainProgram.detail);
    const filters = useSelector(state => state.MainProgram.filters)
    const dataAttachDetach = useSelector(state => state.attachDetachMainProgram)
    const [modal, setModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const filterMembership = useSelector(state => state.Membership.filters)

    useEffect(() => {
        dispatch(setField({ field: 'id', value: id }));
        window.scrollTo(0, 0);
    }, [id])

    const toggle = () => {
        setModal(!modal);
        dispatch(setField({ field: 'type', value: 'attach' }));
    };

    const promiseOptions = (inputValue, callback) => {
        // console.log('Input Value = ', inputValue)
        if (inputValue !== '') {
            dispatch(fetchMemberships({
                ...filterMembership,
                'filter[title]': inputValue,
                'filter[is_not_attached_to_main_program]': true,
                'sort': 'title',
                'page': 1,
            }))
                .then((response) => {
                    const options = response.payload.data.map(item => ({
                        value: item.id,
                        label: item.title,
                    }));
                    callback(options);
                })
                .catch((error) => {
                    console.error('Error fetching options:', error);
                    callback([]);
                });
        }
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedValue(selectedOption);
        // console.log(se   lectedOption, 'selectedOption')
        if (selectedOption === null) {
            dispatch(setField({ field: 'membership_id', value: [] }));
        } else {
            dispatch(setField({ field: 'membership_id', value: selectedOption }));
        }
    };

    const handleSubmit = async (type) => {
        // console.log(dataAttachDetach)
        if (type === 'attach') {
            if (dataAttachDetach.membership_id.length === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Membership tidak boleh kosong!',
                });
            } else {
                // console.log(dataAttachDetach)
                try {
                    const response = await dispatch(attachDetachMainProgram(dataAttachDetach));
                    // console.log('response:', response);
                    if (response.payload === true) {
                        setModal(!modal);
                        const newFilter = {
                            ...filters,
                            'filter[id]': id,
                            'page': 1,
                        };
                        dispatch(fetchMainProgram(newFilter))
                            .then((response) => {
                                // Kode yang akan dijalankan setelah fetch sukses
                                handleGetDetailMainProgram(dispatch, response.payload.data[0], setDetail)
                            })
                            .catch((error) => {
                                // Tangani error jika fetch gagal
                                console.error('Error during fetchMainProgram:', error);
                            });
                        Swal.fire(
                            'Berhasil!',
                            'Membership berhasil ditambahkan ke program.',
                            'success'
                        )
                        dispatch(setField({ field: 'membership_id', value: [] }));
                        setSelectedValue(null);
                    }
                } catch (error) {
                    // Tanggapan error
                    console.error('Error:', error);
                    // Modal tidak akan ditutup jika terjadi kesalahan
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Terjadi kesalahan saat menambahkan membership ke program!',
                    });
                }
            }
        } else if (type === 'detach') {
            console.log(type)
        }
    };


    return (
        <Fragment>
            <Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
                <Card>
                    <CardHeader className="pb-0">
                        <Row>
                            <Col>
                                <H3 attrH3={{ className: 'card-title mb-0' }}>
                                    {detail.title}
                                </H3>
                                    {detail.is_tryout_only === 1 ? <Badges attrBadge={{ color: 'warning' }} > Tryout only </Badges> : ''}
                                    {detail.is_private === 1 ? <Badges attrBadge={{ color: 'danger' }} > Private </Badges> : ''}
                            </Col>
                            <Col className='text-end'>
                                <H3 attrH3={{ className: 'card-title mb-0' }}>Kategori: {detail.category ? detail.category.title : '-'}</H3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Col>
                            <H4 attrH4={{ className: 'font-roboto mt-4 mb-3' }}><b>{'Deskripsi Header Program:'}</b></H4>
                            <H6 attrH6={{ className: 'font-roboto mt-2 mb-0' }}>{detail.header_desc}</H6>
                        </Col>
                        <Col>
                            <H4 attrH4={{ className: 'font-roboto mt-4 mb-3' }}><b>{'Deskripsi Content Program:'}</b></H4>
                            <H6 attrH6={{ className: 'font-roboto mt-2 mb-0' }}>{detail.content_desc}</H6>
                        </Col>
                        <Row>
                            <Col>
                                <H4 attrH4={{ className: 'font-roboto mt-4 mb-3' }}><b>{'Banner Program:'}</b></H4>
                                <div className="blog-page blog-box">
                                    <img style={{ width: "30%" }} src={detail.image} alt={detail.title} />
                                </div>
                            </Col>
                            <Col>
                                <H4 attrH4={{ className: 'font-roboto mt-4' }}><b>{'Batch Program:'}</b></H4>
                                <H4 attrH4={{ className: 'font-roboto mt-2 mb-0' }}><RawComponent htmlContent={detail.batch || '-'} /></H4>
                            </Col>
                        </Row>
                        <Col>
                            <H4 attrH4={{ className: 'font-roboto mt-4 mb-3' }}><b>{'Lampiran Program'}</b></H4>
                            <Link to={detail.attachment} target="_blank">
                                <Button color="primary" className="btn-block">Buka Lampiran PDF</Button>
                            </Link>
                        </Col>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col>
                                <H6 attrH6={{ className: 'font-roboto' }}>Created at: {Moment(detail.created_at).format('dddd, D MMMM YYYY')} - {Moment(detail.created_at).format('HH:mm:ss')} WIB</H6>
                            </Col>
                            <Col className='text-end'>
                                <Link to={`${process.env.PUBLIC_URL}/main-program`}>
                                    <Btn attrBtn={{ color: 'warning', className: 'btn btn-primary m-r-10' }}>
                                        <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                                    </Btn>
                                </Link>
                                <AttachModal colorBtn={'primary'} item={detail} buttonLabel={'Tambah Membership'} toggle={toggle} modalLabel={'Tambah Membership ke program'} modal={modal} handleSubmit={() => handleSubmit('attach')} >
                                    <AsyncSelectCommon label={'Membership'} placeholder={'Silahkan pilih membership...'}
                                        isMulti={true}
                                        promiseOptions={promiseOptions}
                                        handleSelectChange={handleSelectChange}
                                        selectedValue={selectedValue}
                                    />
                                </AttachModal>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Col>
        </Fragment >
    );
};
export default DetailProgramContain;
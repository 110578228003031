import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import SubMateriContain from '../../../Component/Learning/SubMateri';

const LearningList = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Learning List" grandparent="Learning" parent="Materi" title="Daftar Submateri" />
      <SubMateriContain />
    </Fragment>
  );
};
export default LearningList;
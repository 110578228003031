import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import {
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
	Image,
	Btn,
	LI,
	P,
	UL,
	Spinner,
	Badges,
} from "../../../../AbstractElements";
import DetailScheduleContain from "../../../../Component/Classes/Schedule/DetailSchedule/DetailSchedule";
import DetailUserSchedule from "../../../../Component/Classes/Schedule/DetailSchedule/DetailUserSchedule";
import DetailScheduleAttendance from "../../../../Component/Classes/Schedule/DetailSchedule/DetailScheduleAttendance";
import DetailUserClassContain from "../../../../Component/Classes/Class/DetailUserClass/DetailUserClassContain";

const DetailScheduleGeneral = () => {
	const detail = useSelector((state) => state.Schedule.detail);
	const isLoading = useSelector((state) => state.Schedule.isLoading);

	return (
		<Fragment>
			<Container fluid={true}>
				<div>
					{isLoading ? (
						<Col lg="12">
							<div className="loader-box">
								<Spinner attrSpinner={{ className: "loader-30" }} />
							</div>
							<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
								{"Data Loading..."}
							</H6>
						</Col>
					) : (
						detail.id &&
						detail.tentor && (
							<Row className="product-page-main p-0">
								<DetailScheduleContain />
								<DetailScheduleAttendance />
								<DetailUserSchedule />
							</Row>
						)
					)}
				</div>
			</Container>
		</Fragment>
	);
};

export default DetailScheduleGeneral;

import React, { useState, Fragment} from 'react';
import { Col, Form, FormGroup, Label, Input, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { H6, Image, Btn, LI, P, UL } from '../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { setField } from '../../../Features/Learning/Tryout/addTryoutSlice';
import { useEffect } from 'react';
import RichEditor from '../../../Component/Editor/RichEditor/RichEditor';
import Moment from 'moment';
import 'moment/locale/id';


const MateriForm = () => {
    const [startDate, setstartDate] = useState(null);
    const [endDate, setendDate] = useState(null);
    const dispatch = useDispatch()
    const data = useSelector(state => state.addTryout)
    const cards = useSelector(state => state.Tryout)
    const handleStartDate = date => {
        setstartDate(date);
        if (date === null) {
          dispatch(setField({ field: 'start_at', value: '' }));
        } else {
          dispatch(setField({ field: 'start_at', value: Moment(date).format('yyyy-MM-DD HH:mm:ss') }));
        }
    };

    const handleKeyDown = (e) => {
        // Hanya izinkan input angka (0-9) dan backspace
        const isValidInput = /^[0-9\b]+$/.test(e.key);

        if (!isValidInput && e.key !== 'Backspace') {
            e.preventDefault();
        }
    };

    const handleEndDate = date => {
        setendDate(date);
        if (date === null) {
          dispatch(setField({ field: 'end_at', value: '' }));
        } else {
          dispatch(setField({ field: 'end_at', value: Moment(date).format('yyyy-MM-DD HH:mm:ss') }));
        }
    };
    
    const handleChanges = (e) => {
        const { name, value } = e.target;
        dispatch(setField({ field: name, value: value }));
        console.log(name, value);
    }
    const handleChangeDescription = (e) => {
        dispatch(setField({ field: 'description', value: e }));
    }
    const handleChangeInstruction = (e) => {
        dispatch(setField({ field: 'instruction', value: e }));
    }

    useEffect(() => {
      const selectedStart = data.start_at
        ? Moment(data.start_at, 'YYYY-MM-DD HH:mm:ss').toDate()
        : null;
      setstartDate(selectedStart);
      const selectedEnd = data.end_at
        ? Moment(data.end_at, 'YYYY-MM-DD HH:mm:ss').toDate()
        : null;
      setendDate(selectedEnd);
    }, [data.start_at, data.end_at]);

  return (
    <Fragment>
      <Form className="row needs-validation">
        <Col sm="12">
          <FormGroup className='mb-3'>
            <Label for="validationTitle" style={{ fontWeight: 'bold' }}>{'Judul Tryout'}:</Label>
            <Input className="form-control" id="validationTitle" type="text" name="title" placeholder="Nama Tryout" required=""
            value={data.title}
            onChange={handleChanges} />
          </FormGroup>
          <FormGroup className='mb-3'>
            <Label for="validationImage" style={{ fontWeight: 'bold' }}>Apakah Tryout ini digunakan untuk Kuis?</Label>
            <div className="m-checkbox-inline">
              <Label for="is_quizTrue">
                <Input className="radio_animated" id="is_quizTrue" type="radio" name="is_quiz" value="1"
                  checked={data.is_quiz == '1'}
                  onChange={handleChanges} />{'Ya'}
              </Label>
              <Label for="is_quizFalse">
                <Input className="radio_animated" id="is_quizFalse" type="radio" name="is_quiz" value="0"
                  checked={data.is_quiz == '0'}
                  onChange={handleChanges} />{'Tidak'}
              </Label>
            </div>
          </FormGroup>          
          <FormGroup className='mb-3'>
            <Label for="validationImage" style={{ fontWeight: 'bold' }}>Apakah Tryout ini ditampilkan pada laporan siswa?</Label>
            <div className="m-checkbox-inline">
              <Label for="is_report_showTrue">
                <Input className="radio_animated" id="is_report_showTrue" type="radio" name="is_report_show" value="1"
                  checked={data.is_report_show == '1'}
                  onChange={handleChanges} />{'Ya'}
              </Label>
              <Label for="is_report_showFalse">
                <Input className="radio_animated" id="is_report_showFalse" type="radio" name="is_report_show" value="0"
                  checked={data.is_report_show == '0'}
                  onChange={handleChanges} />{'Tidak'}
              </Label>
            </div>
          </FormGroup>          
          <FormGroup className='mb-3'>
            <Label for="validationDuration" style={{ fontWeight: 'bold' }}>{'Durasi Pengerjaan Tryout'}:</Label>
            <Input className="form-control" id="validationDuration" type="text" name="time" placeholder="Lama waktu dalam menit" required=""
            value={data.time}
            onChange={handleChanges} 
            onKeyDown={handleKeyDown} />
          </FormGroup>
          <FormGroup className='mb-3'>
            <Row>
                <Col>
                    <Label for="validationStarted" style={{ fontWeight: 'bold', marginRight: '5px' }}>{'Waktu Mulai'} : </Label>
                    <DatePicker 
                    className="form-control digits" 
                    showPopperArrow={false} 
                    selected={startDate} 
                    showTimeSelect
                    timeFormat="HH:mm:ss" 
                    dateFormat="yyyy-MM-dd HH:mm:ss" 
                    closeOnScroll={true}
                    onChange={handleStartDate}
                    isClearable
                    placeholderText="Pilih waktu mulai!"
                    />
                </Col>
                <Col>
                    <Label for="validationEnded" style={{ fontWeight: 'bold', marginRight: '5px' }}>{'Waktu Berakhir'} : </Label>
                    <DatePicker 
                    className="form-control digits" 
                    showPopperArrow={false} 
                    selected={endDate} 
                    showTimeSelect
                    timeFormat="HH:mm:ss" 
                    dateFormat="yyyy-MM-dd HH:mm:ss" 
                    closeOnScroll={true}
                    onChange={handleEndDate}
                    isClearable
                    placeholderText="Pilih waktu berakhir!"
                    />
                </Col>
            </Row>
          </FormGroup>
          <FormGroup className='mb-3'>
            <Label for="validationQuotas" style={{ fontWeight: 'bold' }}>{'Kuota Peserta Tryout'}:</Label>
            <Input className="form-control" id="validationQuotas" type="text" name="quotas" placeholder="Jumlah maksimal peserta" required=""
            value={data.quotas}
            onChange={handleChanges} 
            onKeyDown={handleKeyDown} />
          </FormGroup>
          <FormGroup className='mb-3'>
            <Label for="validationDescription" style={{ fontWeight: 'bold' }}>{'Description'}:</Label>
            <RichEditor id="validationDescription" handleChangeDescription={handleChangeDescription} description={data.description} />
          </FormGroup>
          {/* <FormGroup className='mb-3'>
            <Label for="validationDescription" style={{ fontWeight: 'bold' }}>{'Instruksi'}:</Label>
            <RichEditor id="validationDescription" handleChangeDescription={handleChangeInstruction} description={data.instruction} />
          </FormGroup> */}
          {data.bannerLink && (
            <FormGroup className='mb-3'>
              <Image attrImage={{ className: 'img-fluid', width: '500px', src: data.bannerLink, alt: data.title }} />
            </FormGroup>
          )}
        </Col>
      </Form>
    </Fragment>
  );
};
export default MateriForm;
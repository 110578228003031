import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import DetailTentor from '../../../Component/Classes/Tentor/DetailTentor';
import { handleGetDetailTentor } from '../../../Component/Classes/Tentor/TentorUtils';
import { fetchTentors } from '../../../Features/Classes/Tentor/Utils/TentorUtils';
import { setDetail } from '../../../Features/Classes/Tentor/TentorSlice';


const TentorDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.Tentor.filters)
    const dataField = useSelector(state => state.Tentor.detail);

    useEffect(() => {
        if (id && !dataField.id) {
            const newFilters = {
                ...filters,
                'filter[id]': id,
                'page': 1,
            };
            dispatch(fetchTentors(newFilters))
                .then((response) => {
                    handleGetDetailTentor(dispatch, response.payload.data[0], setDetail)
                })
                .catch((error) => {
                    console.error('Error during fetchTentor on Detail:', error);
                });
        }
    } , [id])

    return (
        <Fragment>
            <Breadcrumbs parent="Kelas" title="Detail Tentor" />
            <DetailTentor />
        </Fragment>
    );
};
export default TentorDetail;
import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, Input, Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import HistoryReferralCards from './HistoryReferralCards';
import HistoryReferralFilter from './Filter/HistoryReferralFilter';



const HistoryReferralOrderContain = () => {
    const cards = useSelector(state => state.historyUserReferral.data.data)

    return (
        <Fragment>
            <Container fluid={true}>
                <Row className='learning-block'>
                    <Col xl="9" className='xl-80'>
                        <Row>
                            <HistoryReferralCards />
                        </Row>
                    </Col>
                    {cards && (
                        <HistoryReferralFilter />
                    )}
                </Row>
            </Container>
        </Fragment>
    );
};
export default HistoryReferralOrderContain;
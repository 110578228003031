import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import QuestionsBox from './QuestionsBox';
import { Btn } from '../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import { resetState } from '../../../Features/Learning/BankQuestions/addQuestionSlice';
import { resetStateImport } from '../../../Features/Learning/BankQuestions/importQuestionSlice';
import QuestionFilter from './Filter/QuestionFilter';


const QuestionContain = () => {
  const cards = useSelector(state => state.Question.data.data)
  const history = useNavigate();
  const dispatch = useDispatch();

  const handleAdd = (e) => {
    e.preventDefault();
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/learning/soal-add`);
  };

  const handleImport = (e) => {
    e.preventDefault();
    dispatch(resetStateImport());
    history(`${process.env.PUBLIC_URL}/learning/soal-import`);
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Col md="12" className="project-list">
          {cards && (
            <Card>
              <Row className='justify-content-between align-items-center mb-3'>
                <Col className="d-flex justify-content-start">
                  <Btn attrBtn={{ color: 'primary', onClick: handleAdd }}>
                    <i className="fa fa-plus-circle fa-lg"></i> Buat Soal Baru
                  </Btn>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Btn attrBtn={{ color: 'warning', onClick: handleImport }}>
                    <i className="fa fa-upload fa-lg"></i> Import Soal
                  </Btn>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
        <Row className='learning-block'>
          <Col xl="9" className='xl-80'>
            <Row>
              <QuestionsBox />
            </Row>
          </Col>
          {cards && (
          <QuestionFilter />
          )}
        </Row>
      </Container>
    </Fragment>
  );
};
export default QuestionContain;
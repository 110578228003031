import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import HistoryReferralContain from '../../../Component/Learning/HistoryReferral/ReferralMutation';

const UserHistoryReferralMutation = () => {
    return (
        <Fragment>
            <Breadcrumbs grandparent="Users" parent={<Link to={`${process.env.PUBLIC_URL}/users/referrer-list`}>Daftar Referrer</Link>} title="Laporan Mutasi" />
            <HistoryReferralContain />
        </Fragment>
    );
};
export default UserHistoryReferralMutation;
import { createSlice } from '@reduxjs/toolkit';
import { submitUsers } from './Utils/UserUtils';

const initialState = {
    username: '',
    name: '',
    email: '',
    gender: '',
    phone: '',
    password: '',
    referral_code: '',
    old_referral_code: '',
    isLoading: false,
    isUpdate: false,
    id: '',
};

const addUserSlice = createSlice(
    {
        name: 'addUser',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
            .addCase(submitUsers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(submitUsers.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(submitUsers.rejected, (state, action) => {
                state.isLoading = false;
                console.log(action.error.message, "error action")
            })
        }
    }
);

export const { setField, resetState } = addUserSlice.actions;

export default addUserSlice.reducer;
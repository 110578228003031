import React, { Fragment, useEffect } from "react";
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Form,
	FormGroup,
	Input,
	Label,
	Col,
} from "reactstrap";
import { Btn, H4, H5, H6, Image, Spinner } from "../../../../AbstractElements";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Moment from "moment";
import "moment/locale/id";

const VoucherContent = () => {
	// const detail = useSelector(state => state.Voucher.detail);
	const item = useSelector((state) => state.Voucher.detail);
	const formatCurrency = (value) => {
		return new Intl.NumberFormat("id-ID", {
			style: "currency",
			currency: "IDR",
			minimumFractionDigits: 0,
		}).format(value);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Fragment>
			<Card>
				{/* <CardHeader className="pb-0">
                    <H4 attrH4={{ className: 'card-title mb-0' }}>Detail Voucher</H4>
                </CardHeader> */}
				<CardBody>
					{item.id && (
						<Form>
							<FormGroup className="mb-3">
								<Label className="form-label">
									<b>Kode Voucher</b>
								</Label>
								<Input
									readOnly
									className="form-control"
									defaultValue={item.code ? item.code : "-"}
								/>
							</FormGroup>
							<FormGroup className="mb-3">
								<Label className="form-label">
									<b>Jenis Diskon</b>
								</Label>
								<Input
									readOnly
									className="form-control"
									defaultValue={item.is_percentage ? "Persentase" : "Nominal"}
								/>
							</FormGroup>
							<FormGroup className="mb-3">
								<Label className="form-label">
									<b>Diskon</b>
								</Label>
								<Input
									readOnly
									className="form-control"
									defaultValue={
										item.is_percentage && item.discount
											? item.discount + "%"
											: formatCurrency(item.discount)
									}
								/>
							</FormGroup>
							<FormGroup className="mb-3">
								<Label className="form-label">
									<b>Nominal Minimum</b>
								</Label>
								<Input
									readOnly
									className="form-control"
									defaultValue={
										item.minimum_nominal
											? formatCurrency(item.minimum_nominal)
											: "-"
									}
								/>
							</FormGroup>
							<FormGroup className="mb-3">
								<Label className="form-label">
									<b>Masa Berlaku</b>
								</Label>
								<Input
									readOnly
									className="form-control"
									defaultValue={
										item.valid_until
											? Moment.utc(item.valid_until).format("DD MMMM YYYY")
											: "-"
									}
								/>
							</FormGroup>
							<FormGroup className="mb-0">
								<Label>
									<b>Dibuat pada :</b>
								</Label>
								<Label>
									{Moment(item.created_at).format("dddd, D MMMM YYYY")} -{" "}
									{Moment(item.created_at).format("HH:mm:ss")} WIB
								</Label>
							</FormGroup>
						</Form>
					)}
				</CardBody>
				<CardFooter className="text-start">
					<Link to={`${process.env.PUBLIC_URL}/voucher`}>
						<Btn
							attrBtn={{
								color: "warning",
								className: "btn btn-primary m-r-10",
							}}>
							<i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
						</Btn>
					</Link>
				</CardFooter>
			</Card>
		</Fragment>
	);
};
export default VoucherContent;

import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Post, Discard } from '../../../Constant';
import { Btn, H5, H6, Image, Spinner } from '../../../AbstractElements';
import ClassForm from '../../../Component/Classes/Class/ClassForm';
import DropzoneCommon from '../../../Component/Learning/Common/Upload/Dropzone';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { submitClasses } from '../../../Features/Classes/Class/Utils/ClassUtils';
import { resetState, setField } from '../../../Features/Classes/Class/addClassSlice';
import Swal from 'sweetalert2';

const ClassPostContain = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const data = useSelector(state => state.addClass)
  const startUpload = useSelector(state => state.addClass.isLoading);
  const isUpdate = useSelector(state => state.addClass.isUpdate);
  const { classId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSuccess = () => {
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/classes/class-list`);
  };

  const showError = (message) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMessage = '';
    if (!isUpdate) {
      if (data.name === '' || data.description === '' || data.description === '<p><br></p>' || data.banner === '' || data.banner === null) {
        if(data.name === '') {
          errorMessage = 'Nama Kelas tidak boleh kosong.';
        } else if (data.description === '' || data.description === '<p><br></p>') {
          errorMessage = 'Deskripsi Kelas tidak boleh kosong.';
        } else if (data.banner === '' || data.banner === null) {
          errorMessage = 'Banner Kelas tidak boleh kosong.';
        }
        showError(errorMessage);
      } else {
        dispatch(submitClasses({ ...data, successCallback: handleSuccess }))
      }
    } else {
      if (data.name === '' || data.description === '' || data.description === '<p><br></p>') {
        if(data.name === '') {
          errorMessage = 'Nama Kelas tidak boleh kosong.';
        } else if (data.description === '' || data.description === '<p><br></p>') {
          errorMessage = 'Deskripsi Kelas tidak boleh kosong.';
        } 
        showError(errorMessage);
      } else {
        dispatch(submitClasses({ ...data, successCallback: handleSuccess }))
      }
    }
  };


  const handleDiscard = (e) => {
    e.preventDefault();
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/classes/class-list`);
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    // console.log(status, meta, file)
    if (status === 'removed') {
      dispatch(setField({ field: 'banner', value: '' }));
    } else if (status === 'done') {
      dispatch(setField({ field: 'banner', value: file }));
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {startUpload ? (
              <Col lg="12">
                <div className="loader-box">
                  <Spinner attrSpinner={{ className: 'loader-30' }} />
                </div>
                <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses unggah...'}</H6>
              </Col>
            ) : (
            <Card>
              <CardHeader className='pb-0'><H5>{classId ? '' : 'Kelas Baru'}</H5></CardHeader>
              <CardBody className="add-post">
                <ClassForm />
                <DropzoneCommon label="Banner Kelas" inputContent={data.bannerLink ? 'Ganti gambar...' : 'Silahkan upload gambar...'} handleChangeStatus={handleChangeStatus} accept="image/*" />
                <div className="btn-showcase text-end">
                  <Btn attrBtn={{ color: 'primary', type: 'submit', onClick: handleSubmit }}>{Post}</Btn>
                  <Btn attrBtn={{ color: 'light', type: 'reset', onClick: handleDiscard }}>{Discard}</Btn>
                </div>
              </CardBody>
            </Card>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ClassPostContain;
import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import MembershipDetail from './MembershipDetail';

const DetailMembership = () => {
    return (
        <Fragment>
            <Container fluid={true}>
                <div>
                    <Row className="product-page-main p-0">
                        <MembershipDetail />
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};

export default DetailMembership;
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import HistoryReferralOrderContain from '../../../Component/Learning/HistoryReferral/ReferralOrder';

const UserHistoryReferral = () => {
    return (
        <Fragment>
            <Breadcrumbs grandparent="Users" parent={<Link to={`${process.env.PUBLIC_URL}/users/referrer-list`}>Daftar Referrer</Link>} title="Riwayat Pesanan" />
            <HistoryReferralOrderContain />
        </Fragment>
    );
};
export default UserHistoryReferral;
import React, { Fragment, useEffect, useState } from "react";
import {
	Col,
	Card,
	CardBody,
	CardFooter,
	Table,
	Row,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
} from "reactstrap";
import { Post, Discard } from "../../../../Constant";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	Image,
	H6,
	H5,
	H4,
	H3,
	H2,
	H1,
	LI,
	UL,
	P,
	Btn,
	Spinner,
	Badges,
} from "../../../../AbstractElements";
import {
	fetchTestimony,
	attachDetachTestimonyMainProgram,
	deleteTestimony,
} from "../../../../Features/Learning/Testimony/Utils/TestimonyUtils";
import { setFilters } from "../../../../Features/Learning/Testimony/TestimonySlice";
import {
	handleNextPagination,
	handleBackPagination,
	handleClickPagination,
} from "../../../../Component/Classes/Class/ClassUtils";
import PaginationComponent from "../../../../Component/Learning/Common/Pagination";
import AsynchSelectMainProgram from "../../../../Component/Users/Testimonial/AsynchSelectMainProgram";
import Swal from "sweetalert2";

const TestimonialList = () => {
	const dispatch = useDispatch();
	const dataTestimony = useSelector((state) => state.Testimony.data.data);
	const filters = useSelector((state) => state.Testimony.filters);
	const pagination = useSelector((state) => state.Testimony.data.pagination);
	const [openPopup, setOpenPopup] = useState(false);
	const [selectedTestimony, setSelectedTestimony] = useState(null);
	const [selectedMainProgram, setSelectedMainProgram] = useState([]);
	const [refatch, setRefatch] = useState(false);
	const history = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		dispatch(fetchTestimony(filters));
	}, [refatch]);

	const handleDeselctMainProgram = (id) => {
		const newMainProgram = selectedMainProgram.filter((item) => item.id !== id);
		setSelectedMainProgram(newMainProgram);
	};

	const handleEditTestimony = (data) => {
		history(`${process.env.PUBLIC_URL}/user-testimonials/add/${data.id}`);
	};

	const handleAttachMainProgram = (data) => {
		setSelectedMainProgram(data);
	};

	const handleDeleteTestimony = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "No, cancel!",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(deleteTestimony(id));
				setTimeout(() => {
					setRefatch(!refatch);
				}, 1000);
			}
		});
	};

	return (
		<Fragment>
			<Col sm="12 order-2">
				<Card className="mt-4 mb-2 p-3">
					<Row>
						<Col lg="9"></Col>
						<Col lg="3" className="text-end">
							<Link to={`${process.env.PUBLIC_URL}/user-testimonials/add`}>
								<Btn color="primary" className="btn-sm">
									<i className="fa fa-plus me-2"></i>
									New Testimonial
								</Btn>
							</Link>
						</Col>
					</Row>
				</Card>
				<Card className="mt-3">
					<CardBody>
						<Table className="table-bordered">
							<thead>
								<tr>
									<th
										scope="col"
										className="bg-primary text-light"
										style={{ verticalAlign: "middle" }}>
										#
									</th>
									<th
										scope="col"
										className="bg-primary text-light"
										style={{ verticalAlign: "middle" }}>
										<b>User</b>
										<br /> <i>Membership</i>
									</th>
									<th
										scope="col"
										className="bg-primary text-light text-center"
										style={{ verticalAlign: "middle" }}>
										Testimoni
									</th>
									<th
										scope="col"
										className="bg-primary text-light text-center"
										style={{ verticalAlign: "middle" }}>
										Rating
									</th>
									<th
										scope="col"
										className="bg-primary text-light text-center"
										style={{ width: "80px", verticalAlign: "middle" }}>
										Tampil di Program
									</th>
									<th
										scope="col"
										className="bg-primary text-light text-center"
										style={{ width: "80px", verticalAlign: "middle" }}>
										Tampil di Home
									</th>
									<th
										scope="col"
										className="bg-primary text-light text-center"
										style={{ width: "120px", verticalAlign: "middle" }}>
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								{dataTestimony &&
									dataTestimony.map((item, index) => (
										<tr key={`testimony-${index}`}>
											<td>{index + 1}</td>
											<td>
												<b>{item.name}</b> <br />
												<i>{item.membership_title ?? "-"}</i>
											</td>
											<td className="text-left">{item.review}</td>
											<td className="text-center">
												<Label
													className={
														"badge badge-" +
														(item.star_rating > 3 ? "success" : "danger")
													}>
													{item.star_rating ?? "-"}
												</Label>
											</td>
											<td className="text-center">
												<Label
													className={
														"badge badge-" +
														(item.main_programs?.length > 0
															? "success"
															: "danger")
													}>
													{item.main_programs?.length}
												</Label>
											</td>
											<td className="text-center">
												<Label
													className={
														"badge badge-" +
														(item.show_in_home == 1 ? "success" : "secondary")
													}>
													{item.show_in_home ? "Ya" : "Tidak"}
												</Label>
											</td>
											<td className="text-center">
												<span
													className="me-2"
													style={{ cursor: "pointer" }}
													title="Edit"
													onClick={() => {
														handleEditTestimony(item);
													}}>
													<i className="fa fa-edit fa-lg me-2"></i>
												</span>
												<span
													className="me-2"
													style={{ cursor: "pointer" }}
													title="Attach to Program"
													onClick={() => {
														setSelectedTestimony(item);
														setOpenPopup(true);
														setSelectedMainProgram(item.main_programs);
													}}>
													<i className="fa fa-link fa-lg me-2"></i>
												</span>
												<span
													style={{ cursor: "pointer" }}
													title="Delete"
													onClick={() => {
														handleDeleteTestimony(item.id);
													}}>
													<i className="fa fa-trash fa-lg"></i>
												</span>
											</td>
										</tr>
									))}
							</tbody>
						</Table>
					</CardBody>
					<CardFooter>
						{pagination && (
							<PaginationComponent
								pagination={pagination}
								handleBackPagination={() =>
									handleBackPagination(
										pagination,
										filters,
										dispatch,
										setFilters,
										setRefatch
									)
								}
								handleClickPagination={(page) =>
									handleClickPagination(
										page,
										filters,
										dispatch,
										setFilters,
										setRefatch
									)
								}
								handleNextPagination={() =>
									handleNextPagination(
										pagination,
										filters,
										dispatch,
										setFilters,
										setRefatch
									)
								}
							/>
						)}
					</CardFooter>
				</Card>
			</Col>

			{/* Modal */}
			<Modal
				isOpen={openPopup}
				toggle={() => setOpenPopup(!openPopup)}
				size="lg">
				<Fragment>
					<ModalHeader toggle={() => setOpenPopup(!openPopup)}>
						Header
					</ModalHeader>
					<ModalBody>
						<AsynchSelectMainProgram
							handleSelectChange={handleAttachMainProgram}
							selectedMainProgram={selectedMainProgram}
						/>
						<div className="btn-showcase text-end mt-3">
							<Btn
								attrBtn={{
									color: "primary",
									type: "submit",
									onClick: () => {
										dispatch(
											attachDetachTestimonyMainProgram({
												id: selectedTestimony.id,
												data: selectedMainProgram,
											})
										);
										setOpenPopup(false);
										setRefatch(!refatch);
									},
								}}>
								{Post}
							</Btn>
							<Btn
								attrBtn={{
									color: "light",
									type: "reset",
									onClick: () => setOpenPopup(false),
								}}>
								{Discard}
							</Btn>
						</div>
					</ModalBody>
				</Fragment>
			</Modal>
		</Fragment>
	);
};
export default TestimonialList;

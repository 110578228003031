import React, { useState, Fragment} from 'react';
import { Button, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SubMateriService } from '../../../Services/axios/SubMateri.Service';
import { toast } from 'react-toastify';
import { H6, Image, Btn, LI, P, UL } from '../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import VideoPlayer from '../../../Component/Learning/DetailSubMateri/VideoPlayer';
import AudioPlayer from '../../../Component/Learning/DetailSubMateri/AudioPlayer';
import { setField } from '../../../Features/Learning/SubMateri/addSubMaterialSlice';
import RichEditor from '../../../Component/Editor/RichEditor/RichEditor';
import Swal from 'sweetalert2';

const FormPost = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.addSubMaterial)
  const handleChanges = (e) => {
    const { name, value } = e.target;
    dispatch(setField({ field: name, value: value }));
    // check if type is changed then reset link and file
    if (name === 'type') {
      dispatch(setField({ field: 'link', value: '' }));
      dispatch(setField({ field: 'linkFile', value: '' }));
      dispatch(setField({ field: 'file', value: null }));
      if (data.isUpdate) {
        Swal.fire({
          icon: 'warning',
          title: 'Perhatian',
          text: 'Anda telah mengubah tipe, pastikan untuk mengisi kembali link atau file yang sesuai dengan tipe yang dipilih.',
        })
      }
    }
  };

  const handleChangeDescription = (e) => {
    dispatch(setField({ field: 'description', value: e }));
  }

  return (
    <Fragment>
      <Form className="row needs-validation">
        <Col sm="12" className="pb-5">
          <FormGroup className='mb-3'>
            <Label for="validationImage" style={{ fontWeight: 'bold' }}>{'Judul'}:</Label>
            <Input className="form-control" id="validationCustom01" type="text" name="name" placeholder="Judul Submateri" required=""
            value={data.name}
            onChange={handleChanges} />
            <div className="valid-feedback">{'Looks good!'}</div>
          </FormGroup>
          <FormGroup className='mb-3'>
            <Label for="validationImage" style={{ fontWeight: 'bold' }}>{'Tipe'}:</Label>
            <div className="m-checkbox-inline">
              <Label for="type_image">
                <Input className="radio_animated" id="type_image" type="radio" name="type" value="image"
                  checked={data.type == 'image'}
                onChange={handleChanges}/>{'Gambar'}
              </Label>
              <Label for="type_youtube">
                <Input className="radio_animated" id="type_youtube" type="radio" name="type" value="youtube"
                  checked={data.type == 'youtube'}
                onChange={handleChanges}/>{'Youtube'}
              </Label>
              <Label for="type_video">
                <Input className="radio_animated" id="type_video" type="radio" name="type" value="video"
                  checked={data.type == 'video'}
                onChange={handleChanges}/>{'Video'}
              </Label>
              <Label for="type_ebook">
                <Input className="radio_animated" id="type_ebook" type="radio" name="type" value="ebook"
                  checked={data.type == 'ebook'}
                onChange={handleChanges}/>{'E-book'}
              </Label>
              <Label for="type_audio">
                <Input className="radio_animated" id="type_audio" type="radio" name="type" value="audio"
                  checked={data.type == 'audio'}
                onChange={handleChanges}/>{'Audio'}
              </Label>
            </div>
          </FormGroup>
          <FormGroup className='mb-3'>
            <Label for="validationImage" style={{ fontWeight: 'bold' }}>{'Deskripsi'}:</Label>
            <RichEditor handleChangeDescription={handleChangeDescription} description={data.description}/>
          </FormGroup>
          {(data.type == 'youtube' || data.type == 'ebook') &&
          <FormGroup className='mb-3'>
            <Label for="validationImage" style={{ fontWeight: 'bold' }}>{'Link'}</Label>
            <Input className="form-control" type="url" name="link" placeholder={data.type == 'youtube' ? 'Youtube Embed Link' : 'E-book Link'}
            value={data.link}
            onChange={handleChanges} />
          </FormGroup>
          }
        </Col>
      </Form>

      {(data.isUpdate === true && data.type === 'video' && data.linkFile !== '') && (
        <FormGroup className='mb-3'>
          <Label for="validationImage" style={{ fontWeight: 'bold' }}>Video:</Label>
          <VideoPlayer link={data.linkFile} width={720} />
        </FormGroup>
      )}
      {(data.isUpdate === true && data.type === 'audio' && data.linkFile !== '') && (
        <FormGroup className='mb-3'>
          <Label for="validationImage" style={{ fontWeight: 'bold' }}>Audio:</Label>
          <AudioPlayer link={data.linkFile} />
        </FormGroup>
      )}
      {(data.isUpdate === true && data.type === 'image' && data.linkFile !== '') && (
        <FormGroup className='mb-3'>
          <Label for="validationImage" style={{ fontWeight: 'bold' }}>Gambar:</Label>
          <div className="embed-responsive embed-responsive-16by9">
            <Image attrImage={{ className: 'img-fluid', src: data.linkFile, width: '500px', alt: data.name }} />
          </div>
        </FormGroup>
      )}
      {(data.isUpdate === true && data.type === 'ebook' && data.linkFile !== '') && (
        <FormGroup className='mb-3'>
          <Label for="validationImage" style={{ fontWeight: 'bold' }}>E-book:</Label>
          <div className="embed-responsive embed-responsive-16by9">
          <Link to={data.linkFile} target="_blank">
            <Button color="primary" className="btn-block">Buka</Button>
          </Link>
          </div>
        </FormGroup>
      )}
      {(data.isUpdate === true && data.type === 'youtube' && data.linkFile !== '') && (
        <FormGroup className='mb-3'>
          <Label for="validationImage" style={{ fontWeight: 'bold' }}>Youtube:</Label>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe width="560" height="315" src={data.linkFile} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
          </div>
        </FormGroup>
      )}
    </Fragment>
  );
};
export default FormPost;
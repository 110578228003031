import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import UserPostContain from '../../../Component/Learning/Users/UserPost';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { handleEditUser } from '../../../Component/Learning/Users/UsersUtils';
import { fetchUsers } from '../../../Features/Learning/Users/Utils/UserUtils';
import { setField, resetState } from '../../../Features/Learning/Users/addUserSlice';


const UserPost = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const addField = useSelector(state => state.addUser)
    const filters = useSelector(state => state.User.filters)

    useEffect(() => {
        if (!addField.id && id) {
            const newFilters = {
                ...filters,
                "filter[id]": id,
                "page": 1,
            };
            dispatch(fetchUsers(newFilters))
            .then((response) => {
                // Kode yang akan dijalankan setelah fetch sukses
                handleEditUser(dispatch, response.payload.data[0], setField)
            })
            .catch((error) => {
                // Tangani error jika fetch gagal
                console.error('Error during fetchUsers:', error);
            });
        } else if (!id) {
            dispatch(resetState());
            console.log(addField, "addField");
        }
    } , [id]);

    return (
        <Fragment>
            <Breadcrumbs parent="Users" title={id ? "Update User" : "Tambah User"} />
            <UserPostContain label={id ? "" : "User Baru"} />
        </Fragment>
    );
};

export default UserPost;

import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { H6, Spinner } from '../../../../AbstractElements';
import EditMyProfile from './EditProfile';
import MyProfileEdit from './MyProfile';
import MembershipUser from './MembershipUser';
import HistoryOrder from './HistoryOrder';


const UsersDetailContain = () => {
  const isLoading = useSelector(state => state.User.isLoading)

  return (
    <Fragment>
      {isLoading ? (
        <Col lg="12">
          <div className="loader-box">
            <Spinner attrSpinner={{ className: 'loader-30' }} />
          </div>
          <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
        </Col>
      ) : (
      <Container fluid={true}>
        <div className="edit-profile">
          <Row>
            <Col xl="4">
              <MyProfileEdit />
            </Col>
            <Col xl="8">
              <EditMyProfile />
              <HistoryOrder />
              <MembershipUser />
            </Col>
          </Row>
        </div>
      </Container>
      )}
    </Fragment>
  );
};
export default UsersDetailContain;
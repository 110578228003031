import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import VoucherDetailContain from '../../../Component/Learning/Voucher/Detail';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetDetailVoucher } from '../../../Component/Learning/Voucher/VoucherUtils';
import { fetchVouchers } from '../../../Features/Learning/Vouchers/Utils/VoucherUtils';
import { setDetail } from '../../../Features/Learning/Vouchers/voucherSlice';

const VoucherDetailPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.Voucher.filters)
    const dataField = useSelector(state => state.Voucher.detail);

    useEffect(() => {
        if (id && !dataField.id) {
            const newFilters = {
                ...filters,
                'filter[id]': id,
                'page': 1,
            };
            dispatch(fetchVouchers(newFilters))
                .then((response) => {
                    // Kode yang akan dijalankan setelah fetch sukses
                    handleGetDetailVoucher(dispatch, response.payload.data[0], setDetail)
                    // Kode lain yang perlu dijalankan setelah fetch sukses
                })
                .catch((error) => {
                    // Tangani error jika fetch gagal
                    console.error('Error during fetchTryout on Detail:', error);
                });
        }
    }
        , [id])

    return (
        <Fragment>
            <Breadcrumbs parent="Voucher" title="Detail Voucher" />
            <VoucherDetailContain />
        </Fragment>
    );
};
export default VoucherDetailPage;
import { createSlice } from '@reduxjs/toolkit';
import { submitClasses } from './Utils/ClassUtils';

const initialState = {
    name: '',
    description: '',
    banner: '',
    id: '',
    isLoading: false,
};

const addClass = createSlice(
    {
        name: 'addClass',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(submitClasses.pending, (state) => {
                    // Handle loading state
                    state.isLoading = true;
                })
                .addCase(submitClasses.fulfilled, (state, action) => {
                    // Handle success state
                    state.isLoading = false;
                })
                .addCase(submitClasses.rejected, (state, action) => {
                    // Handle error state
                    state.isLoading = false;
                });
        }
    }
);

export const { setField, resetState } = addClass.actions;

export default addClass.reducer;
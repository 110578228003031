import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import MembershipPostContain from '../../../Component/Learning/Membership/MembershipPost';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleEditMembership } from '../../../Component/Learning/Membership/MembershipsUtils';
import { fetchMemberships } from '../../../Features/Learning/Memberships/Utils/MembershipUtils';
import { setField, resetState } from '../../../Features/Learning/Memberships/addMembershipSlice';
import { setDetail } from '../../../Features/Learning/Memberships/membershipSlice';

const MembershipPost = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const addField = useSelector(state => state.addMembership);
    const filters = useSelector(state => state.Membership.filters)

    useEffect(() => {
        if (id && !addField.id) {
            const newFilters = {
                ...filters,
                "filter[id]": id,
                "page:": 1,
            };
            dispatch(fetchMemberships(newFilters))
                .then((response) => {
                    // Kode yang akan dijalankan setelah fetch sukses
                    handleEditMembership(dispatch, response.payload.data[0], setField, setDetail)
                    // Kode lain yang perlu dijalankan setelah fetch sukses
                })
                .catch((error) => {
                    // Tangani error jika fetch gagal
                    console.error('Error during fetchMemberships:', error);
                });
        } else if (!id) {
            dispatch(resetState());
            console.log(addField, 'addField');
        }
    }, [id]);

    return (
        <Fragment>
            <Breadcrumbs parent="Membership" title={id ? "Update Membership" : "Tambah Membership"} />
            <MembershipPostContain />
        </Fragment>
    );
};

export default MembershipPost;
import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import { Btn, H4, H5 } from '../../../../AbstractElements';
import { useSelector } from 'react-redux';
import CurrencyFormat from '../../Common/GenerateContent/CurrencyFormatter';


const HistoryProfile = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onEditSubmit = (data) => {
    alert('Form is Submitted');
  };
  const userDetail = useSelector(state => state.User.detail);
  const [detail, setDetail] = useState({});
  useEffect(() => {
    setDetail(userDetail);
  } , [userDetail]);

  return (
    <Fragment>
      {detail.order && detail.order.length > 0 && (
        <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
          <CardHeader className="pb-0">
            <H4 attrH4={{ className: 'card-title mb-0' }}>History Order</H4>
          </CardHeader>
          <CardBody>
              <Col sm="12 order-2">
                <Card>
                  <div>
                    <Table className="table-responsive">
                      <thead className="table-light">
                        <tr>
                          <th scope="col" style={{ verticalAlign: 'middle' }}>{'#'}</th>
                          <th scope="col" style={{ verticalAlign: 'middle' }}>{'Produk'}</th>
                          <th scope="col" style={{ verticalAlign: 'middle' }}>{'Harga'}</th>
                          <th scope="col" className="text-center" style={{ verticalAlign: 'middle' }}>{'Status'}</th>
                          <th scope="col" className="text-center" style={{ verticalAlign: 'middle' }}>{'Diskon'}</th>
                          <th scope="col" className="text-center" style={{ verticalAlign: 'middle' }}>{'Metode Pembayaran'}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          detail.order && detail.order.map((item, index) => {
                            const membership = detail.membership.find(m => m.id === item.membership_id);
                            const membershipTitle = membership ? membership.title : '-';
                            return (
                              <tr key={`order-${index}`}>
                                <th>{index + 1}</th>
                                <td>{membershipTitle}</td>
                                <td><CurrencyFormat amount={item.price} /></td>
                                <td className="text-center">
                                  {item.status === 'expired' ? <Label className="badge badge-danger">Expired</Label> : item.status === 'pending' ? <Label className="badge badge-warning">Pending</Label> : item.status === 'cancelled' ? <Label className="badge badge-dark">Cancelled</Label> : <Label className="badge badge-primary">Paid</Label>}
                                </td>
                                <td><CurrencyFormat amount={item.discount} /></td>
                                <td className="text-center">
                                  <b>{item.payment_method !== null ? item.payment_method.toUpperCase() : '-'}</b>
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </Table>
                  </div>
                </Card>
              </Col>
          </CardBody>
        </Form>
      )}
    </Fragment>
  );
};
export default HistoryProfile;
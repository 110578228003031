import React from 'react';
import { Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Previous, Next } from '../../../../Constant';

const PaginationComponent = ({ pagination, handleBackPagination, handleClickPagination, handleNextPagination }) => {
    return (
        <Row>
            <Col>
                <div className="text-center pb-2">
                    <span>Hal</span>
                    <span className="mx-2">{pagination.current_page}/{pagination.last_page}</span>
                </div>
            </Col>
            <Col>
                <Pagination className="pagination justify-content-center pagination-primary" aria-label="Page navigation example">
                    <PaginationItem disabled={pagination.current_page === 1}>
                        <PaginationLink onClick={() => handleClickPagination(1)}><i className="fa fa-angle-double-left"></i></PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={pagination.current_page === 1}>
                        <PaginationLink onClick={() => handleBackPagination()}><i className="fa fa-angle-left"></i></PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={pagination.current_page === pagination.last_page}>
                        <PaginationLink onClick={() => handleNextPagination()}><i className="fa fa-angle-right"></i></PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={pagination.current_page === pagination.last_page}>
                        <PaginationLink onClick={() => handleClickPagination(pagination.last_page)}><i className="fa fa-angle-double-right"></i></PaginationLink>
                    </PaginationItem>
                </Pagination>
            </Col>
        </Row>
    );
};

export default PaginationComponent;
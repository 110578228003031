import { createSlice } from '@reduxjs/toolkit';
import { fetchDetailMemberClassSchedule, editAttendance  } from './Utils/ScheduleUtils';


const initialState = {
    data: {},
    isGetLoading: false,
    isEditLoading: false,
    is_attended: '',
    attendanceId: '',
};

const detailUserProgressSlice = createSlice({
    name : 'DetailUserProgress',
    initialState : initialState,
    reducers : {
        setField : (state, action) => {
            const { field, value } = action.payload;
            state[field] = value;
        },
        resetState : (state) => {
            return initialState;
        },
    },
    extraReducers : (builder) => {
        builder
            .addCase(fetchDetailMemberClassSchedule.pending, (state) => {
                state.isGetLoading = true;
            })
            .addCase(fetchDetailMemberClassSchedule.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isGetLoading = false;
            })
            .addCase(fetchDetailMemberClassSchedule.rejected, (state) => {
                state.isGetLoading = false;
            })
            .addCase(editAttendance.pending, (state) => {
                state.isEditLoading = true;
            })
            .addCase(editAttendance.fulfilled, (state) => {
                state.isEditLoading = false;
            })
            .addCase(editAttendance.rejected, (state) => {
                state.isEditLoading = false;
            });
    }
});

export const { setField, resetState } = detailUserProgressSlice.actions;

export default detailUserProgressSlice.reducer;
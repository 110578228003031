import React, { useState, Fragment, useEffect } from 'react';
import { Col, Form, FormGroup, Label, Input, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import { setField } from '../../../Features/Classes/Schedule/addScheduleSlice';
import AsynchSelectTentor from '../../../Component/Classes/Schedule/Form/AsynchSelectTentor';
import AsynchSelectCourse from '../../../Component/Classes/Schedule/Form/AsynchSelectCourse';
import Moment from 'moment';
import 'moment/locale/id';
import id from 'date-fns/locale/id';
import Select from 'react-select';


const ScheduleForm = () => {
    registerLocale('id', id);
    const dispatch = useDispatch();
    const [startSession, setStartSession] = useState(null);
    const [endSession, setEndSession] = useState(null);
    const [QRScanStart, setQRScanStart] = useState(null);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const data = useSelector(state => state.addSchedule);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        dispatch(setField({ field: name, value: value }));
    }

    const options = data.materials ? data.materials.map(item => ({
        value: item.id,
        label: item.name,
    })) : [];

    useEffect(() => {
        const selectedMateri = data.materials && data.materials.length > 0 ? data.materials : selectedMaterial;
        setSelectedMaterial(selectedMateri);
    }, [data.materials]);

    const handleSelectChangeMaterial = (selectedOption) => {
        setSelectedMaterial(selectedOption);
        dispatch(setField({ field: 'material_id', value: selectedOption === null ? '' : selectedOption.value }));
    }

    const handleDate = (type) => (date) => {
        if (type === 'startSession') {
            setStartSession(date);
            dispatch(setField({ field: 'session_start_at', value: date === null ? '' : Moment(date).format('yyyy-MM-DD HH:mm:ss') })); 
        } else if (type === 'endSession') {
                setEndSession(date);
                dispatch(setField({ field: 'session_end_at', value: date === null ? '' : Moment(date).format('yyyy-MM-DD HH:mm:ss') })); 
        } else if (type === 'QRScanStart') {
            setQRScanStart(date);
            dispatch(setField({ field: 'QR_start_at', value: date === null ? '' : Moment(date).format('yyyy-MM-DD HH:mm:ss') })); 
        }
    }

    useEffect(() => {
        const selectedStart = data.session_start_at
            ? Moment(data.session_start_at, 'YYYY-MM-DD HH:mm:ss').toDate()
            : null;
        setStartSession(selectedStart);
        const selectedEnd = data.session_end_at
            ? Moment(data.session_end_at, 'YYYY-MM-DD HH:mm:ss').toDate()
            : null;
        setEndSession(selectedEnd);
        const selectedQRStart = data.QR_start_at
            ? Moment(data.QR_start_at, 'YYYY-MM-DD HH:mm:ss').toDate()
            : null;
        setQRScanStart(selectedQRStart);
    }, [data.session_start_at, data.session_end_at, data.QR_start_at]);
    
    return (
        <Fragment>
            <Form className="row needs-validation">
                <Col sm="12">
                    <FormGroup className='mb-3'>
                        <Label for="validationName" style={{ fontWeight: 'bold' }}>Nama Sesi :</Label>
                        <Input className="form-control" id="validationName" type="text" name="name" placeholder="Masukkan nama sesi" required=""
                            value={data.name}
                            onChange={handleChanges} />
                    </FormGroup>
                    {/* select tentor */}
                    <AsynchSelectTentor />
                    <FormGroup className='mb-3'>
                        <Label for="validationPlace" style={{ fontWeight: 'bold' }}>Ruang :</Label>
                        <Input className="form-control" id="validationPlace" type="text" name="place" placeholder="Masukkan Keterangan Ruang yang dipakai" required=""
                            value={data.place}
                            onChange={handleChanges} />
                    </FormGroup>
                    <FormGroup className='mb-3'>
                        <Row>
                            <Col sm="12" md="12" lg="12" xl="4" className="mb-3">
                                <Label for="validationEnded" style={{ fontWeight: 'bold', marginRight: '5px' }}>QR Scan Mulai :</Label>
                                <DatePicker
                                    locale="id"
                                    className="form-control digits"
                                    showPopperArrow={false}
                                    selected={QRScanStart}
                                    showTimeSelect
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    closeOnScroll={true}
                                    onChange={handleDate('QRScanStart')}
                                    isClearable
                                    placeholderText="Pilih waktu mulai scan!"
                                />
                            </Col>
                            <Col sm="12" md="12" lg="12" xl="4" className="mb-3">
                                <Label for="validationStarted" style={{ fontWeight: 'bold', marginRight: '5px' }}>Sesi Mulai :</Label>
                                <DatePicker
                                    locale="id"
                                    className="form-control digits"
                                    showPopperArrow={false}
                                    selected={startSession}
                                    showTimeSelect
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    closeOnScroll={true}
                                    onChange={handleDate('startSession')}
                                    isClearable
                                    placeholderText="Pilih waktu mulai!"
                                />
                            </Col>
                            <Col sm="12" md="12" lg="12" xl="4" className="mb-3">
                                <Label for="validationEnded" style={{ fontWeight: 'bold', marginRight: '5px' }}>Sesi Berakhir :</Label>
                                <DatePicker
                                    locale="id"
                                    className="form-control digits"
                                    showPopperArrow={false}
                                    selected={endSession}
                                    showTimeSelect
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    closeOnScroll={true}
                                    onChange={handleDate('endSession')}
                                    isClearable
                                    placeholderText="Pilih waktu berakhir!"
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    {/* select course nullable, if clear send payload 'delete' */}
                    <AsynchSelectCourse />
                    {/* select material nullable, if clear send payload 'delete' */}
                    {data.course_id !== null && data.materials.length > 0 && (
                    <FormGroup className='mb-3'>
                        <Label for="validationMaterial" style={{ fontWeight: 'bold' }}>Materi :</Label>
                        <Select
                            options={options}
                            className="js-example-basic-single"
                            isMulti={false}
                            isClearable={true}
                            placeholder="Pilih Materi..."
                            onChange={handleSelectChangeMaterial}
                            value={selectedMaterial}
                        />
                    </FormGroup>
                    )}
                </Col>
            </Form>
        </Fragment>
    )
}

export default ScheduleForm;
import React, { Fragment, useEffect, useState } from "react";
import {
	CheckCircle,
	XCircle,
	Info,
	PlusCircle,
	Minus,
	MinusCircle,
} from "react-feather";
import {
	Col,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Media,
	Table,
	Row,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
} from "reactstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	Image,
	H6,
	H5,
	H4,
	H3,
	H2,
	H1,
	LI,
	UL,
	P,
	Btn,
	Spinner,
	Badges,
} from "../../../../AbstractElements";
import {
	fetchDetailMemberClassSchedule,
	editAttendance,
} from "../../../../Features/Classes/Schedule/Utils/ScheduleUtils";
import Swal from "sweetalert2";
import Moment from "moment";
import "moment/locale/id";

const DetailUserProgressContain = () => {
	const { classId, userId, userName } = useParams();
	const dispatch = useDispatch();
	const detailClass = useSelector(
		(state) => state.detailUserProgress.data.data
	);
	const isLoading = useSelector(
		(state) => state.detailUserProgress.isGetLoading
	);
	const [refatch, setRefatch] = useState(false);
	const [openProgressMaterial, setOpenProgressMaterial] = useState(false);
	const [detailProgress, setDetailProgress] = useState({});

	const formatDate = (date, isShort) => {
		const d = new Date(date);
		const month = d.toLocaleString("default", {
			month: isShort ? "short" : "long",
		});
		const day = d.getDate();
		const year = d.getFullYear();
		return `${day} ${month} ${year}`;
	};

	const timestampToDate = (timestamp) => {
		const d = new Date(timestamp * 1000);
		return d.toDateString();
	};

	const timestampToTime = (timestamp, includeSecond = true) => {
		const d = new Date(timestamp * 1000);
		const hour = String(d.getHours()).padStart(2, "0");
		const minute = String(d.getMinutes()).padStart(2, "0");
		const second = String(d.getSeconds()).padStart(2, "0");
		return `${hour}:${minute}${includeSecond ? `:${second}` : ""}`;
	};

	const TimeComponent = (startDate, endDate) => {
		return (
			<>
				{timestampToTime(startDate, false)} - {timestampToTime(endDate, false)}
			</>
		);
	};

	const handleAttended = (e, attendanceId) => {
		const { checked } = e.target;
		console.log(checked, "checked");
		Swal.fire({
			title: "Apakah anda yakin?",
			text: `Ubah kehadiran menjadi ${checked ? "Hadir" : "Tidak Hadir"}`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Ya, Ubah!",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(editAttendance({ attendanceId, is_attended: checked ? 1 : 0 }))
					.then((response) => {
						console.log(response);
						setRefatch(!refatch);
						Swal.fire("Berhasil!", "Kehadiran berhasil diubah", "success");
					})
					.catch((error) => {
						Swal.fire("Gagal!", "Kehadiran gagal diubah", "error");
					});
			}
		});
	};

	const handleOpenProgressMaterial = (material) => {
		setOpenProgressMaterial(true);
		setDetailProgress(material);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (classId && userId) {
			dispatch(fetchDetailMemberClassSchedule({ classId, userId }));
		}
	}, [classId, userId, refatch]);

	return (
		<Fragment>
			<Col sm="12 order-2">
				{isLoading ? (
					<Col lg="12">
						<div className="loader-box">
							<Spinner attrSpinner={{ className: "loader-30" }} />
						</div>
						<H6 attrH6={{ className: "mb-0 text-center" }}>
							{"Data Loading..."}
						</H6>
					</Col>
				) : (
					<Card>
						<CardHeader className="pb-0">
							<Row>
								<Col lg="9">
									<H5>
										Jadwal Kelas{" "}
										{detailClass && detailClass.class
											? detailClass.class.name
											: ""}{" "}
										- {userName}
									</H5>
								</Col>
								<Col lg="3" className="text-end p-0">
									<Link
										to={`${process.env.PUBLIC_URL}/classes/class-user-detail/${classId}`}>
										<Btn
											attrBtn={{
												color: "warning",
												className: "btn btn-primary m-r-10",
											}}>
											<i className="fa fa-chevron-circle-left fa-lg me-2"></i>
											Back
										</Btn>
									</Link>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							{detailClass &&
							detailClass.schedules &&
							detailClass.schedules.length === 0 ? (
								<H5 attrH5={{ className: "text-center" }}>
									Belum ada sesi terjadwal untuk peserta ini!
								</H5>
							) : (
								<Table className="table-bordered">
									<thead>
										<tr>
											<th
												scope="col"
												className="bg-primary text-light"
												style={{ verticalAlign: "middle" }}>
												#
											</th>
											<th
												scope="col"
												className="bg-primary text-light"
												style={{ verticalAlign: "middle" }}>
												Nama Sesi - Tentor
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Tanggal
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Waktu (WIB)
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Tempat
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ width: "80px", verticalAlign: "middle" }}>
												Materi Pra sesi
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Kehadiran
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Status
											</th>
										</tr>
									</thead>
									<tbody>
										{detailClass &&
											detailClass.schedules &&
											detailClass.schedules.map((item, index) => (
												<tr key={`schedule-${index}`}>
													{console.log(detailClass, "detailClass")}
													<td>{index + 1}</td>
													<td>
														{item.name} - {item.tentor.name}
													</td>
													<td className="text-center">
														{formatDate(
															timestampToDate(item.session_start_at),
															true
														)}
													</td>
													<td className="text-center">
														{TimeComponent(
															item.session_start_at,
															item.session_end_at
														)}
													</td>
													<td className="text-center">{item.place}</td>
													<td className="text-center">
														<div
															style={{ cursor: "pointer" }}
															onClick={() =>
																handleOpenProgressMaterial(item.material)
															}>
															{!item.course.id && !item.material.id ? (
																<Minus size={30} className="text-dark me-1" />
															) : item.progress.is_learned ? (
																<CheckCircle
																	size={30}
																	className="text-success me-1"
																/>
															) : (
																<XCircle
																	size={30}
																	className="text-danger me-1"
																/>
															)}
														</div>
													</td>
													<td className="text-center">
														<Media body className="text-center icon-state">
															<Label className="switch">
																<Input
																	type="checkbox"
																	onChange={(e) => handleAttended(e, item.id)}
																	checked={item.progress.is_attended || ""}
																	name="is_attended"
																/>
																<span className="switch-state"></span>
															</Label>
														</Media>
													</td>
													<td className="text-center">
														{!item.course.id &&
														!item.material.id &&
														!item.progress.is_learned &&
														item.progress.is_attended ? (
															<CheckCircle
																size={30}
																className="text-success me-1"
															/>
														) : item.progress.is_learned &&
														  item.progress.is_attended ? (
															<CheckCircle
																size={30}
																className="text-success me-1"
															/>
														) : (
															<XCircle size={30} className="text-danger me-1" />
														)}
													</td>
												</tr>
											))}
									</tbody>
								</Table>
							)}
						</CardBody>
					</Card>
				)}
			</Col>

			{/* Modal Progress Material */}
			<Modal
				isOpen={openProgressMaterial}
				toggle={() => setOpenProgressMaterial(!openProgressMaterial)}
				size="lg">
				{detailProgress.id && detailProgress.progress_detail.length > 0 ? (
					<Fragment>
						<ModalHeader
							toggle={() => setOpenProgressMaterial(!openProgressMaterial)}>
							{detailProgress.detail.name}
						</ModalHeader>
						<ModalBody>
							<Table className="table-bordered">
								<thead>
									<tr>
										<th
											scope="col"
											className="bg-primary text-light"
											style={{ verticalAlign: "middle" }}>
											#
										</th>
										<th
											scope="col"
											className="bg-primary text-light"
											style={{ verticalAlign: "middle" }}>
											Materi
										</th>
										<th
											scope="col"
											className="bg-primary text-light"
											style={{ verticalAlign: "middle" }}>
											Status
										</th>
										<th
											scope="col"
											className="bg-primary text-light"
											style={{ verticalAlign: "middle" }}>
											Diselesaikan Pada
										</th>
									</tr>
								</thead>
								<tbody>
									{detailProgress.progress_detail.map((item, index) => (
										<tr key={`progress-detail-${index}`}>
											<td>{index + 1}</td>
											<td>{item.sub_material_name}</td>
											<td>
												{item.is_learned ? (
													<CheckCircle
														size={30}
														className="text-success me-1"
													/>
												) : (
													<XCircle size={30} className="text-danger me-1" />
												)}
											</td>
											<td>
												{item.completed_at &&
													Moment(item.completed_at).format(
														"DD MMMM YYYY HH:mm:ss"
													)}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</ModalBody>
					</Fragment>
				) : (
					<ModalBody>
						<H5 attrH5={{ className: "text-center" }}>
							Belum ada materi yang tersedia
						</H5>
					</ModalBody>
				)}
			</Modal>
		</Fragment>
	);
};
export default DetailUserProgressContain;

import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import MembershipContain from '../../../Component/Learning/Membership';

const MembershipList = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Membership List" parent="Membership" title="Daftar Membership" />
            <MembershipContain />
        </Fragment>
    );
};
export default MembershipList;
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserServices } from '../../../../Services/axios/User.Service';
import { checkFilter } from '../../../../Features/Common/CommonUtils';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export const fetchUsers = createAsyncThunk(
    'Users/fetchUsers',
    async (filters) => {
        try {
            let newFilter = checkFilter(filters);
            const response = await UserServices.getAll(newFilter);
            return response.data;
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server. Mohon hubungi pengembang.';
                } else if (statusCode === 0) {
                    errorMessage = 'Tidak ada koneksi internet.';
                } else if (statusCode === 403) {
                    errorMessage = 'Anda tidak memiliki akses untuk mengakses sumber daya ini.';
                }

                Swal.fire({
                    icon: 'error',
                    title: statusCode === 0 ? 'Error' : `Error ${statusCode}`,
                    text: errorMessage,
                });
            } else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
        }
    }
);

export const fetchHistoryReferral = createAsyncThunk(
    'Users/fetchHistoryReferral',
    async (filters) => {
        try {
            let newFilter = checkFilter(filters);
            const response = await UserServices.getHistoryReferral(newFilter);
            return response.data;
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server. Mohon hubungi pengembang.';
                } else if (statusCode === 0) {
                    errorMessage = 'Tidak ada koneksi internet.';
                } else if (statusCode === 403) {
                    errorMessage = 'Anda tidak memiliki akses untuk mengakses sumber daya ini.';
                }

                Swal.fire({
                    icon: 'error',
                    title: statusCode === 0 ? 'Error' : `Error ${statusCode}`,
                    text: errorMessage,
                });
            } else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
        }
    }
);

export const fetchHistoryReferralCompact = createAsyncThunk(
    'Users/fetchHistoryReferralCompact',
    async (filters) => {
        try {
            let newFilter = checkFilter(filters);
            const response = await UserServices.getHistoryReferralCompact(newFilter);
            return response.data;
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server. Mohon hubungi pengembang.';
                } else if (statusCode === 0) {
                    errorMessage = 'Tidak ada koneksi internet.';
                } else if (statusCode === 403) {
                    errorMessage = 'Anda tidak memiliki akses untuk mengakses sumber daya ini.';
                }

                Swal.fire({
                    icon: 'error',
                    title: statusCode === 0 ? 'Error' : `Error ${statusCode}`,
                    text: errorMessage,
                });
            } else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
        }
    }
);

export const fetchHistoryReferralMutation = createAsyncThunk(
    'Users/fetchHistoryReferralMutation',
    async (filters) => {
        try {
            let newFilter = checkFilter(filters);
            const response = await UserServices.getHistoryReferralMutation(newFilter);
            return response.data;
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server. Mohon hubungi pengembang.';
                } else if (statusCode === 0) {
                    errorMessage = 'Tidak ada koneksi internet.';
                } else if (statusCode === 403) {
                    errorMessage = 'Anda tidak memiliki akses untuk mengakses sumber daya ini.';
                }

                Swal.fire({
                    icon: 'error',
                    title: statusCode === 0 ? 'Error' : `Error ${statusCode}`,
                    text: errorMessage,
                });
            } else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
        }
    }
);
            
export const exportUsers = createAsyncThunk(
    'Users/exportUsers',
    async (filters) => {
        try {
        let newFilter = checkFilter(filters);
        const response = await UserServices.export(newFilter);
        const fileLink = response.data.file;
        window.open(fileLink, '_blank');
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server. Mohon hubungi pengembang.';
                } else if (statusCode === 0) {
                    errorMessage = 'Tidak ada koneksi internet.';
                }

                Swal.fire({
                    icon: 'error',
                    title: statusCode === 0 ? 'Error' : `Error ${statusCode}`,
                    text: errorMessage,
                });
            } else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
        }
    }
);

export const fetchUsersFiltered = createAsyncThunk(
    'Users/fetchUsersFiltered',
    async (filters) => {
        try {
        let newFilter = checkFilter(filters);
        const response = await UserServices.getAll(newFilter);
        return response.data;
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server. Mohon hubungi pengembang.';
                } else if (statusCode === 0) {
                    errorMessage = 'Tidak ada koneksi internet.';
                }

                Swal.fire({
                    icon: 'error',
                    title: statusCode === 0 ? 'Error' : `Error ${statusCode}`,
                    text: errorMessage,
                });
            } else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
        }
    }
);

export const submitUsers = createAsyncThunk(
    'Users/submitUsers',
    async (data) => {
        try {
        const formData = new FormData();
        formData.append('username', data.username);
        formData.append('name', data.name);
        formData.append('email', data.email);
        formData.append('gender', data.gender);
        formData.append('phone', data.phone);
        formData.append('parent_name', data.parent_name);
        formData.append('parent_phone', data.parent_phone);
        if (data.password) {
            formData.append('password', data.password);
        }
        if (data.referral_code !== data.old_referral_code) {
            formData.append('referral_code', data.referral_code);
        }
        let response;
        if (data.isUpdate) {
            response = await UserServices.update(data.id, formData);
            toast.success('User berhasil diperbarui', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            response = await UserServices.create(formData);
            toast.success('User berhasil ditambahkan', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        data.successCallback && data.successCallback();
        return response.data.data;
        } catch (error) {
            console.log(error);
            for (const errorField in error.response.data.errors) {
                if (error.response.data.errors.hasOwnProperty(errorField)) {
                    const errorMessages = error.response.data.errors[errorField];
                    let errorMessage = errorMessages[0];
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                }
            }
            if (error.code === "ERR_NETWORK") {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else if (error.response.status === 500) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan sistem. Mohon hubungi pengembang.',
                });
            }
        }
    }
);

export const deleteUsers = createAsyncThunk(
    'Users/deleteUsers',
    async (id) => {
        try {
        const response = await UserServices.delete(id);
        return response.data.data;
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }
);

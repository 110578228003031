import React, { Fragment, useEffect, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Card, Col, Row, CardBody, CardHeader, CardFooter, Table } from 'reactstrap';
import { H1, H2, H3, H4, H5, H6, Image, Btn, LI, P, UL, Spinner } from '../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import RawComponent from '../../../Component/Learning/Common/RawHTML';
import Moment from 'moment';
import 'moment/locale/id';


const TicketDetail = () => {
    const isLoading = useSelector(state => state.Ticket.isLoading)
    const cards = useSelector(state => state.Ticket.data.data)


    return (
        <Fragment>
            {isLoading ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
                </Col>
            ) : (
                cards && cards.length > 0 && (
                    <Col sm="12 order-2">
                      {cards && cards.map((item, index) =>
                        <Card key={`ticketDetail-${index}`} className="social-widget-card">
                            <CardHeader className="pb-0">
                                <H2 attrH2={{ className: 'card-title mb-0' }}>{item.title}</H2>
                            </CardHeader>
                            <CardBody>
                                <Col>
                                    <Row>
                                        <Col>
                                            <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-4' }}>Deskripsi Laporan: </H5>
                                            <H6 attrH6={{ className: 'font-roboto mt-2' }}>{item.description}</H6>
                                        </Col>
                                        <Col>
                                            <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-4' }}>Status Laporan: </H5>
                                            <H6 attrH6={{ className: 'font-roboto mt-2' }}>{item.is_solved ? <i className="fa fa-check-square-o mx-auto text-success fa-lg"></i> : <i className="icofont icofont-close-squared-alt  mx-auto text-danger fa-lg"></i>}{item.is_solved ? ' Sudah' : ' Belum'} Ditanggapi</H6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-4' }}>Soal yang dilaporkan(label soal)</H5>
                                            <H6 attrH6={{ className: 'font-roboto mt-2' }}><RawComponent htmlContent={item.question.title} /></H6>
                                        </Col>
                                        {item.is_solved === 1 && (
                                            <Col>
                                                <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-4' }}>Umpan Balik:</H5>
                                                <H6 attrH6={{ className: 'font-roboto mt-2' }}>{item.feedback ? item.feedback : 'Belum ada umpan balik'}</H6>
                                            </Col>
                                        )}
                                      </Row>
                                </Col>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col>
                                        <H6 attrH6={{ className: 'font-roboto mb-2' }}>Dilaporkan oleh: {item.user.name}</H6>
                                        <H6 attrH6={{ className: 'font-roboto' }}>{Moment(item.created_at).format('dddd, D MMMM YYYY')} - {Moment(item.created_at).format('HH:mm:ss')} WIB</H6>
                                    </Col>
                                    <Col className='text-end'>
                                        <Link to={`${process.env.PUBLIC_URL}/user-tickets`}>
                                            <Btn attrBtn={{ color: 'warning', className: 'btn btn-primary m-r-10' }}>
                                                <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                                            </Btn>
                                        </Link>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                      )}
                    </Col>
                )
            )}

            {cards && cards.length === 0 && (
                <Col lg="12">
                    <H3 attrH3={{ className: 'sub-title mb-5 text-center' }}>{'Data Not Found...'}</H3>
                </Col>
            )}


        </Fragment>
    );
};

export default TicketDetail;
import { createSlice } from "@reduxjs/toolkit";
import { attachDetachCourses } from "./Utils/CoursesUtils";

const initialState = {
    id: '',
    materials: [],
    isLoading: false,
    idDetach: '',
};

const attachDetachCourseSlice = createSlice(
    {
        name: 'attachDetachCourse',
        initialState: initialState,
        reducers: {
            setMaterials: (state, action) => {
                state.materials = action.payload;
            },
            setId : (state, action) => {
                state.id = action.payload;
            },
            setIdDetach : (state, action) => {
                state.idDetach = action.payload;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(attachDetachCourses.pending, (state) => {
                    // Handle loading state
                    console.log("pending action")
                })
                .addCase(attachDetachCourses.fulfilled, (state, action) => {
                    console.log("fulfilled action")
                })
                .addCase(attachDetachCourses.rejected, (state, action) => {
                   console.log(action.error.message, "error action")
                });
        }
    }
);


export const { setMaterials, setId, setIdDetach, resetState } = attachDetachCourseSlice.actions;

export default attachDetachCourseSlice.reducer;
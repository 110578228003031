import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import QuestionContain from '../../../Component/Learning/BankQuestion';

const QuestionList = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Bank Soal" parent="Learning" title="Bank Soal" />
            <QuestionContain />
        </Fragment>
    );
};
export default QuestionList;
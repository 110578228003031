import React, { Fragment, useState } from 'react';
import { Col, Card, CardHeader, CardBody, Input } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { Search } from 'react-feather';
import { Filter } from '../../../Constant';
import { H5, Btn } from '../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../Features/Learning/Categories/categoriesSlice';
import { fetchCategories } from '../../../Features/Learning/Categories/Utils/CategoriesUtils';
import { fetchCategory } from '../Categories/CategoriesUtils';

const FindCategory = () => {
  const [isFilter, setIsFilter] = useState(true);
  const dispatch = useDispatch()
  const filters = useSelector(state => state.Categories.filters)

  const handleClickSearch = (e) => {
    e.preventDefault()
    const newFilters = {
      ...filters,
      'page': 1,
    };
    dispatch(setFilters(newFilters));
    fetchCategory(dispatch, newFilters);
  }

  const handleResetFilter = () => {
    const newFilters = {
      ...filters,
      'filter[id]': '',
      'filter[title]': '',
      'sort': '',
    };

    dispatch(setFilters(newFilters));
    fetchCategory(dispatch, newFilters);
  }

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: 'mb-0' }} >
              <Btn attrBtn={{ className: 'btn btn-link ps-0', onClick: () => setIsFilter(!isFilter), color: 'transperant', datastoggle: 'collapse', databstarget: '#collapseicon', ariaexpanded: 'true', ariacontrols: 'collapseicon' }} >{'Temukan Kategori'}</Btn>
            </H5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
              <CardBody className="filter-cards-view animate-chk">
                <div className="job-filter">
                  <div className="faq-form">
                    <Input className="form-control" type="text" placeholder="Search.." 
                      value={filters['filter[title]']}
                      onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e): null}
                      onChange={(e) => {
                        dispatch(setFilters({
                          ...filters,
                          'filter[title]': e.target.value
                        }))
                      }}
                    />
                    <Search className="search-icon" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Btn attrBtn={{ color: 'primary text-center p-1', onClick: handleClickSearch }}>{Filter}</Btn>
                  </div>
                  <div className="col-md-6">
                    <Btn attrBtn={{ color: 'warning text-center p-1', onClick: handleResetFilter }}>{'Reset'}</Btn>
                  </div>
                </div>
              </CardBody>
            </div>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default FindCategory;
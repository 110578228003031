import React, { Fragment } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { Form, Label, FormGroup } from 'reactstrap';

const CommonDropzone = (props) => {


  return (
    <Fragment>
        <FormGroup className='mb-3'>
            <Form className="m-b-20" encType="multipart/form-data">
            <Label for="validationImage" style={{ fontWeight: 'bold' }}>{props.label}:</Label>
                <div className="m-0 dz-message needsclick">
                <Dropzone
                    key={props.key}
                    onChangeStatus={props.handleChangeStatus}
                    maxFiles={1}
                    multiple={false}
                    canCancel={false}
                    inputContent={props.inputContent}
                    styles={{
                    dropzone: { width: '100%', height: 50 },
                    dropzoneActive: { borderColor: 'green' },
                    }}
                    accept={props.accept}
                />
                </div>
            </Form>
        </FormGroup>
    </Fragment>
  );
};

export default CommonDropzone;
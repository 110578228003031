import React, { useState, Fragment } from 'react';
import { Input, Label } from 'reactstrap';
import { handleSortBy } from './TryoutCheckBoxUtils';
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';
import { setField } from '../../../../Features/Learning/Tryout/addTryoutSlice';
import { setFilters } from '../../../../Features/Learning/Tryout/tryoutSlice';
import { useEffect } from 'react';
import Moment from 'moment';
import 'moment/locale/id';

const TryoutDateCheck = ({ inputDateValue, setInputDateValue }) => {
    const dispatch = useDispatch()
    const [onGoing, setonGoing] = useState(null);
    const filters = useSelector(state => state.Tryout.filters)
    const handleonGoing = date => {
      setInputDateValue(date);
        if (date === null) {
            dispatch(setFilters({
                ...filters,
                'filter[onGoing]': '', // Kosongkan category_id
            }));
        } else {
            dispatch(setFilters({
                ...filters,
                'filter[onGoing]': Moment(date).format('yyyy-MM-DD'), // Atur category_id sesuai dengan nilai yang dipilih
            }));
        }
    };
    // console.log("onGoing = ", onGoing);

  return (
    <Fragment>
      <div className="checkbox-animated mt-0">
        <Label className="col-form-label" style={{ fontWeight: 'bold' }}>{'Sort Tryout yang berjalan '}:</Label>
        <DatePicker
            className="form-control digits"
            showPopperArrow={false}
            selected={filters['filter[onGoing]'] ? new Date(filters['filter[onGoing]']) : null}
            dateFormat="yyyy-MM-dd"
            closeOnScroll={true}
            onChange={handleonGoing}
            isClearable
            placeholderText="Pilih tanggal!"
        />
      </div>
    </Fragment>
  );
};

export default TryoutDateCheck;
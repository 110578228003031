import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import { PlusCircle } from 'react-feather';
import { Btn } from '../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import VoucherContain from './VoucherContain';
import { resetState } from '../../../Features/Learning/Vouchers/addVoucherSlice';
import VoucherFilter from './Filter';


const Vouchers = () => {
    const cards = useSelector(state => state.Voucher.data.data)
    const history = useNavigate();
    const dispatch = useDispatch();
    const handleAdd = (e) => {
        dispatch(resetState());
    };

    return (
        <Fragment>
            <Container fluid={true}>
                <Col md="12" className="project-list">
                    {cards && (
                        <Card>
                            <Row>
                                <Col>
                                    <Link to={`${process.env.PUBLIC_URL}/voucher/add`} onClick={handleAdd}>
                                        <Btn attrBtn={{ color: 'primary'}}>
                                            <i className="fa fa-plus-circle fa-lg"></i> Tambah Voucer Baru
                                        </Btn>
                                    </Link>
                                </Col>
                            </Row>
                        </Card>
                    )}
                </Col>
                <Row className='learning-block'>
                    <Col xl="9" className='xl-80'>
                        <Row>
                            <VoucherContain />
                        </Row>
                    </Col>
                    {cards && (
                    <VoucherFilter />
                    )}
                </Row>
            </Container>
        </Fragment>
    );
};
export default Vouchers;
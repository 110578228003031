import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { Btn, H4 } from '../../../../AbstractElements';
import { EditProfile, Company, Username, UsersCountryMenu, AboutMe, UpdateProfile, FirstName, LastName, Address, EmailAddress, PostalCode, Country, City } from '../../../../Constant';
import { useSelector } from 'react-redux';

const EditMyProfile = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onEditSubmit = (data) => {
    alert('Form is Submitted');
  };
  const userDetail = useSelector(state => state.User.detail);
  const [detail, setDetail] = useState({});
  useEffect(() => {
    setDetail(userDetail);
  } , [userDetail]);

  return (
    <Fragment>
      <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: 'card-title mb-0' }}>User Profile</H4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="5">
              <FormGroup className="mb-3"> 
                <Label className="form-label"><b>Kode Referal</b></Label>
                <Input readOnly className="form-control" defaultValue={detail.referral_code} />
              </FormGroup>
            </Col>
            <Col sm="6" md="3">
              <FormGroup> 
                <Label className="form-label"><b>Username</b></Label>
                <Input readOnly className="form-control" defaultValue={detail.username} />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup> 
                <Label className="form-label"><b>EmailAddress</b></Label>
                <Input readOnly className="form-control" defaultValue={detail.email} />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label className="form-label"><b>Address</b></Label>
                <Input readOnly className="form-control" defaultValue={detail.address} />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Form>
    </Fragment>
  );
};
export default EditMyProfile;
import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Post, Discard } from '../../../../Constant';
import { Btn, H5 } from '../../../../AbstractElements';

const PostContain = (props) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className='pb-0'><H5>{props.title}</H5></CardHeader>
              <CardBody className="add-post">
                {props.children}
                <div className="btn-showcase text-end">
                  <Btn attrBtn={{ color: 'primary', type: 'submit', onClick: props.handleSubmit }}>{Post}</Btn>
                  <Btn attrBtn={{ color: 'light', type: 'reset', onClick: props.handleDiscard }}>{Discard}</Btn>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PostContain;
import React, { Fragment, useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { setField } from '../../../Features/Learning/BankQuestions/addQuestionSlice';

const CategorySelectForm = () => {
  const [enable, setEnable] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector(state => state.addQuestion)
  const dataOptions = [
    { value: 'multiple_choice', label: 'Pilihan Ganda' },
    { value: 'fill_in_the_blanks', label: 'Isian Singkat' },
    { value: 'sentence_arrangement', label: 'Susunan Kalimat' },
  ];

  const parentTitle = data.type ? dataOptions.find(item => item.value === data.type)?.label : null;

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    if (selectedOption !== null) {
      dispatch(setField({ field: 'type', value: selectedOption.value }));
    } else {
      dispatch(setField({ field: 'type', value: '' }));
    }
    // console.log('Selected Value:', selectedOption);
    // console.log('Data State Type: ', data);
  };
  return (
    <Fragment>
      <div className="mb-3">
        <Label className="col-form-label" style={{ fontWeight: 'bold' }}>{'Tipe Soal'}:</Label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Pilihlah tipe soal..."
            isClearable
            isSearchable
            name="color"
            options={dataOptions}
            onChange={handleSelectChange}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          value={{ value: data.type, label: parentTitle }}
          />
      </div>
    </Fragment>
  );
};
export default CategorySelectForm;
import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import MateriContain from '../../../Component/Learning/Materi/MateriPost';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setField, resetState } from '../../../Features/Learning/Materi/addMaterialSlice';
import { fetchMaterial } from '../../../Features/Learning/Materi/Utils/MateriUtils';
import { handleEditMateri } from '../../../Component/Learning/Materi/MateriUtils';

const MateriPost = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const addField = useSelector(state => state.addMaterial)
    const filters = useSelector(state => state.Material.filters)

    useEffect(() => {
        if (!addField.id && id) {
            const newFilters = {
                ...filters,
                "filter[id]": id,
                "page": 1,
            };
            dispatch(fetchMaterial(newFilters))
            .then((response) => {
                // Kode yang akan dijalankan setelah fetch sukses
                handleEditMateri(dispatch, response.payload.data[0], setField)
            })
            .catch((error) => {
                // Tangani error jika fetch gagal
                console.error('Error during fetchMaterial:', error);
            });
        } else if (!id) {
            dispatch(resetState());
            console.log(addField, "addField");
        }
    } , [id]);

    return (
        <Fragment>
            <Breadcrumbs grandparent="Learning" parent="Materi" title={id ? 'Update Materi' : 'Tambah Materi'}/>
            <MateriContain />
        </Fragment>
    );
};

export default MateriPost;
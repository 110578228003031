import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const ThreeDotMenu = ({ visibleActions, dropdownActions }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    return (
        <td className='text-center'>
            <div>
                {visibleActions.map((action, index) => (
                    <div key={index} className='d-inline-block p-1'>
                        {action}
                    </div>
                ))}
                {dropdownActions.length > 0 && (
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className='d-inline-block'>
                        <DropdownToggle color="primary" className='p-1'>
                            <i className="fa fa-ellipsis-h mx-auto fa-lg"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            {dropdownActions.map((action, index) => (
                                <DropdownItem key={index} className='p-1'>
                                    {action}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                )}
            </div>
        </td>
    );
};

export default ThreeDotMenu;

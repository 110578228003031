import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Label, Row, Table, CardFooter } from 'reactstrap';
import { H3, H4, Btn, H6, Spinner } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../Features/Learning/Transactions/transactionSlice';
import {
    fetchTransaction,
    handleSetOrderStatus,
    handleBackPagination,
    handleNextPagination,
    handleClickPagination
} from '../Transaction/TransactionUtils';
import PaginationComponent from '../Common/Pagination';
import CurrencyFormat from '../Common/GenerateContent/CurrencyFormatter';
import Moment from 'moment';
import 'moment/locale/id';

const TransactionCards = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const filters = useSelector(state => state.Transaction.filters)
    const isLoading = useSelector(state => state.Transaction.isLoading)
    const cards = useSelector(state => state.Transaction.data.data)
    const pagination = useSelector(state => state.Transaction.data.pagination)
    const [refatch, setRefatch] = useState(false)

    useEffect(() => {
        fetchTransaction(dispatch, filters, refatch)
    }, [refatch])

  const formatPaymentMethod = (paymentMethod) => {
    if (!paymentMethod) {
      return "-";
    }

    // Mengganti underscore dengan spasi dan mengubah setiap huruf pertama menjadi kapital
    const formattedMethod = paymentMethod
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());

    return formattedMethod;
  }


  return (
    <Fragment>
      <Container fluid={true}>
        {isLoading ? (
          <Col lg="12">
            <div className="loader-box">
              <Spinner attrSpinner={{ className: 'loader-30' }} />
            </div>
            <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
          </Col>
        ) : (
          cards && cards.length > 0 && (
            <Col sm="12 order-2">
              {cards && cards.map ((item, index) => (
                <div key={index} style={{ marginBottom: '50px' }}>
                  <div style={{ padding: '5px', backgroundColor: '#3C6F7D', width: '98%', margin: 'auto', borderRadius: '10px 10px 0 0' }}>
                    <Row style={{ paddingLeft : '10px', paddingRight : '10px' }}>
                      <Col>
                        <Label style={{ color: '#fff', fontSize: '20px', fontWeight: 'bold', display: 'block', marginBottom: '0' }}>Transaction ID : {item.id}</Label>
                        <Label style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', display: 'block' }}>{Moment(item.created_at, 'DD-MM-YYYY HH:mm:ss').format('dddd, DD MMMM YYYY HH:mm:ss')}</Label>
                      </Col>
                      <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                        {item.status && item.status === 'paid' ? (
                              <i className="fa fa-check-circle fa-2x" style={{ color: '#fff' }}></i>
                          ) : (
                            <Btn attrBtn={{ color: 'warning', className: 'btn btn-secondary m-r-10', onClick: () => handleSetOrderStatus(dispatch, item.id, 'paid', setRefatch) }}>
                              <i className="fa fa-shopping-cart fa-lg me-2"></i>Perbarui Status Transaksi
                            </Btn>
                          )
                        }
                      </Col>
                    </Row>
                  </div>
                  <div style={{ border: '1px dashed #000', borderRadius: '10px', padding: '10px', marginBottom: '10px', backgroundColor: '#fff' }}>
                    <Row className="product-page-main p-0">
                      <Col xl="5" md="6" className="box-col-7 xl-50">
                        <Card style={{ marginBottom: '15px' }}>
                          <CardBody>
                              <div>
                              {/* User name, email, phone */}
                                  <H4>Rincian Pelanggan</H4>
                                  <hr style={{ marginTop: '0' }} />
                              </div>
                              <Row>
                                <Col xl="2" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                                  <div>
                                    <i className="fa fa-user fa-lg"></i>
                                  </div>
                                </Col>
                                <Col xl="10">
                                  <div>
                                    <p style={{ fontWeight: 'bold', fontSize: '14px' }}>Nama Pelanggan</p>
                                  </div>
                                  <div>
                                    <Label>{item.user.name}</Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="2" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                                  <div>
                                    <i className="fa fa-envelope fa-lg"></i>
                                  </div>
                                </Col>
                                <Col xl="10">
                                  <div>
                                    <p style={{ fontWeight: 'bold', fontSize: '14px' }}>Alamat Email</p>
                                  </div>
                                  <div>
                                    <Label>{item.user.email}</Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="2" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                                  <div>
                                    <i className="fa fa-phone fa-lg"></i>
                                  </div>
                                </Col>
                                <Col xl="10">
                                  <div>
                                    <p style={{ fontWeight: 'bold', fontSize: '14' }}>Phone</p>
                                  </div>
                                  <div>
                                    <a href={`https://wa.me/62${item.user.phone}`} target="_blank" rel="noreferrer">{item.user.phone.replace(/(\d{4})(\d{4})(\d{4})/, '$1 $2 $3')}</a>
                                  </div>
                                </Col>
                              </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl="5" md="6" className="box-col-7 xl-50">
                        <Card>
                          <CardBody>
                              {/* Status, Payment Method */}
                              <div>
                                  <H4>Rincian Transaksi</H4>
                                  <hr style={{ marginTop: '0' }} />
                              </div>
                              <Row>
                                <Col xl="2" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                                  <div>
                                    <i className="fa fa-shopping-cart fa-lg"></i>
                                  </div>
                                </Col>
                                <Col xl="10">
                                  <div>
                                    <p style={{ fontWeight: 'bold', fontSize: '14px' }}>Status</p>
                                  </div>
                                  <div>
                                  <Label>
                                    {item.order?.status === 'paid' && 'Sudah Dibayar'}
                                    {item.order?.status === 'pending' && 'Menunggu Pembayaran'}
                                    {item.order?.status === 'cancelled' && 'Dibatalkan'}
                                    {!['paid', 'pending', 'cancelled'].includes(item.order?.status) && 'By Admin'}
                                  </Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="2" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                                  <div>
                                    <i className="fa fa-money fa-lg"></i>
                                  </div>
                                </Col>
                                <Col xl="10">
                                  <div>
                                    <p style={{ fontWeight: 'bold', fontSize: '14px' }}>Metode Pembayaran</p>
                                  </div>
                                  <div>
                                    <Label>{formatPaymentMethod(item.payment_method)}</Label>
                                  </div>
                                </Col>
                              </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl="5" className="box-col-12 proorder-xl-3 xl-100">
                        <Card style={{ marginBottom: '0' }}>
                          <CardBody>
                              <div>
                                  <H4>Rincian Pesanan</H4>
                                  <hr style={{ marginTop: '0' }} />
                              </div>
                              {/* item.membership.title, item.membership.duration, item.price.membership_fee */}
                              {/* item.price.registration_fee */}
                              {/* item.diskon */}
                              {/* item.price.total */}
                              <Table> 
                                <thead>
                                  <tr>
                                    <th style={{ width: '50%' }}>Paket</th> 
                                    <th>Durasi</th>
                                    <th>Harga Paket</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{item.membership.title}</td>
                                    <td>{item.membership.duration_days} Hari</td>
                                    <td><CurrencyFormat amount={item.price.membership_fee} /></td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2" style={{ textAlign: 'right' }}><b>Biaya Pendaftaran</b></td>
                                    <td><CurrencyFormat amount={item.price.registration_fee} /></td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2" style={{ textAlign: 'right' }}><b>Diskon</b></td>
                                    <td><CurrencyFormat amount={item.discount} /></td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2" style={{ textAlign: 'right' }}><b>Total</b></td>
                                    <td style={{ fontWeight: 'bold' }}><CurrencyFormat amount={item.price.total} /></td>
                                  </tr>
                                </tbody>
                              </Table>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
              <Card>
                <CardFooter>
                  {pagination && (
                    <PaginationComponent
                      pagination={pagination}
                      handleBackPagination={() => handleBackPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                      handleClickPagination={(page) => handleClickPagination(page, filters, dispatch, setFilters, setRefatch)}
                      handleNextPagination={() => handleNextPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                    />
                  )}
                </CardFooter>
              </Card>
            </Col>
          )
        )}

        {isLoading === false && !cards ? (
          <Col lg="12">
            <H3 attrH3={{ className: 'sub-title mb-5 text-center' }}>Sumber Data Tidak Tersedia</H3>
          </Col>
        ) : (
          isLoading === false && cards.length === 0 && (
            <Col lg="12">
              <H3 attrH3={{ className: 'sub-title mb-5 text-center' }}>Data Tidak Ditemukan</H3>
            </Col>
          )
        )}

      </Container>
    </Fragment>
  );
};
export default TransactionCards;
import { axiosInstance } from './axiosInstance';

export const UserServices = {
    getAll: async (filter) => {
        return await axiosInstance.get('/api/feature/user', { params: filter });
    },
    create: async (data) => {
        return await axiosInstance.post('/api/feature/user', data);
    },
    update: async (id, data) => {
        return await axiosInstance.post(`/api/feature/user/update/${id}`, data);
    },
    delete: async (id) => {
        return await axiosInstance.delete(`/api/feature/user/delete/${id}`);
    },
    export: async (filter) => {
        return await axiosInstance.get('/api/feature/export/user', { params: filter });
    },
    getHistoryReferral: async (filter) => {
        return await axiosInstance.get('/api/feature/user/history-referral-admin', { params: filter });
    },
    getHistoryReferralCompact: async (filter) => {
        return await axiosInstance.get('/api/feature/user/history-referral-admin-compact', { params: filter });
    },
    getHistoryReferralMutation: async (filter) => {
        return await axiosInstance.get('/api/feature/user/history-referral-admin-mutation', { params: filter });
    },
    postWithdrawReferral: async (id, amount) => {
        return await axiosInstance.post(`/api/feature/user/withdraw-referral/${id}`, { amount: amount });
    },
}

import { fetchUsers, submitUsers, deleteUsers, fetchUsersFiltered } from '../../../Features/Learning/Users/Utils/UserUtils';
import Swal from 'sweetalert2';
import { AuthService } from '../../../Services/axios/auth.service';

/* **************************************************** */
/* CRUD Users                                            */
/* **************************************************** */

export const fetchUser = (dispatch, filters, refetch) => {
    dispatch(fetchUsers(filters));
}

export const fetchUserFiltered = (dispatch, filters, refetch) => {
    dispatch(fetchUsersFiltered(filters));
}

export const handleDeleteUser = async (dispatch, id, setRefetch) => {
    try {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(deleteUsers(id));
                setRefetch(prevRefetch => !prevRefetch);
                Swal.fire(
                    'Terhapus!',
                    'Data telah dihapus.',
                    'success'
                )
            }
        })
    } catch (error) {
        console.error("Delete error:", error);
    }
}

export const handleEditUser = async (dispatch, item, setField) => {
    const fieldsToUpdate = ['name', 'username', 'phone', 'gender','email','password','referral_code'];
    fieldsToUpdate.forEach(field => {
        dispatch(setField({ field, value: item[field] }));
    });
    dispatch(setField({ field: 'parent_name', value: item.parent_user.name ? item.parent_user.name : '' }));
    dispatch(setField({ field: 'parent_phone', value: item.parent_user.phone ? item.parent_user.phone : '' }));
    dispatch(setField({ field: 'old_referral_code', value: item.referral_code }));
    dispatch(setField({ field: 'isUpdate', value: true }));
    dispatch(setField({ field: 'id', value: item.id }));
}

export const getDetailUser = async (dispatch, item, setDetail) => {
    dispatch(setDetail(item));
}

export const handleLoginAs = async (id, name) => {
    try {
        const result = await Swal.fire({
            title: 'Login As',
            text: `Apakah anda yakin ingin login sebagai ${name}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        });

        if (result.isConfirmed) {
            const response = await AuthService.loginAs(id);
            if (response.status === 200) {
                window.open(response.data.redirect, '_blank');
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        }
    } catch (error) {
        console.error('Error during login as:', error);
    }
};

export const handleCopy = (token) => {
		const link = document.createElement("textarea");
    link.value = `${process.env.REACT_APP_USER_URL}/report/member/${token}`;
		document.body.appendChild(link);
		link.select();

		try {
			document.execCommand("copy");
			Swal.fire({
				title: "Copied!",
				text: "Link laporan berhasil dicopy",
				icon: "success",
        confirmButtonColor: '#3085d6',
			});
		} catch (err) {
			Swal.fire({
				title: "Error!",
				text: "Gagal menyalin link laporan",
				icon: "error",
        confirmButtonColor: '#3085d6',
			});
		}
		document.body.removeChild(link);
};

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page > 1) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page - 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  console.log(pagination.current_page, pagination.last_page);
  if (pagination.current_page < pagination.last_page) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page + 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
  // console.log(page);
  dispatch(setFilters({
    ...filters,
    page: page
  }));
  setRefetch(prevRefetch => !prevRefetch);
}

import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, CardHeader, Table, Row, Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Image, H6, H5, H4, LI, UL, P, Btn, Spinner, Badges } from '../../../../AbstractElements';
import AttachModal from '../../../../Component/Learning/Common/Modal/ModalButton';
import { fetchUsers } from '../../../../Features/Learning/Users/Utils/UserUtils';
import { fetchMemberships } from '../../../../Features/Learning/Memberships/Utils/MembershipUtils';
import { attachDetachUserToClass, fetchClasses } from '../../../../Features/Classes/Class/Utils/ClassUtils';
import { setField as setFieldAttachDetach, resetState as resetAttachDetach } from '../../../../Features/Classes/Class/attachDetachUserToClassSlice';
import { handleGetDetailClass } from '../../../../Component/Classes/Class/ClassUtils';
import { setDetail } from '../../../../Features/Classes/Class/ClassSlice';
import AsyncSelectCommon from '../../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { fetchDetailMemberClass } from '../../../../Features/Classes/Class/Utils/ClassUtils';
import Moment from 'moment';
import 'moment/locale/id';
import Swal from 'sweetalert2';


const DetailUserClassContain = () => {
    const { classId } = useParams();
    const dispatch = useDispatch();
    const detail = useSelector(state => state.Class.detail);
    const filterUser = useSelector(state => state.User.filters);
    const filterClass = useSelector(state => state.Class.filters);
    const [modal, setModal] = useState(false);
    const [selectedValueUser, setSelectedValueUser] = useState(null);
    const data = useSelector(state => state.attachDetachUserToClass);
    const dataMember = useSelector(state => state.detailMemberClass.data.data);
    const isLoading = useSelector(state => state.detailMemberClass.isLoading);
    const [refatch, setRefatch] = useState(false);
    const [membershipTitle, setMembershipTitle] = useState('');

    useEffect(() => {
        dispatch(fetchDetailMemberClass(classId));
    }, [refatch]);

    const handleToggle = (data) => {
        console.log('data', data);
        const selectedUserClass = data.members && data.members.map(item => ({
            value: item.id,
            label: item.name,
        }));
        setModal(!modal);
        setSelectedValueUser(selectedUserClass);
        setMembershipTitle(data.title);
        dispatch(setFieldAttachDetach({ field: 'userId', value: selectedUserClass.map(item => item.value) }));
        dispatch(setFieldAttachDetach({ field: 'class_id', value: parseInt(classId) }));
        dispatch(setFieldAttachDetach({ field: 'membershipId', value: data.id })); //id memmbership toefl
    };
    
    // set logic option user for get next page if data is empty & have next page
    const fetchData = async (dispatch, inputValue, callback) => {        
        const fetchDataFromPage = async (page) => {
            try {
                const response = await dispatch(fetchUsers({
                    ...filterUser,
                    'filter[name]': inputValue,
                    'sort': 'name',
                    'page': page,
                    'include': '',
                    'filter[membership.id]': data.membershipId,
                }));
                return response;
            } catch (error) {
                console.error('Error fetching options:', error);
                return [];
            }
        }

        const fetchDataRecursively = async (page) => {
            const responsePayload = await fetchDataFromPage(page);
            const filteredPayload = responsePayload.payload.data.filter(item => !data.userId.includes(item.id));
            // console.log('filteredPayloadLength', filteredPayload.length);
            if (filteredPayload.length > 0) {
                // console.log('set options')
                const options = filteredPayload.map(item => ({
                    value: item.id,
                    label: item.name,
                }));
                callback(options);
            } else {
                // console.log('fetch next page')
                const { pagination } = responsePayload.payload;
                const totalPages = pagination.last_page;
                if (page < totalPages) {
                    await fetchDataRecursively(page + 1);
                } else {
                    callback([]);
                }
            }
        }

        await fetchDataRecursively(1);
    };

    const promiseOptionUsers = (inputValue, callback) => {
        fetchData(dispatch, inputValue, callback);
    };

    const handleSelectChangeUsers = (selectedOption) => {
        setSelectedValueUser(selectedOption);
        if (selectedOption) {
            dispatch(setFieldAttachDetach({ field: 'userId', value: selectedOption.map(item => item.value) }));
        } else {
            dispatch(setFieldAttachDetach({ field: 'userId', value: null }));
        }
    };

    const handleSubmit = async () => {
        console.log('data', data);
        try {
                // Menampilkan pesan konfirmasi
            const confirmResult = await Swal.fire({
                title: 'Apakah Anda yakin?',
                text: 'Anda akan mengubah data peserta kelas',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal'
            });

            // Jika pengguna menekan tombol "Ya"
            if (confirmResult.isConfirmed) {
                const response = await dispatch(attachDetachUserToClass(data));
                if (response.payload === true) {
                    setModal(!modal);
                    Swal.fire({
                        title: 'Berhasil!',
                        text: 'User berhasil ditambahkan ke kelas',
                        icon: 'success',
                        confirmButtonText: 'Oke'
                    });
                    dispatch(resetAttachDetach());
                    setRefatch(!refatch);
                    const newFilters = {
                        ...filterClass,
                        "filter[id]": classId,
                        "page": 1,
                    };
                    dispatch(fetchClasses(newFilters))
                        .then((response) => {
                            // Kode yang akan dijalankan setelah fetch sukses
                            handleGetDetailClass(dispatch, response.payload.data[0], setDetail)
                            // Kode lain yang perlu dijalankan setelah fetch sukses
                        })
                        .catch((error) => {
                            // Tangani error jika fetch gagal
                            console.error('Error during fetchClasses after success attachUser:', error);
                        });
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: 'User gagal ditambahkan ke kelas',
                        icon: 'error',
                        confirmButtonText: 'Oke'
                    });
                }
            }
            
        } catch (error) {
            console.error('Error:', error);
            // Modal tidak akan ditutup jika terjadi kesalahan
        }
    };

    const [open, setOpen] = useState('');

    const toggle = (id) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };


    return (
        <Fragment>
            <Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
                <Card className="social-widget-card" style={{ background: 'none' }}>
                    <CardHeader>
                        <h5>Peserta Kelas {detail.name}</h5>
                    </CardHeader>
                    <CardBody>
                        {isLoading ? (
                            <Col lg="12">
                                <div className="loader-box">
                                    <Spinner attrSpinner={{ className: 'loader-30' }} />
                                </div>
                                <H6 attrH6={{ className: 'mb-0 text-center' }}>{'Data Loading...'}</H6>
                            </Col>
                        ) : (
                            dataMember && dataMember.length > 0 ? (
                                <Row className="justify-content-center">
                                {dataMember.map((item, index) => {
                                    return (
                                        <Col key={index} lg="6" className="mb-3">
                                            <Accordion key={index} open={open} toggle={() => toggle(`${index}`)}>
                                                <AccordionItem>
                                                    <CardHeader
                                                        style={{
                                                            backgroundColor: "#2f707f",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                        className="py-3">
                                                        <h6 className="text-white">{item.title} ({item.members.length})</h6>
                                                        <AccordionHeader
                                                            targetId={`${index}`}
                                                            style={{
                                                                background: "transparent",
                                                            }}></AccordionHeader>
                                                    </CardHeader>
                                                    <AccordionBody accordionId={`${index}`}>
                                                        <AttachModal colorBtn={'light'} buttonLabel={'Edit Peserta'} toggle={() => handleToggle(dataMember[index])} modalLabel={'Edit Peserta Kelas'} modal={modal} handleSubmit={() => {
                                                            handleSubmit();
                                                        }} >
                                                            <AsyncSelectCommon label={`Users ${membershipTitle}`} placeholder={'Silahkan pilih User...'}
                                                                isMulti={true}
                                                                promiseOptions={promiseOptionUsers}
                                                                handleSelectChange={(selectedOption) => handleSelectChangeUsers(selectedOption)}
                                                                selectedValue={selectedValueUser}
                                                            />
                                                            {data.isLoading && (
                                                                <Col lg="12">
                                                                    <div className="loader-box">
                                                                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                                                                    </div>
                                                                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses edit peserta...'}</H6>
                                                                </Col>
                                                            )}
                                                        </AttachModal>
                                                        <Table className="table-responsive table-bordered mt-3">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th scope="col" className='text-center' style={{ width: '15px' }}>#</th>
                                                                    <th scope="col">Nama </th>
                                                                    <th scope="col" colSpan="2" className='text-center' style={{ width: '15%' }}>Aksi</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {item.members && item.members.map((student, i) => (
                                                                    <tr key={`student-${i}`}>
                                                                        <td>{i + 1}</td>
                                                                        <td>{student.name}</td>
                                                                        <td className="text-center">
                                                                            <Link to={`${process.env.PUBLIC_URL}/users/user-detail/${student.id}`} title="Detail User" className="p-1 float-right">
                                                                                <i className="fa fa-info-circle mx-auto fa-lg"></i>
                                                                            </Link>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <Link to={`${process.env.PUBLIC_URL}/classes/user-schedule-progress/${classId}/${student.id}/${student.name}`} title="Detail Progress" className="p-1 float-right">
                                                                                <i className="fa fa-dashboard mx-auto fa-lg text-dark"></i>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                            {/* <Card className="social-widget-card">
                                                <CardHeader style={{ backgroundColor: '#2f707f' }} className="py-3">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h6 className="text-white">{item.title}</h6>
                                                        <AttachModal colorBtn={'light'} buttonLabel={'Edit Peserta'} toggle={() => handleToggle(dataMember[index])} modalLabel={'Edit Peserta Kelas'} modal={modal} handleSubmit={() => {
                                                            handleSubmit();
                                                        }} >
                                                            <AsyncSelectCommon label={`Users ${membershipTitle}`} placeholder={'Silahkan pilih User...'}
                                                                isMulti={true}
                                                                promiseOptions={promiseOptionUsers}
                                                                handleSelectChange={(selectedOption) => handleSelectChangeUsers(selectedOption)}
                                                                selectedValue={selectedValueUser}
                                                            />
                                                            {data.isLoading && (
                                                                <Col lg="12">
                                                                    <div className="loader-box">
                                                                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                                                                    </div>
                                                                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses edit peserta...'}</H6>
                                                                </Col>
                                                            )}
                                                        </AttachModal>
                                                    </div>
                                                </CardHeader>
                                                <CardBody className="px-3 py-2">
                                                    <Table className="table-responsive table-bordered">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th scope="col" className='text-center' style={{ width: '15px' }}>#</th>
                                                                <th scope="col">Nama </th>
                                                                <th scope="col" colSpan="2" className='text-center' style={{ width: '15%' }}>Aksi</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item.members && item.members.map((student, i) => (
                                                            <tr key={`student-${i}`}>
                                                                <td>{i + 1}</td>
                                                                <td>{student.name}</td>
                                                                <td className="text-center">
                                                                    <Link to={`${process.env.PUBLIC_URL}/users/user-detail/${student.id}`} title="Detail User" className="p-1 float-right">
                                                                        <i className="fa fa-info-circle mx-auto fa-lg"></i>
                                                                    </Link>
                                                                </td>
                                                                <td className="text-center">
                                                                    <Link to={`${process.env.PUBLIC_URL}/classes/user-schedule-progress/${classId}/${student.id}/${student.name}`} title="Detail Progress" className="p-1 float-right">
                                                                        <i className="fa fa-dashboard mx-auto fa-lg text-dark"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card> */}
                                        </Col>
                                    )
                                } )}
                                </Row>
                            ) : (
                            <H6 attrH6={{ className: 'text-center' }}>Belum ada peserta</H6>
                        )
                        )}
                    </CardBody>
                </Card>
            </Col>
        </Fragment >
    );
};
export default DetailUserClassContain;
import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { Search } from 'react-feather';
import { Filter } from '../../../../../Constant';
import { H5, Btn } from '../../../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setFilters } from '../../../../../Features/Learning/Users/historyUserReferralMutationSlice';
import { fetchHistoryReferralMutations } from '../../ReferralMutation/ReferralMutationUtils';

const FindReferrralMutation = () => {
    const [isFilter, setIsFilter] = useState(true);
    const dispatch = useDispatch()
    const { id } = useParams();
    const filters = useSelector(state => state.historyUserReferralMutation.filters)

    const handleClickSearch = (e) => {
        e.preventDefault()
        const newFilters = {
            ...filters,
            'referee_id': id,
            'page': 1,
        };
        dispatch(setFilters(newFilters));
        fetchHistoryReferralMutations(dispatch, newFilters);
    }


    const handleResetFilter = () => {
        const newFilters = {
            ...filters,
            'filter[membership.title]': '',
            'referee_id': id,
            // 'sort': '',
            'page': 1,
        };

        dispatch(setFilters(newFilters));
        fetchHistoryReferralMutations(dispatch, newFilters);
    }

    const updateFilters = (filters, dispatch, field, value) => {
        dispatch(setFilters({
            ...filters,
            [field]: value,
        }));
    };

    const handleSortBy = (filters, dispatch) => (e) => {
        const filterValue = e.target.value;
        updateFilters(filters, dispatch, 'sort', filterValue);
    };


    return (
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardHeader>
                        <H5 attrH5={{ className: 'mb-0' }} >
                            <Btn attrBtn={{ className: 'btn btn-link ps-0', onClick: () => setIsFilter(!isFilter), color: 'transperant', datastoggle: 'collapse', databstarget: '#collapseicon', ariaexpanded: 'true', ariacontrols: 'collapseicon' }} >{'Temukan Mutation'}</Btn>
                        </H5>
                    </CardHeader>
                    <Collapse isOpen={isFilter}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                            <CardBody className="filter-cards-view animate-chk">
                                <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <Input className="form-control" type="text" placeholder="Cari nama membership.."
                                            value={filters['filter[membership.title]']}
                                            onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                                            onChange={(e) => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[membership.title]': e.target.value
                                                }));
                                            }}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div>
                                {/* <div className="checkbox-animated mt-0">
                                    <div className="learning-header"><span className="f-w-600">Sort by:</span></div>
                                    <Label className="d-block" htmlFor="sort-new">
                                        <Input className="radio_animated" id="sort-new" type="radio" name="sort"
                                            checked={filters.sort === '-id'}
                                            value="-id"
                                            onChange={handleSortBy(filters, dispatch)}
                                        />
                                        {'Terbaru'}
                                    </Label>
                                    <Label className="d-block" htmlFor="sort-old">
                                        <Input className="radio_animated" id="sort-old" type="radio" name="sort"
                                            checked={filters.sort === 'id'}
                                            value="id"
                                            onChange={handleSortBy(filters, dispatch)}
                                        />
                                        {'Terlama'}
                                    </Label>
                                </div> */}
                                <div className="row">
                                    <div className="col-md-6">
                                        <Btn attrBtn={{ color: 'primary text-center p-1', onClick: handleClickSearch }}>{Filter}</Btn>
                                    </div>
                                    <div className="col-md-6">
                                        <Btn attrBtn={{ color: 'warning text-center p-1', onClick: handleResetFilter }}>{'Reset'}</Btn>
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default FindReferrralMutation;
import { createSlice } from '@reduxjs/toolkit';
import { fetchTryout } from './Utils/TryoutUtils';

const TryoutSlice = createSlice({
  name: 'Tryout',
  initialState: {
    filters: {
      'filter[category_id]': '',
      'filter[title]': '',
      'filter[id]': '',
      'filter[onGoing]': '',
      'filter[is_quiz]': '',
      'sort': '',
      'include[0]': 'category',
      'include[1]': '',
    //   'page': 1,
    },
    data: [],
    detail: {},
    isLoading: false,
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDetail: (state, action) => {
      state.detail = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTryout.pending, (state) => {
        // Handle loading state
        state.isLoading = true;
      })
      .addCase(fetchTryout.fulfilled, (state, action) => {
        // Handle success state
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchTryout.rejected, (state, action) => {
        // Handle error state
        state.isLoading = false;
        console.log('error', action);
      });
  }
});

export const { setFilters, setData, setDetail } = TryoutSlice.actions;

export default TryoutSlice.reducer;

import React, { Fragment, useState, useEffect } from "react";
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Form,
	FormGroup,
	Label,
	Input,
	InputGroup,
	InputGroupText,
} from "reactstrap";
import { Post, Discard } from "../../../Constant";
import { Btn, H5, H6, Image, Spinner } from "../../../AbstractElements";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PostCommon from "../../../Component/Learning/Common/Form/Post";
import { submitVoucher } from "../../../Features/Learning/Vouchers/Utils/VoucherUtils";
import {
	setField,
	resetState,
} from "../../../Features/Learning/Vouchers/addVoucherSlice";
import AsyncSelectCommon from "../../../Component/Learning/Common/SelectOption/AsyncSelect";
import { fetchMembership } from "../../../Component/Learning/Membership/MembershipsUtils";
import { fetchMemberships } from "../../../Features/Learning/Memberships/Utils/MembershipUtils";
import Swal from "sweetalert2";
import Moment from "moment";
import "moment/locale/id";

const UserPostContain = (props) => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const data = useSelector((state) => state.addVoucher);
	const startUpload = useSelector((state) => state.addVoucher.isLoading);
	const isUpdate = useSelector((state) => state.addVoucher.isUpdate);
	const dataVoucher = useSelector((state) => state.Voucher.data.data);
	const [selectedValue, setSelectedValue] = useState(null);
	const [validUntil, setValidUntil] = useState(null);
	const dataOptions = useSelector((state) => state.Membership.data.data);
	const filtersMembership = useSelector((state) => state.Membership.filters);

	const handleSuccess = () => {
		dispatch(resetState());
		history(`${process.env.PUBLIC_URL}/voucher`);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (
			data.code === "" ||
			data.discount === "" ||
			data.valid_until === "" ||
			data.minimum_nominal === "" ||
			data.membership.length === 0
		) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Data tidak boleh kosong!",
			});
		} else {
			dispatch(submitVoucher({ ...data, successCallback: handleSuccess }));
		}
	};

	const handleDiscard = (e) => {
		e.preventDefault();
		dispatch(resetState());
		history(`${process.env.PUBLIC_URL}/voucher`);
	};

	const handleChanges = (e) => {
		const { name, value } = e.target;
		dispatch(setField({ field: name, value: value }));
	};

	const promiseOptions = (inputValue, callback) => {
		if (inputValue !== "") {
			dispatch(
				fetchMemberships({
					...filtersMembership,
					"filter[title]": inputValue,
					sort: "title",
					page: 1,
				})
			)
				.then((response) => {
					const options = response.payload.data.map((item) => ({
						value: item.id,
						label: item.title,
					}));
					callback(options);
				})
				.catch((error) => {
					console.error("Error fetching options:", error);
					callback([]);
				});
		}
	};

	const handleonGoing = (date) => {
		setValidUntil(date);
		if (date === null) {
			dispatch(setField({ field: "valid_until", value: "" }));
		} else {
			dispatch(
				setField({
					field: "valid_until",
					value: Moment(date).format("yyyy-MM-DD"),
				})
			);
		}
	};

	const handleSelectChange = (selectedOption) => {
		setSelectedValue(selectedOption);
		dispatch(setField({ field: "membership", value: selectedOption }));
	};

	useEffect(() => {
		const selectedValid = data.valid_until
			? Moment(data.valid_until, "yyyy-MM-DD").toDate()
			: null;
		// console.log(selectedValid, 'selectedValid')
		setValidUntil(selectedValid);
		const selectedMembership = data.membership ? data.membership : null;
		setSelectedValue(selectedMembership);
	}, [data.valid_until, data.membership]);

	return (
		<PostCommon
			title={props.label}
			handleSubmit={handleSubmit}
			handleDiscard={handleDiscard}>
			{startUpload ? (
				<Col lg="12">
					<div className="loader-box">
						<Spinner attrSpinner={{ className: "loader-30" }} />
					</div>
					<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
						{"Sedang proses unggah..."}
					</H6>
				</Col>
			) : (
				<div>
					<Form className="row needs-validation">
						<Col sm="12">
							<FormGroup className="mb-3">
								<Label for="code" style={{ fontWeight: "bold" }}>
									Kode Voucher :
								</Label>
								<Input
									className="form-control"
									id="code"
									type="text"
									name="code"
									placeholder="Masukkan kode voucher..."
									value={data.code}
									onChange={handleChanges}
								/>
							</FormGroup>
							<FormGroup className="mb-3">
								<Label style={{ fontWeight: "bold" }}>Jenis Potongan:</Label>
								<div className="m-checkbox-inline">
									<Label for="is_percentage_false">
										<Input
											className="radio_animated"
											id="is_percentage_false"
											type="radio"
											name="is_percentage"
											value={0}
											checked={Number(data.is_percentage) == 0}
											onChange={handleChanges}
										/>
										Nominal
									</Label>
									<Label for="is_percentage_true">
										<Input
											className="radio_animated"
											id="is_percentage_true"
											type="radio"
											name="is_percentage"
											value={1}
											checked={Number(data.is_percentage) == 1}
											onChange={handleChanges}
										/>
										Persentase
									</Label>
								</div>
							</FormGroup>
							<FormGroup className="mb-3">
								<Label for="discount" style={{ fontWeight: "bold" }}>
									Diskon :
								</Label>
								<InputGroup>
									<InputGroupText>
										{data.is_percentage == 1 ? "%" : "Rp"}
									</InputGroupText>
									<Input
										className="form-control"
										id="discount"
										type="number"
										name="discount"
										placeholder="Masukkan diskon..."
										value={data.discount}
										onChange={handleChanges}
									/>
								</InputGroup>
							</FormGroup>
							<FormGroup className="mb-3">
								<Label for="valid_until" style={{ fontWeight: "bold" }}>
									Berlaku Sampai :
								</Label>
								<Col>
									<DatePicker
										className="form-control digits"
										showPopperArrow={false}
										selected={validUntil}
										dateFormat="dd-MM-yyyy"
										closeOnScroll={true}
										onChange={handleonGoing}
										isClearable={true}
										placeholderText="Pilih tanggal!"
									/>
								</Col>
							</FormGroup>
							<FormGroup className="mb-3">
								<Label for="minimum_nominal" style={{ fontWeight: "bold" }}>
									Nominal Minimum :
								</Label>
								<Input
									className="form-control"
									id="minimum_nominal"
									type="number"
									name="minimum_nominal"
									placeholder="Masukkan minimal nominal..."
									value={data.minimum_nominal}
									onChange={handleChanges}
								/>
							</FormGroup>
							<AsyncSelectCommon
								label={"Pilih Membership"}
								placeholder={"Silahkan pilih Membership..."}
								isMulti={true}
								promiseOptions={promiseOptions}
								handleSelectChange={handleSelectChange}
								selectedValue={selectedValue}
							/>
						</Col>
					</Form>
				</div>
			)}
		</PostCommon>
	);
};

export default UserPostContain;

import { createSlice } from '@reduxjs/toolkit';
import { submitCategories } from './Utils/CategoriesUtils';

const initialState = {
    title: '',
    icon: '',
    is_parent: '',
    parent_id: '',
    is_question: '',
    is_main_program: '',
    isUpdate: false,
    id: '',
    isLoading: false,
    value: 0, // passing grade for question type
    max_score: 0, // max score for question type
};

const addCategories = createSlice(
    {
        name: 'addCategories',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(submitCategories.pending, (state) => {
                    // Handle loading state
                    state.isLoading = true;
                })
                .addCase(submitCategories.fulfilled, (state, action) => {
                    // Handle success state
                    console.log('Sukses:', action.payload);
                    // return initialState;
                    state.isLoading = false;
                })
                .addCase(submitCategories.rejected, (state, action) => {
                    // Handle error state
                    console.log('rejected', action.error);
                    state.isLoading = false;
                });
        }
    }
);


export const { setField, resetState } = addCategories.actions;

export default addCategories.reducer;
import { use } from "echarts";
import { axiosInstance } from "./axiosInstance";

export const ScheduleServices = {
    getAll: async (filter) => {
        return await axiosInstance.get("/api/feature/class/schedule/admin", { params: filter });
    },
    create: async (data) => {
        return await axiosInstance.post("/api/feature/class/schedule", data);
    },
    update: async (id, data) => {
        return await axiosInstance.post(`/api/feature/class/schedule/update/${id}`, data);
    },
    delete: async (id) => {
        return await axiosInstance.delete(`/api/feature/class/schedule/delete/${id}`);
    },
    attachDetachUserToSchedule: async (id, data) => {
        return await axiosInstance.post(`/api/feature/class/schedule/attach-detach-to-attendances/${id}`, data);
    },
    QRCodePresence: async () => {
        return await axiosInstance.post(`/api/feature/class/schedule/scanner/generate`);
    },
    getDetailMemberClassSchedule: async (classId, userId) => {
        return await axiosInstance.get(`/api/feature/class/schedule/admin/detail/${classId}/${userId}`);
    },
    editAttendance: async (id, data) => {
        return await axiosInstance.post(`/api/feature/class/schedule/attendance/update/${id}`, data);
    }   
};
import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, FormGroup, Label } from 'reactstrap';
import { Post, Discard } from '../../../Constant';
import { Btn, H5, H6, Spinner } from '../../../AbstractElements';
import ScheduleForm from '../../../Component/Classes/Schedule/ScheduleForm';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { submitSchedules } from '../../../Features/Classes/Schedule/Utils/ScheduleUtils';
import { resetState, setField } from '../../../Features/Classes/Schedule/addScheduleSlice';
import Swal from 'sweetalert2';


const SchedulePostContain = () => {
  const { classId, scheduleId } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const data = useSelector(state => state.addSchedule)
  const startUpload = useSelector(state => state.addSchedule.isLoading);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSuccess = () => {
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/classes/class-schedule-detail/${classId}`);
  };

  const showError = (errorMessage) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: errorMessage,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data,'submit data');
    let errorMessage = '';
    // validation
    if (data.name === '' || data.tentor_id === '' || data.place === '' || data.QR_start_at === '' || data.session_start_at === '' || data.session_end_at === '' || (data.course_id !== '' && data.material_id === '')) {
      // set message validation
      if (data.name === '') {
        errorMessage = 'Nama sesi belum diisi.';
      } else if (data.tentor_id === '') {
        errorMessage = 'Mohon pilih Tentor terlebih dahulu.';
      } else if (data.place === '') {
        errorMessage = 'Tempat belum diisi.';
      } else if (data.QR_start_at === '') {
        errorMessage = 'Waktu Mulai QR belum diisi.';
      } else if (data.session_start_at === '') {
        errorMessage = 'Waktu Mulai belum diisi.';
      } else if (data.session_end_at === '') {
        errorMessage = 'Waktu Berakhir belum diisi.';
      } else if (data.course_id !== '' && data.material_id === '') {
        errorMessage = 'Mohon pilih Materi terlebih dahulu atau hapus Course.';
      }
      showError(errorMessage);
    } else if (data.session_start_at <= data.QR_start_at) {
      errorMessage = 'Waktu Mulai tidak boleh lebih kecil atau sama dengan Waktu Scan QR.';
      showError(errorMessage);
    } else if (data.session_end_at <= data.session_start_at) {
      errorMessage = 'Waktu Berakhir tidak boleh lebih kecil atau sama dengan Waktu Mulai.';
      showError(errorMessage);
    } else {
      dispatch(submitSchedules({ ...data, successCallback: handleSuccess }))
    }
  };


  const handleDiscard = (e) => {
    e.preventDefault();
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/classes/class-schedule-detail/${classId}`);
  };

  return (
    <Fragment>
      {startUpload && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 9999 }}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Card>
              <CardBody>
                <div className="loader-box">
                  <Spinner attrSpinner={{ className: 'loader-30' }} />
                </div>
                <H5 attrH5={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses unggah...'}</H5>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className='pb-0'><H5>{scheduleId ? '' : 'Jadwal Sesi Baru'}</H5></CardHeader>
              <CardBody className="add-post">
                <ScheduleForm />
              </CardBody>
              <CardFooter>
                  <div className="btn-showcase text-end">
                    <Btn attrBtn={{ color: 'primary', type: 'submit', onClick: handleSubmit }}>{Post}</Btn>
                    <Btn attrBtn={{ color: 'light', type: 'reset', onClick: handleDiscard }}>{Discard}</Btn>
                  </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SchedulePostContain;
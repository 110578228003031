import { fetchCategories, deleteCategories } from '../../../Features/Learning/Categories/Utils/CategoriesUtils';
import Swal from 'sweetalert2';


/* **************************************************** */
/* CRUD Categories                                       */
/* **************************************************** */

export const fetchCategory = (dispatch, filters, refetch) => {
  dispatch(fetchCategories(filters));
};

export const handleDeleteCategories = async (dispatch, id, setRefetch) => {
  try {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteCategories(id));
        setRefetch(prevRefetch => !prevRefetch);
        Swal.fire(
          'Terhapus!',
          'Data telah dihapus.',
          'success'
        )
      }
    })
  } catch (error) {
    console.error("Delete error:", error);
  }
};

export const handleEditCategories = async (dispatch, item, setField) => {
  const fieldsToUpdate = ['title', 'parent_id'];
  fieldsToUpdate.forEach(field => {
    dispatch(setField({ field, value: item[field] }));
  });
  const fieldToString = ['is_parent', 'is_question', 'is_main_program'];
  fieldToString.forEach(field => {
    dispatch(setField({ field, value: item[field].toString() }));
  });
  dispatch(setField({ field: 'iconLink', value: item.icon }));
  dispatch(setField({ field: 'isUpdate', value: true }));
  dispatch(setField({ field: 'id', value: item.id }));
  dispatch(setField({ field: 'value', value: item.passing_grade ? item.passing_grade?.value : 0 }));
  dispatch(setField({ field: 'max_score', value: item.passing_grade ? item.passing_grade?.max_score : 0 }));
};

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page > 1) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page - 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page < pagination.last_page) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page + 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
  console.log(page);
  dispatch(setFilters({
    ...filters,
    page: page
  }));
  setRefetch(prevRefetch => !prevRefetch);
}
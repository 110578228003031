import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Form, FormGroup, Label, Input } from 'reactstrap';
import { Btn, H5, H4, Image, Spinner } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PostCommon from '../../../Component/Learning/Common/Form/Post';
import DropzoneCommon from '../../../Component/Learning/Common/Upload/Dropzone';
import { importQuestion } from '../../../Features/Learning/BankQuestions/Utils/QuestionsUtils';
import { setFieldImport, resetStateImport } from '../../../Features/Learning/BankQuestions/importQuestionSlice';
import Swal from 'sweetalert2';

const QuestionImportContain = (props) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const data = useSelector(state => state.importQuestion)
    const startUpload = useSelector(state => state.importQuestion.isLoading);

    const handleSuccess = () => {
        history(`${process.env.PUBLIC_URL}/learning/soal-list`);
        dispatch(resetStateImport());
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(data);
        let errorMessage = '';
        if (data.countChoices === '') {
            errorMessage = 'Jumlah pilihan jawaban belum diisi';
        } else if (data.file === '') {
            errorMessage = 'File soal belum dipilih';
        }
        if (data.countChoices === '' || data.file === '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errorMessage,
            })
            // console.log(data);
        } else {
            dispatch(importQuestion({ ...data, successCallback: handleSuccess }))
            // console.log(data);
        }
        
    };
    
    const handleChangeCountChoice = (e) => {
        dispatch(setFieldImport({ field: 'countChoices', value: e.target.value }));
    };

    const handleDiscard = (e) => {
        e.preventDefault();
        dispatch(resetStateImport());
        history(`${process.env.PUBLIC_URL}/learning/soal-list`);
    };

    const handleChangeUpload = ({ meta, file }, status) => {
        if (status === 'removed') {
            dispatch(setFieldImport({ field: 'file', value: '' }));
        } else if (status === 'done') {
            dispatch(setFieldImport({ field: 'file', value: file }));
        }
    };

    return (
        <div>
        {startUpload && (
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 9999 }}>
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Card>
                        <CardBody>
                            <div className="loader-box">
                                <Spinner attrSpinner={{ className: 'loader-30' }} />
                            </div>
                            <H5 attrH5={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses unggah...'}</H5>
                        </CardBody>
                    </Card>
                </div>
            </div>
        )}
        <PostCommon title={props.label} handleSubmit={handleSubmit} handleDiscard={handleDiscard} >
                <div>
                    <Col sm="12">
                        <FormGroup className='mb-3'>
                            <Label for="validationTitle" style={{ fontWeight: 'bold' }}>Jumlah Pilihan Jawaban :</Label>
                            <Input className="form-control" id="validationTitle" type="number" name="countChoices" placeholder="Tuliskan jumlah pilihan jawaban sesuai dengan file yang diupload"
                                value={data.countChoices || ''}
                                onChange={(e) => handleChangeCountChoice(e) }  />
                        </FormGroup>
                        <DropzoneCommon label={'File Soal '} inputContent={'Silahkan upload file xlsx/xls/csv'} handleChangeStatus={handleChangeUpload} accept=".xlsx, .xls, .csv" />
                    </Col>
                </div>
        </PostCommon>
        </div>
    );
};

export default QuestionImportContain;
import { createSlice } from '@reduxjs/toolkit';
import { fetchTestimony } from './Utils/TestimonyUtils';

const TestimonySlice = createSlice({
    name : 'Testimony',
    initialState : {
        filters: {
            "filter[id]": "",
            "filter[user_id]": "",
            "filter[membership_id]": "",
            "filter[review]": "",
            "filter[star_rating]": "",
            "filter[show_in_home]": "",
            "filter[additional_info]": "",
            "include": "mainProgram",
            "sort": "",
            "page": "",
        },
        data: [],
        detail: {},
        isLoading: false,
    },
    reducers : {
        setFilters : (state, action) => {
            state.filters = action.payload;
        },
        setDetail : (state, action) => {
            state.detail = action.payload;
        }
    },
    extraReducers : (builder) => {
        builder
        .addCase(fetchTestimony.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchTestimony.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        })
        .addCase(fetchTestimony.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export const { setFilters, setDetail } = TestimonySlice.actions;

export default TestimonySlice.reducer;
import { fetchTransactions, setOrderStatus } from "../../../Features/Learning/Transactions/Utils/TransactionUtils";
import Swal from "sweetalert2";

/* **************************************************** */
/* CRUD Transactions                                    */
/* **************************************************** */

export const fetchTransaction = (dispatch, filters, refetch) => {
    dispatch(fetchTransactions(filters));
}

export const handleSetOrderStatus = async (dispatch, id, status, setRefetch) => {
    try {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Pastikan transaksi pembayaran telah diselesaikan!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ya, sudah!',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(setOrderStatus({ id, status }));
                setRefetch(prevRefetch => !prevRefetch);
                Swal.fire(
                    'Berhasil!',
                    'Status pembayaran berhasil diubah.',
                    'success'
                )
            }
        })
    } catch (error) {
        console.error("Delete error:", error);
    }
}

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
    if (pagination.current_page > 1) {
        dispatch(setFilters({
            ...filters,
            page: pagination.current_page - 1,
        }));
        setRefetch(prevRefetch => !prevRefetch);
    }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
    if (pagination.current_page < pagination.total_pages) {
        dispatch(setFilters({
            ...filters,
            page: pagination.current_page + 1,
        }));
        setRefetch(prevRefetch => !prevRefetch);
    }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
    dispatch(setFilters({
        ...filters,
        page: page,
    }));
    setRefetch(prevRefetch => !prevRefetch);
}

import { fetchSubMaterial, deleteSubMaterial } from '../../../Features/Learning/SubMateri/Utils/SubMateriUtils';
import Swal from 'sweetalert2';


/* **************************************************** */
/* CRUD SubMateri                                       */
/* **************************************************** */

export const fetchSubMaterials = (dispatch, filters, refatch) => {
  dispatch(fetchSubMaterial(filters));
};

export const handleDeleteSubMaterial = async (dispatch, id, setRefetch) => {
  try {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteSubMaterial(id));
        setRefetch(prevRefetch => !prevRefetch);
        Swal.fire(
          'Terhapus!',
          'Data telah dihapus.',
          'success'
        )
      }
    })
  } catch (error) {
    console.error("Delete error:", error);
  }
};

export const handleEditSubMaterial = async (dispatch, item, setField) => {
  const fieldsToUpdate = ['name', 'description', 'type', 'link'];
  fieldsToUpdate.forEach(field => {
    dispatch(setField({ field, value: item[field] }));
  });
  dispatch(setField({ field: 'linkFile', value: item.link }));
  dispatch(setField({ field: 'isUpdate', value: true }));
  dispatch(setField({ field: 'id', value: item.id }));
};

export const handleGetDetailSubMaterial = async (dispatch, item, setDetail) => {
  dispatch(setDetail(item));
};

export const handleDetach = async (detail, setDetail, item, setField, filter, data, dispatch, attachDetachSubMaterial, resetState) => {
  try {
    const payload = {
      'idTryout': item.id,
      'type': item.type,
      'method': 'detach',
      'idSubmateri': detail.id
    }
    // dispatch(setField({ field: 'idTryout', value: item.id }));
    // dispatch(setField({ field: 'type', value: item.type }));
    // dispatch(setField({ field: 'method', value: 'detach' }));
    // dispatch(setField({ field: 'idSubmateri', value: detail.id }));
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await dispatch(attachDetachSubMaterial(payload));
          if (response.payload === true) {
            dispatch(resetState());
            const getSubMateri = await dispatch(fetchSubMaterial({
              ...filter,
              'filter[id]': detail.id,
            }));
            dispatch(setDetail(getSubMateri.payload.data[0]));
            Swal.fire(
            'Terhapus!',
            'Data telah dihapus.',
            'success'
            )
          }
        } catch (error) {
          // Tanggapan error
          Swal.fire(
            'Gagal!',
            'Data gagal dihapus.',
            'error'
          )
          console.error('Error:', error);
          // Modal tidak akan ditutup jika terjadi kesalahan
        }
      }
    })
  } catch (error) {
    console.error("Delete error:", error);
  }

};

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page > 1) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page - 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page < pagination.last_page) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page + 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
  console.log(page);
  dispatch(setFilters({
    ...filters,
    page: page
  }));
  setRefetch(prevRefetch => !prevRefetch);
}
import React, { Fragment, useState, useEffect } from "react";
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Form,
	FormGroup,
	Label,
	Input,
} from "reactstrap";
import { Post, Discard } from "../../../Constant";
import { Btn, H5, H6, Image, Spinner } from "../../../AbstractElements";
import DropzoneCommon from "../../../Component/Learning/Common/Upload/Dropzone";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PostCommon from "../../../Component/Learning/Common/Form/Post";
import { submitTentor } from "../../../Features/Classes/Tentor/Utils/TentorUtils";
import {
	setField,
	resetState,
} from "../../../Features/Classes/Tentor/addTentorSlice";
import Swal from "sweetalert2";

const TentorPostContain = (props) => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const data = useSelector((state) => state.addTentor);
	const startUpload = useSelector((state) => state.addTentor.isLoading);
	const isUpdate = useSelector((state) => state.addTentor.isUpdate);

	const handleSuccess = () => {
		dispatch(resetState());
		history(`${process.env.PUBLIC_URL}/classes/tentor-list`);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(data, "data");
		let errorMessage = "";
		if (data.name === "") {
			errorMessage = "Nama belum diisi.";
		} else if (data.phone === "") {
			errorMessage = "Nomer Telpon belum diisi.";
		} else if (data.mastered_fields === "") {
			errorMessage = "Bidang Keahlian belum diisi.";
		}

		if (data.name === "" || data.mastered_fields === "" || data.phone === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: errorMessage,
			});
		} else {
			dispatch(submitTentor({ ...data, successCallback: handleSuccess }));
		}
	};
	const handleDiscard = (e) => {
		e.preventDefault();
		dispatch(resetState());
		history(`${process.env.PUBLIC_URL}/classes/tentor-list`);
	};
	const handleChanges = (e) => {
		const { name, value } = e.target;
		dispatch(setField({ field: name, value: value }));
	};

	// const handleKeyDown = (e) => {
	//     // Hanya izinkan input angka (0-9) dan backspace
	//     const isValidInput = /^[0-9\b]+$/.test(e.key);

	//     if (!isValidInput && e.key !== 'Backspace') {
	//         e.preventDefault();
	//     }
	// };

	const handleUploadPhoto = ({ meta, file }, status) => {
		if (status === "done") {
			dispatch(setField({ field: "photo", value: file }));
		} else if (status === "removed") {
			dispatch(setField({ field: "photo", value: null }));
		}
	};

	return (
		<PostCommon
			title={props.label}
			handleSubmit={handleSubmit}
			handleDiscard={handleDiscard}>
			{startUpload ? (
				<Col lg="12">
					<div className="loader-box">
						<Spinner attrSpinner={{ className: "loader-30" }} />
					</div>
					<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
						{"Sedang proses unggah..."}
					</H6>
				</Col>
			) : (
				<div>
					<Form className="row needs-validation">
						<Col sm="12">
							<FormGroup className="mb-3">
								<Label for="name" style={{ fontWeight: "bold" }}>
									Nama :
								</Label>
								<Input
									className="form-control"
									id="name"
									type="text"
									name="name"
									placeholder="Masukkan nama tentor..."
									value={data.name}
									onChange={handleChanges}
								/>
							</FormGroup>
							<FormGroup className="mb-3">
								<Label for="phone" style={{ fontWeight: "bold" }}>
									Nomer Telpon :
								</Label>
								<Input
									className="form-control"
									id="phone"
									type="text"
									name="phone"
									placeholder="Masukkan nomer telepon..."
									required
									value={data.phone}
									onChange={handleChanges}
								/>
							</FormGroup>
							<FormGroup className="mb-3">
								<Label for="mastered_fields" style={{ fontWeight: "bold" }}>
									Bidang :
								</Label>
								<Input
									className="form-control"
									id="mastered_fields"
									type="text"
									name="mastered_fields"
									placeholder="Masukkan keahlian bidang..."
									required
									value={data.mastered_fields}
									onChange={handleChanges}
								/>
							</FormGroup>
							{data.photo_url && (
								<FormGroup className="mb-3">
									<Label for="mastered_fields" style={{ fontWeight: "bold" }}>
										Foto Saat Ini :
									</Label>
									<img
										src={data.photo_url}
										alt="Foto Tentor"
										style={{ width: "300px", height: "auto" }}
									/>
								</FormGroup>
							)}
							<FormGroup className="mb-3">
								<DropzoneCommon
									label={"Foto Tentor"}
									inputContent={
										data.photo_url
											? "Upload Foto Baru"
											: "Silahkan Upload Foto Tentor"
									}
									handleChangeStatus={handleUploadPhoto}
									accept="image/*"
								/>
							</FormGroup>
						</Col>
					</Form>
				</div>
			)}
		</PostCommon>
	);
};

export default TentorPostContain;

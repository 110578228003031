import React, { Fragment, useState } from 'react';
import { Col, Card, CardHeader, CardBody, Input } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { Search } from 'react-feather';
import { Filter } from '../../../../Constant';
import { H5, Btn } from '../../../../AbstractElements';
import CatCheckBox from '../CheckBox/CatCheckBox';
import PriceCheck from '../CheckBox/PriceCheck';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../../Features/Learning/SubMateri/subMaterialSlice';
import { fetchSubMaterial } from '../../../../Features/Learning/SubMateri/Utils/SubMateriUtils';
import { handleFilterName } from '../CheckBox/CatCheckBoxUtils';

const FindFilter = (props) => {
  const [isFilter, setIsFilter] = useState(true);
  // const dispatch = useDispatch()
  // const filters = useSelector(state => state.subMaterial.filters)

  // const handleClickFilter = (e) => {
  //   e.preventDefault()
  //   dispatch(fetchSubMaterial(filters));
  // }

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: 'mb-0' }} >
              <Btn attrBtn={{ className: 'btn btn-link ps-0', onClick: () => setIsFilter(!isFilter), color: 'transperant', datastoggle: 'collapse', databstarget: '#collapseicon', ariaexpanded: 'true', ariacontrols: 'collapseicon' }} >{props.label}</Btn>
            </H5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
              <CardBody className="filter-cards-view animate-chk">
                <div className="job-filter">
                  <div className="faq-form">
                   {props.search}
                    {props.search ? <Search className="search-icon" /> : ''}
                  </div>
                </div>
                {props.children}              
                {/* <Btn attrBtn={{ color: 'primary text-center', onClick: props.handleClickFilter }}>{props.labelButton}</Btn>
                <Btn attrBtn={{ color: 'warning text-center', onClick: props.handleResetFilter }}>{props.labelReset}</Btn> */}
                {/* make Btn in row */}
                <div className="row">
                  <div className="col-md-6">
                    <Btn attrBtn={{ color: 'primary text-center p-1', onClick: props.handleClickFilter }}>{props.labelButton}</Btn>
                  </div>
                  <div className="col-md-6">
                    <Btn attrBtn={{ color: 'warning text-center p-1', onClick: props.handleResetFilter }}>{props.labelReset}</Btn>
                  </div>
                </div>
              </CardBody>
            </div>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default FindFilter;
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import MaterialContain from '../../../Component/Learning/Materi';

const MaterialList = () => {
    return (
        <Fragment>
            <Breadcrumbs grandparent="Learning" parent="Materi" title="Daftar Materi" />
            <MaterialContain />
        </Fragment>
    );
};
export default MaterialList;
import React, { Fragment, useState, useEffect } from 'react';
import { Lock, Mail } from 'react-feather';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, H5, P, H6, UL, LI } from '../../../AbstractElements';
import { CreateAccount, EmailAddress, ForgotPassword, Login, Password, RememberPassword, SignIn } from '../../../Constant';
import { FaceBookSVG, GoogleSVG, InstagramSVG, TwitterSVG } from '../../../Data/svgIcons';
import { AuthService } from '../../../Services/axios/auth.service';
import { toast } from 'react-toastify';

toast.configure();

const LoginForm = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [togglePassword, setTogglePassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await AuthService.login(email, password);
            if (response) {
                localStorage.setItem("TOKEN", response.data.bearer);
                localStorage.setItem("USER", JSON.stringify(response.data.data));
                toast.success('Login berhasil!', {
                    position: toast.POSITION.TOP_RIGHT
                });

                // Memberi jeda 1 detik sebelum redirect
                setTimeout(() => {
                    window.location.href = "/";
                }, 1000);
            }
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setLoading(false);
        }
    }
    
    const handleChanges = (event) => {
        const {name, value} = event.target;
        if (name === "email") {
            setEmail(value);
        } else if(name === "password") {
            setPassword(value);
        }
    }

    return (
        <Fragment>
            <Form className="theme-form login-form" onSubmit={handleSubmit}>
                <div className="login-header text-center">
                    <H4>{Login}</H4>
                    <H6>Welcome back! Log in as Admin Brofesional.</H6>
                </div>
                <FormGroup>
                    <Label>{EmailAddress}</Label>
                    <div className="input-group"><span className="input-group-text"><Mail /></span>
                        <Input className="form-control" type="email" required="" name="email"  placeholder="Test@gmail.com" onChange={handleChanges}/>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label>{Password}</Label>
                    <div className="input-group"><span className="input-group-text"><Lock /></span>
                        <Input className="form-control" type={togglePassword ? 'text' : 'password'} name="password" required="" placeholder="*********" onChange={handleChanges}/>
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? 'hide' : 'show'}></span></div>
                    </div>
                </FormGroup>
                {/*<FormGroup className="login-btn form-group">
                    <div className="checkbox">
                        <Input id="checkbox1" type="checkbox" />
                        <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                    </div>
                    <Link to={`${process.env.PUBLIC_URL}/pages/authentication/forget-pwd`} className="link">
                        {ForgotPassword}?
                    </Link>
                </FormGroup>*/}
                <FormGroup>
                    <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }} >{SignIn}</Btn>
                </FormGroup>
                {/*<P>Don't have account?
                    <Link to={`${process.env.PUBLIC_URL}/pages/authentication/create-pwd`} className="ms-2">
                        {CreateAccount}
                    </Link>
                    </P>
                */}
            </Form>
        </Fragment>
    );
};

export default LoginForm;
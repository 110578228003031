import { createSlice } from '@reduxjs/toolkit';
import { submitSubMaterial } from './Utils/SubMateriUtils';

const initialState = {
    name: '',
    description: '',
    type: '',
    link: '',
    file: '',
    isUpdate: false,
    id: '',
    isLoading: false,
};

const addSubMaterial = createSlice(
    {
        name: 'addSubMaterial',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(submitSubMaterial.pending, (state) => {
                    // Handle loading state
                    state.isLoading = true;
                })
                .addCase(submitSubMaterial.fulfilled, (state, action) => {
                    // Handle success state
                    state.isLoading = false;
                    console.log('Sukses:', action.payload);
                })
                .addCase(submitSubMaterial.rejected, (state, action) => {
                    // Handle error state
                    state.isLoading = false;
                   console.log(action.error.message, "error action")
                });
        }
    }
);


export const { setField, resetState } = addSubMaterial.actions;

export default addSubMaterial.reducer;
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import VoucherContain from '../../../Component/Learning/Voucher';

const VoucherPage = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Voucher List" parent="Voucher" title="Daftar Voucher" />
            <VoucherContain />
        </Fragment>
    );
};
export default VoucherPage;
import React, { Fragment } from 'react';
import { Input, Label } from 'reactstrap';
import { handleSortBy } from './TryoutCheckBoxUtils';
import { useSelector, useDispatch } from 'react-redux';

const TryoutCheck = ({ inputRadioValue, setInputRadioValue }) => {
  const dispatch = useDispatch()
    const filters = useSelector(state => state.Tryout.filters)

  return (
    <Fragment>
      <div className="checkbox-animated mt-0">
        <Label className="col-form-label" style={{ fontWeight: 'bold' }}>{'Sort by '}:</Label>
        <Label className="d-block" htmlFor="edo-ani">
          <Input className="radio_animated" id="edo-ani" type="radio" name="sort"
          checked={filters['sort'] === '-id'}
          value="-id" 
          onChange={handleSortBy(filters, dispatch, setInputRadioValue, inputRadioValue)}
           />
          {'Terbaru'}
        </Label>
        <Label className="d-block" htmlFor="edo-ani1">
          <Input className="radio_animated" id="edo-ani1" type="radio" name="sort"
          checked={filters['sort'] === 'id'}
          value="id"
          onChange={handleSortBy(filters, dispatch, setInputRadioValue, inputRadioValue)}
           />
          {'Terlama'}
        </Label>
        <Label className="d-block" htmlFor="edo-ani2">
          <Input className="radio_animated" id="edo-ani2" type="radio" name="sort"
          checked={filters['sort'] === 'title'}
          value="title" 
          onChange={handleSortBy(filters, dispatch, setInputRadioValue, inputRadioValue)}
           />
          {'A-Z'}
        </Label>
        <Label className="d-block" htmlFor="edo-ani3">
          <Input className="radio_animated" id="edo-ani3" type="radio" name="sort"
          checked={filters['sort'] === '-title'}
          value="-title" 
          onChange={handleSortBy(filters, dispatch, setInputRadioValue, inputRadioValue)}
           />
          {'Z-A'}
        </Label>
      </div>
    </Fragment>
  );
};

export default TryoutCheck;
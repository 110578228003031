import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Post, Discard } from '../../../Constant';
import { Btn, H5, H6, Image, Spinner } from '../../../AbstractElements';
import MateriForm from '../../../Component/Learning/Materi/MateriForm';
import DropzoneCommon from '../../../Component/Learning/Common/Upload/Dropzone';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { submitMaterial } from '../../../Features/Learning/Materi/Utils/MateriUtils';
import { resetState, setField } from '../../../Features/Learning/Materi/addMaterialSlice';
import AsyncSelectCommon from '../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { fetchSubMaterial } from '../../../Features/Learning/SubMateri/Utils/SubMateriUtils';
import Swal from 'sweetalert2';

const MateriPostContain = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const data = useSelector(state => state.addMaterial)
  const filters = useSelector(state => state.subMaterial.filters)
  const [selectedValue, setSelectedValue] = useState(null);
  const dataOptions = useSelector(state => state.subMaterial.data);
  const startUpload = useSelector(state => state.addMaterial.isLoading);
  const isUpdate = useSelector(state => state.addMaterial.isUpdate);

  useEffect(() => {
    setSelectedValue(data.subMaterials);
  }, [isUpdate]);

  const handleSuccess = () => {
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/learning/materi-list`);
  };

  const showError = (message) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMessage = '';
    if (!isUpdate) {
      if (data.name === '' || data.description === '' || data.description === '<p><br></p>' || data.image === '' || data.image === null || data.subMaterials.length === 0) {
        if(data.name === '') {
          errorMessage = 'Nama Materi tidak boleh kosong.';
        } else if (data.description === '' || data.description === '<p><br></p>') {
          errorMessage = 'Deskripsi Materi tidak boleh kosong.';
        } else if (data.image === '' || data.image === null) {
          errorMessage = 'Banner Materi tidak boleh kosong.';
        } else if (data.subMaterials.length === 0) {
          errorMessage = 'Sub Materi belum dipilih.';
        }
        showError(errorMessage);
      } else {
        dispatch(submitMaterial({ ...data, successCallback: handleSuccess }))
      }
    } else {
      if (data.name === '' || data.description === '' || data.description === '<p><br></p>' || data.subMaterials.length === 0) {
        if(data.name === '') {
          errorMessage = 'Nama Materi tidak boleh kosong.';
        } else if (data.description === '' || data.description === '<p><br></p>') {
          errorMessage = 'Deskripsi Materi tidak boleh kosong.';
        } else if (data.subMaterials.length === 0) {
          errorMessage = 'Sub Materi belum dipilih.';
        }
        showError(errorMessage);
      } else {
        dispatch(submitMaterial({ ...data, successCallback: handleSuccess }))
      }
    }
  };


  const handleDiscard = (e) => {
    e.preventDefault();
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/learning/materi-list`);
  };

  const promiseOptions = (inputValue, callback) => {
    dispatch(fetchSubMaterial({
      ...filters,
      'filter[name]': inputValue,
      'sort': 'name',
      'page': 1,
    }))
    .then((response) => {
      const options = response.payload.data.map(item => ({
        value: item.id,
        label: item.name,
      }));
      callback(options);
    })
    .catch((error) => {
      console.error('Error fetching options:', error);
      callback([]);
    });
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    dispatch(setField({ field: 'subMaterials', value: selectedOption }));
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    // console.log(status, meta, file)
    if (status === 'removed') {
      dispatch(setField({ field: 'image', value: '' }));
    } else if (status === 'done') {
      dispatch(setField({ field: 'image', value: file }));
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {startUpload ? (
              <Col lg="12">
                <div className="loader-box">
                  <Spinner attrSpinner={{ className: 'loader-30' }} />
                </div>
                <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses unggah...'}</H6>
              </Col>
            ) : (
            <Card>
              <CardHeader className='pb-0'><H5>{isUpdate ? '' : 'Materi Baru'}</H5></CardHeader>
              <CardBody className="add-post">
                <MateriForm />
                <DropzoneCommon label={'Banner Materi'} inputContent={data.imageLink ? 'Ganti gambar...' : 'Silahkan upload gambar...'} handleChangeStatus={handleChangeStatus} accept="image/*" />
                <AsyncSelectCommon label={'Sub Materi'} placeholder={'Silahkan pilih sub materi...'}
                  isMulti={true}
                  promiseOptions={promiseOptions}
                  handleSelectChange={handleSelectChange}
                  selectedValue={selectedValue}
                />
                <div className="btn-showcase text-end">
                  <Btn attrBtn={{ color: 'primary', type: 'submit', onClick: handleSubmit }}>{Post}</Btn>
                  <Btn attrBtn={{ color: 'light', type: 'reset', onClick: handleDiscard }}>{Discard}</Btn>
                </div>
              </CardBody>
            </Card>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MateriPostContain;
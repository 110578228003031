const GenerateUntagHtml = ({ htmlContent, characterLimit }) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlContent, 'text/html');

    const text = htmlDoc.body.textContent;
    // Mengecek apakah prop characterLimit telah diberikan
    if (characterLimit) {
        // Memotong teks jika panjangnya melebihi batasan karakter
        if (text.length > characterLimit) {
            return (
                <div>
                    {text.slice(0, characterLimit)}...
                </div>
            );
        }
    }
    return (
        <div>
            {text}
        </div>
    );
};

export default GenerateUntagHtml;
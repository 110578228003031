import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import VoucherPostContain from '../../../Component/Learning/Voucher/VoucherPostContain';
import { useParams } from 'react-router-dom';
import { fetchVouchers } from '../../../Features/Learning/Vouchers/Utils/VoucherUtils';
import { setField } from '../../../Features/Learning/Vouchers/addVoucherSlice';
import { handleEditVoucher } from '../../../Component/Learning/Voucher/VoucherUtils';


const VoucherAddPage = () => {
    const stateFormVoucher = useSelector(state => state.addVoucher)
    const dispatch = useDispatch();
    const filters = useSelector(state => state.Voucher.filters)
    const { id } = useParams();

    useEffect(() => {
        if (id && !stateFormVoucher.id) {
            const newFilters = {
                ...filters,
                "filter[id]": id,
            };
            dispatch(fetchVouchers(newFilters))
            .then((response) => {
                // Kode yang akan dijalankan setelah fetch sukses
                handleEditVoucher(dispatch, response.payload.data[0], setField)
                // Kode lain yang perlu dijalankan setelah fetch sukses
            })
            .catch((error) => {
                // Tangani error jika fetch gagal
                console.error('Error during handleEditVoucher:', error);
            });
        }
    }, [id]);

    return (
        <Fragment>
            <Breadcrumbs parent="Voucher" title={id ? "Update Voucher" : "Tambah Voucher"} />
            <VoucherPostContain label={id ? "" : "Voucher Baru"} />
        </Fragment>
    );
};

export default VoucherAddPage;

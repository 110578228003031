import React, { Fragment, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Container, Row, Col, Card, Input, Label } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Btn } from '../../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import CourseBox from './CourseBox';
import CourseFilter from './Filter/CourseFilter';
import { resetState } from '../../../Features/Learning/Courses/addCourseSlice';


const CoursesContain = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const filters = useSelector(state => state.Course.filters)
  const cards = useSelector(state => state.Course.data.data)
  
  const handleAdd = (e) => {
    dispatch(resetState());
  };


  return (
    <Fragment>
      <Container fluid={true}>
        <Col md="12" className="project-list">
          {cards && (
          <Card>
            <Row>
              <Col>
                <Link to={`${process.env.PUBLIC_URL}/membership/course-add`} onClick={handleAdd}>
                  <Btn attrBtn={{ color: 'primary'}}> 
                    <i className="fa fa-plus-circle fa-lg"></i> Tambah Course Baru
                  </Btn>
                </Link>
              </Col>
            </Row>
          </Card>
          )}
        </Col>
        <Row className='learning-block'>
          <Col xl="9" className='xl-80'>
            <Row>
              <CourseBox />
            </Row>
          </Col>
          {cards && (
          <CourseFilter />
          )}
        </Row>
      </Container>
    </Fragment>
  );
};
export default CoursesContain;
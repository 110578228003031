import React, { useContext, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card, CardBody, Table, Input, Label } from 'reactstrap';
import { Btn, LI, P, UL, H4 } from '../../../AbstractElements';
import { XCircle, CheckSquare } from 'react-feather';
import { Brand, Availability, AddToCart, BuyNow } from '../../../Constant';
import ProductContext from '../../../_helper/ecommerce/product';
import WishtListContext from '../../../_helper/ecommerce/wishlist';
import GenerateUniqueContain from '../../../Component/Learning/Common/GenerateUnique';
import RawHTMLComponent from '../../../Component/Learning/Common/RawHTML';
import VideoPlayer from '../../../Component/Learning/DetailSubMateri/VideoPlayer';
import AudioPlayer from '../../../Component/Learning/DetailSubMateri/AudioPlayer';
import Moment from 'moment';
import 'moment/locale/id';

const QuestionsDetails = () => {
  const detail = useSelector(state => state.Question.detail);
  // console.log(detail, "detail")

  //// Memeriksa apakah item.answer[0].answer adalah string JSON yang valid
  const answerArray = detail.answer && detail.answer[0] && detail.answer[0].answer
    ? JSON.parse(detail.answer[0].answer)
    : [];

  // Membuat array baru untuk menyimpan hasil pengecekan
  const results = detail.type && detail.type === "multiple_choice" && detail.choices && detail.choices.map(choice => {
    // Memeriksa apakah id choice ada dalam answerArray
    if (answerArray.includes(String(choice.id))) {
      return <><CheckSquare key={choice.id} color="green" style={{ verticalAlign: 'middle' }} /><span style={{ verticalAlign: 'middle' }}>  Benar</span></>
    }
    return <><XCircle key={choice.id} color="red" style={{ verticalAlign: 'middle' }} /><span style={{ verticalAlign: 'middle' }}>  Salah</span></>
  });
  // console.log(results, "results")

  return (
    <Fragment>
      <Col xl="5" className="box-col-12 proorder-xl-3 xl-100">
        <Card>
          <CardBody>
            <div className="pro-group pt-0 border-0">
              <Row>
                <Col>
                  <div className="product-price">{'Soal ID : '}{GenerateUniqueContain(detail.type === 'multiple_choice' ? 'MC' : detail.type === 'fill_in_the_blanks' ? 'FB' : detail.type === 'sentence_arrangement' ? 'SA' : 'undefined', detail.id, 5)}</div>
                </Col>
                <Col>
                  <div className="product-price">{'Tipe Soal : '}{detail.type === 'multiple_choice' ? 'Pilihan Ganda' : detail.type === 'fill_in_the_blanks' ? 'Isian Singkat' : detail.type === 'sentence_arrangement' ? 'Susunan Kalimat' : 'undefined'}</div>
                </Col>
              </Row>
            </div>
            <div className="pro-group">
              <div className="product-price mb-3">{'Label Soal : '}
              </div>
              {detail.title}
            </div>
            <div className="pro-group">
              <div className="product-price mb-3">
                Audio Soal :
              </div>
              <p>
                {detail.audio_source === '' ? 'Tidak ada' : ''}
              </p>
              {detail.audio_source && (
                <AudioPlayer link={detail.audio_source} />
              )}
            </div>
            <div className="pro-group">
              <div className="product-price mb-3">{'Pertanyaan : '}
              </div>
              <RawHTMLComponent htmlContent={detail.description} />
            </div>
            {(detail.type === 'multiple_choice' || detail.type === 'sentence_arrangement') && (
              <div className="pro-group">
                <div className="product-price mb-3">{'Pilihan Jawaban : '}
                </div>
                <UL attrUL={{ className: 'simple-list' }}>
                  {detail.choices && detail.choices.map((itemChoice, index) => (
                    <LI key={itemChoice.id} className="mt-3">
                      <div>
                        <Row>
                          <Col>
                            <Label htmlFor={`itemChoice-${index}`}><RawHTMLComponent htmlContent={itemChoice.choice} /></Label>
                          </Col>
                          <Col>
                            {results[index]}
                          </Col>
                        </Row>
                      </div>
                    </LI>
                  ))}
                </UL>
              </div>
            )}
            <div className="pro-group">
              <div className="product-price mb-3">
                {'Pembahasan Soal : '}
              </div>
              <RawHTMLComponent htmlContent={detail.answer && detail.answer[0].answer_explanation} />
            </div>
            {detail.answer && (
              <div className="pro-group">
                <div className="product-price mb-3">
                  Video Pembahasan Soal : 
                </div>
                <p>
                  {detail.answer[0].answer_explanation_video === null ? 'Tidak ada' : ''}
                </p>
                {detail.answer[0].answer_explanation_video && (
                  <VideoPlayer link={detail.answer && detail.answer[0].answer_explanation_video} width={720} />
                )}
              </div>
            )}
            {(detail.type === 'fill_in_the_blanks' || detail.type === 'sentence_arrangement') && (
              <div className="pro-group">
                <div className="product-price mb-3">{'Jawaban Benar : '}
                </div>
                <UL attrUL={{ className: 'simple-list' }}>
                  {answerArray && answerArray.map((itemAnswer, index) => (
                    <LI key={index} className="mt-3">
                      <div className="form-check">
                        <Input className="form-check-input" type="radio" value="" disabled />
                        <Label>{itemAnswer}</Label>
                      </div>
                    </LI>
                  ))}
                </UL>
              </div>
            )}
            <div className="pro-group pb-0">
              <Row className="product-page-main p-0">
                <Col>
                  <div className="text-start">
                    <b>Created At : </b>{Moment(detail.created_at).format('dddd, DD/MM/yyyy')} - {Moment(detail.created_at).format('HH:mm:ss')} WIB
                  </div>
                </Col>
                <Col>
                  <div className="pro-shop text-end">
                    <Link to={`${process.env.PUBLIC_URL}/learning/soal-list`}>
                      <Btn attrBtn={{ color: 'primary', className: 'btn btn-primary m-r-10' }}>
                        <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                      </Btn>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment >
  );
};
export default QuestionsDetails;
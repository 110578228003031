import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import MateriDetail from './MateriDetail';

const DetailMateri = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row className='learning-block'>
          <MateriDetail />
        </Row>
      </Container>
    </Fragment>
  );
};

export default DetailMateri;
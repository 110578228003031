import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { H6, Spinner } from '../../../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import DetailTentorContain from '../../../../Component/Classes/Tentor/DetailTentor/DetailTentorContain';

const DetailTentor = () => {
    const isLoading = useSelector(state => state.Tentor.isLoading);
    const dataField = useSelector(state => state.Tentor.detail);

    return (
        <Fragment>
            <Container fluid={true}>
                <div>
                    {isLoading || !dataField.id ? (
                        <Col lg="12">
                            <div className="loader-box">
                                <Spinner attrSpinner={{ className: 'loader-30' }} />
                            </div>
                            <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
                        </Col>
                    ) : (
                    <Row className="product-page-main p-0">
                        <DetailTentorContain />
                    </Row>
                    )}
                </div>
            </Container>
        </Fragment>
    );
};

export default DetailTentor;
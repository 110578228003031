import { fetchSchedules, deleteSchedules } from '../../../Features/Classes/Schedule/Utils/ScheduleUtils';
import Swal from 'sweetalert2';

/* **************************************************** */
/* CRUD Schedule                                       */
/* **************************************************** */

export const fetchSchedule = (dispatch, filters, refetch) => {
    dispatch(fetchSchedules(filters));
}

export const handleDeleteSchedule = async (dispatch, id, setRefetch) => {
    try {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(deleteSchedules(id));
                setRefetch(prevRefetch => !prevRefetch);
                Swal.fire(
                    'Terhapus!',
                    'Data telah dihapus.',
                    'success'
                )
            }
        })
    } catch (error) {
        console.error("Delete error:", error);
    }
}

export const handleEditSchedule = async (dispatch, item, setField) => {
    console.log(item, "hande edit schedule")
    dispatch(setField({ field: 'id', value: item.id }));
    dispatch(setField({ field: 'isUpdate', value: true }));
    const fieldsToUpdate = ['class_id', 'name', 'tentor_id', 'QR_start_at', 'session_start_at', 'session_end_at', 'place'];
    fieldsToUpdate.forEach(field => {
        dispatch(setField({ field, value: item[field] }));
    });
    dispatch(setField({ field: 'course_id', value: item.course_id ? parseInt(item.course_id) : null }));
    dispatch(setField({ field: 'material_id', value: item.material_id ? parseInt(item.material_id) : null }));
    dispatch(setField({ field: 'classes', value: item.classes }));
    dispatch(setField({ field: 'tentor', value: item.tentor }));
    dispatch(setField({ field: 'course', value: item.course }));
    const includeMateri = item.material ? [{ value: item.material.id, label: item.material.name }] : [];
    dispatch(setField({ field: 'materials', value: includeMateri }));
    dispatch(setField({ field: 'attendance', value: item.attendance }));
}

export const handleGetDetailSchedule = async (dispatch, item, setDetail) => {
    console.log(item, "handle detail schedule")
    dispatch(setDetail(item));
}

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page > 1) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page - 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page < pagination.last_page) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page + 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
  console.log(page);
  dispatch(setFilters({
    ...filters,
    page: page
  }));
  setRefetch(prevRefetch => !prevRefetch);
}
import { createSlice } from '@reduxjs/toolkit';
import { submitMemberships } from './Utils/MembershipUtils';

const initialState = {
    title: '',
    price: '',
    gimmick_price: '',
    is_price_bottom: '0',
    subheader_desc: '',
    duration_days: '',
    is_to_only: '',
    is_online: '',
    is_guaranteed: '',
    is_campus_event: '0',
    link: '', // registration link
    main_program_id: '',
    points: [ { name: '', status: true } ],
    isLoading: false,
    isUpdate: false,
    id: '',
};

const addMembershipSlice = createSlice(
    {
        name: 'addMembership',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
            .addCase(submitMemberships.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(submitMemberships.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(submitMemberships.rejected, (state, action) => {
                state.isLoading = false;
                console.log(action.error.message, "error action")
            })
        }
    }
);

export const { setField, resetState } = addMembershipSlice.actions;

export default addMembershipSlice.reducer;

import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Previous, Next } from "../../Constant";

const PaginationComponent = ({
	pagination,
	handleBackPagination,
	handleClickPagination,
	handleNextPagination,
}) => {
	return (
		<Pagination
			className="pagination justify-content-center pagination-primary"
			aria-label="Page navigation example">
			<PaginationItem disabled={pagination.current_page === 1}>
				<PaginationLink onClick={() => handleBackPagination()}>
					{Previous}
				</PaginationLink>
			</PaginationItem>
			{[...Array(pagination.last_page).keys()].map((i) => (
				<PaginationItem key={i} active={pagination.current_page === i + 1}>
					<PaginationLink onClick={() => handleClickPagination(i + 1)}>
						{i + 1}
					</PaginationLink>
				</PaginationItem>
			))}
			<PaginationItem
				disabled={pagination.current_page === pagination.last_page}>
				<PaginationLink onClick={() => handleNextPagination()}>
					{Next}
				</PaginationLink>
			</PaginationItem>
		</Pagination>
	);
};

export default PaginationComponent;

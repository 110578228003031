import { setFilters } from '../../../../Features/Learning/SubMateri/subMaterialSlice';

const updateFilters = (filters, dispatch, field, value) => {
  dispatch(setFilters({
    ...filters,
    [field]: value,
  }));
};

export const handleFilterType = (filters, dispatch) => (e) => {
  const filterValue = e.target.value;
  const field = 'filter[type]';
  const existingValues = filters[field] ? filters[field].split(',') : [];
  const updatedValues = existingValues.includes(filterValue)
    ? existingValues.filter((value) => value !== filterValue)
    : [...existingValues, filterValue];

  updateFilters(filters, dispatch, field, updatedValues.join(','));
};

export const handleFilterName = (filters, dispatch) => (e) => {
  const filterValue = e.target.value;
  updateFilters(filters, dispatch, 'filter[name]', filterValue);
};

export const handleFilterId = (filters, dispatch) => (e) => {
  const filterValue = e.target.value;
  updateFilters(filters, dispatch, 'filter[id]', filterValue);
};

export const handleSortBy = (filters, dispatch) => (e) => {
  const filterValue = e.target.value;
  updateFilters(filters, dispatch, 'sort', filterValue);
};

export const isCheckedType = (filters, type) => {
  if (filters['filter[type]']) {
    return filters['filter[type]'].includes(type)
  }
  return false
}
import React from 'react';

function CleanLimitHTML({ htmlContent, limit }) {
    // Membersihkan teks dari tag HTML
    const cleanContent = htmlContent.replace(/<\/?[^>]+(>|$)/g, '');

    // Memotong teks jika melebihi batas karakter
    const truncatedContent = cleanContent.length > limit ? cleanContent.substring(0, limit) + '...' : cleanContent;

    return <>{truncatedContent}</>;
}

export default CleanLimitHTML;

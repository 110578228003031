import { createSlice } from '@reduxjs/toolkit';
import { detachTryout } from './Utils/TryoutUtils';

const detachTryoutSlice = createSlice({
  name: 'detachTryout',
  initialState: {
    detachments: {
      'question_id': '',
      'category_id': '',
      'order': '',
      'score': [
        {
          'choice_id': 109,
          'point': 5
        }
      ]
    },
    id: '',
    isLoading: false,
  },
  reducers: {
    setDetachments: (state, action) => {
        state.detachments = action.payload;
        },
    setIdDetach: (state, action) => {
        state.id = action.payload;
        }
  },
  extraReducers: (builder) => {
    builder
        .addCase(detachTryout.pending, (state) => {
            // Handle loading state
            state.isLoading = true;
        }
        )
        .addCase(detachTryout.fulfilled, (state, action) => {
            // Handle success state
            // console.log('Sukses:', action.payload);
            state.isLoading = false;
        }
        )
        .addCase(detachTryout.rejected, (state, action) => {
            console.log(action.error.message, "error action")
            state.isLoading = false;
        }
        );
  }
});

export const { setDetachments, setIdDetach } = detachTryoutSlice.actions;

export default detachTryoutSlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { TicketServices } from '../../../../Services/axios/Ticket.Service';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export const fetchTickets = createAsyncThunk(
    'Tickets/fetchTickets',
    async (filters) => {
        try {
        const response = await TicketServices.getAll(filters);
        return response.data;
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data. Mohon periksa koneksi internet Anda.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server.';
                } // Anda dapat menambahkan penanganan lain sesuai kebutuhan Anda

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                });
            } else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
            // if (error.response && error.response.data) {
            //     toast.error(error.response.data.message, {
            //         position: toast.POSITION.TOP_RIGHT
            //     });
            //     console.log(error.response.data);
            // }
        }
    }
);

export const markAsSolved = createAsyncThunk(
    'Tickets/markAsSolved',
    async (data) => {
        try {
            const formData = new FormData();
            formData.append('feedback', data.feedback);

            let response;
            response = await TicketServices.markAsSolved(data.id, formData);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                console.log(error.response.data);
            }
        }
    }
);
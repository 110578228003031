import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import ClassContain from '../../../Component/Classes/Class';

const ClassList = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Kelas" title="Daftar Kelas" />
            <ClassContain />
        </Fragment>
    );
};
export default ClassList;
import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import MembershipUsersContain from '../../../Component/Learning/Membership/UsersMembership/DetailUserList';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetDetailMembership } from '../../../Component/Learning/Membership/MembershipsUtils';
import { fetchMemberships } from '../../../Features/Learning/Memberships/Utils/MembershipUtils';
import { setDetail } from '../../../Features/Learning/Memberships/membershipSlice';

const UsersMembership = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const addField = useSelector(state => state.Membership.detail);
    const filters = useSelector(state => state.Membership.filters)

    useEffect(() => {
        if (!addField.id) {
            const newFilters = {
                ...filters,
                "filter[id]": id,
                "page": 1,
            };
            dispatch(fetchMemberships(newFilters))
                .then((response) => {
                    // Kode yang akan dijalankan setelah fetch sukses
                    handleGetDetailMembership(dispatch, response.payload.data[0], setDetail)
                    // Kode lain yang perlu dijalankan setelah fetch sukses
                })
                .catch((error) => {
                    // Tangani error jika fetch gagal
                    console.error('Error during fetchMemberships:', error);
                });
        }
    }, [id]);

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Users Membership" parent="Membership" title="Users Membership" />
            <MembershipUsersContain />
        </Fragment>
    );
};
export default UsersMembership;
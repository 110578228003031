import { axiosInstance } from './axiosInstance';

export const MembershipServices = {
    getAll: async (filter) => {
        return await axiosInstance.get('/api/feature/membership', { params: filter });
    },
    create: async (data) => {
        return await axiosInstance.post('/api/feature/membership', data);
    },
    update: async (id, data) => {
        return await axiosInstance.post(`/api/feature/membership/update/${id}`, data);
    },
    delete: async (id) => {
        return await axiosInstance.delete(`/api/feature/membership/delete/${id}`);
    },
    attachDetachCourse: async (id, data) => {
        return await axiosInstance.post(`/api/feature/membership/attach-detach/${id}`, data);
    },
    attachDetachTryout: async (id, data) => {
        return await axiosInstance.post(`/api/feature/membership/attach-detach-tryout/${id}`, data);
    },
    attachUser: async (id, data) => {
        return await axiosInstance.post(`/api/feature/membership/attach-user/${id}`, data);
    },
    detachUser: async (id, data) => {
        return await axiosInstance.post(`/api/feature/membership/detach-user/${id}`, data);
    },
    upgradeUserMembership: async (data) => {
        return await axiosInstance.post(`/api/feature/membership/upgrade`, data);
    }
}

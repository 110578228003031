import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import TryoutContain from '../../../Component/Learning/Tryout';

const TryoutList = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Learning List" parent="Learning" title="Daftar Tryout" />
            <TryoutContain />
        </Fragment>
    );
};
export default TryoutList;
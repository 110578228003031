import { createSlice } from '@reduxjs/toolkit';
import { submitQuestion } from './Utils/QuestionsUtils';

const initialState = {
    title: '',
    description: '',
    type: '',
    answer_explanation: '',
    answer_explanation_video: '',
    audio_source: '',
    choice: [],
    answer: [],
    isUpdate: false,
    id: '',
    isLoading: false,
};

const addQuestion = createSlice(
    {
        name: 'addQuestion',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(submitQuestion.pending, (state) => {
                    // Handle loading state
                    state.isLoading = true;
                })
                .addCase(submitQuestion.fulfilled, (state, action) => {
                    // Handle success state
                    // console.log('Sukses:', action.payload);
                    state.isLoading = false;
                })
                .addCase(submitQuestion.rejected, (state, action) => {
                    // Handle error state
                    console.log(action.error.message, "error action")
                    state.isLoading = false;
                });
        }
    }
);


export const { setField, resetState } = addQuestion.actions;

export default addQuestion.reducer;
import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
	Table,
} from "reactstrap";
import { H5, H4, H6, Btn } from "../../../../AbstractElements";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CurrencyFormat from "../../Common/GenerateContent/CurrencyFormatter";
import Swal from "sweetalert2";
import { detachUserMemberships } from "../../../../Features/Learning/Memberships/Utils/MembershipUtils";
import { fetchUsers } from "../../../../Features/Learning/Users/Utils/UserUtils";
import { getDetailUser } from "../../../../Component/Learning/Users/UsersUtils";
import { setDetail } from "../../../../Features/Learning/Users/userSlice";
import MembershipUpgrade from "./MembershipUpgrade";

const MembershipProfile = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const onEditSubmit = (data) => {
		alert("Form is Submitted");
	};
	const userDetail = useSelector((state) => state.User.detail);
	const [detailLocal, setDetailLocal] = useState({});
	const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
	const [upgradeData, setUpgradeData] = useState({
		userId: "",
		oldMembershipId: "",
		newMembershipId: "",
		oldMembershipName: "",
		userName: "",
	});

	useEffect(() => {
		setDetailLocal(userDetail);
	}, [userDetail]);

	const filters = useSelector((state) => state.User.filters);
	const dispatch = useDispatch();
	const { id } = useParams();

	const handleDetach = async (membershipId, membershipUserId) => {
		try {
			const data = {
				id: membershipId,
				membership_user_id: membershipUserId,
			};
			Swal.fire({
				title: "Apakah anda yakin?",
				text: "User akan dihapus dari membership",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Ya, Hapus!",
			}).then(async (result) => {
				if (result.isConfirmed) {
					const response = await dispatch(detachUserMemberships(data));
					if (response.payload === true) {
						Swal.fire(
							"Berhasil!",
							"User berhasil dihapus dari membership",
							"success"
						);
						const newFilters = {
							...filters,
							"filter[id]": id,
							page: 1,
						};
						dispatch(fetchUsers(newFilters))
							.then((response) => {
								// Kode yang akan dijalankan setelah fetch sukses
								getDetailUser(dispatch, response.payload.data[0], setDetail);
							})
							.catch((error) => {
								// Tangani error jika fetch gagal
								console.error("Error during fetchUsers:", error);
							});
					} else {
						Swal.fire("Gagal!", "User gagal dihapus dari membership", "error");
					}
				}
			});
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleUpgrade = async (item) => {
		setUpgradeData({
			userId: id,
			oldMembershipId: item,
			oldMembershipName: detailLocal.membership.filter(
				(el) => el.id === item
			)[0].title,
			userName: detailLocal.name,
		});
		setUpgradeModalOpen(true);
	};

	return (
		<Fragment>
			<Card>
				<CardHeader className="pb-0">
					<H4 attrH4={{ className: "card-title mb-0 text-center" }}>
						{detailLocal.membership && detailLocal.membership.length > 0
							? "Daftar Membership"
							: "Belum ada membership terdaftar"}
					</H4>
				</CardHeader>
				<CardBody>
					{detailLocal.membership && detailLocal.membership.length > 0 && (
						<Col sm="12 order-2">
							<Card>
								<div className="table-responsive">
									<Table>
										<thead className="table-light">
											<tr>
												<th scope="col">{"#"}</th>
												<th scope="col">{"Membership"}</th>
												<th scope="col">{"Harga"}</th>
												<th scope="col">{"Durasi"}</th>
												<th scope="col">{"Tipe"}</th>
												<th scope="col">{"Aksi"}</th>
											</tr>
										</thead>
										<tbody>
											{detailLocal.membership &&
												detailLocal.membership.map((item, index) => (
													<tr key={`membership-${index}`}>
														<th>{index + 1}</th>
														<td>{item.title}</td>
														<td>
															<CurrencyFormat amount={item.price} />
														</td>
														<td>
															<div>{item.duration_days} Hari</div>
															{item.pivot && item.pivot.start_date && (
																<p>
																	{item.pivot.start_date} s/d{" "}
																	{item.pivot.end_date}
																</p>
															)}
														</td>
														<td>
															{item.is_online === true ? (
																<Label className="badge badge-success">
																	Online
																</Label>
															) : (
																<Label className="badge badge-primary">
																	Offline
																</Label>
															)}
															{item.is_to_only === true ? (
																<Label className="badge badge-warning">
																	To Only
																</Label>
															) : (
																""
															)}
														</td>
														<td className="justify-content-center">
															<Btn
																attrBtn={{
																	title: "Hapus Membership",
																	color: "link",
																	outline: true,
																	className: "p-1",
																	onClick: () => {
																		handleDetach(item.id, item.pivot.id);
																	},
																}}>
																<i className="fa fa-trash-o mx-auto text-danger fa-lg"></i>
															</Btn>
															<Btn
																attrBtn={{
																	title: "Upgrade Membership",
																	color: "link",
																	outline: true,
																	className: "p-1",
																	onClick: () => {
																		handleUpgrade(item.id);
																	},
																}}>
																<i className="fa fa-level-up mx-auto text-primary fa-lg"></i>
															</Btn>
														</td>
													</tr>
												))}
										</tbody>
									</Table>
								</div>
							</Card>
						</Col>
					)}
				</CardBody>
				{detailLocal.membership_upgrade &&
					detailLocal.membership_upgrade.length > 0 && (
						<>
							<CardHeader className="pb-0">
								<H4 attrH4={{ className: "card-title mb-0 text-center" }}>
									"Riwayat Upgrade Membership"
								</H4>
							</CardHeader>
							<CardBody>
								<Col sm="12 order-2">
									<Card>
										<div className="table-responsive">
											<Table>
												<thead className="table-light">
													<tr>
														<th scope="col">{"Membership Lama"}</th>
														<th scope="col">{"Membership Baru"}</th>
														<th scope="col">{"Waktu"}</th>
													</tr>
												</thead>
												<tbody>
													{detailLocal.membership_upgrade &&
														detailLocal.membership_upgrade.map(
															(item, index) => (
																<tr key={`membership-${index}`}>
																	<td>{item.membership_old_title}</td>
																	<td>{item.membership_new_title}</td>
																	<td>
																		{new Date(
																			item.created_at
																		).toLocaleDateString("en-GB")}
																	</td>
																</tr>
															)
														)}
												</tbody>
											</Table>
										</div>
									</Card>
								</Col>
							</CardBody>
						</>
					)}

				<MembershipUpgrade
					upgradeData={upgradeData}
					open={upgradeModalOpen}
					setOpen={setUpgradeModalOpen}
				/>
			</Card>
		</Fragment>
	);
};
export default MembershipProfile;

import React, { useState, Fragment, useEffect } from 'react';
import { Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Image } from '../../../AbstractElements';
import { setField } from '../../../Features/Learning/Categories/addCategoriesSlice';
import { useParams } from 'react-router-dom';
import AsyncSelectCommon from '../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { fetchCategories } from '../../../Features/Learning/Categories/Utils/CategoriesUtils';
import DropzoneCommon from '../../../Component/Learning/Common/Upload/Dropzone';

const CategoriesForm = () => {
  const dispatch = useDispatch()
  const [selectedValue, setSelectedValue] = useState(null);
  const filtersCategory = useSelector(state => state.Categories.filters);
  const data = useSelector(state => state.addCategories)
  const cards = useSelector(state => state.Categories.data.data);
  const { id } = useParams();

  useEffect(() => {
    if (cards) {
      const selectedParent = data.parent_id
        ? [{
          value: data.parent_id,
          label: cards.find(item => item.id === data.parent_id)?.title,
        }]
        : [];
      setSelectedValue(selectedParent);
    }
  }, [id]);
  
  const handleChanges = (e) => {
    const { name, value } = e.target;
    if (name == 'is_main_program' && value == '1') {
      dispatch(setField({ field: 'is_question', value: '0' }));
    } else if (name == 'is_main_program' && value == '0') {
      dispatch(setField({ field: 'is_question', value: '' }));
    }
    dispatch(setField({ field: name, value: value }));
    // console.log(e);
  }

  const handleChangeStatus = ({ meta, file }, status) => {
    // console.log(status, meta, file)
    if (status === 'removed') {
      dispatch(setField({ field: 'icon', value: '' }));
    } else if (status === 'done') {
      dispatch(setField({ field: 'icon', value: file }));
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    if (selectedOption !== null) {
      dispatch(setField({ field: 'parent_id', value: selectedOption.value }));
    } else {
      dispatch(setField({ field: 'parent_id', value: '' }));
    }
  };

  const promiseOptions = (inputValue, callback) => {
    dispatch(fetchCategories({
      ...filtersCategory,
      'filter[is_parent]': '1',
      'filter[title]': inputValue,
    }))
      .then((response) => {
        const options = response.payload.data.map(item => ({
          value: item.id,
          label: item.title,
        }));
        callback(options);
      })
      .catch((error) => {
        console.error('Error fetching options:', error);
        callback([]);
      });
  };
   
  return (
    <Fragment>
      <Form className="row needs-validation">
        <Col sm="12">
          <FormGroup className='mb-3'>
            <Label for="validationtitle" style={{ fontWeight: 'bold' }}>{'Nama Kategori'}:</Label>
            <Input className="form-control" id="validationtitle" type="text" name="title" placeholder="Nama Kategori" required=""
            value={data.title}
            onChange={handleChanges} />
            <div className="valid-feedback">{'Looks good!'}</div>
          </FormGroup>
          <FormGroup className='mb-3'>
            <Label style={{ fontWeight: 'bold' }}>Kategori Program ?</Label>
            <div className="m-checkbox-inline">
              <Label for="is_main_programFalse">
                <Input className="radio_animated" id="is_main_programFalse" type="radio" name="is_main_program" value="0"
                  checked={data.is_main_program == '0'}
                  onChange={handleChanges} />{'No'}
              </Label>
              <Label for="is_main_programTrue">
                <Input className="radio_animated" id="is_main_programTrue" type="radio" name="is_main_program" value="1"
                  checked={data.is_main_program == '1'}
                  onChange={handleChanges} />{'Yes'}
              </Label>
            </div>
          </FormGroup>
          <FormGroup className='mb-3'>
            <Label style={{ fontWeight: 'bold' }}>Kategori Induk ?</Label>
            <div className="m-checkbox-inline">
              <Label for="is_parentFalse">
                <Input className="radio_animated" id="is_parentFalse" type="radio" name="is_parent" value="0"
                  checked={data.is_parent == '0'}
                onChange={handleChanges}/>{'No'}
              </Label>
              <Label for="is_parentTrue">
                <Input className="radio_animated" id="is_parentTrue" type="radio" name="is_parent" value="1" 
                  checked={data.is_parent == '1'}
                onChange={handleChanges}/>{'Yes'}
              </Label>
            </div>
          </FormGroup>
          {data.is_main_program == '0' && (
          <FormGroup className='mb-3'>
            <Label style={{ fontWeight: 'bold' }}>Kategori Pertanyaan ?</Label>
            <div className="m-checkbox-inline">
              <Label for="is_questionFalse">
                <Input className="radio_animated" id="is_questionFalse" type="radio" name="is_question" value="0"
                  checked={data.is_question == '0'}
                onChange={handleChanges}/>{'No'}
              </Label>
              <Label for="is_questionTrue">
                <Input className="radio_animated" id="is_questionTrue" type="radio" name="is_question" value="1" 
                  checked={data.is_question == '1'}
                onChange={handleChanges}/>{'Yes'}
              </Label>
            </div>
          </FormGroup>
          )}
          {data.is_question == '1' && (<>
            <FormGroup className='mb-3'>
              <Label for="value" style={{ fontWeight: 'bold' }}>{'Passing Grade (0 = no passing grade)'}:</Label>
              <Input className="form-control" id="value" type="number" name="value" placeholder="Passing Grade" required=""
              value={data.value}
              onChange={handleChanges} />
              <div className="valid-feedback">{'Looks good!'}</div>
            </FormGroup>
            <FormGroup className='mb-3'>
              <Label for="max_score" style={{ fontWeight: 'bold' }}>{'Maximum Score (0 = no max score)'}:</Label>
              <Input className="form-control" id="max_score" type="number" name="max_score" placeholder="Nilai Maksimal" required=""
              value={data.max_score}
              onChange={handleChanges} />
              <div className="valid-feedback">{'Looks good!'}</div>
            </FormGroup>
            </>
          )}
          {data.iconLink && (
            <FormGroup className='mb-3'>
              <Image attrImage={{ className: 'img-fluid sm-100-w', width: '200px', src: data.iconLink, alt: data.title }} />
            </FormGroup>
          )}
          <DropzoneCommon label={'Ikon Kategori'} inputContent={'Silahkan upload gambar.'} handleChangeStatus={handleChangeStatus} accept="image/*" />
          {data.is_main_program == '0' && data.is_parent === '0' && (
          <AsyncSelectCommon label={'Pilih Induk'} placeholder={'Silahkan pilih Induk Kategori...'}
            isMulti={false}
            promiseOptions={promiseOptions}
            handleSelectChange={handleSelectChange}
            selectedValue={selectedValue}
          />
          )}
        </Col>
      </Form>
    </Fragment>
  );
};
export default CategoriesForm;
import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { H6, Spinner } from '../../../../AbstractElements';
import { useSelector } from 'react-redux';
import VoucherDetailContain from './VoucherDetailContain';
import VoucherMembershipContain from './VoucherMembershipContain';

const VoucherDetailBox = () => {
    const isLoading = useSelector(state => state.Voucher.isLoading)

  return (
    <Fragment>
        {isLoading ? (
            <Col lg="12">
                <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-30' }} />
                </div>
                <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
            </Col>
        ) : (
        <Container fluid={true}>
            <div className="edit-profile">
            <Row>
                <Col xl="4">
                <VoucherDetailContain />
                </Col>
                <Col xl="8">
                <VoucherMembershipContain />
                </Col>
            </Row>
            </div>
        </Container>
        )}
    </Fragment>
  );
};
export default VoucherDetailBox;
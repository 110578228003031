import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import TentorContain from '../../../Component/Classes/Tentor';

const TentorList = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Kelas" title="Daftar Tentor" />
            <TentorContain />
        </Fragment>
    );
};
export default TentorList;
import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import ReferralMutationTable from './ReferralMutationTable';
import ReferralMutationFilter from './Filter/ReferralMutationFilter';


const HistoryReferralMutationContain = () => {
    const cards = useSelector(state => state.historyUserReferralMutation.data.data)

    return (
        <Fragment>
            <Container fluid={true}>
                <Row className='learning-block'>
                    <Col xl="9" className='xl-80'>
                        <Row>
                            <ReferralMutationTable />
                        </Row>
                    </Col>
                    {cards && (
                        <ReferralMutationFilter />
                    )}
                </Row>
            </Container>
        </Fragment>
    );
};
export default HistoryReferralMutationContain;
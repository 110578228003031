import { createSlice } from '@reduxjs/toolkit';
import { fetchMaterial } from './Utils/MateriUtils';

const MaterialSlice = createSlice({
  name: 'Material',
  initialState: {
    filters: {
      'filter[description]': '',
      'filter[name]': '',
      'filter[id]': '',
      'sort': '',
      'include[0]': 'submaterials',
      'page': 1,
    },
    data: [],
    detail: {},
    isLoading: false,
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDetail: (state, action) => {
      state.detail = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaterial.pending, (state) => {
        // Handle loading state
        state.isLoading = true;
      })
      .addCase(fetchMaterial.fulfilled, (state, action) => {
        // Handle success state
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchMaterial.rejected, (state, action) => {
        // Handle error state
        state.isLoading = false;
        console.log(action.error.message, "error action")
      });
  }
});

export const { setFilters, setData, setDetail } = MaterialSlice.actions;

export default MaterialSlice.reducer;

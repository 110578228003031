import { createSlice } from "@reduxjs/toolkit";
import { attachDetachMainProgram } from "./Utils/MainProgramUtils";

const initialState = {
    id: '',
    membership_id: [],
    type: '',
    isLoading: false,
};

const attachDetachMainProgramSlice = createSlice(
    {
        name: 'attachDetachMainProgram',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(attachDetachMainProgram.pending, (state) => {
                    // Handle loading state
                    state.isLoading = true;
                })
                .addCase(attachDetachMainProgram.fulfilled, (state, action) => {
                    // Handle successful http response
                    state.isLoading = false;
                })
                .addCase(attachDetachMainProgram.rejected, (state, action) => {
                    // Handle error
                    state.isLoading = false;
                    console.log(action.error.message, "error action")
                });
        }
    }
);


export const { setField, resetState } = attachDetachMainProgramSlice.actions;

export default attachDetachMainProgramSlice.reducer;
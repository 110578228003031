import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AsyncSelectCommon from '../../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { fetchTentors } from '../../../../Features/Classes/Tentor/Utils/TentorUtils';
import { setField } from '../../../../Features/Classes/Schedule/addScheduleSlice';

const AsynchSelectTentor = () => {
    const { classId, scheduleId } = useParams();
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState(null);
    const filterTentor = useSelector(state => state.Tentor.filters);
    const data = useSelector(state => state.addSchedule)

    useEffect(() => {
        const selectedTentor = data.tentor && data.tentor.id
            ? [{
                value: data.tentor.id,
                label: data.tentor.name,
            }]
            : selectedValue;
        setSelectedValue(selectedTentor);
    }, [data.tentor]);

    const promiseOptionTentor = (inputValue, callback) => {
        dispatch(fetchTentors({
            ...filterTentor,
            'filter[name]': inputValue,
            'sort': 'name',
            'page': 1,
        }))
            .then((response) => {
                const options = response.payload.data.map(item => ({
                    value: item.id,
                    label: item.name,
                }));
                callback(options);
            })
            .catch((error) => {
                console.error('Error fetching options:', error);
                callback([]);
            });
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedValue(selectedOption === null ? '' : selectedOption)
        dispatch(setField({ field: 'tentor_id', value: selectedOption === null ? '' : selectedOption.value }));
        dispatch(setField({ field: 'class_id', value: parseInt(classId) }));
    }

    return (
        <Fragment>
            <AsyncSelectCommon 
                label={'Pilih Tentor'} 
                placeholder={'Silahkan pilih Tentor...'}
                isMulti={false}
                promiseOptions={promiseOptionTentor}
                handleSelectChange={handleSelectChange}
                selectedValue={selectedValue}
            />
        </Fragment>
    );
}

export default AsynchSelectTentor;
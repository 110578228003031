import React, { Fragment } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import Blog from "../../Component/Blog";

const BlogList = () => {
	return (
		<Fragment>
			<Breadcrumbs parent="Blog" title="Daftar Blog" />
			<Blog />
		</Fragment>
	);
};
export default BlogList;

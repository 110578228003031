import React, { Fragment } from 'react';
import { Input, Label } from 'reactstrap';
import { handleSortBy } from './CatCheckBoxUtils';
import { useSelector, useDispatch } from 'react-redux';

const PriceCheck = () => {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.subMaterial.filters)

  return (
    <Fragment>
      <div className="checkbox-animated mt-0">
        <div className="learning-header"><span className="f-w-600">Sort by:</span></div>
        <Label className="d-block" htmlFor="edo-ani">
          <Input className="radio_animated" id="edo-ani" type="radio" name="sort"
          checked={filters.sort === '-id'}
          value="-id" 
          onChange={handleSortBy(filters, dispatch)}
           />
          {'Terbaru'}
        </Label>
        <Label className="d-block" htmlFor="edo-ani1">
          <Input className="radio_animated" id="edo-ani1" type="radio" name="sort"
          checked={filters.sort === 'id'}
          value="id"
          onChange={handleSortBy(filters, dispatch)}
           />
          {'Terlama'}
        </Label>
        <Label className="d-block" htmlFor="edo-ani2">
          <Input className="radio_animated" id="edo-ani2" type="radio" name="sort"
          checked={filters.sort === 'name'}
          value="name" 
          onChange={handleSortBy(filters, dispatch)}
           />
          {'A-Z'}
        </Label>
        <Label className="d-block" htmlFor="edo-ani3">
          <Input className="radio_animated" id="edo-ani3" type="radio" name="sort"
          checked={filters.sort === '-name'}
          value="-name" 
          onChange={handleSortBy(filters, dispatch)}
           />
          {'Z-A'}
        </Label>
      </div>
    </Fragment>
  );
};

export default PriceCheck;
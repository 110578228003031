import { createSlice } from '@reduxjs/toolkit';
import { fetchSubMaterial} from './Utils/SubMateriUtils';

const subMaterialSlice = createSlice({
  name: 'SubMaterial',
  initialState: {
    filters: {
      'filter[type]': '',
      'filter[name]': '',
      'filter[id]': '',
      'sort': '',
      'include': 'tryout',
      'page': 1,
    },
    data: [],
    detail: {},
    isLoading: false,
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDetail: (state, action) => {
      state.detail = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubMaterial.pending, (state) => {
        // Handle loading state
        state.isLoading = true;
      })
      .addCase(fetchSubMaterial.fulfilled, (state, action) => {
        // Handle success state
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchSubMaterial.rejected, (state, action) => {
        // Handle error state
        state.isLoading = false;
        console.log(action.error.message, "error action")
      });
  }
});

export const { setFilters, setData, setDetail } = subMaterialSlice.actions;

export default subMaterialSlice.reducer;

import axios from 'axios';

export const axiosInstance = axios.create({
    baseURL : process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('TOKEN');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    }
)

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    }
)
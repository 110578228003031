import { createSlice } from '@reduxjs/toolkit';
import { fetchHistoryReferralCompact } from './Utils/UserUtils';

const historyReferralCompactSlice = createSlice({
    name: 'historyReferralCompact',
    initialState: {
        filters: {
            "filter[id]": "",
            "filter[email]": "",
            "filter[name]": "",
            "filter[referral_code]": "",
            "sort": "",
            "page": "",
        },
        data: [],
        detail: {},
        isLoading: false,
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        setDetail: (state, action) => {
            state.detail = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHistoryReferralCompact.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchHistoryReferralCompact.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchHistoryReferralCompact.rejected, (state) => {
                state.isLoading = false;
            })
    }
});

export const { setFilters, setData, setDetail } = historyReferralCompactSlice.actions;

export default historyReferralCompactSlice.reducer;
import { axiosInstance } from "./axiosInstance";


export const TryoutService = {
    getAll: async (filter) => {
        return await axiosInstance.get('/api/feature/membership/tryout', { params: filter });
    },
    create: async (data) => {
        return await axiosInstance.post('/api/feature/membership/tryout', data);
    },
    update: async (id, data) => {
        return await axiosInstance.post(`/api/feature/membership/tryout/update/${id}`, data);
    },
    delete: async (id) => {
        return await axiosInstance.delete(`/api/feature/membership/tryout/delete/${id}`);
    },
    attach: async (id, attachment) => {
        return await axiosInstance.post(`/api/feature/membership/tryout/attach/${id}`, attachment.attachments);
    },
    detach: async (id, detachment) => {
        return await axiosInstance.post(`/api/feature/membership/tryout/detach/${id}`, detachment.detachments);
    },
    setOrder: async (id, data) => {
        return await axiosInstance.post(`/api/feature/membership/tryout/set-order/${id}`, { question_id: data });
    },
}
import React, { useState, useEffect } from 'react';
import { Image, H6, H5, H4, LI, UL, P, Btn, Spinner } from '../../../../AbstractElements';
import { Col, Card, CardBody, CardHeader, CardFooter, Media, Table, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterUsers } from '../../../../Features/Learning/Users/userFilteredSlice';
import { setDetail } from '../../../../Features/Learning/Users/userSlice';
import { setField } from '../../../../Features/Learning/Users/addUserSlice';
import PaginationComponent from '../../../../Component/Learning/Common/Pagination';
import {
    fetchUserFiltered,
    handleEditUser,
    getDetailUser,
    handleNextPagination,
    handleBackPagination,
    handleClickPagination
} from '../../../../Component/Learning/Users/UsersUtils';


const UsersContain = ( props ) => {
    const dispatch = useDispatch();
    const filterUsers = useSelector(state => state.userFiltered.filters);
    const data = useSelector(state => state.userFiltered.data.data);
    const status = useSelector(state => state.userFiltered.isLoading);
    const allData = useSelector(state => state.userFiltered.data);
    const filteredUsers = useSelector(state => state.userFiltered.filters);
    const pagination = useSelector(state => state.userFiltered.data.pagination);
    const { id } = useParams();

    return (
        <Col sm="12 order-2">
            { status ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Loading data...'}</H6>
                </Col>
            ) : (
                data && data.length === 0 ? (
                    <Card>
                        <CardHeader>
                                <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{filterUsers['filter[name]'] || filterUsers['filter[email]'] || filterUsers['filter[phone]'] ? 'Data tidak ditemukan' : 'Belum ada user terdaftar'}</H6>
                        </CardHeader>
                    </Card>
                ) : (
                    <Card>
                        <CardHeader>
                            <H5>List Users terdaftar ({allData && allData.pagination && allData.pagination.total} User)</H5>
                        </CardHeader>
                        <CardBody>
                        <div>
                            <Table className="table-responsive table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col">{'#'}</th>
                                        <th scope="col">{'Name '}</th>
                                        <th scope="col">{'Email '}</th>
                                        <th scope="col">{'Phone '}</th>
                                        <th scope="col" className="text-center" colSpan="3">{'Aksi'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.map((item, index) => (
                                        <tr key={`item-${index}`}>
                                            <th>{pagination.from + index}</th>
                                            <td>{item.name}</td>
                                            {/* make email just for 20 character but if cursor on show full email */}
                                            <td>
                                                <span title={item.email}>{item.email.length > 25 ? item.email.substring(0, 25) + '...' : item.email}</span>
                                            </td>
                                            <td>{item.phone}</td>
                                            <td className='text-center'>
                                                <Link to={`${process.env.PUBLIC_URL}/users/user-detail/${item.id}`}
                                                    title="Tekan untuk detail"
                                                >
                                                    <Btn
                                                        attrBtn={{
                                                            color: 'link',
                                                            outline: true,
                                                            className: 'p-1',
                                                            onClick: () => {
                                                                getDetailUser(dispatch, item, setDetail)
                                                            }
                                                        }}
                                                    >
                                                        <i className="fa fa-info-circle mx-auto fa-lg"></i>
                                                    </Btn>
                                                </Link>
                                            </td>
                                            <td className='text-center'>
                                                <Link to={`${process.env.PUBLIC_URL}/users/user-add/${item.id}`}
                                                    title="Tekan untuk edit"
                                                >
                                                    <Btn
                                                        attrBtn={{
                                                            color: 'link',
                                                            outline: true,
                                                            className: 'p-1',
                                                            onClick: () => {
                                                                handleEditUser(dispatch, item, setField)
                                                            }
                                                        }}
                                                    >
                                                        <i className="fa fa-edit mx-auto fa-lg"></i>
                                                    </Btn>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        </CardBody>
                        <CardFooter>
                            {pagination && (
                                <PaginationComponent
                                    pagination={pagination}
                                    handleBackPagination={() => handleBackPagination(pagination, filterUsers, dispatch, setFilterUsers, props.setRefatch)}
                                    handleClickPagination={(page) => handleClickPagination(page, filterUsers, dispatch, setFilterUsers, props.setRefatch)}
                                    handleNextPagination={() => handleNextPagination(pagination, filterUsers, dispatch, setFilterUsers, props.setRefatch)}
                                />
                            )}
                        </CardFooter>
                    </Card>
                )
            )}
        </Col>
    );
};

export default UsersContain;

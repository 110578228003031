import { axiosInstance } from "./axiosInstance";

export const TestimonyServices = {
    getAll: async (filter) => {
        return await axiosInstance.get("/api/feature/testimony", {
            params: filter,
        });
    },
    create: async (data) => {
        return await axiosInstance.post("/api/feature/testimony", data);
    },
    update: async (id, data) => {
        return await axiosInstance.post(
            `/api/feature/testimony/update/${id}`,
            data
        );
    },
    delete: async (id) => {
        return await axiosInstance.delete(`/api/feature/testimony/delete/${id}`);
    },

    attachDetachMainProgram: async (id, data) => {
        return await axiosInstance.post(
            `/api/feature/testimony/attach-detach/${id}`,
            data
        );
    }
};
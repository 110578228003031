import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DetailTryoutContain from '../../../Component/Learning/DetailTryout';
import { fetchTryouts, handleGetDetailTryout } from '../../../Component/Learning/Tryout/TryoutUtils';
import { fetchTryout } from '../../../Features/Learning/Tryout/Utils/TryoutUtils';
import { setDetail } from '../../../Features/Learning/Tryout/tryoutSlice';

const DetailTryout = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const filters = useSelector(state => state.Tryout.filters)
  
  useEffect(() => {
    if (id) {
      const newFilters = {
        ...filters,
        'filter[id]': id,
        'include[1]': 'questions',
        'page': 1,
      };
      dispatch(fetchTryout(newFilters))
        .then((response) => {
          // Kode yang akan dijalankan setelah fetch sukses
          handleGetDetailTryout(dispatch, response.payload.data[0], setDetail)
          // Kode lain yang perlu dijalankan setelah fetch sukses
        })
        .catch((error) => {
          // Tangani error jika fetch gagal
          console.error('Error during fetchTryout on Detail:', error);
        });
    }
  }
  , [id])
  
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Detail Tryout" parent="Learning" title="Detail Tryout" />
      <DetailTryoutContain />
    </Fragment>
  );
};
export default DetailTryout;
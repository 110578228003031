import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { Search } from 'react-feather';
import { useParams } from 'react-router-dom';
import { Filter } from '../../../../../Constant';
import { H5, Btn } from '../../../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../../../Features/Learning/Users/historyUserReferralSlice';
import AsyncSelectCommon from '../../../../../Component/Learning/Common/SelectOption/AsyncSelect';
import Select from 'react-select';
import { fetchHistoryReferrals } from '../../ReferralOrder/HistoryReferralUtils';
import { fetchMemberships } from '../../../../../Features/Learning/Memberships/Utils/MembershipUtils';

const FindHistory = () => {
    const [isFilter, setIsFilter] = useState(true);
    const dispatch = useDispatch()
    const filters = useSelector(state => state.historyUserReferral.filters)
    const filterMemberships = useSelector(state => state.Membership.filters)
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const { id } = useParams();


    useEffect(() => {
        const selectedMembership = filters['filter[membership.title]']
            ? [{
                value: filters['filter[membership.id]'],
                label: filters['filter[membership.title]']
            }]
            : [];
        setSelectedValue(selectedMembership);
    }, [filters['filter[membership.id]']]);

    const dataOptionType = [
        { value: 'debit', label: 'Debit' },
        { value: 'kredit', label: 'Kredit' },
    ];

    const promiseOptions = (inputValue, callback) => {
        // console.log('Input Value = ', inputValue)
        // if (inputValue !== '') {
            dispatch(fetchMemberships({
                ...filterMemberships,
                'filter[title]': inputValue,
                'sort': 'title',
                'page': 1,
            }))
                .then((response) => {
                    const options = response.payload.data.map(item => ({
                        value: item.id,
                        label: item.title,
                    }));
                    callback(options);
                })
                .catch((error) => {
                    console.error('Error fetching options:', error);
                    callback([]);
                });
        // }
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedValue(selectedOption);

        const membershipTitleFilter = selectedOption ? selectedOption.label : '';

        const newFilters = {
            ...filters,
            'filter[membership.title]': membershipTitleFilter,
            'filter[referee.id]': id,
            'page': 1,
        };

        dispatch(setFilters(newFilters));
        fetchHistoryReferrals(dispatch, newFilters);
    };

    const handleSelectChangeStatus = (selectedOption) => {
        if (selectedOption !== null) {
            setSelectedStatus(selectedOption);
            const newFilters = {
                ...filters,
                'filter[type]': selectedOption.value,
                'filter[referee.id]': id,
                'page': 1,
            };
            dispatch(setFilters(newFilters));
            fetchHistoryReferrals(dispatch, newFilters);
        } else {
            setSelectedStatus(null);
            const newFilters = {
                ...filters,
                'filter[type]': '',
                'filter[referee.id]': id,
                'page': 1,
            };
            dispatch(setFilters(newFilters));
            fetchHistoryReferrals(dispatch, newFilters);
        }
    };


    const handleClickSearch = (e) => {
        e.preventDefault()
        const newFilters = {
            ...filters,
            'filter[referee.id]': id,
            'page': 1,
        };
        dispatch(setFilters(newFilters));
        fetchHistoryReferrals(dispatch, newFilters);
    }


    const handleResetFilter = () => {
        const newFilters = {
            ...filters,
            'filter[referee.id]': id,
            'filter[id]': '',
            'filter[user.email]': '',
            'filter[user.name]': '',
            'filter[user.phone]': '',
            'filter[membership.title]': '',
            'filter[type]': '',
            'sort': '',
            'page': 1,
        };
        setSelectedStatus(null);
        setSelectedValue(null);
        dispatch(setFilters(newFilters));
        fetchHistoryReferrals(dispatch, newFilters);
    }

    const updateFilters = (filters, dispatch, field, value) => {
        dispatch(setFilters({
            ...filters,
            [field]: value,
        }));
    };

    const handleSortBy = (filters, dispatch) => (e) => {
        const filterValue = e.target.value;
        updateFilters(filters, dispatch, 'sort', filterValue);
    };


    return (
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardHeader>
                        <H5 attrH5={{ className: 'mb-0' }} >
                            <Btn attrBtn={{ className: 'btn btn-link ps-0', onClick: () => setIsFilter(!isFilter), color: 'transperant', datastoggle: 'collapse', databstarget: '#collapseicon', ariaexpanded: 'true', ariacontrols: 'collapseicon' }} >{'Temukan Pesanan'}</Btn>
                        </H5>
                    </CardHeader>
                    <Collapse isOpen={isFilter}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                            <CardBody className="filter-cards-view animate-chk">
                                <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <Input className="form-control" type="text" placeholder="Cari nama pengguna.."
                                            value={filters['filter[user.name]']}
                                            onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                                            onChange={(e) => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[user.name]': e.target.value,
                                                    'filter[referee.id]': id
                                                }));
                                            }}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div>
                                <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <Input className="form-control" type="text" placeholder="Cari email pengguna.."
                                            value={filters['filter[user.email]']}
                                            onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                                            onChange={(e) => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[user.email]': e.target.value,
                                                    'filter[referee.id]': id
                                                }));
                                            }}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div>
                                <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <Input className="form-control" type="number" placeholder="Cari nomer telpon pengguna.."
                                            value={filters['filter[user.phone]']}
                                            onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                                            onChange={(e) => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[user.phone]': e.target.value,
                                                    'filter[referee.id]': id
                                                }));
                                            }}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div>
                                {/* <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <Input className="form-control" type="text" placeholder="Cari nama referee.."
                                            value={filters['filter[referee.name]']}
                                            onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                                            onChange={(e) => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[referee.name]': e.target.value
                                                }));
                                            }}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div>
                                <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <Input className="form-control" type="text" placeholder="Cari email referee.."
                                            value={filters['filter[referee.email]']}
                                            onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                                            onChange={(e) => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[referee.email]': e.target.value
                                                }));
                                            }}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div>
                                <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <Input className="form-control" type="number" placeholder="Cari nomer telpon referee.."
                                            value={filters['filter[referee.phone]']}
                                            onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                                            onChange={(e) => {
                                                dispatch(setFilters({
                                                    ...filters,
                                                    'filter[referee.phone]': e.target.value
                                                }));
                                            }}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div> */}
                                <div className="job-filter mb-4">
                                    <div className="faq-form">
                                        <AsyncSelectCommon label={'Membership'} placeholder={'Cari membership..'}
                                            isMulti={false}
                                            promiseOptions={promiseOptions}
                                            handleSelectChange={(selectedOption) => handleSelectChange(selectedOption)}
                                            selectedValue={selectedValue}
                                        />
                                        <Search className="search-icon" />
                                    </div>
                                </div>
                                <div className="checkbox-animated mt-0">
                                    <div className="learning-header"><span className="f-w-600">Tipe komisi:</span></div>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Pilihlah tipe komisi.."
                                        isClearable
                                        isSearchable
                                        name="color"
                                        options={dataOptionType}
                                        onChange={handleSelectChangeStatus}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.value}
                                        value={selectedStatus}
                                    />
                                </div>
                                <div className="checkbox-animated mt-0">
                                    <div className="learning-header"><span className="f-w-600">Sort by:</span></div>
                                    <Label className="d-block" htmlFor="sort-new">
                                        <Input className="radio_animated" id="sort-new" type="radio" name="sort"
                                            checked={filters.sort === '-id'}
                                            value="-id"
                                            onChange={handleSortBy(filters, dispatch)}
                                        />
                                        {'Terbaru'}
                                    </Label>
                                    <Label className="d-block" htmlFor="sort-old">
                                        <Input className="radio_animated" id="sort-old" type="radio" name="sort"
                                            checked={filters.sort === 'id'}
                                            value="id"
                                            onChange={handleSortBy(filters, dispatch)}
                                        />
                                        {'Terlama'}
                                    </Label>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Btn attrBtn={{ color: 'primary text-center p-1', onClick: handleClickSearch }}>{Filter}</Btn>
                                    </div>
                                    <div className="col-md-6">
                                        <Btn attrBtn={{ color: 'warning text-center p-1', onClick: handleResetFilter }}>{'Reset'}</Btn>
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default FindHistory;
import React, { useState, Fragment, useEffect } from "react";
import { Post, Discard } from "../../../../Constant";
import { Btn, H5, H6, Spinner } from "../../../../AbstractElements";
import {
	Col,
	Form,
	FormGroup,
	Label,
	Input,
	Row,
	Card,
	CardBody,
} from "reactstrap";
import { submitTestimony } from "../../../../Features/Learning/Testimony/Utils/TestimonyUtils";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import { setField } from "../../../../Features/Learning/Testimony/addTestimonySlice";
import AsynchSelectUser from "../../../../Component/Users/Testimonial/AsynchSelectUser";
import AsynchSelectMembership from "../../../../Component/Users/Testimonial/AsynchSelectMembership";
import "moment/locale/id";
import id from "date-fns/locale/id";

const TestimonialPost = () => {
	registerLocale("id", id);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const data = useSelector((state) => state.addTestimony);

	const handleChanges = (e) => {
		const { name, value } = e.target;
		dispatch(setField({ field: name, value: value }));
	};

	const handleSelectChangeUser = (selectedOption) => {
		console.log(selectedOption, "selectedOption");
		dispatch(
			setField({
				field: "user_id",
				value: selectedOption === null ? "" : selectedOption,
			})
		);
	};

	const handleSelectChangeMembeship = (selectedOption) => {
		dispatch(
			setField({
				field: "membership_id",
				value: selectedOption === null ? "" : selectedOption,
			})
		);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(submitTestimony({...data, successCallback: () => navigate("/user-testimonials")}));
	};

	const handleDiscard = (e) => {
		navigate("/user-testimonials");
	};

	return (
		<Fragment>
			<Card>
				<CardBody>
					<Form className="row needs-validation">
						<Col sm="12">
							{data.isUpdate ? (
								<FormGroup className="mb-3">
									<Label for="name" style={{ fontWeight: "bold" }}>
										Nama
									</Label>
									<Input
										className="form-control"
										id="name"
										type="text"
										name="name"
										required=""
										disabled
										value={data.name}
										onChange={handleChanges}
									/>
								</FormGroup>
							) : (
								<AsynchSelectUser handleSelectChange={handleSelectChangeUser} />
							)}
							<FormGroup className="mb-3">
								<Label for="additional_info" style={{ fontWeight: "bold" }}>
									Info Tambahan tentang Siswa :
								</Label>
								<Input
									className="form-control"
									id="additional_info"
									type="text"
									name="additional_info"
									placeholder="CPNS Kejaksaan Agung..."
									required=""
									value={data.additional_info}
									onChange={handleChanges}
								/>
							</FormGroup>
							<FormGroup className="mb-3">
								<Label for="review" style={{ fontWeight: "bold" }}>
									Testimoni :
								</Label>
								<Input
									className="form-control"
									id="review"
									type="textarea"
									name="review"
									placeholder="Masukkan testimoni..."
									required=""
									value={data.review}
									onChange={handleChanges}
								/>
							</FormGroup>
							<FormGroup className="mb-3">
								<Row>
									<Col sm="12" md="12" lg="12" xl="4" className="mb-3">
										<Label
											for="validationEnded"
											style={{ fontWeight: "bold", marginRight: "5px" }}>
											Rating (1-5) :
										</Label>
										<Input
											className="form-control"
											id="rating"
											type="number"
											name="star_rating"
											placeholder="Masukkan Rating..."
											max={5}
											min={1}
											required=""
											value={data.star_rating}
											onChange={handleChanges}
										/>
									</Col>
								</Row>
							</FormGroup>
							{data.isUpdate ? (
								<FormGroup className="mb-3">
									<Label for="membership_title" style={{ fontWeight: "bold" }}>
										Membership
									</Label>
									<Input
										className="form-control"
										id="membership_title"
										type="text"
										name="membership_title"
										required=""
										disabled
										value={data.membership_title ?? "-"}
										onChange={handleChanges}
									/>
								</FormGroup>
							) : (
								<AsynchSelectMembership
									handleSelectChange={handleSelectChangeMembeship}
								/>
							)}
							<FormGroup className="mb-3">
								<Label style={{ fontWeight: "bold" }}>
									Tampilkan di Halaman Home:
								</Label>
								<div className="m-checkbox-inline">
									<Label for="show_in_home_false">
										<Input
											className="radio_animated"
											id="show_in_home_false"
											type="radio"
											name="show_in_home"
											value={0}
											checked={data.show_in_home == 0}
											onChange={handleChanges}
										/>
										TIDAK
									</Label>
									<Label for="show_in_home_true">
										<Input
											className="radio_animated"
											id="show_in_home_true"
											type="radio"
											name="show_in_home"
											value={1}
											checked={data.show_in_home == 1}
											onChange={handleChanges}
										/>
										YA
									</Label>
								</div>
							</FormGroup>
							<div className="btn-showcase text-end">
								<Btn
									attrBtn={{
										color: "primary",
										type: "submit",
										onClick: handleSubmit,
									}}>
									{Post}
								</Btn>
								<Btn
									attrBtn={{
										color: "light",
										type: "reset",
										onClick: handleDiscard,
									}}>
									{Discard}
								</Btn>
							</div>
						</Col>
					</Form>
				</CardBody>
			</Card>
		</Fragment>
	);
};

export default TestimonialPost;

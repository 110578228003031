import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import QuestionImport from '../../../Component/Learning/BankQuestion/QuestionImport';

const ImportQuestionPage = () => {
    return (
        <Fragment>
            <Breadcrumbs grandparent="Learning" parent="Bank Soal" title={'Import Soal'} />
            <QuestionImport />
        </Fragment>
    );
};

export default ImportQuestionPage;
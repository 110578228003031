import React, { Fragment, useEffect, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Card, Col, Row, CardBody, CardHeader, CardFooter, Table } from 'reactstrap';
import { H1, H2, H3, H4, H5, H6, Image, Btn, LI, P, UL, Spinner } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDetail, setFilters } from '../../../Features/Learning/BankQuestions/QuestionSlice';
import { setField } from '../../../Features/Learning/BankQuestions/addQuestionSlice';
import { 
  fetchQuestions,
  handleGetDetailQuestion,
  handleEditQuestion,
  handleDeleteQuestion,
  handleBackPagination,
  handleNextPagination,
  handleClickPagination,
} from './QuestionsUtils';
import PaginationComponent from '../Common/Pagination';
import Moment from 'moment';
import 'moment/locale/id';
import CurrencyFormat from '../Common/GenerateContent/CurrencyFormatter';
import RawHTMLComponent from '../../../Component/Learning/Common/RawHTML';
import CleanLimitHTML from '../../../Component/Learning/Common/GenerateContent/CleanLimitHtml';


const QuestionsBox = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const filters = useSelector(state => state.Question.filters)
    const isLoading = useSelector(state => state.Question.isLoading)
    const cards = useSelector(state => state.Question.data.data)
    const pagination = useSelector(state => state.Question.data.pagination)
    const [refatch, setRefatch] = useState(false)
    // console.log('cards', cards);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
      fetchQuestions(dispatch, filters, refatch)
    }, [refatch])

    const getEncryptedParams = () => {
        const encryptedParams = btoa(
            `title=${filters["filter[title]"]}&type=${filters["filter[type]"]}&sort=${filters["sort"]}`
        );
        return encryptedParams;
    }

    return (
        <Fragment>
            {isLoading ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
                </Col>
            ) : (
                cards && cards.length > 0 ? (
                    <Col sm="12 order-2">
                        <Card>
                            <CardBody>
                                <Table className="table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="bg-primary text-light">{'#'}</th>
                                            <th scope="col" className="bg-primary text-light">{'Nama Soal'}</th>
                                            <th scope="col" className="bg-primary text-light">{'Tipe'}</th>
                                            {/* <th scope="col" className="bg-primary text-light">{'Soal'}</th> */}
                                            <th scope="col" className="bg-primary text-light text-center">{'Aksi'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cards && cards.map((item, index) =>
                                                <tr key={`course-${index}`} className="table-light">
                                                    <th>{pagination.from + index}</th>
                                                    <td>{item.title}</td>
                                                    <td>
                                                      {item.type === 'fill_in_the_blanks'
                                                        ? 'Isian Singkat'
                                                        : item.type === 'multiple_choice'
                                                          ? 'Pilihan Ganda'
                                                          : item.type === 'sentence_arrangement'
                                                            ? 'Susunan Kalimat'
                                                            : 'Other'}
                                                    </td>
                                                    {/* <td>
                                                      <CleanLimitHTML htmlContent={item.description} limit={50} />
                                                    </td> */}
                                                    <td className='text-center'>
                                                        <div>
                                                            <Link to={`${process.env.PUBLIC_URL}/learning/soal-detail/${item.id}`} className="p-1" onClick={() => handleGetDetailQuestion(dispatch, item, setDetail)}>
                                                                <i className="fa fa-info-circle mx-auto fa-lg"></i>
                                                            </Link>
                                                            <Link to={`${process.env.PUBLIC_URL}/learning/soal-add/${pagination.current_page}/${index}?params=${getEncryptedParams()}`} className="p-1" onClick={() => handleEditQuestion(dispatch, item, setField)}>
                                                                <i className="fa fa-edit mx-auto text-secondary fa-lg"></i>
                                                            </Link>
                                                            <Btn
                                                                attrBtn={{
                                                                    color: 'link',
                                                                    outline: true,
                                                                    className: 'p-1',
                                                                    onClick: () => handleDeleteQuestion(dispatch, item.id, setRefatch)
                                                                }}
                                                            >
                                                                <i className="fa fa-trash-o mx-auto text-danger fa-lg"></i>
                                                            </Btn>
                                                        </div >
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                            <CardFooter>
                                {pagination && (
                                <PaginationComponent
                                    pagination={pagination}
                                    handleBackPagination={() => handleBackPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                    handleClickPagination={(page) => handleClickPagination(page, filters, dispatch, setFilters, setRefatch)}
                                    handleNextPagination={() => handleNextPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                />
                                )}
                            </CardFooter>
                        </Card>
                    </Col>
                ) : (
                    <Col lg="12">
                        <H4 attrH4={{ className: 'sub-title mb-5 text-center' }}>{!cards ? 'Sumber Data Tidak Tersedia' : 'Data Tidak Ditemukan'}</H4>
                    </Col>
                )
            )}
            
        </Fragment>
    );
};

export default QuestionsBox;
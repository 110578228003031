import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, CardHeader, CardFooter, Media, Table, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Image, H6, H5, H4, LI, UL, P, Btn, Spinner } from '../../../../AbstractElements';
import AttachModal from '../../../../Component/Learning/Common/Modal/ModalButton';
import { handleDetachCourse, handleDetachTryout, tryParseJSON } from '../../../../Component/Learning/Membership/MembershipsUtils';
import { setFieldAttachDetachCourse, resetStateAttachDetachCourse } from '../../../../Features/Learning/Memberships/attachDetachCourseSlice';
import { setFieldAttachDetachTryout, resetStateAttachDetachTryout } from '../../../../Features/Learning/Memberships/attachDetachTryoutSlice';
import { attachDetachCourseMemberships, attachDetachTryoutMemberships, fetchMemberships } from '../../../../Features/Learning/Memberships/Utils/MembershipUtils';
import { setDetail as setDetailMembership } from '../../../../Features/Learning/Memberships/membershipSlice';
import { fetchCourses } from '../../../../Features/Learning/Courses/Utils/CoursesUtils';
import { fetchCourse } from '../../../../Component/Learning/Courses/CoursesUtils';
import { fetchTryout } from '../../../../Features/Learning/Tryout/Utils/TryoutUtils';
import { fetchTryouts } from '../../../../Component/Learning/Tryout/TryoutUtils';
import CleanLimitHTML from '../../../../Component/Learning/Common/GenerateContent/CleanLimitHtml';
import GenerateUniqueContain from '../../../../Component/Learning/Common/GenerateUnique';
import AsyncSelectCommon from '../../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { setFilters } from '../../../../Features/Learning/Tryout/tryoutSlice';
import Moment from 'moment';
import 'moment/locale/id';
import Swal from 'sweetalert2';
import CurrencyFormat from '../../Common/GenerateContent/CurrencyFormatter';
import MembershipList from './DetailAttachDetachList';
import AttachTryout from'./AttachTryout';

const BoxListMembershipDetail = () => {
    const dispatch = useDispatch();
    const detail = useSelector(state => state.Membership.detail);
    const filter = useSelector(state => state.Membership.filters);
    const isLoading = useSelector(state => state.Membership.isLoading);
    const [refatch, setRefatch] = useState(false)
    //variabel for attach detach tryout
    const data = useSelector(state => state.attachDetachMembershipTryout);
    const filtersTryout = useSelector(state => state.Tryout.filters);
    const dataOptions = useSelector(state => state.Tryout.data);
    const [modal, setModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    // detail && console.log('detail', detail)
    const allIds = detail.tryouts && detail.tryouts.map(material => ({ value: material.id }));
    // detail.tryouts && console.log('allIds', allIds);
    const filteredIds = detail.tryouts && detail.tryouts.filter((materi) => {
        return !selectedMaterials.some((item) => item === materi.id);
    });
    const formatCombinedIds = filteredIds && Array.from(filteredIds).map(materialSelect => ({ value: materialSelect.id }));
    //variabel for attach detach course
    const dataCourse = useSelector(state => state.attachDetachMembershipCourse);
    const filtersCourse = useSelector(state => state.Course.filters);
    const dataOptionCourses = useSelector(state => state.Course.data);
    const [modalCourse, setModalCourse] = useState(false);
    const [selectedValueCourse, setSelectedValueCourse] = useState(null);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const allIdsCourse = detail.courses && detail.courses.map(course => ({ value: course.id }));
    const filteredIdsCourse = detail.courses && detail.courses.filter((course) => {
        return !selectedCourses.some((item) => item === course.id);
    });
    const formatCombinedIdsCourse = filteredIdsCourse && Array.from(filteredIdsCourse).map(courseSelect => ({ value: courseSelect.id }));

    useEffect(() => {
        // fetchTryouts(dispatch, filtersTryout)
        // fetchCourse(dispatch)
        dispatch(setFieldAttachDetachTryout({ field: 'id', value: detail.id }));
        dispatch(setFieldAttachDetachCourse({ field: 'id', value: detail.id }));
    }, [detail.id])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const ERROR_MESSAGES = {
        MEMBERSHIP_MINIMUM_TRYOUT: 'Membership harus memiliki minimal 1 tryout.',
        MEMBERSHIP_MINIMUM_COURSE: 'Membership harus memiliki minimal 1 course.',
        TRYOUT_ALREADY_EXISTS: 'Tryout sudah ada di membership, silahkan pilih tryout lain.',
        COURSE_ALREADY_EXISTS: 'Course sudah ada di membership, silahkan pilih course lain.',
        SELECT_TRYOUT: 'Silahkan pilih tryout yang akan dihapus.',
        SELECT_COURSE: 'Silahkan pilih course yang akan dihapus.',
        DELETE_TRYOUT: 'Tryout telah dihapus dari membership.',
        DELETE_COURSE: 'Course telah dihapus dari membership.',
        SUCCESS_TRYOUT: 'Tambah tryout ke membership.',
        SUCCESS_COURSE: 'Tambah course ke membership.',
    };

    const handleCheckboxChange = (id, type) => {
        const selectedItems = type === 'tryout' ? selectedMaterials : selectedCourses;
        const setSelectedItems = type === 'tryout' ? setSelectedMaterials : setSelectedCourses;

        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter((item) => item !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const handleToggle = (type) => {
        if (type === 'tryouts') {
            setModal(!modal);
            //handle attach detach tryout
            dispatch(setFieldAttachDetachTryout({ field: 'id', value: detail.id }));
            dispatch(setFieldAttachDetachTryout({ field: type, value: [] }));
            setSelectedValue(null);
            setSelectedMaterials([]);
        } else if (type === 'courses') {
            setModalCourse(!modalCourse);
            //handle attach detach course
            dispatch(setFieldAttachDetachCourse({ field: 'id', value: detail.id }));
            dispatch(setFieldAttachDetachCourse({ field: type, value: [] }));
            setSelectedValueCourse(null);
            setSelectedCourses([]);
        }
    };

    const fetchData = async (dispatch, filters, inputValue, callback, fetchAction, type) => {
        const fetchDataFromPage = async (page) => {
            try {
                const response = await dispatch(fetchAction({
                    ...filters,
                    'filter[title]': inputValue,
                    'sort': 'title',
                    'page': page,
                }));
                return response.payload;
            } catch (error) {
                console.error('Error fetching options:', error);
                return [];
            }
        };

        const fetchDataRecursively = async (page) => {
            const dataDetailTryout = type === 'tryouts' ? detail.tryouts : detail.courses;
            const responsePayload = await fetchDataFromPage(page);
            const filteredPayload = responsePayload.data.filter(item => !dataDetailTryout.some(dataItem => dataItem.id === item.id));

            if (filteredPayload.length > 0) {
                const options = filteredPayload.map(item => ({
                    value: item.id,
                    label: item.title,
                }));
                callback(options);
            } else {
                // console.log(responsePayload);
                const { pagination } = responsePayload;
                // console.log('pagination', pagination);  
                const totalPages = pagination.last_page;
                // console.log('totalPages', totalPages);
                if (page < totalPages) {
                    await fetchDataRecursively(page + 1);
                } else {
                    callback([]);
                }
            }
        };

        // Mulai dengan halaman pertama
        await fetchDataRecursively(1);
    };


    const promiseOptions = (inputValue, callback) => {
        fetchData(dispatch, filtersTryout, inputValue, callback, fetchTryout,'tryouts');
    };

    const promiseOptionsCourse = (inputValue, callback) => {
        fetchData(dispatch, filtersCourse, inputValue, callback, fetchCourses,'courses');
    };

    const handleSelectChange = (selectedOption, field, allIds, dispatch, setFieldAction, errorMessage) => {
        const setSelectedChange = field === 'tryouts' ? setSelectedValue : setSelectedValueCourse;
        setSelectedChange(selectedOption);

        if (selectedOption === null) {
            dispatch(setFieldAction({ field, value: [] }));
        } else {
            selectedOption.forEach(option => {
                const idExists = allIds.some(item => item.value === option.value);
                if (idExists) {
                    Swal.fire('Gagal!', errorMessage, 'error');
                    setSelectedChange(selectedOption.slice(0, -1));
                } else {
                    setSelectedChange(selectedOption);
                    const combinedIdsSet = new Set([...allIds.map(item => item.value), ...selectedOption.map(item => item.value)]);
                    const combinedIds = Array.from(combinedIdsSet).map(value => ({ value }));
                    dispatch(setFieldAction({ field, value: combinedIds }));
                }
            });
        }
    };

    const handleSubmit = async (data, modalState, modalSetter, successMessage) => {
        try {
            const response = await dispatch(data);

            if (response.payload === true) {
                modalSetter(!modalState);
                const getMembership = await dispatch(fetchMemberships({
                    ...filter,
                    'filter[id]': detail.id,
                    'sort': '',
                    'page': '',
                }));

                dispatch(setDetailMembership(getMembership.payload.data[0]));
                Swal.fire('Berhasil!', successMessage, 'success');
            }
        } catch (error) {
            console.error('Error:', error);
            // Modal tidak akan ditutup jika terjadi kesalahan
        }
    };

    return (
        <Fragment>
            <Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
                <Card className="social-widget-card">
                    <CardHeader>
                        <Col>
                            <Row>
                                <Col>
                                    <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>{detail.title || 'Title Membership'}</H5>
                                </Col>
                                <Col className='text-end'>
                                    <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>ID Membership : {GenerateUniqueContain('MBS', detail.id, 5) || 'ID Membership'}</H5>
                                </Col>
                            </Row>
                        </Col>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mt-2' }}>{'Harga'}</H5>
                                            <H6 attrH6={{ className: 'font-roboto mb-0 font-primary' }}><CurrencyFormat amount={detail.price} /></H6>
                                        </Col>
                                        <Col>
                                            <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mt-2' }}>{'Harga Coret'}</H5>
                                            <H6 attrH6={{ className: 'font-roboto mb-0 font-primary' }}>{detail.gimmick_price ? <CurrencyFormat amount={detail.gimmick_price} /> : '-'}</H6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mt-2' }}>{'Masa Aktif Membership'}</H5>
                                    <H6 attrH6={{ className: 'font-roboto mb-0 font-primary' }}>{detail.duration_days} Hari</H6>
                                </Col>
                                <Col>
                                    <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mt-2' }}>{'Jenis Membership'}</H5>
                                    <H6 attrH6={{ className: 'font-roboto mb-0 font-primary' }}>
                                        {detail.is_online === true ? 'Online ' : 'Offline '}
                                        {detail.is_to_only === true ? '(Tryout Only)' : ''}
                                    </H6>
                                </Col>
                                <Col>
                                    <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mt-2' }}>{'Garansi Membership'}</H5>
                                    <H6 attrH6={{ className: 'font-roboto mb-0 font-primary' }}>
                                        {detail.is_guaranteed === true ? 'Tersedia ' : 'Tidak Tersedia '}
                                    </H6>
                                </Col>
                                <Col>
                                    <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mt-2' }}>{'Kategori Program Utama'}</H5>
                                    <H6 attrH6={{ className: 'font-roboto mb-0 font-primary' }}>{detail.main_program ? detail.main_program.title : '-'}</H6>
                                </Col>
                            </Col>
                        <Col>
                            <H5 attrH5={{ className: 'counter mt-4 font-roboto font-primary mb-2' }}>{'Fasilitas Membership'}</H5>
                            {detail.points && detail.points.length > 0 ? (
                                <UL attrUl={{ className: 'list-unstyled mt-2 mb-0' }}>
                                    {detail.points.map((item, index) => {
                                        const isDataJSON = tryParseJSON(item); // Panggil fungsi isDataJSON di sini
                                        const pointName = isDataJSON ? JSON.parse(item).name : item;
                                        const pointStatus = isDataJSON ? JSON.parse(item).status : true;

                                        return (
                                        <LI key={`points-${index}`} attrLI={{ className: 'd-flex mb-2' }}>
                                            <i className={`fa fa-${pointStatus ? 'check' : 'times'} fa-lg me-2`} style={{ color: pointStatus ? 'green' : 'red' }}></i>
                                            <b>{pointName}</b>
                                        </LI>
                                        )
                                        })}
                                </UL>
                            ) : (
                                <P attrP={{ className: 'mb-0 font-roboto' }}><b>Tidak ada fasilitas.</b></P>
                            )}
                        </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col>
                                <P attrP={{ className: 'font-roboto' }}>Created at: {Moment(detail.created_at).format('dddd, D MMMM YYYY')} - {Moment(detail.created_at).format('HH:mm:ss')} WIB</P>
                                <P attrP={{ className: 'font-roboto' }}><b>Updated at: {Moment(detail.updated_at).format('dddd, D MMMM YYYY')} - {Moment(detail.updated_at).format('HH:mm:ss')} WIB</b></P>
                            </Col>
                            <Col className='text-end'>
                                <Link to={`${process.env.PUBLIC_URL}/membership/membership-list`}>
                                    <Btn attrBtn={{ color: 'warning', className: 'btn btn-primary m-r-10' }}>
                                        <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                                    </Btn>
                                </Link>
                                <AttachModal colorBtn={'primary'} buttonLabel={'Tambah Tryout'} toggle={() => handleToggle('tryouts')} modalLabel={'Tambah tryout ke membership'} modal={modal} handleSubmit={() => {
                                    handleSubmit(
                                        attachDetachTryoutMemberships(data),
                                        modal,
                                        setModal,
                                        ERROR_MESSAGES.SUCCESS_TRYOUT
                                    );
                                }} >
                                    <AsyncSelectCommon label={'Tryout'} placeholder={'Silahkan pilih Tryout...'} 
                                    isMulti={true}
                                    promiseOptions={promiseOptions}
                                    handleSelectChange={(selectedOption) => handleSelectChange(selectedOption, 'tryouts', allIds, dispatch, setFieldAttachDetachTryout, ERROR_MESSAGES.TRYOUT_ALREADY_EXISTS)}
                                    selectedValue={selectedValue}
                                    />
                                    {data.isLoading && (
                                        <Col lg="12">
                                            <div className="loader-box">
                                                <Spinner attrSpinner={{ className: 'loader-30' }} />
                                            </div>
                                            <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses penambahan tryout...'}</H6>
                                        </Col>
                                    )}
                                </AttachModal>
                                {/* {detail && detail.is_to_only === false && ( */}
                                    <AttachModal colorBtn={'light'} buttonLabel={'Tambah Course'} toggle={() => handleToggle('courses')} modalLabel={'Tambah course ke membership'} modal={modalCourse} handleSubmit={() => {
                                        handleSubmit(
                                            attachDetachCourseMemberships(dataCourse),
                                            modalCourse,
                                            setModalCourse,
                                            ERROR_MESSAGES.SUCCESS_COURSE
                                        );
                                    }} >
                                        <AsyncSelectCommon label={'Course'} placeholder={'Silahkan pilih Course...'}
                                            isMulti={true}
                                            promiseOptions={promiseOptionsCourse}
                                            handleSelectChange={(selectedOption) => handleSelectChange(selectedOption, 'courses', allIdsCourse, dispatch, setFieldAttachDetachCourse, ERROR_MESSAGES.COURSE_ALREADY_EXISTS)}
                                            selectedValue={selectedValueCourse}
                                        />
                                        {dataCourse.isLoading && (
                                            <Col lg="12">
                                                <div className="loader-box">
                                                    <Spinner attrSpinner={{ className: 'loader-30' }} />
                                                </div>
                                                <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses penambahan course...'}</H6>
                                            </Col>
                                        )}
                                    </AttachModal>
									<AttachTryout items={detail.tryouts} membershipId={detail.id} />
                                {/* // )} */}
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Col>
            {isLoading ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
                </Col>
            ) : (
                detail.tryouts && detail.tryouts.length > 0 && (
                    <MembershipList
                        title="tryouts"
                        data={data}
                        items={detail.tryouts}
                        itemInverse={detail.courses}
                        detail={detail}
                        selectedItems={selectedMaterials}
                        handleCheckboxChange={(itemId) => handleCheckboxChange(itemId, 'tryout')}
                        handleDetach={handleDetachTryout}
                        formatCombinedIds={formatCombinedIds}
                        errorMessage={ERROR_MESSAGES}
                        setFieldAction={setFieldAttachDetachTryout}
                        dispatch={dispatch}
                        setDetailMembership={setDetailMembership}
                    />
                )
            )}
            {isLoading ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
                </Col>
            ) : (
                detail.courses && detail.courses.length > 0 && (
                    <MembershipList
                        title="courses"
                        data={dataCourse}
                        detail={detail}
                        items={detail.courses}
                        itemInverse={detail.tryouts}
                        selectedItems={selectedCourses}
                        handleCheckboxChange={(itemId) => handleCheckboxChange(itemId, 'course')}
                        handleDetach={handleDetachCourse}
                        formatCombinedIds={formatCombinedIdsCourse}
                        errorMessage={ERROR_MESSAGES}
                        setFieldAction={setFieldAttachDetachCourse}
                        dispatch={dispatch}
                        setDetailMembership={setDetailMembership}
                    />
                )
            )}
        </Fragment >
    );
};
export default BoxListMembershipDetail;
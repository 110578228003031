import { createSlice } from '@reduxjs/toolkit';
import { fetchMainProgram } from './Utils/MainProgramUtils';

const MainProgramSlice = createSlice({
    name : 'MainPrograms',
    initialState : {
        filters : {
            'filter[category_id]' : '',
            'filter[title]' : '',
            'sort' : '',
            'include' : 'category,membership',
        },
        data : [],
        detail : {},
        isLoading : false,
    },
    reducers : {
        setFilters : (state, action) => {
            state.filters = action.payload;
        },
        setData : (state, action) => {
            state.data = action.payload;
        },
        setDetail : (state, action) => {
            state.detail = action.payload;
        }
    },
    extraReducers : (builder) => {
        builder
            .addCase(fetchMainProgram.pending, (state) => {
                // Handle loading state
                state.isLoading = true;
            })
            .addCase(fetchMainProgram.fulfilled, (state, action) => {
                // Handle success state
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchMainProgram.rejected, (state, action) => {
                // Handle error state
                state.isLoading = false;
                console.log('error', action);
            });
    }
    
});

export const { setFilters, setData, setDetail } = MainProgramSlice.actions;

export default MainProgramSlice.reducer;
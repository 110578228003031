import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, Input } from "reactstrap";
import { handleWithdrawReferral } from "./ReferralMutationUtils";
import { useDispatch } from "react-redux";
import { Fragment } from "react";
import Swal from "sweetalert2";

const ReferralWithdrawPopup = ({ id, setRefatch, refatch }) => {
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const [amount, setAmount] = useState(0);

	const handleWithdraw = () => {
		Swal.fire({
			title: `Penarikan sebesar ${amount} akan dilakukan`,
			text: "Perintah ini tidak dapat dibatalkan",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Ya, Tarik Saldo",
			cancelButtonText: "Batal",
		}).then(async (result) => {
			if (result.isConfirmed) {
				console.log("Withdraw", amount, id);
				handleWithdrawReferral(dispatch, id, amount);
				setShow(!show);
				setRefatch(!refatch);
			}
		});
	};

	return (
		<>
			<Button
				color="primary"
				className="btn btn-primary mb-3"
				style={{ cursor: "pointer" }}
				onClick={() => setShow(!show)}>
				Tarik Saldo
			</Button>
			<Modal isOpen={show} toggle={() => setShow(!show)} size="lg">
				<Fragment>
					<ModalHeader toggle={() => setShow(!show)}>
						<h5 className="text-center">Tarik Saldo</h5>
					</ModalHeader>
					<ModalBody>
						<div className="popup-body">
							<div className="popup-content">
								<div className="popup-text">
									<p className="">Jumlah Penarikan</p>
									<Input
										type="number"
										onChange={(e) => setAmount(e.target.value)}
									/>
									<div className="text-end mt-2">
										<Button
											color="primary"
											className="btn btn-primary"
											onClick={handleWithdraw}>
											Tambah History Penarikan
										</Button>
									</div>
								</div>
							</div>
						</div>
					</ModalBody>
				</Fragment>
			</Modal>
		</>
	);
};

export default ReferralWithdrawPopup;

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AsyncSelectCommon from '../../Learning/Common/SelectOption/AsyncSelect.jsx';
import { fetchUsers } from '../../../Features/Learning/Users/Utils/UserUtils.jsx';
import { fetchMainProgram } from '../../../Features/Learning/MainPrograms/Utils/MainProgramUtils';

const AsynchSelectMainProgram = ({ handleSelectChange, selectedMainProgram }) => {
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        setSelectedValue(selectedMainProgram.map(item => ({
            value: item.id ?? item.value,
            label: item.title ?? item.label,
        })));
    }, [selectedMainProgram]);

    const promiseOption = (inputValue, callback) => {
        dispatch(fetchMainProgram({
            'filter[title]': inputValue,
            'page': 1,
        }))
            .then((response) => {
                const options = response.payload.data.map(item => ({
                    value: item.id,
                    label: item.title,
                }));
                callback(options);
            })
            .catch((error) => {
                console.error('Error fetching options:', error);
                callback([]);
            });
    };

    return (
        <Fragment>
            <AsyncSelectCommon 
                label={'Pilih Main Program'}
                placeholder={'Silahkan pilih Main Program'}
                isMulti={true}
                promiseOptions={promiseOption}
                handleSelectChange={(selectedOption) => {
                    console.log(selectedOption, "selectedOption");
                    setSelectedValue(selectedOption === null ? '' : selectedOption)
                    handleSelectChange(selectedOption === null ? '' : selectedOption);
                }}
                selectedValue={selectedValue}
            />
        </Fragment>
    );
}

export default AsynchSelectMainProgram;
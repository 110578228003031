import { createAsyncThunk } from '@reduxjs/toolkit';
import { CategoriesService } from '../../../../Services/axios/Categories.Service';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";

export const fetchCategories = createAsyncThunk(
  'Categories/fetchCategories',
  async (filters) => {
    try {
      const response = await CategoriesService.getAll(filters);
      return response.data;
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        let errorMessage = 'Terjadi kesalahan saat mengambil data.';

        if (statusCode === 400) {
          errorMessage = 'Permintaan tidak valid.';
        } else if (statusCode === 401) {
          errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
        } else if (statusCode === 404) {
          errorMessage = 'Sumber daya tidak ditemukan.';
        } else if (statusCode === 500) {
          errorMessage = 'Terjadi kesalahan internal server.';
        } else if (statusCode === 0) {
          errorMessage = 'Tidak ada koneksi internet.';
        }

        Swal.fire({
          icon: 'error',
          title: statusCode === 0 ? 'Error' : `Error ${statusCode}`,
          text: errorMessage,
        });
      } else if (error.request) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
        });
      }
      throw error;
    }
  }
);

export const getMainProgramCategory = createAsyncThunk(
  'Categories/getMainProgramCategory',
  async () => {
    try {
      const response = await CategoriesService.getMainProgram();
      return response.data;
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        let errorMessage = 'Terjadi kesalahan saat mengambil data.';

        if (statusCode === 400) {
          errorMessage = 'Permintaan tidak valid.';
        } else if (statusCode === 401) {
          errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
        } else if (statusCode === 404) {
          errorMessage = 'Sumber daya tidak ditemukan.';
        } else if (statusCode === 500) {
          errorMessage = 'Terjadi kesalahan internal server.';
        } else if (statusCode === 0) {
          errorMessage = 'Tidak ada koneksi internet.';
        }

        Swal.fire({
          icon: 'error',
          title: statusCode === 0 ? 'Error' : `Error ${statusCode}`,
          text: errorMessage,
        });
      } else if (error.request) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
        });
      }
      throw error;
    }
  }
);

export const submitCategories = createAsyncThunk(
  'addCategories/submitCategories',
  async (data) => {
    try {
      const formData = new FormData();
      formData.append('title', data.title);
      data.icon && formData.append('icon', data.icon);
      formData.append('is_parent', data.is_parent);
      data.parent_id && formData.append('parent_id', data.parent_id);
      formData.append('is_question', data.is_question);
      formData.append('is_main_program', data.is_main_program);
      data.value > 0 && formData.append('value', data.value);
      data.max_score > 0 && formData.append('max_score', data.max_score);
      let response;
      if (data.isUpdate) {
        response = await CategoriesService.update(data.id, formData);
      } else {
        response = await CategoriesService.create(formData);
      }
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      data.successCallback && data.successCallback();
      return response.data.data;
    } catch (error) {
      for (const errorField in error.response.data.errors) {
        if (error.response.data.errors.hasOwnProperty(errorField)) {
          const errorMessages = error.response.data.errors[errorField];
          let errorMessage = errorMessages[0];
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
          });
        }
      }
      if (error.code === "ERR_NETWORK") {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Terjadi kesalahan sistem. Mohon hubungi pengembang.',
        });
      }
    }
  }
);

export const deleteCategories = createAsyncThunk(
  'deleteCategories/deleteCategories',
  async (id) => {
    try {
      const response = await CategoriesService.delete(id);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }
);



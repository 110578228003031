import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PlusCircle } from "react-feather";
import {
	Card,
	Col,
	Row,
	CardBody,
	CardHeader,
	CardFooter,
	Table,
	Label,
} from "reactstrap";
import {
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
	Image,
	Btn,
	LI,
	P,
	UL,
	Spinner,
	Badges,
} from "../../../../AbstractElements";
import {
	setFilters,
	setDetail,
} from "../../../../Features/Learning/Users/historyUserReferralCompactSlice";
import {
	fetchHistoryReferralsCompacts,
	handleBackPagination,
	handleNextPagination,
	handleClickPagination,
} from "./ReferrerListUtils";
import CurrencyFormat from "../../Common/GenerateContent/CurrencyFormatter";
import PaginationComponent from "../../Common/Pagination";
import Moment from "moment";
import "moment/locale/id";

const ReferrerListTable = () => {
	const dispatch = useDispatch();
	const filters = useSelector(
		(state) => state.historyUserReferralCompact.filters
	);
	const isLoading = useSelector(
		(state) => state.historyUserReferralCompact.isLoading
	);
	const cards = useSelector(
		(state) => state.historyUserReferralCompact.data.data
	);
	const pagination = useSelector(
		(state) => state.historyUserReferralCompact.data.pagination
	);
	const [refatch, setRefatch] = useState(false);

	useEffect(() => {
		fetchHistoryReferralsCompacts(dispatch, filters, refatch);
		window.scrollTo(0, 0);
	}, [refatch]);

	return (
		<Fragment>
			{isLoading ? (
				<Col lg="12">
					<div className="loader-box">
						<Spinner attrSpinner={{ className: "loader-30" }} />
					</div>
					<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
						{"Data Loading..."}
					</H6>
				</Col>
			) : (
				cards &&
				cards.length > 0 && (
					<Col sm="12 order-2">
						<Card>
							<CardBody>
								<Table className="table-bordered table-striped table-hover">
									<thead>
										<tr>
											<th
												scope="col"
												className="bg-primary text-light"
												style={{ verticalAlign: "middle" }}>
												#
											</th>
											<th
												scope="col"
												className="bg-primary text-light"
												style={{ verticalAlign: "middle" }}>
												Nama Referrer
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ width: "15%", verticalAlign: "middle" }}>
												Saldo
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ width: "10%", verticalAlign: "middle" }}>
												Jumlah Order
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ width: "10%", verticalAlign: "middle" }}>
												Jumlah Referee
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ width: "15%", verticalAlign: "middle" }}>
												Total Komisi
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ width: "15%", verticalAlign: "middle" }}>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{cards.map((item, index) => (
											<tr key={index}>
												<th>{pagination.from + index}</th>
												<td>
													{item.name}
													<Link
														to={`${process.env.PUBLIC_URL}/users/user-detail/${item.id}`}
														title="Lihat detail user"
														className="p-1 ms-2">
														<i className="fa fa-info-circle mx-auto fa-lg"></i>
													</Link>
													({item.referral_code})
													<br />
													<b>
														<a
															href={`https://wa.me/62${item.phone}?text=Halo%20kak%20${item.name},%20saya%20ingin%20bertanya%20terkait..`}
															target="_blank"
															rel="noreferrer"
															title="Hubungi via whatsapp">
															{item.phone.replace(
																/(\d{4})(\d{4})(\d{4})/,
																"$1 $2 $3"
															)}
														</a>
													</b>
												</td>
												<td className="text-center">
                                                    <Badges attrBadge={{ color: "primary" }}>
                                                        <CurrencyFormat amount={item.referral_amount} />
                                                    </Badges>
												</td>
												<td className="text-center">
													<Link
														to={`${process.env.PUBLIC_URL}/users/referral-order/${item.id}`}
														title="Lihat detail order">
														<h4>
															<Badges attrBadge={{ color: "primary" }}>
																{item.order_paid}
															</Badges>
														</h4>
													</Link>
												</td>
												<td className="text-center">
													<h4>
														<Badges attrBadge={{ color: "warning" }}>
															{item.total_referral_user}
														</Badges>
													</h4>
												</td>
												<td className="text-right">
													<CurrencyFormat amount={item.sum_order_paid} />
												</td>
												<td className="text-center">
													<Link
														to={`${process.env.PUBLIC_URL}/users/referral-mutation/${item.id}`}
														title="Lihat laporan komisi">
														<h4>
															<Badges attrBadge={{ color: "primary" }}>
																<i className="fa fa-info-circle mx-auto fa-lg"></i>
															</Badges>
														</h4>
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</CardBody>
							<CardFooter>
								<PaginationComponent
									pagination={pagination}
									handleBackPagination={() =>
										handleBackPagination(
											pagination,
											filters,
											dispatch,
											setFilters,
											setRefatch
										)
									}
									handleNextPagination={() =>
										handleNextPagination(
											pagination,
											filters,
											dispatch,
											setFilters,
											setRefatch
										)
									}
									handleClickPagination={(page) =>
										handleClickPagination(
											page,
											filters,
											dispatch,
											setFilters,
											setRefatch
										)
									}
								/>
							</CardFooter>
						</Card>
					</Col>
				)
			)}

			{(!cards || cards.length === 0) && isLoading === false && (
				<Col lg="12">
					<H3 attrH3={{ className: "sub-title mb-5 text-center" }}>
						{!cards ? "Sumber Data Tidak Tersedia" : "Data Tidak Ditemukan"}
					</H3>
				</Col>
			)}
		</Fragment>
	);
};

export default ReferrerListTable;

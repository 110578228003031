import { createSlice } from '@reduxjs/toolkit';
import { fetchQuestion } from './Utils/QuestionsUtils';

const QuestionSlice = createSlice({
  name: 'Question',
  initialState: {
    filters: {
      'filter[description]': '',
      'filter[title]': '',
      'filter[type]': '',
      'filter[id]': '',
      'sort': '',
      'include[0]': 'answer',
      'include[1]': 'category',
      'page': 1,
    },
    data: [],
    detail: {},
    isLoading: false,
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDetail: (state, action) => {
      state.detail = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestion.pending, (state) => {
        // Handle loading state
        state.isLoading = true;
      })
      .addCase(fetchQuestion.fulfilled, (state, action) => {
        // Handle success state
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchQuestion.rejected, (state, action) => {
        // Handle error state
        console.log(action.error.message, "error action")
        state.isLoading = false;
      });
  }
});

export const { setFilters, setData, setDetail } = QuestionSlice.actions;

export default QuestionSlice.reducer;

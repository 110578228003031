import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import QRCodePresenceComponent from '../../../Component/Classes/Class/QRCodePresence';

const QRPresenceClass = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Kelas" title="QR Code Presensi" />
            <QRCodePresenceComponent />
        </Fragment>
    );
};
export default QRPresenceClass;
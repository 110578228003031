import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import MainProgramDetailContain from '../../../Component/Learning/MainProgram/Detail';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMainProgram } from '../../../Features/Learning/MainPrograms/Utils/MainProgramUtils';
import { handleGetDetailMembership } from '../../../Component/Learning/Membership/MembershipsUtils';
import { setDetail } from '../../../Features/Learning/MainPrograms/mainProgramSlice';


const MainProgramDetailPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.MainProgram.filters);
    const dataField = useSelector(state => state.MainProgram.detail);

    useEffect(() => {
        if (id && !dataField.id) {
            const newFilters = {
                ...filters,
                "filter[id]": id,
                "page": 1,
            };
            dispatch(fetchMainProgram(newFilters))
                .then((response) => {
                    // Kode yang akan dijalankan setelah fetch sukses
                    handleGetDetailMembership(dispatch, response.payload.data[0], setDetail)
                })
                .catch((error) => {
                    // Tangani error jika fetch gagal
                    console.error('Error during fetchMainProgram:', error);
                });
        }
    }, [id]);

    return (
        <Fragment>
            <Breadcrumbs parent="Program Utama" title="Detail Program" />
            <MainProgramDetailContain />
        </Fragment>
    );
};
export default MainProgramDetailPage;
import { createSlice } from '@reduxjs/toolkit';
import { setOrderStatus } from './Utils/TransactionUtils';

const initialState = {
    id: '',
    status: '',
    isLoading: false,
};

const setOrderStatusSlice = createSlice(
    {
        name: 'setOrderStatus',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(setOrderStatus.pending, (state) => {
                    // Handle loading state
                    state.isLoading = true;
                })
                .addCase(setOrderStatus.fulfilled, (state, action) => {
                    // Handle success state
                    state.isLoading = false;
                    // console.log('Sukses:', action.payload);
                })
                .addCase(setOrderStatus.rejected, (state, action) => {
                   console.log(action.error.message, "error action")
                   state.isLoading = false;
                });
        }
    }
);


export const { setField, resetState } = setOrderStatusSlice.actions;

export default setOrderStatusSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';
import { attachUserMemberships } from './Utils/MembershipUtils';

const initialState = {
    id : '',
    user_id : '',
    isLoading: false,
};

const attachUserSlice = createSlice(
    {
        name: 'attachUser',
        initialState: initialState,
        reducers: {
            setFieldAttachUser: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetStateAttachUser: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
            .addCase(attachUserMemberships.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(attachUserMemberships.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(attachUserMemberships.rejected, (state, action) => {
                state.isLoading = false;
                console.log(action.error.message, "error action")
            })
        }
    }
);

export const { setFieldAttachUser, resetStateAttachUser } = attachUserSlice.actions;

export default attachUserSlice.reducer;
import React, { Fragment, useEffect, useState } from "react";
import {
	Col,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Media,
	Table,
	Row,
} from "reactstrap";
import {
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
	Image,
	Btn,
	LI,
	P,
	UL,
	Spinner,
	Badges,
} from "../../../../AbstractElements";
import { useSelector, useDispatch } from "react-redux";
import { resetQRCode } from "../../../../Features/Classes/Schedule/QRPresenceSlice";
import { fetchQRCode } from "../../../../Features/Classes/Schedule/Utils/ScheduleUtils";
import QRCode from "react-qr-code";

const QRCodePresenceComponent = () => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.QRCode.data);
	const isLoading = useSelector((state) => state.QRCode.isLoading);
	const [timer, setTimer] = useState(60);
	const [refatch, setRefatch] = useState(false);
	const [sixLastDigit, setSixLastDigit] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (data.QR_Code) {
			const countdown = setInterval(() => {
				setTimer((prevTimer) => prevTimer - 1);
			}, 1000);
			setSixLastDigit(data.QR_Code.slice(-6));

			return () => clearInterval(countdown);
		}
	}, [data.QR_Code]);

	useEffect(() => {
		if (timer === 0) {
			setTimer(60); // Reset the timer
			setRefatch(!refatch); // Trigger refetch
		}
	}, [timer]);

	useEffect(() => {
		dispatch(fetchQRCode());
	}, [refatch]);

	return (
		<Fragment>
			{isLoading ? (
				<Col lg="12">
					<div className="loader-box">
						<Spinner attrSpinner={{ className: "loader-30" }} />
					</div>
					<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
						{"Data Loading..."}
					</H6>
				</Col>
			) : (
				data.QR_Code && (
					<Card>
						<CardHeader className="pb-0">
							<h2 className="text-center">SCAN ME</h2>
						</CardHeader>
						<CardBody>
							<Col md="12" className="text-center">
								<QRCode
									value={data.QR_Code}
									style={{ height: "auto", width: "30%" }}
								/>
							</Col>
							<Col md="12" className="text-center">
								<h1 className="text-center">{sixLastDigit}</h1>
								{data.QR_Code && (
									<p> This QR code will expire in {timer} seconds</p>
								)}
							</Col>
						</CardBody>
					</Card>
				)
			)}
		</Fragment>
	);
};

export default QRCodePresenceComponent;

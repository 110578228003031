import { createSlice } from '@reduxjs/toolkit';
import { fetchUsersFiltered } from './Utils/UserUtils';

const UserFilterSlice = createSlice({
    name : 'UserFiltered',
    initialState : {
        filters: {
            "filter[id]": "",
            "filter[name]": "",
            "filter[username]": "",
            "filter[email]": "",
            "filter[gender]": "",
            "filter[phone]": "",
            "filter[address]": "",
            "filter[order.status]": "",
            "filter[order.payment_method]": "",
            "filter[membership.title]": "",
            "filter[membership.id]": "",
            "sort": "",
            "include": "order,membership",
            "page": "",
        },
        data: [],
        // detail: {},
        isLoading: false,
    },
    reducers : {
        setFilterUsers : (state, action) => {
            state.filters = action.payload;
        },
        setFilterData : (state, action) => {
            state.data = action.payload;
        },
        // setDetail : (state, action) => {
        //     state.detail = action.payload;
        // },
    },
    extraReducers : (builder) => {
        builder
        .addCase(fetchUsersFiltered.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchUsersFiltered.fulfilled, (state, action) => {
            state.isLoading = false;
            // console.log('status fulfilled', action.payload)
            state.data = action.payload;
            // console.log('data', state.data)
        })
        .addCase(fetchUsersFiltered.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export const { setFilterUsers, setFilterData } = UserFilterSlice.actions;

export default UserFilterSlice.reducer;
import React, { Fragment, useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import ClassDetail from '../../../../Component/Classes/Class/ClassDetail';
import DetailScheduleClassContain from '../../../../Component/Classes/Class/DetailScheduleClass/DetailScheduleClassContain';

const DetailScheduleClass = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <Container fluid={true}>
                <div>
                    <Row className="product-page-main p-0">
                        <ClassDetail type="schedule" />
                        <DetailScheduleClassContain />
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};

export default DetailScheduleClass;
import React, { Fragment, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs } from '../../../../AbstractElements';
import DetailUserProgressContain from '../../../../Component/Classes/Schedule/DetailSchedule/DetailUserProgress';
import { handleGetDetailSchedule } from '../../../../Component/Classes/Schedule/ScheduleUtils';
import { setDetail } from '../../../../Features/Classes/Schedule/ScheduleSlice';
import { fetchSchedules } from '../../../../Features/Classes/Schedule/Utils/ScheduleUtils';

const DetailScheduleUserProgressPage = () => {
    const { classId, userId } = useParams();
    // const dispatch = useDispatch();
    // const filters = useSelector(state => state.Schedule.filters)
    // const detailSchedule = useSelector(state => state.Schedule.detail)

    // useEffect(() => {
    //     if (!detailSchedule.id && scheduleId) {
    //         const newFilters = {
    //             ...filters,
    //             "filter[id]": scheduleId,
    //             "page": 1,
    //         };
    //         dispatch(fetchSchedules(newFilters))
    //             .then((response) => {
    //                 handleGetDetailSchedule(dispatch, response.payload.data[0], setDetail)
    //             })
    //             .catch((error) => {
    //                 console.error('Error during fetchClasses:', error);
    //             });
    //     }
    // }, [scheduleId])
    return (
        <Fragment>
            <Breadcrumbs grandparent={<Link to={`${process.env.PUBLIC_URL}/classes/class-list`}>Kelas</Link>} parent={<Link to={`${process.env.PUBLIC_URL}/classes/class-user-detail/${classId}`}>Detail Siswa Kelas</Link>} title="Progress Sesi" />
            <DetailUserProgressContain />
        </Fragment>
    );
};
export default DetailScheduleUserProgressPage;
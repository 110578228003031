import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "reactstrap";
import { Breadcrumbs, Spinner, H6 } from "../../../../AbstractElements";
import TestimonialPost from "./TestimonialPost";
import { handleEditSchedule } from "../../../../Component/Classes/Schedule/ScheduleUtils";
import { handleEditTestimony, fetchTestimony } from "../../../../Features/Learning/Testimony/Utils/TestimonyUtils";
import {
	setField,
	resetState,
} from "../../../../Features/Learning/Testimony/addTestimonySlice";
// import { fetchTestimony } from "../../../../Features/Learning/Testimony/Utils/TestimonyUtils";
import { fetchSchedules } from "../../../../Features/Classes/Schedule/Utils/ScheduleUtils";

const TestimonialAdd = () => {
	const { id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
        if (id) {
            const newFilters = {
                "filter[id]": id,
            };
            dispatch(fetchTestimony(newFilters))
            .then((response) => {
                handleEditTestimony(dispatch, response.payload.data[0], setField)
            })
            .catch((error) => {
                // Tangani error jika fetch gagal
                console.error('Error during handleEditTestimony:', error);
            });
        } else {
            dispatch(resetState());
        }
    }, [id]);

	return (
		<Fragment>
			<Breadcrumbs
				parent="Testimonial"
				title={id ? "Update Testimonial" : "Tambah Testimonial"}
			/>
			<TestimonialPost />
		</Fragment>
	);
};
export default TestimonialAdd;

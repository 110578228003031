import React, { Fragment } from 'react';
import { PlusCircle } from 'react-feather';
import { Container, Row, Col, Card } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Btn } from '../../../AbstractElements';
import MateriBox from './MateriBox';
import { useDispatch, useSelector } from 'react-redux';
import MateriFilter from './Filter/MateriFilter';
import { resetState } from '../../../Features/Learning/Materi/addMaterialSlice';


const MateriContain = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const cards = useSelector(state => state.Material.data.data)

  const handleAdd = (e) => {
    dispatch(resetState());
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Col md="12" className="project-list">
          {cards && (
          <Card>
            <Row>
              <Col>
                <Link to={`${process.env.PUBLIC_URL}/learning/materi-add`} onClick={handleAdd}>
                  <Btn attrBtn={{ color: 'primary' }}>
                    <i className="fa fa-plus-circle fa-lg"></i> Buat Materi Baru
                  </Btn>
                </Link>
              </Col>
            </Row>
          </Card>
          )}
        </Col>
        <Row className='learning-block'>
          <Col xl="9" className='xl-80'>
            <Row>
              <MateriBox />
            </Row>
          </Col>
          {cards && (
          <MateriFilter />
          )}
        </Row>
      </Container>
    </Fragment>
  );
};
export default MateriContain;
import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import TryoutPostContain from '../../../Component/Learning/Tryout/TryoutPost';
import { handleEditTryout } from '../../../Component/Learning/Tryout/TryoutUtils';
import { fetchTryout } from '../../../Features/Learning/Tryout/Utils/TryoutUtils';
import { setField } from '../../../Features/Learning/Tryout/addTryoutSlice';

const TryoutPost = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.Tryout.filters)
    const dataTryoutEdit = useSelector(state => state.addTryout);

    useEffect(() => {
        if (id && !dataTryoutEdit.id) {
            const newFilters = {
                ...filters,
                'filter[id]': id,
                'page': 1,
            };
            dispatch(fetchTryout(newFilters))
                .then((response) => {
                    // Kode yang akan dijalankan setelah fetch sukses
                    handleEditTryout(dispatch, response.payload.data[0], setField)
                    // Kode lain yang perlu dijalankan setelah fetch sukses
                })
                .catch((error) => {
                    // Tangani error jika fetch gagal
                    console.error('Error during fetchTryout on Detail:', error);
                });
        }
    }
        , [id])
    return (
        <Fragment>
            <Breadcrumbs grandparent="Learning" parent="Tryout" title={id ? "Update Tryout" : "Tambah Tryout"} />
            <TryoutPostContain />
        </Fragment>
    );
};

export default TryoutPost;
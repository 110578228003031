import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { CheckCircle, XCircle } from "react-feather";
import { H6 } from "../../../../AbstractElements";
import {
	Col,
	Card,
	CardBody,
	Table,
	Row,
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";
import { fetchDetailMemberClass } from "../../../../Features/Classes/Class/Utils/ClassUtils";
import { setField } from "../../../../Features/Classes/Schedule/attachDetachUserToScheduleSlice";

const DetailScheduleAttendance = () => {
	const { classId, scheduleId } = useParams();
	const dispatch = useDispatch();
	const detail = useSelector((state) => state.Schedule.detail);
	const [totalAttendance, setTotalAttendance] = useState(0);
	const [totalLearned, setTotalLearned] = useState(0);
	const [totalUser, setTotalUser] = useState(0);
	const [totalStatusValid, setTotalStatusValid] = useState(0);

	useEffect(() => {
		dispatch(fetchDetailMemberClass(classId));
		dispatch(setField({ field: "scheduleId", value: parseInt(scheduleId) }));

		if (detail.attendance && detail.attendance.length > 0) {
			let totalAttendance = 0;
			let totalLearned = 0;
			let totalStatusValid = 0;

			detail.attendance.map((session) => {
				if (session.is_attended) totalAttendance += 1;
				if (session.is_learned) totalLearned += 1;
				if (
					(!detail.course_id &&
						!detail.material_id &&
						!session.is_learned &&
						session.is_attended) ||
					(session.is_learned && session.is_attended)
				) {
					totalStatusValid += 1;
				}
			});

			setTotalAttendance(totalAttendance);
			setTotalLearned(totalLearned);
			setTotalUser(detail.attendance.length);
			setTotalStatusValid(totalStatusValid);
		}
	}, []);

	const [open, setOpen] = useState("0");
	const toggle = (id) => {
		if (open === id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};

	return (
		<Fragment>
			<Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
				<Card className="social-widget-card" style={{ background: "none" }}>
					<Accordion open={open} toggle={toggle}>
						<AccordionItem>
							<AccordionHeader targetId="1">
								<div className="d-flex justify-content-between align-items-center">
									<h5>Kehadiran Pada {detail.name}</h5>
								</div>
							</AccordionHeader>
							<AccordionBody accordionId="1">
								<CardBody>
									{detail.attendance && detail.attendance.length > 0 ? (
										<Row className="">
											<Col lg="6" md="12">
												<Card className="social-widget-card">
													<CardBody className="px-2 py-2">
														<Table className="table-responsive table-bordered">
															<thead className="table-light">
																<tr>
																	<th
																		scope="col"
																		className="text-center"
																		style={{ width: "15px" }}>
																		#
																	</th>
																	<th scope="col">Nama </th>
																	<th className="text-center" scope="col">
																		Materi Pra-sesi
																	</th>
																	<th className="text-center" scope="col">
																		Kehadiran{" "}
																	</th>
																	<th className="text-center" scope="col">
																		Status
																	</th>
																	<th scope="col">Detail</th>
																</tr>
															</thead>
															<tbody>
																{detail.attendance &&
																	detail.attendance.length > 0 &&
																	detail.attendance.map((session, i) => (
																		<tr key={`session-${i}`}>
																			<th>{i + 1}</th>
																			<td>{session.user.name}</td>
																			<td align="center">
																				{session.is_learned ? (
																					<CheckCircle
																						size={20}
																						className="text-success me-1"
																					/>
																				) : (
																					<XCircle
																						size={20}
																						className="text-danger me-1"
																					/>
																				)}
																			</td>
																			<td align="center">
																				{session.is_attended ? (
																					<CheckCircle
																						size={20}
																						className="text-success me-1"
																					/>
																				) : (
																					<XCircle
																						size={20}
																						className="text-danger me-1"
																					/>
																				)}
																			</td>
																			<td align="center">
																				{!detail.course_id &&
																				!detail.material_id &&
																				!session.is_learned &&
																				session.is_attended ? (
																					<CheckCircle
																						size={20}
																						className="text-success me-1"
																					/>
																				) : session.is_learned &&
																				  session.is_attended ? (
																					<CheckCircle
																						size={20}
																						className="text-success me-1"
																					/>
																				) : (
																					<XCircle
																						size={20}
																						className="text-danger me-1"
																					/>
																				)}
																			</td>
																			<td
																				className="text-center"
																				style={{ width: "10%" }}>
																				<Link
																					to={`${process.env.PUBLIC_URL}/classes/user-schedule-progress/${detail.class_id}/${session.user.id}/${session.user.name}`}
																					title="Detail Progress User"
																					className="p-1 float-right">
																					<i className="fa fa-info-circle mx-auto fa-lg"></i>
																				</Link>
																			</td>
																		</tr>
																	))}
															</tbody>
														</Table>
													</CardBody>
												</Card>
											</Col>
											<Col lg="6" md="12">
												<H6 attrH6={{ className: "text-center" }}>
													Statistik Kehadiran
												</H6>
												<Card className="social-widget-card">
													<CardBody className="px-2 py-2">
														<Table className="table-responsive table-bordered">
															<thead className="table-light">
																<tr>
																	<th scope="col" className="text-center">
																		Total Peserta
																	</th>
																	<th scope="col" className="text-center">
																		Total Selesai Materi
																	</th>
																	<th scope="col" className="text-center">
																		Total Kehadiran
																	</th>
																	<th scope="col" className="text-center">
																		Total Status Valid
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td align="center">{totalUser}</td>
																	<td align="center">{totalLearned}</td>
																	<td align="center">{totalAttendance}</td>
																	<td align="center">{totalStatusValid}</td>
																</tr>
															</tbody>
														</Table>
													</CardBody>
												</Card>
											</Col>
										</Row>
									) : (
										<H6 attrH6={{ className: "text-center" }}>
											Belum ada peserta
										</H6>
									)}
								</CardBody>
							</AccordionBody>
						</AccordionItem>
					</Accordion>
				</Card>
			</Col>
		</Fragment>
	);
};
export default DetailScheduleAttendance;

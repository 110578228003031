import { axiosInstance } from "./axiosInstance";


export const MateriService = {
    getAll: async (filter) => {
        return await axiosInstance.get('/api/feature/membership/materi', { params: filter });
    },
    create: async (data) => {
        return await axiosInstance.post('/api/feature/membership/materi', data);
    },
    update: async (id, data) => {
        return await axiosInstance.post(`/api/feature/membership/materi/update/${id}`, data);
    },
    delete: async (id) => {
        return await axiosInstance.delete(`/api/feature/membership/materi/delete/${id}`);
    }
}
import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Form, FormGroup, Label, Input } from 'reactstrap';
import { Post, Discard } from '../../../Constant';
import { Btn, H5, H6, Image, Spinner } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PostCommon from '../../../Component/Learning/Common/Form/Post';
import { submitUsers } from '../../../Features/Learning/Users/Utils/UserUtils';
import { setField, resetState } from '../../../Features/Learning/Users/addUserSlice';
import Swal from 'sweetalert2';


const UserPostContain = (props) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const data = useSelector(state => state.addUser)
    const startUpload = useSelector(state => state.addUser.isLoading);
    const isUpdate = useSelector(state => state.addUser.isUpdate);

    const handleSuccess = () => {
        dispatch(resetState());
        history(`${process.env.PUBLIC_URL}/users/user-list`);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(data, "data");
        let errorMessage = '';
        if (data.name === '') {
            errorMessage = 'Nama belum diisi.';
        } else if (data.gender === '') {
            errorMessage = 'Jenis Kelamin belum dipilih.';
        } else if (data.phone === '') {
            errorMessage = 'Nomer Telpon belum diisi.';
        } else if (data.email === '') {
            errorMessage = 'Email belum diisi.';
        } else if (data.username === '') {
            errorMessage = 'Username belum diisi.';
        } else if (data.password === '') {
            errorMessage = 'Password belum diisi.';
        }

        if (data.name === '' || data.gender === '' || data.phone === '' || data.email === '' || data.username === '' || data.password === '' && !isUpdate) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errorMessage,
            });
        } else {
            dispatch(submitUsers({ ...data, successCallback: handleSuccess }))
        }
    };
    const handleDiscard = (e) => {
        e.preventDefault();
        dispatch(resetState());
        history(`${process.env.PUBLIC_URL}/users/user-list`);
    };
    const handleChanges = (e) => {
        const { name, value } = e.target;
        dispatch(setField({ field: name, value: value }));
    }

    // const handleKeyDown = (e) => {
    //     // Hanya izinkan input angka (0-9) dan backspace
    //     const isValidInput = /^[0-9\b]+$/.test(e.key);

    //     if (!isValidInput && e.key !== 'Backspace') {
    //         e.preventDefault();
    //     }
    // };

    return (
        <PostCommon title={props.label} handleSubmit={handleSubmit} handleDiscard={handleDiscard} >
            {startUpload ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses unggah...'}</H6>
                </Col>
            ) : (
            <div>
            <Form className="row needs-validation">
                <Col sm="12">
                    <FormGroup className='mb-3'>
                        <Label for="name" style={{ fontWeight: 'bold' }}>Nama :</Label>
                        <Input className="form-control" id="name" type="text" name="name" placeholder="Masukkan nama user..."
                            value={data.name}
                            onChange={handleChanges} />
                    </FormGroup>
                    <FormGroup className='mb-3'>
                        <Label style={{ fontWeight: 'bold' }}>Jenis Kelamin :</Label>
                        <div className="m-checkbox-inline">
                            <Label for="male">
                                <Input className="radio_animated" id="male" type="radio" name="gender" value="Male"
                                    checked={data.gender === 'Male'}
                                    onChange={handleChanges}
                                    />
                                    Pria
                            </Label>
                            <Label for="female">
                                <Input className="radio_animated" id="female" type="radio" name="gender" value="Female"
                                    checked={data.gender === 'Female'}
                                    onChange={handleChanges}
                                    />
                                    Wanita
                            </Label>
                        </div>
                    </FormGroup>
                    <FormGroup className='mb-3'>
                        <Label for="phone" style={{ fontWeight: 'bold' }}>Nomer Telpon :</Label>
                        <Input className="form-control" id="phone" type="text" name="phone" placeholder="Masukkan nomer telepon..." required
                            value={data.phone}
                            onChange={handleChanges} />
                    </FormGroup>
                    <FormGroup className='mb-3'>
                        <Label for="email" style={{ fontWeight: 'bold' }}>Email :</Label>
                        <Input className="form-control" id="email" type="email" name="email" placeholder="Masukkan email..." required
                            value={data.email}
                            onChange={handleChanges} />
                    </FormGroup>
                    <FormGroup className='mb-3'>
                        <Label for="username" style={{ fontWeight: 'bold' }}>Username :</Label>
                        <Input className="form-control" id="username" type="text" name="username" placeholder="Masukkan username..." required
                            value={data.username}
                            onChange={handleChanges} />
                    </FormGroup>
                    <FormGroup className='mb-3'>
                        <Label for="password" style={{ fontWeight: 'bold' }}>Password :</Label>
                        <Input className="form-control" id="password" type="text" name="password" placeholder="Masukkan password..." required
                            onChange={handleChanges} />
                    </FormGroup>
                    {isUpdate && (
                    <>
                        <FormGroup className='mb-3'>
                            <Label for="referral_code" style={{ fontWeight: 'bold' }}>Referral Kode :</Label>
                            <Input className="form-control" id="referral_code" type="text" name="referral_code" placeholder="Masukkan referral kode..." required
                                value={data.referral_code}
                                onChange={handleChanges} />
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Label for="parent_name" style={{ fontWeight: 'bold' }}>Nama Wali :</Label>
                            <Input className="form-control" id="parent_name" type="text" name="parent_name" placeholder="Opsional masukkan nama wali..." required
                                value={data.parent_name}
                                onChange={handleChanges} />
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Label for="parent_phone" style={{ fontWeight: 'bold' }}>No Telpon Wali :</Label>
                            <Input className="form-control" id="parent_phone" type="text" name="parent_phone" placeholder="Opsional masukan nomer telpon wali..." required
                                value={data.parent_phone}
                                onChange={handleChanges} />
                        </FormGroup>
                    </>
                    )}
                </Col>
            </Form>
            </div>
            )}
        </PostCommon>
    );
};

export default UserPostContain;
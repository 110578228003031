import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Label, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { Btn, UL, LI } from '../../../../AbstractElements';
import { XCircle, CheckSquare } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { setAttachments, setScore } from '../../../../Features/Learning/Tryout/attachTryoutSlice';
import CommonModal from '../../Common/TryoutModal/Modal';
import RawHTMLComponent from '../../../../Component/Learning/Common/RawHTML';
import GenerateUntagHtml from '../../../../Component/Learning/Common/GenerateUntagHtml';
import AsyncSelectCommon from '../../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { fetchMaterial } from '../../../../Features/Learning/Materi/Utils/MateriUtils';
import { fetchCategories } from '../../../../Features/Learning/Categories/Utils/CategoriesUtils';
import { fetchQuestion } from '../../../../Features/Learning/BankQuestions/Utils/QuestionsUtils';


const DetailModal = ({ item, modal, NewMessage, toggle }) => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';    const scores = JSON.parse(item.pivot.score);
    const choicesWithLabel = item.choices.map(choice => {
        const matchingScore = scores.find(score => score.choice_id === choice.id.toString());
        const newChoice = { ...choice };

        if (matchingScore) {
            newChoice.isCorrect = true; // Menambahkan label "Benar"
            newChoice.point = matchingScore.point; // Menambahkan label "Point"
        } else {
            newChoice.isCorrect = false;
        }

        return newChoice;
    });


    return (
        <Modal isOpen={modal} toggle={toggle} size="lg"  centered>
            <ModalHeader toggle={toggle}>
                {NewMessage}
            </ModalHeader>
            <ModalBody>
                <div className="pro-group pt-0 border-0 mb-3">
                    <Row>
                        <Col>
                            <div className="product-price">Label Soal: </div>
                            <b>{item.title}</b>
                        </Col>
                        <Col>
                            <div className="product-price">Tipe Soal: </div>
                            <b>{item.type === 'multiple_choice' ? 'Pilihan Ganda' : item.type === 'fill_in_the_blanks' ? 'Isian Singkat' : item.type === 'sentence_arrangement' ? 'Susunan Kalimat' : 'undefined'}</b>
                        </Col>
                        <Col>
                            <div className="product-price">Kategori Soal: </div>
                            <b>{item.pivot.category.title.replace(/\d/g, '')}</b>
                        </Col>
                    </Row>
                </div>
                <div className="pro-group mb-3">
                    <div className="product-price">Pertanyaan: </div>
                    <h5><RawHTMLComponent htmlContent={item.description} /></h5>
                </div>
                {(item.type === 'multiple_choice' || item.type === 'sentence_arrangement') && (
                    <div className="pro-group">
                        <div className="product-price mb-3">{'Pilihan Jawaban : '}
                        </div>
                        <UL attrUL={{ className: 'simple-list' }}>
                            {choicesWithLabel && choicesWithLabel.map((itemChoice, index) => (
                                <LI key={itemChoice.id} className="mt-3">
                                    <div className="form-check">
                                        <Row>
                                            <Col>
                                                {/* <b>{alphabet[index]}. </b> */}
                                                <Label className={`form-check-label`} htmlFor={`itemChoice-${index}`}><h5><RawHTMLComponent htmlContent={itemChoice.choice} /></h5></Label>
                                            </Col>
                                            <Col>
                                                {itemChoice.isCorrect ? <><CheckSquare color="green" style={{ verticalAlign: 'middle' }} /><span style={{ verticalAlign: 'middle' }}>  Benar<b> ({itemChoice.point} poin)</b></span></> : <><XCircle color="red" style={{ verticalAlign: 'middle' }} /><span style={{ verticalAlign: 'middle' }}>  Salah</span></>}
                                            </Col>
                                        </Row>
                                    </div>
                                </LI>
                            ))}
                        </UL>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Btn attrBtn={{ color: 'warning', onClick: toggle }} >{'Close'}</Btn>
            </ModalFooter>
        </Modal>

    );
};

export default DetailModal;
import {
	fetchTentors,
	deleteTentor,
} from "../../../Features/Classes/Tentor/Utils/TentorUtils";
import Swal from "sweetalert2";

/* **************************************************** */
/* CRUD Tentor                                          */
/* **************************************************** */

export const fetchTentor = (dispatch, filters, refetch) => {
	dispatch(fetchTentors(filters));
};

export const handleGetDetailTentor = async (dispatch, item, setDetail) => {
	dispatch(setDetail(item));
};

export const handleDeleteTentor = async (dispatch, id, setRefetch) => {
	try {
		Swal.fire({
			title: "Apakah anda yakin?",
			text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
		}).then(async (result) => {
			if (result.isConfirmed) {
				await dispatch(deleteTentor(id));
				setRefetch((prevRefetch) => !prevRefetch);
				Swal.fire("Terhapus!", "Data telah dihapus.", "success");
			}
		});
	} catch (error) {
		console.error("Delete error:", error);
	}
};

export const handleEditTentor = async (dispatch, item, setField) => {
	const fieldsToUpdate = ["name", "phone", "mastered_fields", "photo"];
	fieldsToUpdate.forEach((field) => {
		dispatch(setField({ field, value: item[field] }));
	});
	dispatch(setField({ field: "photo_url", value: item.photo }));
	dispatch(setField({ field: "isUpdate", value: true }));
	dispatch(setField({ field: "id", value: item.id }));
};

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (
	pagination,
	filters,
	dispatch,
	setFilters,
	setRefetch
) => {
	if (pagination.current_page > 1) {
		dispatch(
			setFilters({
				...filters,
				page: pagination.current_page - 1,
			})
		);
		setRefetch((prevRefetch) => !prevRefetch);
	}
};

export const handleNextPagination = (
	pagination,
	filters,
	dispatch,
	setFilters,
	setRefetch
) => {
	if (pagination.current_page < pagination.total_pages) {
		dispatch(
			setFilters({
				...filters,
				page: pagination.current_page + 1,
			})
		);
		setRefetch((prevRefetch) => !prevRefetch);
	}
};

export const handleClickPagination = (
	page,
	filters,
	dispatch,
	setFilters,
	setRefetch
) => {
	dispatch(
		setFilters({
			...filters,
			page: page,
		})
	);
	setRefetch((prevRefetch) => !prevRefetch);
};

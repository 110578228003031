import { createSlice } from "@reduxjs/toolkit";
import { submitTestimony } from "./Utils/TestimonyUtils";

const initialState = {
	user_id: "",
	membership_id: "",
	review: "",
	star_rating: "",
	additional_info: "",
	show_in_home: 0,
	id: "",
	isUpdate: false,
};

const addTestimonySlice = createSlice({
	name: "addTestimony",
	initialState: initialState,
	reducers: {
		setField: (state, action) => {
			const { field, value } = action.payload;
			state[field] = value;
		},
		resetState: (state) => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(submitTestimony.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(submitTestimony.fulfilled, (state) => {
				state.isLoading = false;
			})
			.addCase(submitTestimony.rejected, (state, action) => {
				state.isLoading = false;
				console.log(action.error.message, "error action");
			});
	},
});

export const { setField, resetState } = addTestimonySlice.actions;

export default addTestimonySlice.reducer;

import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { H6, Spinner } from '../../../../AbstractElements';
import DetailProgramContain from './DetailProgramContain';
import MembershipProgramContain from './MembershipProgramContain';

const DetailProgram = () => {
  const isLoading = useSelector(state => state.MainProgram.isLoading)

  return (
    <Fragment>
        <Container fluid={true}>
          {isLoading ? (
            <Col lg="12">
              <div className="loader-box">
                <Spinner attrSpinner={{ className: 'loader-30' }} />
              </div>
              <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
            </Col>
          ) : (
            <div>
              <DetailProgramContain />
              <MembershipProgramContain />
            </div>
          )}
        </Container>
    </Fragment>
  );
};

export default DetailProgram;
import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import CourseDetail from './CourseDetail';

const DetailCourse = () => {
  return (
    <Fragment>
        <Container fluid={true}>
            <div>
                <Row className="product-page-main p-0">
                    <CourseDetail />
                </Row>
            </div>
        </Container>
    </Fragment>
  );
};

export default DetailCourse;
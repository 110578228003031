import { createSlice } from '@reduxjs/toolkit';
import { attachDetachTryoutMemberships } from './Utils/MembershipUtils';

const initialState = {
    id : '',
    tryouts: [],
    isLoading: false,
};

const attachDetachTryoutSlice = createSlice(
    {
        name: 'attachDetachTryout',
        initialState: initialState,
        reducers: {
            setFieldAttachDetachTryout: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetStateAttachDetachTryout: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
            .addCase(attachDetachTryoutMemberships.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(attachDetachTryoutMemberships.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(attachDetachTryoutMemberships.rejected, (state, action) => {
                state.isLoading = false;
                console.log(action.error.message, "error action")
            })
        }
    }
);

export const { setFieldAttachDetachTryout, resetStateAttachDetachTryout } = attachDetachTryoutSlice.actions;

export default attachDetachTryoutSlice.reducer;
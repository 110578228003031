import React, { Fragment, useEffect, useState } from "react";
import {
	CheckCircle,
	XCircle,
	Info,
	PlusCircle,
	Minus,
	MinusCircle,
} from "react-feather";
import {
	Col,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Media,
	Table,
	Row,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
} from "reactstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	Image,
	H6,
	H5,
	H4,
	H3,
	H2,
	H1,
	LI,
	UL,
	P,
	Btn,
	Spinner,
	Badges,
} from "../../../AbstractElements";
import {
	fetchDetailMemberClassSchedule,
	editAttendance,
} from "../../../Features/Classes/Schedule/Utils/ScheduleUtils";
import Swal from "sweetalert2";
import Moment from "moment";
import "moment/locale/id";

const TestimonialList = () => {
	const { classId, userId, userName } = useParams();
	const dispatch = useDispatch();
	const detailClass = useSelector(
		(state) => state.detailUserProgress.data.data
	);
	const isLoading = useSelector(
		(state) => state.detailUserProgress.isGetLoading
	);
	const [refatch, setRefatch] = useState(false);
	const [openProgressMaterial, setOpenProgressMaterial] = useState(false);
	const [detailProgress, setDetailProgress] = useState({});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (classId && userId) {
			dispatch(fetchDetailMemberClassSchedule({ classId, userId }));
		}
	}, [classId, userId, refatch]);

	return (
		<Fragment>
			<Col sm="12 order-2">
				<Card className="mt-4 mb-2 p-3">
                    <Row>
                        <Col lg="9">
                            
                        </Col>
                        <Col lg="3" className="text-end">
                            <Btn color="primary" to={``} className="btn-sm">
                                <i className="fa fa-plus me-2"></i>
                                New Testimonial
                            </Btn>
                        </Col>
                    </Row>
                </Card>
				<Card className="mt-3">
					<CardHeader className="pb-0">
						<Row>
							<Col lg="9">
								<H5>Daftar Testimonial</H5>
							</Col>
						</Row>
					</CardHeader>
					<CardBody>
						<Table className="table-bordered">
							<thead>
								<tr>
									<th
										scope="col"
										className="bg-primary text-light"
										style={{ verticalAlign: "middle" }}>
										#
									</th>
									<th
										scope="col"
										className="bg-primary text-light"
										style={{ verticalAlign: "middle" }}>
										User
									</th>
									<th
										scope="col"
										className="bg-primary text-light text-center"
										style={{ verticalAlign: "middle" }}>
										Testimoni
									</th>
									<th
										scope="col"
										className="bg-primary text-light text-center"
										style={{ verticalAlign: "middle" }}>
										Rating
									</th>
									<th
										scope="col"
										className="bg-primary text-light text-center"
										style={{ width: "80px", verticalAlign: "middle" }}>
										Tampil di Home
									</th>
									<th
										scope="col"
										className="bg-primary text-light text-center"
										style={{ width: "80px", verticalAlign: "middle" }}>
										Tampil di Program
									</th>
									<th
										scope="col"
										className="bg-primary text-light text-center"
										style={{ verticalAlign: "middle" }}>
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1</td>
									<td>Yusuf Ginanjar</td>
									<td className="text-left">
										Lorem ipsum dolor sit amet, consectetur adipisicing elit.
										Dolor voluptate incidunt modi maxime numquam! Natus,
										voluptatem sequi. Ipsam sint quibusdam autem possimus
										perferendis minima explicabo, recusandae doloribus,
										laudantium nam quas?
									</td>
									<td className="text-center">
										<Badges color="primary">5</Badges>
									</td>
									<td className="text-center">
										<Badges color="success">Ya</Badges>
									</td>
									<td className="text-center">
										<Badges color="success">Ya</Badges>
									</td>
									<td className="text-center">
										<span className="me-2" style={{ cursor: "pointer" }}>
											<i className="fa fa-edit fa-lg me-2"></i>
											Edit
										</span>
									</td>
								</tr>
								<tr>
									<td>2</td>
									<td>Yusuf Ginanjar</td>
									<td className="text-left">
										Lorem ipsum dolor sit amet, consectetur adipisicing elit.
										Dolor voluptate incidunt modi maxime numquam! Natus,
										voluptatem sequi. Ipsam sint quibusdam autem possimus
										perferendis minima explicabo, recusandae doloribus,
										laudantium nam quas?
									</td>
									<td className="text-center">
										<Badges color="primary">5</Badges>
									</td>
									<td className="text-center">
										<Badges color="success">Ya</Badges>
									</td>
									<td className="text-center">
										<Badges color="success">Ya</Badges>
									</td>
									<td className="text-center">
										<span className="me-2" style={{ cursor: "pointer" }}>
											<i className="fa fa-edit fa-lg me-2"></i>
											Edit
										</span>
									</td>
								</tr>
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Col>

			{/* Modal Progress Material */}
			<Modal
				isOpen={openProgressMaterial}
				toggle={() => setOpenProgressMaterial(!openProgressMaterial)}
				size="lg">
				{detailProgress.id && detailProgress.progress_detail.length > 0 ? (
					<Fragment>
						<ModalHeader
							toggle={() => setOpenProgressMaterial(!openProgressMaterial)}>
							{detailProgress.detail.name}
						</ModalHeader>
						<ModalBody>
							<Table className="table-bordered">
								<thead>
									<tr>
										<th
											scope="col"
											className="bg-primary text-light"
											style={{ verticalAlign: "middle" }}>
											#
										</th>
										<th
											scope="col"
											className="bg-primary text-light"
											style={{ verticalAlign: "middle" }}>
											Materi
										</th>
										<th
											scope="col"
											className="bg-primary text-light"
											style={{ verticalAlign: "middle" }}>
											Status
										</th>
										<th
											scope="col"
											className="bg-primary text-light"
											style={{ verticalAlign: "middle" }}>
											Diselesaikan Pada
										</th>
									</tr>
								</thead>
								<tbody>
									{detailProgress.progress_detail.map((item, index) => (
										<tr key={`progress-detail-${index}`}>
											<td>{index + 1}</td>
											<td>{item.sub_material_name}</td>
											<td>
												{item.is_learned ? (
													<CheckCircle
														size={30}
														className="text-success me-1"
													/>
												) : (
													<XCircle size={30} className="text-danger me-1" />
												)}
											</td>
											<td>
												{item.completed_at &&
													Moment(item.completed_at).format(
														"DD MMMM YYYY HH:mm:ss"
													)}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</ModalBody>
					</Fragment>
				) : (
					<ModalBody>
						<H5 attrH5={{ className: "text-center" }}>
							Belum ada materi yang tersedia
						</H5>
					</ModalBody>
				)}
			</Modal>
		</Fragment>
	);
};
export default TestimonialList;

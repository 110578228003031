import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import CategoryContain from '../../../Component/Learning/Categories/CategoriesPost';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setField } from '../../../Features/Learning/Categories/addCategoriesSlice';
import { fetchCategories } from '../../../Features/Learning/Categories/Utils/CategoriesUtils';
import { handleEditCategories } from '../../../Component/Learning/Categories/CategoriesUtils';

const CategoriesAdd = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const addField = useSelector(state => state.addCategories)
    const filters = useSelector(state => state.Categories.filters)

    useEffect(() => {
        if (!addField.id && id) {
            const newFilters = {
                ...filters,
                "filter[id]": id,
                "page": 1,
            };
            dispatch(fetchCategories(newFilters))
            .then((response) => {
                // Kode yang akan dijalankan setelah fetch sukses
                handleEditCategories(dispatch, response.payload.data[0], setField)
            })
            .catch((error) => {
                // Tangani error jika fetch gagal
                console.error('Error during fetchCategories:', error);
            });
        }
    } , [id]);

    return (
        <Fragment>
            <Breadcrumbs grandparent="Learning" parent="Kategori" title={id ? 'Update Kategori' : 'Tambah Kategori'} />
            {<CategoryContain />}
        </Fragment>
    );
};

export default CategoriesAdd;
import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { Search } from 'react-feather';
import { Filter } from '../../../../Constant';
import { H5, Btn } from '../../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../../Features/Learning/Users/userSlice';
import AsyncSelectCommon from '../../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { fetchUser } from '../UsersUtils';
import { fetchMemberships } from '../../../../Features/Learning/Memberships/Utils/MembershipUtils';

const FindUser = () => {
  const [isFilter, setIsFilter] = useState(true);
  const dispatch = useDispatch()
  const filters = useSelector(state => state.User.filters)
  const filterMemberships = useSelector(state => state.Membership.filters)
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    const selectedMembership = filters['filter[membership.title]']
      ? [{
        value: filters['filter[membership.id]'],
        label: filters['filter[membership.title]']
      }]
      : [];
      setSelectedValue(selectedMembership);
  }, [filters['filter[membership.id]']]);

  const promiseOptions = (inputValue, callback) => {
    // console.log('Input Value = ', inputValue)
    if (inputValue !== '') {
      dispatch(fetchMemberships({
        ...filterMemberships,
        'filter[title]': inputValue,
        'sort': 'title',
        'page': 1,
      }))
        .then((response) => {
          const options = response.payload.data.map(item => ({
            value: item.id,
            label: item.title,
          }));
          callback(options);
        })
        .catch((error) => {
          console.error('Error fetching options:', error);
          callback([]);
        });
    }
  };

  const handleSelectChange = (selectedOption) => {
  setSelectedValue(selectedOption);
  
  const membershipTitleFilter = selectedOption ? selectedOption.label : '';
  
  const newFilters = {
    ...filters,
    'filter[membership.title]': membershipTitleFilter,
    'page': 1,
  };

  dispatch(setFilters(newFilters));
  fetchUser(dispatch, newFilters);
};


  const handleClickSearch = (e) => {
    e.preventDefault()
    const newFilters = {
      ...filters,
      'page': 1,
    };
    dispatch(setFilters(newFilters));
    fetchUser(dispatch, newFilters);
  }


  const handleResetFilter = () => {
    const newFilters = {
      ...filters,
        'filter[id]': '',
        'filter[name]': '',
        'filter[username]': '',
        'filter[email]': '',
        'filter[gender]': '',
        'filter[phone]': '',
        'filter[address]': '',
        'filter[order.status]': '',
        'filter[order.payment_method]': '',
        'filter[membership.title]': '',
        'filter[membership.id]': '',
        'sort': '',
        'page': 1,
    };
    setSelectedValue(null)
    dispatch(setFilters(newFilters));
    fetchUser(dispatch, newFilters);
  }

  const updateFilters = (filters, dispatch, field, value) => {
    dispatch(setFilters({
      ...filters,
      [field]: value,
    }));
  };

  const handleSortBy = (filters, dispatch) => (e) => {
    const filterValue = e.target.value;
    updateFilters(filters, dispatch, 'sort', filterValue);
  };


  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: 'mb-0' }} >
              <Btn attrBtn={{ className: 'btn btn-link ps-0', onClick: () => setIsFilter(!isFilter), color: 'transperant', datastoggle: 'collapse', databstarget: '#collapseicon', ariaexpanded: 'true', ariacontrols: 'collapseicon' }} >{'Temukan User'}</Btn>
            </H5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
              <CardBody className="filter-cards-view animate-chk">
                <div className="job-filter mb-4">
                  <div className="faq-form">
                    <Input className="form-control" type="text" placeholder="Cari nama.." 
                      value={filters['filter[name]']}
                      onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e): null}
                      onChange={(e) => {
                        dispatch(setFilters({
                          ...filters,
                          'filter[name]': e.target.value
                        }));
                      }}
                    />
                    <Search className="search-icon" />
                  </div>
                </div>
                <div className="job-filter mb-4">
                  <div className="faq-form">
                    <Input className="form-control" type="text" placeholder="Cari email.." 
                      value={filters['filter[email]']}
                      onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e): null}
                      onChange={(e) => {
                        dispatch(setFilters({
                          ...filters,
                          'filter[email]': e.target.value
                        }));
                      }}
                    />
                    <Search className="search-icon" />
                  </div>
                </div>
                <div className="job-filter mb-4">
                  <div className="faq-form">
                    <Input className="form-control" type="text" placeholder="Cari nomer telp.." 
                      value={filters['filter[phone]']}
                      onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e): null}
                      onChange={(e) => {
                        dispatch(setFilters({
                          ...filters,
                          'filter[phone]': e.target.value
                        }));
                      }}
                    />
                    <Search className="search-icon" />
                  </div>
                </div>
                <div className="job-filter mb-4">
                  <div className="faq-form">
                    <Input className="form-control" type="text" placeholder="Cari kode referral" 
                      value={filters['filter[referral_code]']}
                      onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e): null}
                      onChange={(e) => {
                        dispatch(setFilters({
                          ...filters,
                          'filter[referral_code]': e.target.value
                        }));
                      }}
                    />
                    <Search className="search-icon" />
                  </div>
                </div>
                <div className="job-filter mb-4">
                  <div className="faq-form">
                    {/* <Input className="form-control" type="text" placeholder="Cari membership.." 
                      value={filters['filter[membership.title]']}
                      onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e): null}
                      onChange={(e) => {
                        dispatch(setFilters({
                          ...filters,
                          'filter[membership.title]': e.target.value
                        }));
                      }}
                    /> */}
                    <AsyncSelectCommon label={'Membership'} placeholder={'Cari membership..'}
                      isMulti={false}
                      promiseOptions={promiseOptions}
                      handleSelectChange={(selectedOption) => handleSelectChange(selectedOption)}
                      selectedValue={selectedValue}
                    />
                    <Search className="search-icon" />
                  </div>
                </div>
                <div className="checkbox-animated mt-0">
                  <div className="learning-header"><span className="f-w-600">Sort by:</span></div>
                  <Label className="d-block" htmlFor="sort-new">
                    <Input className="radio_animated" id="sort-new" type="radio" name="sort"
                      checked={filters.sort === '-id'}
                      value="-id"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'Terbaru'}
                  </Label>
                  <Label className="d-block" htmlFor="sort-old">
                    <Input className="radio_animated" id="sort-old" type="radio" name="sort"
                      checked={filters.sort === 'id'}
                      value="id"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'Terlama'}
                  </Label>
                  <Label className="d-block" htmlFor="sort-az">
                    <Input className="radio_animated" id="sort-az" type="radio" name="sort"
                      checked={filters.sort === 'name'}
                      value="name"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'A-Z'}
                  </Label>
                  <Label className="d-block" htmlFor="sort-za">
                    <Input className="radio_animated" id="sort-za" type="radio" name="sort"
                      checked={filters.sort === '-name'}
                      value="-name"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'Z-A'}
                  </Label>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Btn attrBtn={{ color: 'primary text-center p-1', onClick: handleClickSearch }}>{Filter}</Btn>
                  </div>
                  <div className="col-md-6">
                    <Btn attrBtn={{ color: 'warning text-center p-1', onClick: handleResetFilter }}>{'Reset'}</Btn>
                  </div>
                </div>
              </CardBody>
            </div>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default FindUser;
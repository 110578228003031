import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import AsyncSelectCommon from '../../Learning/Common/SelectOption/AsyncSelect.jsx';
import { fetchUsers } from '../../../Features/Learning/Users/Utils/UserUtils.jsx';

const AsynchSelectUser = ({ handleSelectChange }) => {
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState(null);

    const promiseOption = (inputValue, callback) => {
        dispatch(fetchUsers({
            'filter[name]': inputValue,
            'sort': 'name',
            'page': 1,
        }))
            .then((response) => {
                const options = response.payload.data.map(item => ({
                    value: item.id,
                    label: item.name + ' - ' + item.email,
                }));
                callback(options);
            })
            .catch((error) => {
                console.error('Error fetching options:', error);
                callback([]);
            });
    };

    return (
        <Fragment>
            <AsyncSelectCommon 
                label={'Pilih Siswa'} 
                placeholder={'Silahkan pilih Siswa...'}
                isMulti={false}
                promiseOptions={promiseOption}
                handleSelectChange={(selectedOption) => {
                    setSelectedValue(selectedOption === null ? '' : selectedOption)
                    handleSelectChange(selectedOption === null ? '' : selectedOption.value);
                }}
                selectedValue={selectedValue}
            />
        </Fragment>
    );
}

export default AsynchSelectUser;
import { fetchQuestion, deleteQuestion } from '../../../Features/Learning/BankQuestions/Utils/QuestionsUtils';
import Swal from 'sweetalert2';


/* **************************************************** */
/* CRUD Materi                                       */
/* **************************************************** */

export const fetchQuestions = (dispatch, filters, refetch) => {
  dispatch(fetchQuestion(filters));
};

export const handleDeleteQuestion = async (dispatch, id, setRefetch) => {
  try {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteQuestion(id));
        setRefetch(prevRefetch => !prevRefetch);
        Swal.fire(
          'Terhapus!',
          'Data telah dihapus.',
          'success'
        )
      }
    })
  } catch (error) {
    console.error("Delete error:", error);
  }
};

export const handleEditQuestion = async (dispatch, item, setField) => {
  const fieldsToUpdate = ['title','description', 'type', 'answer_explanation', 'choice', 'answer'];
  // console.log ("item", item);
  // console.log ("answer explanation", item.answer[0].answer_explanation)
  fieldsToUpdate.forEach(field => {
    if (field === 'type' || field === 'description' || field === 'title') {
      dispatch(setField({ field, value: item[field] }));
    } else if (field === 'answer_explanation') {
      dispatch(setField({ field: 'answer_explanation', value: item.answer[0].answer_explanation }));
    } else if (field === 'choice') {
      dispatch(setField({ field: 'choice', value: item.choices.map(choice => choice.choice) }));
    } else if (field === 'answer') {
      if (item.type === 'fill_in_the_blanks' || item.type === 'sentence_arrangement') {
        dispatch(setField({ field: 'answer', value: JSON.parse(item.answer[0].answer) }));
      } else if (item.type === 'multiple_choice') {
        const answerArray = JSON.parse(item.answer[0].answer);
        // Membuat array baru untuk menyimpan hasil pengecekan
        const results = item.choices.map(choice => {
          // Memeriksa apakah id choice ada dalam answerArray
          if (answerArray.includes(String(choice.id))) {
            return "1"; // Jika ada, beri nilai 1
          } else {
            return "0"; // Jika tidak ada, beri nilai 0
          }
        });
        // console.log("results", results);
        dispatch(setField({ field: 'answer', value: results }));
      }
    }
  });
  dispatch(setField({ field: 'answer_explanation_video', value: '' }));
  dispatch(setField({ field: 'audio_source', value: '' }));
  dispatch(setField({ field: 'answer_explanation_videoLink', value: item.answer[0].answer_explanation_video }));
  dispatch(setField({ field: 'audio_sourceLink', value: item.audio_source }));
  dispatch(setField({ field: 'isUpdate', value: true }));
  dispatch(setField({ field: 'id', value: item.id }));
};

export const handleGetDetailQuestion = async (dispatch, item, setDetail) => {
  dispatch(setDetail(item));
  // console.log("item detail", item);
};

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */
export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page > 1) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page - 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page < pagination.last_page) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page + 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
  dispatch(setFilters({
    ...filters,
    page: page
  }));
  setRefetch(prevRefetch => !prevRefetch);
}
import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import BlogPostContain from '../../../Component/Learning/SubMateri/SubMateriPost';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setField, resetState } from '../../../Features/Learning/SubMateri/addSubMaterialSlice';
import { fetchSubMaterial } from '../../../Features/Learning/SubMateri/Utils/SubMateriUtils';
import { handleEditSubMaterial } from '../../../Component/Learning/SubMateri/SubMateriUtils';

const BlogPost = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const addField = useSelector(state => state.addSubMaterial)
  const filters = useSelector(state => state.subMaterial.filters)

  useEffect(() => {
    if (!addField.id && id) {
      const newFilters = {
        ...filters,
        "filter[id]": id,
        "page": 1,
      };
      dispatch(fetchSubMaterial(newFilters))
        .then((response) => {
          // Kode yang akan dijalankan setelah fetch sukses
          handleEditSubMaterial(dispatch, response.payload.data[0], setField)
        })
        .catch((error) => {
          // Tangani error jika fetch gagal
          console.error('Error during fetchSubMaterial:', error);
        });
    } else if (!id) {
      dispatch(resetState());
      console.log(addField, "addField");
    }
  } , [id]);
  

  return (
    <Fragment>
      <Breadcrumbs greatgrandparent="Learning" grandparent="Materi" parent="Submateri" title={id ? "Update Submateri" : "Tambah Submateri"} />
      <BlogPostContain />
    </Fragment>
  );
};

export default BlogPost;
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import { fetchTentors } from "../../../../Features/Classes/Tentor/Utils/TentorUtils";
import { fetchSchedule } from "../../../Classes/Schedule/ScheduleUtils";
import AsyncSelectCommon from "../../../../Component/Learning/Common/SelectOption/AsyncSelect";
import { setFilters } from "../../../../Features/Classes/Schedule/ScheduleSlice";

const FilterTentorSchedule = () => {
	const dispatch = useDispatch();
	const filterTentor = useSelector((state) => state.Tentor.filters);
	const dataTentor = useSelector((state) => state.Tentor.data.data);
	const filterSchedule = useSelector((state) => state.Schedule.filters);
	const [selectedValue, setSelectedValue] = useState(null);

	useEffect(() => {
		if (filterSchedule["filter[tentor_id]"] === "") {
			setSelectedValue(null);
		} else {
			setSelectedValue({
				value: filterSchedule["filter[tentor_id]"],
				label:
					dataTentor.find(
						(item) => item.id === filterSchedule["filter[tentor_id]"]
					)?.name || "",
			});
		}
	}, [filterSchedule["filter[tentor_id]"]]);

	const promiseOptionTentor = (inputValue, callback) => {
		dispatch(
			fetchTentors({
				...filterTentor,
				"filter[name]": inputValue,
				sort: "name",
				page: 1,
			})
		)
			.then((response) => {
				const options = response.payload.data.map((item) => ({
					value: item.id,
					label: item.name,
				}));
				callback(options);
			})
			.catch((error) => {
				console.error("Error fetching options:", error);
				callback([]);
			});
	};

	const handleSelectChangeTentor = (selectedOption) => {
		setSelectedValue(selectedOption);
		const newFilters = {
			...filterSchedule,
			"filter[tentor_id]": selectedOption === null ? "" : selectedOption.value,
			page: 1,
		};
		dispatch(setFilters(newFilters));
		fetchSchedule(dispatch, newFilters);
	};

	return (
		<AsyncSelectCommon
			label={false}
			placeholder={"Tentor..."}
			isMulti={false}
			promiseOptions={promiseOptionTentor}
			handleSelectChange={(selectedOption) =>
				handleSelectChangeTentor(selectedOption)
			}
			selectedValue={selectedValue}
		/>
	);
};

export default FilterTentorSchedule;

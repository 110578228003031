import React, { Fragment, useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import ClassDetail from '../../../../Component/Classes/Class/ClassDetail';
import DetailUserClassContain from '../../../../Component/Classes/Class/DetailUserClass/DetailUserClassContain';

const DetailUserClass = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Fragment>
            <Container fluid={true}>
                <div>
                    <Row className="product-page-main p-0">
                        <ClassDetail type="user" />
                        <DetailUserClassContain />
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};

export default DetailUserClass;
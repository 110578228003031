import React, { Fragment, useEffect } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import DetailQuestionContain from "../../../Component/Learning/DetailBankQuestion";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuestions } from "../../../Component/Learning/BankQuestion/QuestionsUtils";
import { fetchQuestion } from "../../../Features/Learning/BankQuestions/Utils/QuestionsUtils";
import { handleGetDetailQuestion } from "../../../Component/Learning/BankQuestion/QuestionsUtils";
import { setDetail } from "../../../Features/Learning/BankQuestions/QuestionSlice";
import Swal from "sweetalert2";

const DetailQuestion = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const filters = useSelector((state) => state.Question.filters);
	const dataQuestionDetail = useSelector((state) => state.Question.detail);

	useEffect(() => {
		if (dataQuestionDetail?.id != id) {
			const newFilters = {
				"filter[id]": id,
				page: 1,
			};
			dispatch(fetchQuestion(newFilters))
				.then((response) => {
					// Kode yang akan dijalankan setelah fetch sukses
					handleGetDetailQuestion(
						dispatch,
						response.payload.data[0],
						setDetail
					);
					// Kode lain yang perlu dijalankan setelah fetch sukses
				})
				.catch((error) => {
					// Tangani error jika fetch gagal
					console.error("Error during fetchQuestion on Detail:", error);
				});
		}
	}, [id]);

	return (
		<Fragment>
			<Breadcrumbs
				grandparent="Learning"
				parent="Bank Soal"
				title="Rincian Soal"
			/>
			<DetailQuestionContain />
		</Fragment>
	);
};

export default DetailQuestion;

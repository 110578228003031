import { createSlice } from "@reduxjs/toolkit";
import { fetchBlog } from "./Utils/BlogUtils";

const BlogSlice = createSlice({
	name: "Blog",
	initialState: {
		filters: {
			"filter[title]": "",
			"filter[id]": "",
			page: 1,
		},
		data: [],
		detail: {},
		isLoading: false,
	},
	reducers: {
		setFilters: (state, action) => {
			state.filters = action.payload;
		},
		setData: (state, action) => {
			state.data = action.payload;
		},
		setDetail: (state, action) => {
			state.detail = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchBlog.pending, (state) => {
				// Handle loading state
				state.isLoading = true;
			})
			.addCase(fetchBlog.fulfilled, (state, action) => {
				// Handle success state
				state.isLoading = false;
				state.data = action.payload;
			})
			.addCase(fetchBlog.rejected, (state, action) => {
				// Handle error state
				state.isLoading = false;
				console.log(action.error.message, "error action");
			});
	},
});

export const { setFilters, setData, setDetail } = BlogSlice.actions;

export default BlogSlice.reducer;

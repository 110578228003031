// import ComingBgImg from "../Component/Pages/ComingSoon/ComingBgImg";
// import ComingSimple from "../Component/Pages/ComingSoon/ComingSimple";

import ErrorPage1 from "../Component/Pages/ErrorPages/error-page1";
import ErrorPage2 from "../Component/Pages/ErrorPages/error-page2";
import ErrorPage3 from "../Component/Pages/ErrorPages/error-page3";
import ErrorPage4 from "../Component/Pages/ErrorPages/error-page4";

// import Logins from "../Auth/Signin";
import LoginOne from "../Component/Pages/Auth/LoginOne";
// import LoginTwo from "../Component/Pages/Auth/LoginTwo";
// import LoginValidation from "../Component/Pages/Auth/LoginValidation";
// import LoginSweetalert from "../Component/Pages/Auth/LoginSweetalert";
// import LoginTooltip from "../Component/Pages/Auth/LoginTooltip";
// import RegisterSimple from "../Component/Pages/Auth/RegisterSimple";
// import RegisterBgImg from "../Component/Pages/Auth/RegisterBgImg";
// import RegisterVideo from "../Component/Pages/Auth/RegisterVideo";
// import UnlockUser from "../Component/Pages/Auth/UnlockUser";
// import ForgetPwd from "../Component/Pages/Auth/ForgetPwd";
// import CreatePwd from "../Component/Pages/Auth/CreatePwd";
// import Maintenance from "../Component/Pages/Auth/Maintenance";
// import LoginSample from "../Component/Pages/Auth/Login";

export const authRoutes = [
	{ path: `${process.env.PUBLIC_URL}/login`, Component: <LoginOne /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/login-simple`, Component: <LoginSample /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/login-img`, Component: <LoginOne /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/login-bg-img`, Component: <LoginTwo /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/login-validation`, Component: <LoginValidation /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/login-tooltip`, Component: <LoginTooltip /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/login-sweetalert`, Component: <LoginSweetalert /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/register-simpleimg`, Component: <RegisterSimple /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/register-bg-img`, Component: <RegisterBgImg /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/register-video`, Component: <RegisterVideo /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/unlock-user`, Component: <UnlockUser /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/forget-pwd`, Component: <ForgetPwd /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/create-pwd`, Component: <CreatePwd /> },
	// { path: `${process.env.PUBLIC_URL}/pages/authentication/maintenance`, Component: <Maintenance /> },

	//Coming soon
	// { path: `${process.env.PUBLIC_URL}/pages/coming/comingsimple`, Component: <ComingSimple /> },
	// {
	//   path: `${process.env.PUBLIC_URL}/pages/coming/comingbgimage`, Component: <ComingBgImg />
	// },

	//Error Page
	{
		path: `${process.env.PUBLIC_URL}/pages/error/error-page1`,
		Component: <ErrorPage1 />,
	},
	{
		path: `${process.env.PUBLIC_URL}/pages/error/error-page2`,
		Component: <ErrorPage2 />,
	},
	{
		path: `${process.env.PUBLIC_URL}/pages/error/error-page3`,
		Component: <ErrorPage3 />,
	},
	{
		path: `${process.env.PUBLIC_URL}/pages/error/error-page4`,
		Component: <ErrorPage4 />,
	},
];

import React from 'react';

const AudioPlayer = (props) => {
    const { link } = props;
  return (
    <div>
        <audio controls style={{ width: '100%' }}>
            <source src={link} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    </div>
  );
};

export default AudioPlayer;

import React, { Fragment, useState } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { PlusCircle } from "react-feather";
import { Btn } from "../../../AbstractElements";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TentorContain from "../../../Component/Classes/Tentor/TentorContain";
import { resetState } from "../../../Features/Learning/Vouchers/addVoucherSlice";
import TentorFilter from "../../../Component/Classes/Tentor/Filter";

const Tentors = () => {
	const cards = useSelector((state) => state.Tentor.data.data);
	const dispatch = useDispatch();
	const handleAdd = (e) => {
		dispatch(resetState());
	};

	return (
		<Fragment>
			<Col md="12" className="project-list">
				{cards && (
					<Card>
						<Row className="justify-content-between align-items-center mb-3">
							<Col className="d-flex justify-content-start">
								<Link
									to={`${process.env.PUBLIC_URL}/classes/tentor/add`}
									onClick={handleAdd}>
									<Btn attrBtn={{ color: "primary" }}>
										<i className="fa fa-plus-circle fa-lg"></i> Tambah Tentor
										Baru
									</Btn>
								</Link>
							</Col>
						</Row>
					</Card>
				)}
			</Col>
			<Container fluid={true}>
				<Row className="learning-block">
					<Col xl="9" className="xl-80">
						<Row>
							<TentorContain />
						</Row>
					</Col>
					{cards && cards.length > 0 && <TentorFilter />}
				</Row>
			</Container>
		</Fragment>
	);
};
export default Tentors;

import React from 'react';

function formatPhoneNumber(phone) {
    if (!phone) return ""; // Return empty string if phone number is undefined or null
    // Regular expression to match phone numbers with at least 4 digits in each group
    const regex = /(\d{4})(\d{4})(\d+)/;
    // Replace matched pattern with formatted phone number
    return phone.replace(regex, function(match, p1, p2, p3) {
        return `${p1} ${p2} ${p3}`;
    });
}

export default formatPhoneNumber;
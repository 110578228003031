import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, CardHeader, CardFooter, Media, Table, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Image, H6, H5, H4, LI, UL, P, Btn, Spinner, Badges } from '../../../../AbstractElements';
import formatPhoneNumber from '../../../../Component/Learning/Common/GenerateContent/FormatPhoneNumber';


const TentorDetailGeneral = () => {
    const detail = useSelector(state => state.Tentor.detail);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Fragment>
            <Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
                <Card className="social-widget-card">
                    <CardHeader>
                        <Col>
                            <Row>
                                <Col>
                                    <h5>{detail.name || 'Nama Tentor'}</h5>
                                </Col>
                            </Row>
                        </Col>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm="2" xl="2" lg="2">
                                <img src={detail.photo} alt="banner" className="img-fluid rounded" style={{ width: '150px', height: 'auto' }} />
                            </Col>
                            <Col sm="10" xl="10" lg="10">
                                <FormGroup className="mb-3">
                                    <Label className="form-label"><b>Materi Ajar:</b></Label>
                                    <Input readOnly className="form-control" defaultValue={detail.mastered_fields} />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <Label className="form-label"><b>Nomer Telepon:</b></Label>
                                    <Input readOnly className="form-control" defaultValue={formatPhoneNumber(detail.phone)} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col className='text-end'>
                                <Link to={`${process.env.PUBLIC_URL}/classes/tentor-list`}>
                                    <Btn attrBtn={{ color: 'warning', className: 'btn btn-primary m-r-10' }}>
                                        <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                                    </Btn>
                                </Link>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Col>
        </Fragment >
    );
};
export default TentorDetailGeneral;
import { createSlice } from '@reduxjs/toolkit';
import { fetchQRCode } from './Utils/ScheduleUtils';

const QRPresenceSlice = createSlice({
    name : 'QRCode',
    initialState : {
        data: {},
        isLoading: false,
    },
    reducers : {
        resetQRCode : (state) => {
            state.data = {};
        }
    },
    extraReducers : {
        [fetchQRCode.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchQRCode.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        [fetchQRCode.rejected]: (state) => {
            state.isLoading = false;
        },
    }
});

export const { resetQRCode } = QRPresenceSlice.actions;

export default QRPresenceSlice.reducer;
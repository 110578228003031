import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Post, Discard } from '../../../Constant';
import { Btn, H5, H6, Spinner } from '../../../AbstractElements';
import CategoriesForm from './CategoriesForm';
import { useSelector, useDispatch } from 'react-redux';
import { submitCategories } from '../../../Features/Learning/Categories/Utils/CategoriesUtils';
import { resetState } from '../../../Features/Learning/Categories/addCategoriesSlice';
import Swal from 'sweetalert2';

const CategoriesPostContain = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(state => state.addCategories)
  const startUpload = useSelector(state => state.addCategories.isLoading);
  const isUpdate = useSelector(state => state.addCategories.isUpdate);

  const handleSuccess = () => {
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/learning/category-list`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMessage = '';
    if(data.title === '') {
      errorMessage = 'Nama Kategori tidak boleh kosong.';
    } else if (data.is_main_program === '') {
      errorMessage = 'Kategori Program belum dipilih.';
    } else if (data.is_parent === '') {
      errorMessage = 'Kategori Induk belum dipilih.';
    } else if (data.is_question === '') {
      errorMessage = 'Kategori Pertanyaan belum dipilih.';
    } else if (data.icon === '' || data.icon === null) {
      errorMessage = 'Icon tidak boleh kosong.';
    } else if (data.is_parent === '0' && data.is_main_program === '0' && data.parent_id === '') {
      errorMessage = 'Induk Kategori belum dipilih.';
    }

    if ((data.title === '' || data.is_main_program === '' || data.is_parent === '' || data.is_question === '' || (data.icon === '' || data.icon === null) && !isUpdate)
        || (data.is_parent === '0' && data.is_main_program === '0' && data.parent_id === '')) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage,
      });
    } else {
      dispatch(submitCategories({ ...data, successCallback: handleSuccess }))
      // console.log(data);
    }
  };
  
  const handleDiscard = (e) => {
    e.preventDefault();
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/learning/category-list`);
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {startUpload ? (
              <Col lg="12">
                <div className="loader-box">
                  <Spinner attrSpinner={{ className: 'loader-30' }} />
                </div>
                <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses unggah...'}</H6>
              </Col>
            ) : (
            <Card>
              <CardHeader className='pb-0'>
                <H5>{isUpdate ? '' : 'Kategori Baru'}</H5>
              </CardHeader>
              <CardBody className="add-post">
                <CategoriesForm />
                <div className="btn-showcase text-end">
                  <Btn attrBtn={{ color: 'primary', type: 'submit', onClick: handleSubmit }}>{Post}</Btn>
                  <Btn attrBtn={{ color: 'light', type: 'reset', onClick: handleDiscard }}>{Discard}</Btn>
                </div>
              </CardBody>
            </Card>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CategoriesPostContain;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { TestimonyServices } from "../../../../Services/axios/Testimony.service";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const fetchTestimony = createAsyncThunk(
	"Testimony/fetchTestimony",
	async (filters) => {
		try {
			const response = await TestimonyServices.getAll(filters);
			return response.data;
		} catch (error) {
			if (error.response) {
				const statusCode = error.response.status;
				let errorMessage =
					"Terjadi kesalahan saat mengambil data. Mohon periksa koneksi internet Anda.";

				if (statusCode === 400) {
					errorMessage = "Permintaan tidak valid.";
				} else if (statusCode === 401) {
					errorMessage =
						"Anda tidak diotorisasi untuk mengakses sumber daya ini.";
				} else if (statusCode === 404) {
					errorMessage = "Sumber daya tidak ditemukan.";
				} else if (statusCode === 500) {
					errorMessage = "Terjadi kesalahan internal server.";
				}

				Swal.fire({
					icon: "error",
					title: "Error",
					text: errorMessage,
				});
			} else if (error.request) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Permintaan gagal. Mohon periksa koneksi internet Anda.",
				});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Terjadi kesalahan. Mohon coba lagi nanti.",
				});
			}
			throw error;
		}
	}
);

export const submitTestimony = createAsyncThunk(
	"Testimony/submitTestimony",
	async (data) => {
		try {
			const formData = new FormData();
			formData.append("user_id", data.user_id);
			data.membership_id && formData.append("membership_id", data.membership_id);
			formData.append("review", data.review);
			formData.append("star_rating", data.star_rating);
			formData.append("additional_info", data.additional_info);
			formData.append("show_in_home", data.show_in_home);

			let response;
			if (data.isUpdate) {
				response = await TestimonyServices.update(data.id, formData);
				toast.success("Testimony berhasil diperbarui", {
					position: toast.POSITION.TOP_RIGHT,
				});
			} else {
				response = await TestimonyServices.create(formData);
				toast.success("Testimony berhasil ditambahkan", {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
			data.successCallback && data.successCallback();
			return response.data.data;
		} catch (error) {
			console.log(error);
			for (const errorField in error.response.data.errors) {
				if (error.response.data.errors.hasOwnProperty(errorField)) {
					const errorMessages = error.response.data.errors[errorField];
					let errorMessage = errorMessages[0];
					toast.error(errorMessage, {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 3000,
					});
				}
			}
			if (error.code === "ERR_NETWORK") {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Permintaan gagal. Mohon periksa koneksi internet Anda.",
				});
			} else if (error.response.status === 500) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Terjadi kesalahan sistem. Mohon hubungi pengembang.",
				});
			}
		}
	}
);


export const handleEditTestimony = (dispatch, data, setField) => {
	for (const key in data) {
		if (data.hasOwnProperty(key)) {
			dispatch(setField({ field: key, value: data[key] }));
		}
		dispatch(setField({ field: "isUpdate", value: true }));
	}
}

export const deleteTestimony = createAsyncThunk(
	"Testimony/deleteTestimony",
	async (id) => {
		try {
			const response = await TestimonyServices.delete(id);
			toast.success("Testimony berhasil dihapus", {
				position: toast.POSITION.TOP_RIGHT,
			});
			return response.data.data;
		} catch (error) {
			if (error.response && error.response.data) {
				toast.error(error.response.data.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}
	}
);

export const attachDetachTestimonyMainProgram = createAsyncThunk(
	"Testimony/attachDetachMainProgram",
	async (data) => {
		const formData = new FormData();
		data.data.forEach((element) => {
			formData.append("main_program[]", element.value);
		});
		try {
			const response = await TestimonyServices.attachDetachMainProgram(
				data.id,
				formData
			);
			return response.data.data;
		} catch (error) {
			if (error.response && error.response.data) {
				toast.error(error.response.data.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}	
	}
);

import { createSlice } from '@reduxjs/toolkit';
import { fetchVouchers } from './Utils/VoucherUtils';

const VoucherSlice = createSlice({
    name : 'Vouchers',
    initialState : {
        filters : {
            'filter[id]' : '',
            'filter[code]' : '',
            'sort' : '',
            'include[0]' : 'membership',
        },
        data : [],
        detail : {},
        isLoading : false,
    },
    reducers : {
        setFilters : (state, action) => {
            state.filters = action.payload;
        },
        setData : (state, action) => {
            state.data = action.payload;
        },
        setDetail : (state, action) => {
            state.detail = action.payload;
        }
    },
    extraReducers : (builder) => {
        builder
            .addCase(fetchVouchers.pending, (state) => {
                // Handle loading state
                state.isLoading = true;
            })
            .addCase(fetchVouchers.fulfilled, (state, action) => {
                // Handle success state
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchVouchers.rejected, (state, action) => {
                // Handle error state
                state.isLoading = false;
                console.log('error', action);
            });
    }
    
});

export const { setFilters, setData, setDetail } = VoucherSlice.actions;

export default VoucherSlice.reducer;
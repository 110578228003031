import { axiosInstance } from "./axiosInstance";

export const TentorServices = {
	getAll: async (filter) => {
		return await axiosInstance.get("/api/feature/class/tentor", {
			params: filter,
		});
	},
	create: async (data) => {
		return await axiosInstance.post("/api/feature/class/tentor", data);
	},
	update: async (id, data) => {
		return await axiosInstance.post(
			`/api/feature/class/tentor/update/${id}`,
			data
		);
	},
	delete: async (id) => {
		return await axiosInstance.delete(`/api/feature/class/tentor/delete/${id}`);
	},
};

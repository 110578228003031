import { createSlice } from "@reduxjs/toolkit";
import { submitVoucher } from "./Utils/VoucherUtils";

const initialState = {
	code: "",
	is_percentage: 0,
	discount: "",
	valid_until: "",
	minimum_nominal: "",
	membership: [],
	isUpdate: false,
	id: "",
	isLoading: false,
};

const addVoucher = createSlice({
	name: "addVoucher",
	initialState: initialState,
	reducers: {
		setField: (state, action) => {
			const { field, value } = action.payload;
			state[field] = value;
		},
		resetState: (state) => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(submitVoucher.pending, (state) => {
				// Handle loading state
				state.isLoading = true;
			})
			.addCase(submitVoucher.fulfilled, (state, action) => {
				// Handle success state
				state.isLoading = false;
			})
			.addCase(submitVoucher.rejected, (state, action) => {
				// Handle error state
				console.log(action.error.message, "error action");
				state.isLoading = false;
			});
	},
});

export const { setField, resetState } = addVoucher.actions;

export default addVoucher.reducer;

import { axiosInstance } from "./axiosInstance";

export const TransactionServices = {
    getAll: async filter => {
        return await axiosInstance.get("/api/feature/membership/payment/transaction/admin", {
        params: filter
        });
    },
    setOrderStatus: async (id, data) => {
        return await axiosInstance.post(`/api/feature/membership/payment/transaction/status/${id}`, data);
    }
};
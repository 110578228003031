import React, { Fragment, useState } from 'react';
import { PlusCircle, Search } from 'react-feather';
import { Container, Row, Col, Card, CardHeader, Input } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Btn, H5, H6, Image, Spinner } from '../../../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import UsersList from './UsersList';
import Filter from '../../Common/Filter';
import RadioFilter from '../../../../Component/Learning/Common/Filter/RadioFilter';
import { setFilterUsers } from '../../../../Features/Learning/Users/userFilteredSlice';
import { fetchMemberships } from '../../../../Features/Learning/Memberships/Utils/MembershipUtils';
import { fetchUser, fetchUserFiltered } from '../../../../Component/Learning/Users/UsersUtils';


const UsersContain = ( props ) => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.userFiltered.filters);

  const handleClickFilter = (e) => {
    e.preventDefault()
   fetchUserFiltered(dispatch, filters)
  }

  const handleResetFilter = () => {
    const ResetStateFilter = {
        ...filters,
        'filter[name]': '',
        'filter[email]': '',
        'filter[phone]': '',
    }
    dispatch(setFilterUsers(ResetStateFilter));
    fetchUserFiltered(dispatch, ResetStateFilter)
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Row className='learning-block'>
          <Col xl="9" className='xl-80'>
            <Row>
              <UsersList setRefatch={props.setRefatch} />
            </Row>
          </Col>
          <Filter
                xlClass="3 xl-20"
                label="Filter Users Terdaftar"
                labelButton="Filter"
                labelReset="Reset"
                handleClickFilter={handleClickFilter}
                handleResetFilter={handleResetFilter}
            >
                <RadioFilter sort={'By Name'}>
                    <div className="job-filter">
                        <div className="faq-form">
                            <Input className="form-control" type="text" placeholder="Search name.."
                                value={filters['filter[name]']}
                                onKeyUp={(e) => e.key === 'Enter' ? handleClickFilter(e) : null}
                                onChange={(e) => {
                                    dispatch(setFilterUsers({
                                        ...filters,
                                        'filter[name]': e.target.value,
                                        'page': 1,
                                    }));
                                }}
                            />
                            <Search className="search-icon" />
                        </div>
                    </div>
                </RadioFilter>
                <RadioFilter sort={'By Email'}>
                    <div className="job-filter">
                        <div className="faq-form">
                            <Input className="form-control" type="email" placeholder="Search email.."
                                value={filters['filter[email]']}
                                onKeyUp={(e) => e.key === 'Enter' ? handleClickFilter(e) : null}
                                onChange={(e) => {
                                    dispatch(setFilterUsers({
                                        ...filters,
                                        'filter[email]': e.target.value,
                                        'page': 1,
                                    }));
                                }}
                            />
                            <Search className="search-icon" />
                        </div>
                    </div>
                </RadioFilter>
                <RadioFilter sort={'By Phone'}>
                    <div className="job-filter">
                        <div className="faq-form">
                            <Input className="form-control" type="number" placeholder="Search number.."
                                value={filters['filter[phone]']}
                                onKeyUp={(e) => e.key === 'Enter' ? handleClickFilter(e) : null}
                                onChange={(e) => {
                                    dispatch(setFilterUsers({
                                        ...filters,
                                        'filter[phone]': e.target.value,
                                        'page': 1,
                                    }));
                                }}
                            />
                            <Search className="search-icon" />
                        </div>
                    </div>
                </RadioFilter>
          </Filter>
        </Row>
      </Container>
    </Fragment>
  );
};
export default UsersContain;
import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row, CardBody} from 'reactstrap';
import { H1, H2, H3, H4, H5, H6, Image, Btn, LI, P, UL, Spinner } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDetail, setFilters } from '../../../Features/Learning/Courses/courseSlice';
import { setField } from '../../../Features/Learning/Courses/addCourseSlice';
import { 
    fetchCourse,
    handleDeleteCourse,
    handleEditCourse,
    handleGetDetailCourse,
    handleNextPagination,
    handleBackPagination,
    handleClickPagination
} from './CoursesUtils';
import PaginationComponent from '../Common/Pagination';
import Moment from 'moment';

const CoursesBox = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const filters = useSelector(state => state.Course.filters)
  const isLoading = useSelector(state => state.Course.isLoading)
  const cards = useSelector(state => state.Course.data.data)
  const pagination = useSelector(state => state.Course.data.pagination)
  const [refatch, setRefatch] = useState(false)
//   const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchCourse(dispatch, filters, refatch)
    window.scrollTo(0, 0);
  }, [refatch])


  return (
      <Fragment>
      { isLoading ? (
        <Col lg="12">
            <div className="loader-box">
                <Spinner attrSpinner={{ className: 'loader-30' }} />
            </div>
            <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
        </Col>
      ) : (
        cards && cards.map((item) => {
          return (
            <Col xl="6" sm="6" lg="6" key={`tryout-${item.id}`} className='box-col-6'>
                <Card>
                    <div className="product-box learning-box">
                        <div className="product-img">
                            <Image attrImage={{ className: 'img-fluid top-radius-blog', src: item.banner, alt: '' }} />
                            <div className="product-hover">
                                <UL attrUL={{ className: 'simple-list' }}>
                                    <li title="delete" onClick={() => handleDeleteCourse(dispatch, item.id, setRefatch)}>
                                        <i className="icofont icofont-trash text-danger"></i>
                                    </li>
                                    <Link to={`${process.env.PUBLIC_URL}/membership/course-add/${item.id}`} onClick={() => handleEditCourse(dispatch, item, setField)}>
                                      <li title="edit" style={{ marginBottom: '10px', marginTop: '10px' }} >
                                          <i className="icofont icofont-edit"></i>
                                      </li>
                                    </Link>
                                    <Link to={`${process.env.PUBLIC_URL}/membership/course-detail/${item.id}`} onClick={() => handleGetDetailCourse(dispatch, item, setDetail)}>
                                      <li title="detail">
                                          <i className="icofont icofont-info-circle"></i>
                                      </li>
                                    </Link>
                                </UL>
                            </div>
                        </div>
                        <div className="details-main">
                            <Link to={`${process.env.PUBLIC_URL}/membership/course-detail/${item.id}`}
                              onClick={() => handleGetDetailCourse(dispatch, item, setDetail)}
                            >
                                <div className='bottom-details'>
                                    <H6>{item.title}</H6>
                                </div>
                            </Link>
                            <P>Created at : {Moment(item.created_at).format('ddd, DD/MM/YYYY')} - {Moment(item.created_at).format('HH:mm:ss')} WIB</P>
                        </div>
                    </div>
                </Card>
            </Col>
          );
        })
        
        )}
        
        {!cards || cards.length === 0 && (
            <Col lg="12">
                <H4 attrH4={{ className: 'sub-title mb-5 text-center' }}>{!cards ? 'Sumber Data Tidak Tersedia' : 'Data Tidak Ditemukan'}</H4>
            </Col>
        )}

        <CardBody>
      {isLoading === false && cards && cards.length > 0 && pagination && (
            <PaginationComponent 
            pagination={pagination}
            handleBackPagination={() => handleBackPagination(pagination, filters, dispatch, setFilters, setRefatch)}
            handleClickPagination={(page) => handleClickPagination(page, filters, dispatch, setFilters, setRefatch)}
            handleNextPagination={() => handleNextPagination(pagination, filters, dispatch, setFilters, setRefatch)}
            />
        )}
        </CardBody>
    </Fragment>
  );
};

export default CoursesBox;
import { fetchTickets, markAsSolved } from "../../../Features/Learning/Ticket/Utils/TicketUtils";
import Swal from "sweetalert2";

/* **************************************************** */
/* CRUD Tickets                                         */
/* **************************************************** */

export const fetchTicket = (dispatch, filters, refetch) => {
    dispatch(fetchTickets(filters));
}

export const handleMarkAsSolved = async (dispatch, id, setRefetch) => {
    try {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Pastikan anda telah memperbaiki masalah soal yang dilaporkan!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ya, laporan soal telah terselesaikan!',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(markAsSolved(id));
                setRefetch(prevRefetch => !prevRefetch);
                Swal.fire(
                    'Terselesaikan!',
                    'Laporan soal telah diselesaikan.',
                    'success'
                )
            }
        })
    } catch (error) {
        console.error("Delete error:", error);
    }
}

export const handleDetailTicket = async (dispatch, item, setDetail) => {
    dispatch(setDetail(item));
}

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
    if (pagination.current_page > 1) {
        dispatch(setFilters({
            ...filters,
            page: pagination.current_page - 1,
        }));
        setRefetch(prevRefetch => !prevRefetch);
    }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
    if (pagination.current_page < pagination.total_pages) {
        dispatch(setFilters({
            ...filters,
            page: pagination.current_page + 1,
        }));
        setRefetch(prevRefetch => !prevRefetch);
    }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
    dispatch(setFilters({
        ...filters,
        page: page,
    }));
    setRefetch(prevRefetch => !prevRefetch);
}
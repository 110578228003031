import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Input, Collapse, Label, Button } from 'reactstrap';
import { Search } from 'react-feather';
import { Filter } from '../../../../Constant';
import { H5, Btn } from '../../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../../Features/Classes/Schedule/ScheduleSlice';
import { fetchSchedule } from '../../../Classes/Schedule/ScheduleUtils';
import FilterTentorSchedule from '../../../Classes/Schedule/Filter/FilterTentorSchedule';
import Select from 'react-select';

const FindSchedule = () => {
  const [isFilter, setIsFilter] = useState(true);
  const dispatch = useDispatch()
  const filters = useSelector(state => state.Schedule.filters)
  const [selectedSortValue, setSelectedSortValue] = useState(null)

  const options = [
    { value: '-name', label: 'A-Z' },
    { value: 'name', label: 'Z-A' },
    { value: '-id', label: 'Terbaru' },
    { value: 'id', label: 'Terlama' },
  ];

  useEffect(() => {
    const selectedSort = filters.sort ? options.find(option => option.value === filters.sort) : null;
    setSelectedSortValue(selectedSort);
  }, [ filters.sort ]);

  const handleSelectChangeSort = (selectedOption) => {
    setSelectedSortValue(selectedOption);
    const newFilters = {
      ...filters,
      'sort': selectedOption === null ? '' : selectedOption.value,
    }
    dispatch(setFilters(newFilters));
    fetchSchedule(dispatch, newFilters);
  }

  const handleClickSearch = (e) => {
    e.preventDefault()
    const newFilters = {
      ...filters,
      'page': 1,
    };
    dispatch(setFilters(newFilters));
    fetchSchedule(dispatch, newFilters);
  }

  const handleResetFilter = (e) => {
    e.preventDefault()
    const newFilters = {
      ...filters,
        'filter[id]': '',
        'filter[name]': '',
        'filter[tentor_id]': '',
        'filter[place]': '',
        'sort': '',
    }
    dispatch(setFilters(newFilters));
    fetchSchedule(dispatch, newFilters);
  }

  return (
    <Fragment>
      <Col xl="12">
        <div className="job-filter">
          <div className="faq-form">
            <Row>
              <Col className="mt-3">
                <Input className="form-control" type="text" placeholder="Cari sesi.."
                  value={filters['filter[name]']}
                  onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                  onChange={(e) => {
                    dispatch(setFilters({
                      ...filters,
                      'filter[name]': e.target.value
                    }));
                  }}
                />
                <Search className="search-icon" />
              </Col>
              <Col className="mt-3">
                <Input className="form-control" type="text" placeholder="Cari ruang.."
                  value={filters['filter[place]']}
                  onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                  onChange={(e) => {
                    dispatch(setFilters({
                      ...filters,
                      'filter[place]': e.target.value
                    }));
                  }}
                />
                <Search className="search-icon" />
              </Col>
              <Col className="mt-3">
                <Select
                  options={options}
                  className="js-example-basic-single"
                  isMulti={false}
                  isClearable={true}
                  placeholder="Sort by.."
                  onChange={handleSelectChangeSort}
                  value={selectedSortValue}
                />
              </Col>
              <Col className="mt-3">
                <FilterTentorSchedule />
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <Button color="warning me-2" onClick={handleResetFilter}>Reset</Button>
                <Button color="primary me-2" onClick={handleClickSearch}>Filter</Button>
              </Col>
            </Row>
          </div>
        </div>        
      </Col>
    </Fragment>
  );
};

export default FindSchedule;
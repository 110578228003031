import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, CardHeader, CardFooter, Media, Table, Row } from 'reactstrap';
import dragula from 'react-dragula';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Image, H6, H5, H4, LI, UL, P, Btn, Spinner } from '../../../AbstractElements';
import AttachModal from '../../../Component/Learning/Common/TryoutModal/AttachModal';
import { detachTryout, orderTryoutQuestion, fetchTryout } from '../../../Features/Learning/Tryout/Utils/TryoutUtils';
import { handleDetach } from '../../../Component/Learning/Tryout/TryoutUtils';
import { setIdDetach, setDetachments } from '../../../Features/Learning/Tryout/detachTryoutSlice';
import { setIdAttach } from '../../../Features/Learning/Tryout/attachTryoutSlice';
import { setDetail } from '../../../Features/Learning/Tryout/tryoutSlice';
import { setFilters } from '../../../Features/Learning/BankQuestions/QuestionSlice';
import RawComponent from '../../../Component/Learning/Common/RawHTML';
import { fetchQuestions } from '../../../Component/Learning/BankQuestion/QuestionsUtils';
import ModalComponent from '../../../Component/Learning/Common/Modal/ModalGeneral';
import Moment from 'moment';
import Swal from 'sweetalert2';

const BoxListTryoutDetail = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const detail = useSelector(state => state.Tryout.detail);
  const data = useSelector(state => state.detachTryout);
  const filterTryout = useSelector(state => state.Tryout.filters)
  const filtersQuestion = useSelector(state => state.Question.filters)
  const isLoading = useSelector(state => state.Tryout.isLoading)
  const [isSaveButtonVisible, setSaveButtonVisible] = useState(false);
  const [refatch, setRefatch] = useState(false)
  const [orderQuestion, setOrderQuestion] = useState([])

  useEffect(() => {
    if (detail && detail.id) {
      dispatch(setIdDetach(detail.id))
      dispatch(setIdAttach(detail.id))
      window.scrollTo(0, 0);
    }
    // fetchQuestions(dispatch, filtersQuestion);
  }, [detail])

  const dragulaDecorator = (componentBackingInstance) => {
    // console.log(componentBackingInstance, 'componentBackingInstance')
    if (componentBackingInstance) {
      let options = {
        moves: function (el, container, handle) {
          return handle.classList.contains('handle');
        }
      };
      dragula([componentBackingInstance], options).on('drop', function (el, target, source, sibling) {
        setSaveButtonVisible(true);
        // get array order id from elements
        const containerChildren = Array.from(target.children);
        //return new format array include id, order
        const order = containerChildren.map(child => child.id);
        setOrderQuestion(order)
      }
      );
    }
  };

  const handleSaveButtonClick = async () => {
    // Tambahkan logika penyimpanan atau tindakan lain di sini
    Swal.fire({
      title: 'Konfirmasi',
      text: 'Apakah Anda yakin ingin menyimpan urutan soal?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya, Simpan',
      cancelButtonText: 'Batal',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Lakukan sesuatu setelah pengguna mengonfirmasi
        // console.log('Urutan elemen:', orderQuestion);
        // console.log('detail:', detail);
        // setSaveButtonVisible(false); // Sembunyikan tombol setelah menyimpan
        const numberArray = orderQuestion.map(Number);
        const numberString = numberArray.toString();
        const dataOrder = {
          id: detail.id,
          question_id: numberString,
        }
        try {
          const setOrder = await dispatch(orderTryoutQuestion(dataOrder))
          if (setOrder.payload === true) {
            Swal.fire({
              icon: 'success',
              title: 'Sukses',
              text: 'Urutan soal berhasil disimpan',
            });
            setSaveButtonVisible(false); // Sembunyikan tombol setelah menyimpan
            try {
              const newFilter = {
                ...filterTryout,
                'filter[id]': detail.id,
                'include[1]': 'questions',
                'page': 1,
              };
              const getTryout = await dispatch(fetchTryout(newFilter));
              if (getTryout.payload.success === true) {
                dispatch(setDetail(getTryout.payload.data[0]));
              }
            } catch (error) {
              console.log('Error getTryout after setOrder:', error);
            }
          }
          
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Gagal',
            text: 'Urutan soal gagal disimpan',
          });
        }
      }
    });
  };

  return (
    <Fragment>
      {isLoading ? (
        <Col lg="12">
          <div className="loader-box">
            <Spinner attrSpinner={{ className: 'loader-30' }} />
          </div>
          <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
        </Col>
      ) : (
      <Row>
        <Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
            <Card className="social-widget-card">
                 <CardBody>
                    <Col>
                        <Row>
                            <Col>
                                <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>{detail.title || 'Title Tryout'}</H5>
                            </Col>  
                            <Col className='text-end'>
                                <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>Tipe {detail.is_quiz === 1 ? 'Kuis' : 'Tryout'}</H5>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <H6 attrH6={{ className: 'font-roboto mt-4' }}>{'Deskripsi Tryout'}</H6>
                        <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}><RawComponent htmlContent={detail.description || 'Description Tryout'} /></H5>
                    </Col>
                    <Row>
                      <Col>
                          <H6 attrH6={{ className: 'font-roboto mt-4' }}>{'Kategori Tryout'}</H6>
                          <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>{ (detail.category && detail.category.title) || 'Category Tryout'}</H5>
                      </Col>

                      <Col>
                          <H6 attrH6={{ className: 'font-roboto mt-4' }}>{'Kuota Tryout'}</H6>
                          <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>{detail.quotas || 'Quotas Tryout'} Orang</H5>
                      </Col>

                      <Col>
                          <H6 attrH6={{ className: 'font-roboto mt-4' }}>{'Durasi Tryout'}</H6>
                          <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>{detail.time || 'Time Tryout'} Menit</H5>
                      </Col>
                      </Row>
                    <Row>
                      <Col>
                          <H6 attrH6={{ className: 'font-roboto mt-4' }}>{'Tgl Mulai'}</H6>
                          <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>{Moment(detail.start_at).format('ddd, D MMMM YYYY')}</H5>
                          <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>{Moment(detail.start_at).format('HH:mm:ss')} WIB</H5>
                      </Col>

                      <Col>
                          <H6 attrH6={{ className: 'font-roboto mt-4' }}>{'Tgl Berakhir'}</H6>
                          <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>{Moment(detail.end_at).format('ddd, D MMMM YYYY')}</H5>
                          <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>{Moment(detail.end_at).format('HH:mm:ss')} WIB</H5>
                      </Col>
                    </Row>
                    <Row>
                      {/* {detail.instruction && (
                      <Col>
                        <H6 attrH6={{ className: 'font-roboto mt-4 mb-2' }}>{'Instruksi'}</H6>
                        <ModalComponent title="Instruksi" type="html" content={detail.instruction} buttonLabel="Lihat Instruksi" size="lg"/>
                      </Col>
                      )} */}
                      {detail.ads_video && (
                      <Col>
                        <H6 attrH6={{ className: 'font-roboto mt-4 mb-2' }}>{'Video Iklan'}</H6>
                        <ModalComponent title="Video Iklan" type="video" content={detail.ads_video} buttonLabel="Lihat Video Iklan" size="lg"/>
                      </Col>
                      )}
                      {detail.tutorial_video && (
                      <Col>
                        <H6 attrH6={{ className: 'font-roboto mt-4 mb-2' }}>{'Video Tutorial'}</H6>
                        <ModalComponent title="Video Tutorial" type="video" content={detail.tutorial_video} buttonLabel="Lihat Video Tutorial" size="lg"/>
                      </Col>
                      )}
                    </Row>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col>
                      <H6 attrH6={{ className: 'font-roboto' }}>Created at: {Moment(detail.created_at).format('dddd, D MMMM YYYY')} - {Moment(detail.created_at).format('HH:mm:ss')} WIB</H6>
                    </Col>
                    <Col className='text-end'>
                      <Link to={`${process.env.PUBLIC_URL}/learning/tryout-list`}>
                        <Btn attrBtn={{ color: 'warning', className: 'btn btn-primary m-r-10' }}>
                          <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                        </Btn>
                      </Link>
                      <AttachModal item={detail} type={'attach'}/>
                    </Col>
                  </Row>
                </CardFooter>
            </Card>
        </Col>
        {/* { detail.question && detail.question.length > 0 && ( */}
        <Col sm="12 order-2">
          <Card>
            <CardHeader>
              <H5>{'List Soal Tryout'}</H5>
            </CardHeader>
            <div>
              <Table className="table-responsive">
                <thead className="table-light">
                  <tr>
                    <th scope="col" width="8%" className="text-center">{'#'}</th>
                    <th scope="col">{'Judul Soal'}</th>
                    <th scope="col" width="15%" className="text-center">{'Tipe Soal'}</th>
                    <th scope="col" className="text-center">{'Kategori Soal'}</th>
                    <th scope="col" className="text-center" colSpan={2}>{'Aksi'}</th>
                  </tr>
                </thead>
                <tbody ref={dragulaDecorator}>
                  {detail.question && detail.question.length > 0 ? (
                    detail.question && detail.question.map((item, index) =>
                      <tr key={`tryout-${index}`} id={item.id}>
                        <td className="text-center">{index + 1}</td>
                        <td>
                          {item.title === '' ? 'No Title' : item.title}
                          <Btn
                            title="Lihat soal"
                            attrBtn={{
                              color: 'link',
                              outline: true,
                              className: 'p-1 ms-2',
                              onClick: () => {
                                dispatch(setFilters({
                                  ...filtersQuestion,
                                  'filter[title]': item.title
                                }))
                                history(`${process.env.PUBLIC_URL}/learning/soal-list`)
                              }
                            }}
                          >
                            <i className="fa fa-share-square-o mx-auto fa-lg" style={{ color: '#376673' }}></i>
                          </Btn>
                        </td>
                        <td className="text-center">
                          {item.type === 'fill_in_the_blanks'
                            ? 'Isian Singkat'
                            : item.type === 'multiple_choice'
                              ? 'Pilihan Ganda'
                              : item.type === 'sentence_arrangement'
                                ? 'Susunan Kalimat'
                                : 'Other'}
                        </td>
                        <td className="text-center">{item.pivot.category.title.replace(/\d/g, '')}</td>
                        <td className="justify-content-end d-flex">
                          <AttachModal item={item} type={'question'} />
                          <AttachModal item={item} type={'update'} />
                          <Btn
                            title={'Hapus Soal'}
                            attrBtn={{
                              color: 'link',
                              outline: true,
                              className: 'p-1',
                              onFocus: () => {
                                dispatch(setDetachments({ ...data.detachments, 'question_id': item.pivot['question_id'], 'category_id': item.pivot['category_id'], 'order': item.pivot['order'] }))
                              },
                              onClick: () => {
                                handleDetach(detail, setDetail, item, data, dispatch, detachTryout, setRefatch)
                              }
                            }}
                          >
                            <i className="fa fa-trash-o mx-auto text-danger fa-lg"></i>
                          </Btn>
                        </td>
                        <td className="handle"><Btn attrBtn={{ color: 'link', outline: true, className: 'p-1', style: { pointerEvents: 'none' } }}><i className="fa fa-align-justify mx-auto"></i></Btn></td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan={5} className='text-center'>{'Belum ada soal'}</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={6} className='text-end'>
                      {isSaveButtonVisible && (
                        <Btn attrBtn={{ color: 'warning', onClick: handleSaveButtonClick }}><i className="fa fa-list-ol fa-lg me-2"></i>Simpan Urutan Soal</Btn>
                      )}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </Card>
        </Col>
        {/* )} */}
      </Row>
      )}
    </Fragment >
  );
};
export default BoxListTryoutDetail;
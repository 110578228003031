import React, { useState, Fragment} from 'react';
import { Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { H6, Image, Btn, LI, P, UL } from '../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { setField } from '../../../Features/Classes/Class/addClassSlice';
import RichEditor from '../../../Component/Editor/RichEditor/RichEditor';


const ClassForm = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.addClass)
  const handleChanges = (e) => {
    const { name, value } = e.target;
    dispatch(setField({ field: name, value: value }));
    console.log(name, value);
  }
  const handleChangeDescription = (e) => {
    dispatch(setField({ field: 'description', value: e }));
  }
  return (
    <Fragment>
      <Form className="row needs-validation">
        <Col sm="12">
          <FormGroup className='mb-3'>
            <Label for="validationName" style={{ fontWeight: 'bold' }}>Nama Kelas:</Label>
            <Input className="form-control" id="validationName" type="text" name="name" placeholder="Masukkan Nama Kelas..." required=""
            value={data.name}
            onChange={handleChanges} />
            <div className="valid-feedback">{'Looks good!'}</div>
          </FormGroup>
          <FormGroup className='mb-3'>
            <Label for="validationDescription" style={{ fontWeight: 'bold' }}>Deskripsi Kelas:</Label>
            <RichEditor id="validationDescription" handleChangeDescription={handleChangeDescription} description={data.description} />
          </FormGroup>
          {data.bannerLink && (
            <FormGroup className='mb-3'>
              <Image attrImage={{ className: 'img-fluid', width: '500px', src: data.bannerLink, alt: data.name }} />
            </FormGroup>
          )}
        </Col>
      </Form>
    </Fragment>
  );
};
export default ClassForm;
import React, { Fragment, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import FindCategory from './FindCategory';
import LearningContext from '../../../_helper/Leatning';

const CategoryFilter = () => {
  const { isOpen, setIsOpen } = useContext(LearningContext);
  const onClickeHandel = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Fragment>
      <Col xl="3 xl-20">
        <div className="job-sidebar md-sidebar"><a className="btn btn-primary md-sidebar-toggle" href="#javascript" onClick={onClickeHandel}>Filter Kategori</a>
          <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen ? 'open' : ''}`}>
            <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
              <Row>
                <FindCategory />
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default CategoryFilter;
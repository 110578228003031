import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import CategoriesContain from '../../../Component/Learning/Categories';

const CategoriesList = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Learning List" parent="Learning" title="Daftar Kategori" />
            <CategoriesContain />
        </Fragment>
    );
};
export default CategoriesList;
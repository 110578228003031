import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, CardHeader, CardFooter, Media, Table, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Image, H6, H5, H4, LI, UL, P, Btn } from '../../../AbstractElements';
import RawComponent from '../../../Component/Learning/Common/RawHTML';
import GenerateUniqueContain from '../../../Component/Learning/Common/GenerateUnique';
import CleanLimitHTML from '../../../Component/Learning/Common/GenerateContent/CleanLimitHtml';
import { handleGetDetailSubMaterial, handleEditSubMaterial } from '../../../Component/Learning/SubMateri/SubMateriUtils';
import { setDetail } from '../../../Features/Learning/SubMateri/subMaterialSlice';
import { setField } from '../../../Features/Learning/SubMateri/addSubMaterialSlice';
import Moment from 'moment';
import 'moment/locale/id';

const BoxListCourseDetail = () => {
    const detail = useSelector(state => state.Material.detail);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
                <Card className="social-widget-card">
                    <CardHeader>
                            <Row>
                                <Col>
                                    <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>{detail.name || 'Title Materi'}</H5>
                                </Col>
                                <Col className='text-end'>
                                    <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>ID Materi : {GenerateUniqueContain('MTR', detail.id, 5) || 'ID Materi'}</H5>
                                </Col>
                            </Row>
                    </CardHeader>
                    <CardBody>
                        <Col>
                            <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary' }}>{'Deskripsi Materi'}</H5>
                            <H6 attrH6={{ className: 'font-roboto mt-4 font-primary' }}><RawComponent htmlContent={detail.description || 'Description Materi'} /></H6>
                        </Col>
                        <Col>
                            <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-4' }}>{'Banner Materi'}</H5>
                            <div className="blog-page blog-box mt-2">
                                <img style={{ width: "30%" }} src={detail.image} alt={detail.name} />
                            </div>
                        </Col>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col>
                                <H6 attrH6={{ className: 'font-roboto' }}>Created at: {Moment(detail.created_at).format('dddd, D MMMM YYYY')} - {Moment(detail.created_at).format('HH:mm:ss')} WIB</H6>
                            </Col>
                            <Col className='text-end'>
                                <Link to={`${process.env.PUBLIC_URL}/learning/materi-list`}>
                                    <Btn attrBtn={{ color: 'primary', className: 'btn btn-primary m-r-10' }}>
                                        <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                                    </Btn>
                                </Link>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Col>
            {detail.submaterials && detail.submaterials.length > 0 && (
                <Col sm="12 order-2">
                    <Card>
                        <CardHeader>
                            <H5>List Submateri {detail.name}</H5>
                        </CardHeader>
                        <div>
                            <Table className="table-responsive table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col">{'#'}</th>
                                        <th scope="col">{'Nama Submateri'}</th>
                                        <th scope="col">{'Deskripsi'}</th>
                                        <th scope="col">{'Tipe'}</th>
                                        <th colSpan="2" scope="col" className='text-center'>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        detail.submaterials && detail.submaterials.map((item, index) =>
                                            <tr key={`course-${index}`}>
                                                <th>{index + 1}</th>
                                                <td>{item.name}</td>
                                                <td><CleanLimitHTML htmlContent={item.description} limit={80} /></td>
                                                <td>{item.type.charAt(0).toUpperCase() + item.type.slice(1)}</td>
                                                <td className='text-center'>
                                                    <Link to={`${process.env.PUBLIC_URL}/learning/sub-materi-detail/${item.id}`}
                                                        // onClick={() => handleGetDetailSubMaterial(dispatch, item, setDetail)}
                                                    >
                                                        <i className="fa fa-info-circle mx-auto fa-lg"></i>
                                                    </Link>
                                                </td>
                                                <td className='text-center'>
                                                    <Link to={`${process.env.PUBLIC_URL}/learning/sub-materi-add/${item.id}`} className="p-1" 
                                                        onClick={() => handleEditSubMaterial(dispatch, item, setField)}
                                                    >
                                                        <i className="fa fa-edit text-black mx-auto fa-lg"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                </Col>
            )
            }
        </Fragment >
    );
};
export default BoxListCourseDetail;
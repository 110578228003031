import React, { useState, Fragment, useEffect } from 'react';
import { Col, Form, FormGroup, Label, Input, Row, Tooltip } from 'reactstrap';
import { PlusCircle, Trash2 } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { Btn } from '../../../AbstractElements';
import { setField } from '../../../Features/Learning/BankQuestions/addQuestionSlice';
import RichEditor from '../../../Component/Editor/RichEditor/RichEditor';
import FormSelectContain from '../../../Component/Learning/BankQuestion/QuestionsSelectForm';
import DropzoneCommon from '../../../Component/Learning/Common/Upload/Dropzone';
import VideoPlayer from '../../../Component/Learning/DetailSubMateri/VideoPlayer';
import AudioPlayer from '../../../Component/Learning/DetailSubMateri/AudioPlayer';
import Swal from 'sweetalert2';
import RawHTMLComponent from '../../../Component/Learning/Common/RawHTML';
import ModalComponent from '../../../Component/Learning/Common/Modal/ModalGeneral';


const QuestionForm = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.addQuestion)
  const [isPreview, setIsPreview] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  // console.log("data", data)

  useEffect(() => {
    // console.log("masuk")
    if (data.answer.length === 0) {
      dispatch(setField({ field: 'answer', value: [''] }));
    }
  }, []);
    
    
  const handleAddOption = () => {
    const updatedOptions = [...data.answer];
    updatedOptions.push('');
    const updatedChoice = [...data.choice];
    updatedChoice.push('');
    dispatch(setField({ field: 'answer', value: updatedOptions }));
    dispatch(setField({ field: 'choice', value: updatedChoice }));
  };

  const handleRemoveOption = (index) => {
    // handle remove option for data.choice 
    const updatedOptions = [...data.choice];
    updatedOptions.splice(index, 1);
    dispatch(setField({ field: 'choice', value: updatedOptions }));
    // handle remove option for data.answer
    const updatedAnswer = [...data.answer];
    updatedAnswer.splice(index, 1);
    dispatch(setField({ field: 'answer', value: updatedAnswer }));
  };

  const handleChangeChoice = (index, value) => {
    // console.log("index", index)
    // console.log("value", value)
    const updatedOptions = [...data.choice];
    const cleanedDescription = value ? value.replace(/<\/?[^>]+(>|$)/g, '') : '';
    // console.log("change choice")
    if (cleanedDescription.trim() === '') {
      updatedOptions[index] = '';
      dispatch(setField({ field: 'choice', value: updatedOptions }));
    } else {
      updatedOptions[index] = value;
      dispatch(setField({ field: 'choice', value: updatedOptions }));
    }
  }

  const handleChangeAnswer = (e, index, field) => {
    const updatedOptions = [...data.answer];
    updatedOptions[index] = e.target.value;
    dispatch(setField({ field: field, value: updatedOptions }));
  };

  const handleChangeTitle = (e, field) => {
    dispatch(setField({ field: field, value: e.target.value }));
  };

  const handleChangeDescription = (e) => {
    // const cleanedDescription = e.replace(/<\/?[^>]+(>|$)/g, '');

    // if (cleanedDescription.trim() === '') {
      // dispatch(setField({ field: 'description', value: '' }));
    // } else {
      dispatch(setField({ field: 'description', value: e }));
    // }
  }

  const handleChangeExplanation = (e) => {
    const cleanedDescription = e.replace(/<\/?[^>]+(>|$)/g, '');

    if (cleanedDescription.trim() === '') {
      dispatch(setField({ field: 'answer_explanation', value: '' }));
    } else {
      dispatch(setField({ field: 'answer_explanation', value: e }));
    }
  }

  const handleChangeAnswer_explanation_video = ({ meta, file }, status) => {
    if (status === 'removed') {
      dispatch(setField({ field: 'answer_explanation_video', value: '' }));
    } else if (status === 'done') {
      dispatch(setField({ field: 'answer_explanation_video', value: file }));
    }
  };

  const handleChangeAudio = ({ meta, file }, status) => {
    if (status === 'removed') {
      dispatch(setField({ field: 'audio_source', value: '' }));
    } else if (status === 'done') {
      dispatch(setField({ field: 'audio_source', value: file }));
    }
  };

  const handleDeleteFile = (e,type) => {
        e.preventDefault();
        console.log(type)
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda tidak dapat mengembalikan Video Pembahasan yang telah dihapus!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
              if (type === "answer_explanation_video") {
                dispatch(setField({ field: 'answer_explanation_video', value: 'delete' }));
                dispatch(setField({ field: 'answer_explanation_videoLink', value: '' }));
              } else if (type === "audio_source") {
                dispatch(setField({ field: 'audio_source', value: 'delete' }));
                dispatch(setField({ field: 'audio_sourceLink', value: '' }));
              }
            }
        })
    }
  
    
  return (
    <Fragment>
      <Col sm="12">
        <FormGroup className='mb-3'>
          <Label for="validationTitle" style={{ fontWeight: 'bold' }}>{'Label Soal'}:</Label>
          <Input className="form-control" id="validationTitle" type="text" name="title" placeholder="Tuliskan label soal..."
            value={data.title || ''}
            onChange={(e) => handleChangeTitle(e, 'title')} />
        </FormGroup>
        <FormGroup className='mb-3'>
          {/* <Label for="validationDescription" style={{ fontWeight: 'bold', marginBottom: '15px', marginRight: '10px' }}>Pertanyaan :</Label>
          {data.description && (
            <span>
              <Btn attrBtn={{ className: `btn ${isPreview ? 'btn-light' : 'btn-dark'} btn-xs`, onClick: () => setIsPreview(!isPreview), id: 'Tooltip-1' }}>
                <i className={`fa ${isPreview ? 'fa-eye-slash' : 'fa-eye'} fa-lg`}></i>
              </Btn>
              <Tooltip placement="top" isOpen={tooltipOpen} target="Tooltip-1" toggle={toggle}>
                {isPreview ? 'Tutup' : 'Lihat'} preview
              </Tooltip>
            </span>
          )}
          {data.description && isPreview && (
            <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px', marginBottom: '15px' }}>
              <Label for="validationImage" style={{ fontWeight: 'bold'}}>
                Preview Pertanyaan :
              </Label>
              <hr />
              <RawHTMLComponent htmlContent={data.description} />
            </div>
          )} */}
          <div style={{ display: 'flex', alignItems: 'top' }}>
              <Label for="validationImage" style={{ fontWeight: 'bold', marginBottom: '15px', marginRight: '10px' }}>
                Pertanyaan :
              </Label>
              {data.description && (
                <ModalComponent title="Preview pertanyaan" type="html" content={data.description} buttonLabel={<i className="fa fa-info-circle fa-lg"></i>} buttonSize="xs" size="lg" messageTooltip="Preview pertanyaan"/>
              )}
          </div>
          <RichEditor id="validationDescription" handleChangeDescription={handleChangeDescription} description={data.description} />
        </FormGroup>
        {/* {console.log("masuk 2")} */}
        <FormSelectContain />
        {(data.type === 'multiple_choice' || data.type === 'sentence_arrangement' || data.type === 'fill_in_the_blanks') && (
          // data.answer && console.log("answer data",data.answer),
          data.answer.map((option, index) => (
            <Row key={index}>
              {/* {console.log(index, "index")} */}
              {(data.type === 'multiple_choice') && (
                <Col className={data.type === 'multiple_choice' ? 'col-10' : 'default-classname'}>
                  <FormGroup className='mb-3'>
                    <Label for={`validationChoice-${index}`} style={{ fontWeight: 'bold' }}>{'Pilihan Jawaban'}:</Label>
                    <RichEditor key={index} id={`validationChoice-${index}`} handleChangeDescription={handleChangeChoice} description={data.choice[index]} index={index} />
                  </FormGroup>
                </Col>
              )}
              {(data.type === 'sentence_arrangement') && (
                <Col className={data.type === 'sentence_arrangement' ? 'col-8' : 'default-classname'}>
                  <FormGroup className='mb-3'>
                    <Label for={`validationChoice-${index}`} style={{ fontWeight: 'bold' }}>{'Pilihan Jawaban'}:</Label>
                    <Input className="form-control" id={`validationChoice-${index}`} type="textarea" name="choice" placeholder="Tuliskan pilihan jawaban" required=""
                      value={data.choice[index] || ''}
                      onChange={(e) => handleChangeChoice(index, e.target.value)} />
                  </FormGroup>
                </Col>
              )}
              {data.type === 'multiple_choice' && (
                  <Col className='col-2'>
                    <FormGroup className='mb-3'>
                      <Label style={{ fontWeight: 'bold' }}>{'Nilai'}: {index > 0 && (
                        <Trash2 onClick={() => handleRemoveOption(index)} color="red" />
                      )}</Label>
                      <div className="m-checkbox-inline">
                        <Label for={`answer_false-${index}`}>
                          <Input className="radio_animated" id={`answer_false-${index}`} type="radio" name={`answer-${index}`} value="0"
                            checked={data.answer[index] == "0" || false}
                            onChange={(e) => handleChangeAnswer(e, index, 'answer')} />{'Salah'}
                        </Label>
                        <Label for={`answer_true-${index}`}>
                          <Input className="radio_animated" id={`answer_true-${index}`} type="radio" name={`answer-${index}`} value="1"
                            checked={data.answer[index] == "1" || false}
                            onChange={(e) => handleChangeAnswer(e, index, 'answer')} />{'Benar'}
                        </Label>
                      </div>
                    </FormGroup>
                  </Col>
              )}
              {(data.type === 'fill_in_the_blanks' || data.type === 'sentence_arrangement') && (
                <Col className={data.type === 'fill_in_the_blanks' ? 'col-11' : data.type === 'sentence_arrangement' ? 'col-4' : 'default-classname'}>
                  <FormGroup className='mb-3'>
                    <Label for="validationAnswer" style={{ fontWeight: 'bold' }}>{'Jawaban'}: {index > 0 && (
                      <Trash2 onClick={() => handleRemoveOption(index)} color="red" />
                    )}</Label>
                    <Input className="form-control" id="validationAnswer" type="text" name="answer" placeholder="Tuliskan jawaban" required=""
                      value={data.answer[index] || ''} 
                      onChange={(e) => handleChangeAnswer(e, index, 'answer')} />
                  </FormGroup>
                </Col>
              )}
            </Row>
          ))
        )}
        {(data.type === 'fill_in_the_blanks' || data.type === 'sentence_arrangement' || data.type === 'multiple_choice') && (
          <Col className='col-1'>
              <FormGroup className='mb-3'>
                <PlusCircle onClick={handleAddOption} color="#3C6F7D" />
              </FormGroup>
          </Col>
        )}
        <FormGroup className='mb-3'>
          <Label for="validationAnswerExplain" style={{ fontWeight: 'bold' }}>{'Penjelasan Kunci Jawaban'}:</Label>
          <RichEditor id="validationAnswerExplain" handleChangeDescription={handleChangeExplanation} description={data.answer_explanation} />
        </FormGroup>
        {(data.isUpdate === true && data.audio_sourceLink) && (
          <FormGroup className='mb-3'>
            <Label for="validationImage" style={{ fontWeight: 'bold', marginBottom: '15px', marginRight: '10px' }}>
              Preview Audio Soal :
            </Label>
            <Btn attrBtn={{ className: 'btn btn-danger btn-sm', onClick: (e) => handleDeleteFile(e, 'audio_source') }}>
              <i className="fa fa-trash fa-lg"></i>
            </Btn>
            <AudioPlayer link={data.audio_sourceLink} />
          </FormGroup>
        )}
        <DropzoneCommon label={'Audio Soal'} inputContent={data.audio_sourceLink ? 'Audio Soal tersedia' : 'Silahkan upload audio'} handleChangeStatus={handleChangeAudio} accept=".mp3" />
        {(data.isUpdate === true && data.answer_explanation_videoLink) && (
        <FormGroup className='mb-3'>
          <Label for="validationImage" style={{ fontWeight: 'bold', marginBottom: '15px', marginRight: '10px' }}>
            Preview Video Pembahasan :
          </Label>
          <Btn attrBtn={{ className: 'btn btn-danger btn-sm', onClick: (e) => handleDeleteFile(e, 'answer_explanation_video' ) }}>
            <i className="fa fa-trash fa-lg"></i>
          </Btn>
          <VideoPlayer link={data.answer_explanation_videoLink} width={720} />
        </FormGroup>
        )}
        <DropzoneCommon label={'Video Pembahasan'} inputContent={data.answer_explanation_videoLink ? 'video Pembahasan tersedia' : 'Silahkan upload video Pembahasan'} handleChangeStatus={handleChangeAnswer_explanation_video} accept=".mp4" />
      </Col>
    </Fragment>
  );
};

export default QuestionForm;
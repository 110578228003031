import { createAsyncThunk } from '@reduxjs/toolkit';
import { TransactionServices } from '../../../../Services/axios/Transaction.Service';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export const fetchTransactions = createAsyncThunk(
    'Transactions/fetchTransactions',
    async (filters) => {
        try {
            const response = await TransactionServices.getAll(filters);
            return response.data;
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data. Mohon periksa koneksi internet Anda.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server.';
                } // Anda dapat menambahkan penanganan lain sesuai kebutuhan Anda

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                });
            } else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
        }
    }
);

export const setOrderStatus = createAsyncThunk(
    'Transactions/setOrderStatus',
    async (data) => {
        try {
            const formData = new FormData();
            formData.append('status', data.status);
            let response;
            response = await TransactionServices.setOrderStatus(data.id, formData);
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return response.data.data
        } catch (error) {
            for (const errorField in error.response.data.errors) {
                if (error.response.data.errors.hasOwnProperty(errorField)) {
                    const errorMessages = error.response.data.errors[errorField];
                    let errorMessage = errorMessages[0];
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        }
    }
);
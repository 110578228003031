import React, { Fragment, useEffect } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
// import UserPostContain from "../../../Component/Learning/Users/UserPost";
import TentorPostContain from "../../../Component/Classes/Tentor/TentorPost";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { handleEditTentor } from "../../../Component/Classes/Tentor/TentorUtils";
import { fetchTentors } from "../../../Features/Classes/Tentor/Utils/TentorUtils";
import {
	setField,
	resetState,
} from "../../../Features/Classes/Tentor/addTentorSlice";

const TentorAdd = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const addField = useSelector((state) => state.addTentor);
	const filters = useSelector((state) => state.Tentor.filters);

	useEffect(() => {
		if (!addField.id && id) {
			const newFilters = {
				...filters,
				"filter[id]": id,
				page: 1,
			};
			dispatch(fetchTentors(newFilters))
				.then((response) => {
					// Kode yang akan dijalankan setelah fetch sukses
					handleEditTentor(dispatch, response.payload.data[0], setField);
				})
				.catch((error) => {
					// Tangani error jika fetch gagal
					console.error("Error during fetchTentors:", error);
				});
		} else if (!id) {
			dispatch(resetState());
			console.log(addField, "addField");
		}
	}, [id]);

	return (
		<Fragment>
			<Breadcrumbs
				parent="Tentor"
				title={id ? "Update Tentor" : "Tambah Tentor"}
			/>
			<TentorPostContain label={id ? "" : "Tentor Baru"} />
		</Fragment>
	);
};

export default TentorAdd;

import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import TransactionContain from '../../../Component/Learning/Transaction';

const TransactionList = () => {
    return (
        <Fragment>
            <Breadcrumbs title="Daftar Transaksi" />
            <TransactionContain />
        </Fragment>
    );
};
export default TransactionList;
import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Post, Discard } from '../../../Constant';
import { Btn, H5, H6, Image, Spinner } from '../../../AbstractElements';
import QuestionsForm from './QuestionsForm';
import MiniBarQuestion from './MiniBarQuestion';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { submitQuestion, fetchQuestion } from '../../../Features/Learning/BankQuestions/Utils/QuestionsUtils';
import { setField, resetState } from '../../../Features/Learning/BankQuestions/addQuestionSlice';
import { handleEditQuestion, fetchQuestions } from './QuestionsUtils';
import Swal from 'sweetalert2';

const QuestionPostContain = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const data = useSelector(state => state.addQuestion)
  const startUpload = useSelector(state => state.addQuestion.isLoading);
  const isUpdate = useSelector(state => state.addQuestion.isUpdate);
  const dataQuestionUpdate = useSelector(state => state.Question.data.data);
  const isLoading =  useSelector(state => state.Question.isLoading);
  const filters = useSelector(state => state.Question.filters);
  const [emptyChoice, setEmptyChoice] = useState(false);
  const [emptyAnswer, setEmptyAnswer] = useState(false);
  const { page, index } = useParams();
  const checkParams = new URLSearchParams(window.location.search).get('params');
  const decodedParams = checkParams ? atob(checkParams) : '';
  const title = checkParams ? decodedParams.split("&")[0].split("=")[1] : '';
  const type = checkParams ? decodedParams.split("&")[1].split("=")[1] : '';
  const sort = checkParams ? decodedParams.split("&")[2].split("=")[1] : '';


  useEffect(() => {
    const fetchData = async () => {
      if (page) {
        const newFilters = {
          ...filters,
          'filter[title]': title,
          'filter[type]': type,
          'sort': sort,
          'page': page,
        };

        dispatch(fetchQuestion(newFilters))
          .then((response) => {
            // Kode yang akan dijalankan setelah fetch sukses
            handleEditQuestion(dispatch, response.payload.data[index], setField)
            // Kode lain yang perlu dijalankan setelah fetch sukses
          })
          .catch((error) => {
            // Tangani error jika fetch gagal
            console.error('Error during fetchQuestion:', error);
          });
      }
    };
    if (!data.id) {
      fetchData();
    }
  }, [page])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSuccess = () => {
    if (!page) {
      dispatch(resetState());
      history(`${process.env.PUBLIC_URL}/learning/soal-list`);
    } else {
      const newFilters = {
        ...filters,
        'filter[title]': title,
        'filter[type]': type,
        'sort': sort,
        'page': page,
      };
      // fetchQuestions(dispatch, newFilters)
      dispatch(fetchQuestion(newFilters))
        .then((response) => {
          // Kode yang akan dijalankan setelah fetch sukses
          handleEditQuestion(dispatch, response.payload.data[index], setField)
          // Kode lain yang perlu dijalankan setelah fetch sukses
        })
        .catch((error) => {
          // Tangani error jika fetch gagal
          console.error('Error during fetchQuestion:', error);
        });
    }
    // console.log("success callback")
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data, "data");
    let errorMessage = 'Data tidak boleh kosong!';
    const isEmptyChoice = data.choice.some((element) => element === "" || element === undefined);
    const isEmptyAnswer = data.answer.some((element) => element === "" || element === undefined);
    if (data.title === '') {
      errorMessage = 'Label soal belum diisi.';
    } else if (data.description === '') {
      errorMessage = 'Soal belum diisi.';
    } else if (data.type === '') {
      errorMessage = 'Tipe soal belum dipilih.';
    } else if (data.type !== 'fill_in_the_blanks' && data.choice.length === 0) {
      errorMessage = 'Pilihan Jawaban belum diisi.';
    } else if (data.type !== 'fill_in_the_blanks' && isEmptyChoice) {
      errorMessage = 'Ada Pilihan Jawaban belum diisi.';
    } else if (data.answer.length === 0) {
      errorMessage = 'Jawaban belum diisi.';
    } else if (isEmptyAnswer) {
      errorMessage = 'Ada Jawaban yang belum diisi.';
    } else if (data.answer_explanation === '') {
      errorMessage = 'Pembahasan Jawaban belum diisi.';
    }

    if (
      data.title === '' ||
      data.description === '' ||
      data.type === '' ||
      (data.type !== 'fill_in_the_blanks' && data.choice.length === 0) ||
      (data.type !== 'fill_in_the_blanks' && isEmptyChoice) ||
      data.answer.length === 0 ||
      isEmptyAnswer ||
      data.answer_explanation === ''
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage,
      });
      // console.log(data);

    } else {
      dispatch(submitQuestion({ ...data, successCallback: handleSuccess }));
      // console.log(data);
    }
  };
  const handleDiscard = (e) => {
    e.preventDefault();
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/learning/soal-list`);
  };

  return (
    <Fragment>
      <Container fluid={true}>
        {startUpload && (
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 9999 }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Card>
                <CardBody>
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-30' }} />
                  </div>
                  <H5 attrH5={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses unggah...'}</H5>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
        <Row>
          <Col sm={page ? "9" : "12"}>
            <Card>
              <CardHeader className='pb-0'><H5>{isUpdate ? '' : 'Soal Baru'}</H5></CardHeader>
              {isLoading ? (
                <Col lg="12">
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-30' }} />
                  </div>
                  <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang mengambil data...'}</H6>
                </Col>
              ) : (
              <CardBody className="add-post">
                <QuestionsForm />
                <div className="btn-showcase text-end">
                  <Btn attrBtn={{ color: 'primary', type: 'submit', onClick: handleSubmit }}>{Post}</Btn>
                  <Btn attrBtn={{ color: 'light', type: 'reset', onClick: handleDiscard }}>{Discard}</Btn>
                </div>
              </CardBody>
              )}
            </Card>
          </Col>
          {page && (
          <Col sm="3">
            <MiniBarQuestion />
          </Col>
          )}
        </Row>
      </Container>
    </Fragment>
  );
};

export default QuestionPostContain;
import { createSlice } from '@reduxjs/toolkit';
import { fetchTentors } from './Utils/TentorUtils';

const TentorSlice = createSlice({
    name : 'Tentors',
    initialState : {
        filters: {
            "filter[id]": "",
            "filter[name]": "",
            "filter[phone]": "",
            "sort": "",
            "page": "",
        },
        data: [],
        detail: {},
        isLoading: false,
    },
    reducers : {
        setFilters : (state, action) => {
            state.filters = action.payload;
        },
        setDetail : (state, action) => {
            state.detail = action.payload;
        }
    },
    extraReducers : (builder) => {
        builder
        .addCase(fetchTentors.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchTentors.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        })
        .addCase(fetchTentors.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export const { setFilters, setDetail } = TentorSlice.actions;

export default TentorSlice.reducer;
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Btn } from '../../../../AbstractElements';
import { NewMessage } from '../../../../Constant/index';
import { Link } from 'react-router-dom';
import FormModal from '../TryoutModal/FormModal';
import DetailModal from '../TryoutModal/DetailModal';
import { setIdAttach, setAttachments } from '../../../../Features/Learning/Tryout/attachTryoutSlice';
import { attachTryout, fetchTryout } from '../../../../Features/Learning/Tryout/Utils/TryoutUtils';
import { setDetail } from '../../../../Features/Learning/Tryout/tryoutSlice';
import Swal from 'sweetalert2';


const AttachModalButton = ({item, type}) => {
  // console.log(item, 'item')
  const dispatch = useDispatch();
  const data = useSelector(state => state.attachTryout)
  // console.log(data, 'data')
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [modalQuestion, setModalQuestion] = useState(false);
  const questionArray = (item && item.question) || [];
  const dataQuestions = useSelector(state => state.Tryout.data)
  const detailTryout = useSelector(state => state.Tryout.detail);
  const dataAttach = useSelector(state => state.attachTryout)
  const filterTryout = useSelector(state => state.Tryout.filters)
  const { id } = useParams();
  const toggle = () => {
    if (questionArray.length === 0) {
      dispatch(setAttachments({ ...data.attachments, 'order': 1 }))
    } else if (questionArray.length > 0) {
      const lastQuestionOrder = questionArray[questionArray.length - 1].pivot.order;
      dispatch(setAttachments({ ...data.attachments, 'order': lastQuestionOrder + 1 }))
    }
    setModal(!modal);
    // dispatch(setIdAttach(item.id))
  }

  const toogleQuestion = () => {
    setModalQuestion(!modalQuestion);
    // console.log('toogleQuestion:', item);
  }

  const toogleEditScore = () => {
    setEditModal(!editModal);
    dispatch(setAttachments({ ...dataAttach.attachments, 'question_id': '', 'category_id': '', 'score': [] }))
    // console.log('toogleEditScore:', item);
  }

  const handleSubmit = async () => {
    console.log('data:', data);
    let errorMessage = '';
    if (data.attachments.question_id === '' || data.attachments.category_id === '' || data.attachments.score.length === 0) {
      if (data.attachments.question_id === '') {
        errorMessage = 'Anda belum memilih soal!';
      } else if (data.attachments.category_id === '') {
        errorMessage = 'Anda belum memilih kategori!';
      } else if (data.attachments.score.length === 0) {
        errorMessage = 'Mohon isi semua skor!';
      }
    }

    if (data.attachments.score.some((item) => item.point === '')) {
      errorMessage = 'Mohon isi semua skor!';
    }

    if (data.attachments.question_id === '' || data.attachments.category_id === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage,
      });
    } else if (data.type_question === 'multiple_choice' && (data.attachments.score.length === 0 || data.attachments.score.some((item) => item.point === ''))) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage,
      });
    } else {
      try {
        Swal.fire({
          title: 'Apakah anda yakin?',
          text: "Mohon periksa kembali data yang anda masukkan!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ya, Lanjutkan!',
          cancelButtonColor: '#d33',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const response = await dispatch(attachTryout(data));
            // console.log('response.meta.arg.id:', response.meta.arg.id);
            // console.log('detailTryout:', detailTryout.id);
            if (response.payload === true) {
              if (type === 'attach') {
                Swal.fire({
                  icon: 'success',
                  title: 'Berhasil',
                  text: 'Berhasil tambah soal ke tryout',
                });
              } else if (type === 'update') {
                Swal.fire({
                  icon: 'success',
                  title: 'Berhasil',
                  text: 'Berhasil update pengaturan soal tryout',
                });
              }
              setModal(!modal);
              const newFilter = {
                ...filterTryout,
                'filter[id]': id,
                'include[1]': 'questions',
                'page': 1,
              };
              const getTryout = await dispatch(fetchTryout(newFilter));
              // console.log('getTryout:', getTryout);
              // console.log('Sukses tutup modal:', questionData[0]);
              dispatch(setDetail(getTryout.payload.data[0]));
            }
          }
        })
        
      } catch (error) {
        // Tanggapan error
        console.error('Error:', error);
        // Modal tidak akan ditutup jika terjadi kesalahan
      }
    }
    
  };
  
  return (
    <Fragment>
      {type === 'attach' && (
          <Btn attrBtn={{ color: 'primary', onClick: toggle }}><i className="fa fa-plus-square fa-lg me-2"></i>{'Tambah Soal'}</Btn>
      )} 
      {type === 'attach' && (
          <FormModal modal={modal} NewMessage={'Tambah Soal ke Tryout'} toggle={toggle} handleSubmit={handleSubmit} />
      )} 
      {type === 'update' && (
        <FormModal modal={editModal} NewMessage={'Update Pengaturan Soal'} toggle={toogleEditScore} handleSubmit={handleSubmit} data={item} />
      )} 
      {type === 'update' && ( 
        <Btn
            attrBtn={{
              color: 'link',
              outline: false,
              className: 'p-1',
              onClick: toogleEditScore
            }}
          >
            <i className="fa fa-edit mx-auto fa-lg"></i>
          </Btn>
      )}
      {type === 'question' && ( 
        <Btn
            attrBtn={{
              color: 'link',
              outline: false,
              className: 'p-1',
              onClick: toogleQuestion
            }}
          >
            <i className="fa fa-info-circle mx-auto fa-lg"></i>
          </Btn>
      )}
      {type === 'question' && ( 
        <DetailModal item={item} modal={modalQuestion} NewMessage={'Detail Soal'} toggle={toogleQuestion}/>
      )}
    </Fragment>
  );
};

export default AttachModalButton;
// ayo lanjutin
import { createSlice } from "@reduxjs/toolkit";
import { submitCourses } from "./Utils/CoursesUtils";

const initialState = {
    title: '',
    description: '',
    description_image: '',
    description_video: '',
    image: '',
    isUpdate: false,
    id: '',
    isLoading: false,
};

const addCourse = createSlice(
    {
        name: 'addCourse',
        initialState: initialState,
        reducers: {
            setField: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetState: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(submitCourses.pending, (state) => {
                    state.isLoading = true;
                    console.log("pending action")
                })
                .addCase(submitCourses.fulfilled, (state, action) => {
                    state.isLoading = false;
                    console.log("fulfilled action")
                })
                .addCase(submitCourses.rejected, (state, action) => {
                    state.isLoading = false;
                   console.log(action.error.message, "error action")
                });
        }
    }
);


export const { setField, resetState } = addCourse.actions;

export default addCourse.reducer;
import React, { Fragment, useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';

const SelectOptionAsync = ({promiseOptions, handleSelectChange, selectedValue, label, placeholder, isMulti}) => {
  const debouncedPromiseOptions = debounce(promiseOptions, 1000);
  
  return (
    <Fragment>
      <div className="mb-3">
        {label && (
        <Label className="col-form-label" style={{ fontWeight: 'bold' }}>{label} :</Label>
        )}
        <AsyncSelect
          cacheOptions
          defaultOptions
          isMulti={isMulti}
          isClearable={true}
          loadOptions={(inputValue, callback) => debouncedPromiseOptions(inputValue, callback)}
          onChange={handleSelectChange}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          value={selectedValue}
          placeholder={placeholder}
        />
      </div>
    </Fragment>
  );
};
export default SelectOptionAsync;
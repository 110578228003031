import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, Input, Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import ReferrerListTable from './ReferrerListTable';
import ReferrerListFilter from './Filter/ReferrerListFilter';

const ReferrerListContain = () => {
    const cards = useSelector(state => state.historyUserReferralCompact.data.data)

    return (
        <Fragment>
            <Container fluid={true}>
                <Row className='learning-block'>
                    <Col xl="9" className='xl-80'>
                        <Row>
                            <ReferrerListTable />
                        </Row>
                    </Col>
                    {cards && (
                        <ReferrerListFilter />
                    )}
                </Row>
            </Container>
        </Fragment>
    );
}
export default ReferrerListContain;
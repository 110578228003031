import React from 'react';
import 'quill/dist/quill.snow.css';

const RawHTMLComponent = ({ htmlContent, maxCharacters }) => {
    // Memotong konten HTML jika melebihi jumlah karakter maksimum
    const trimmedContent = maxCharacters ? htmlContent.slice(0, maxCharacters) : htmlContent;

    return <div className="ql-editor p-0" dangerouslySetInnerHTML={{ __html: trimmedContent }} />;
};

export default RawHTMLComponent;

import { axiosInstance } from './axiosInstance';

export const TicketServices = {
    getAll: async (filter) => {
        return await axiosInstance.get('/api/feature/ticket', { params: filter });
    },
    markAsSolved: async (id, data) => {
        return await axiosInstance.post(`/api/feature/ticket/mark-as-solved/${id}`, data);
    },
}

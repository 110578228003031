import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AsyncSelectCommon from '../../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { fetchCourses } from '../../../../Features/Learning/Courses/Utils/CoursesUtils';
import { setField } from '../../../../Features/Classes/Schedule/addScheduleSlice';
import Swal from 'sweetalert2';

const AsynchSelectMateri = () => {
    const { classId, scheduleId } = useParams();
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState(null);
    const filtersCourse = useSelector(state => state.Course.filters)
    const data = useSelector(state => state.addSchedule)

    useEffect(() => {
        const selectedCourse = data.course && data.course.id
            ? [{
                value: data.course.id,
                label: data.course.title,
            }]
            : selectedValue;
        setSelectedValue(selectedCourse);
    }, [data.course]);

    const promiseOptions = (inputValue, callback) => {
        dispatch(fetchCourses({
            ...filtersCourse,
            'filter[title]': inputValue,
            'sort': '-id',
        }))
        .then((response) => {
            const options = response.payload.data.map(item => ({
                value: item.id,
                label: item.title,
                materials: item.materials,
            }));
            callback(options);
        })
        .catch((error) => {
            console.error('Error fetching options:', error);
            callback([]);
        });
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedValue(selectedOption === null ? '' : selectedOption)
        dispatch(setField({ field: 'materials', value: selectedOption === null ? '' : selectedOption.materials }));
        if (selectedOption !== null && selectedOption.materials.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Belum ada materi dalam course ini! Pilih yang lain atau tambahkan materi ke course ini.',
            });
        } else {
            dispatch(setField({ field: 'course_id', value: selectedOption === null ? '' : selectedOption.value }));            
            dispatch(setField({ field: 'material_id', value: '' }));
        }
    }

    return (
        <Fragment>
            <AsyncSelectCommon 
                label={'Pilih Course'} 
                placeholder={'Silahkan pilih Course...'}
                isMulti={false}
                promiseOptions={promiseOptions}
                handleSelectChange={handleSelectChange}
                selectedValue={selectedValue}
            />
        </Fragment>
    );
}

export default AsynchSelectMateri;
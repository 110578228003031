import { createSlice } from '@reduxjs/toolkit';
import { fetchHistoryReferral } from './Utils/UserUtils';

const historyReferralSlice = createSlice({
    name: 'historyReferral',
    initialState: {
        filters: {
            "filter[id]": "",
            "filter[user.email]": "",
            "filter[user.name]": "",
            "filter[user.phone]": "",
            "filter[referee.id]": "",
            "filter[referee.email]": "",
            "filter[referee.name]": "",
            "filter[referee.phone]": "",
            "filter[membership.title]": "",
            "filter[type]": "",
            "sort": "",
            "page": "",
        },
        data: [],
        detail: {},
        isLoading: false,
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        setDetail: (state, action) => {
            state.detail = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHistoryReferral.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchHistoryReferral.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchHistoryReferral.rejected, (state) => {
                state.isLoading = false;
            })
    }
});

export const { setFilters, setData, setDetail } = historyReferralSlice.actions;

export default historyReferralSlice.reducer;
import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import CoursesContain from '../../../Component/Learning/Courses/CoursePost';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { handleEditCourse } from '../../../Component/Learning/Courses/CoursesUtils';
import { fetchCourses } from '../../../Features/Learning/Courses/Utils/CoursesUtils';
import { setField, resetState } from '../../../Features/Learning/Courses/addCourseSlice';


const CoursePost = () => {
    const addField = useSelector(state => state.addCourse)
    const { id } = useParams();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.Course.filters)

    useEffect(() => {
        if (!addField.id && id) {
            const newFilters = {
                ...filters,
                "filter[id]": id,
                "page": 1,
            };
            dispatch(fetchCourses(newFilters))
            .then((response) => {
                // Kode yang akan dijalankan setelah fetch sukses
                handleEditCourse(dispatch, response.payload.data[0], setField)
            })
            .catch((error) => {
                // Tangani error jika fetch gagal
                console.error('Error during fetchCourses:', error);
            });
        } else if (!id) {
            dispatch(resetState());
            console.log(addField, "addField");
        }
    } , [id]);

    return (
        <Fragment>
            <Breadcrumbs grandparent="Membership" parent="Course" title={id ? "Update Course" : "Tambah Course"} />
            <CoursesContain label={id ? "" : "Course Baru"} />
        </Fragment>
    );
};

export default CoursePost;

import React, { Fragment, useState } from "react";
import { Container, Row, Col, Card, Input, Label } from "reactstrap";
import { useSelector } from "react-redux";
import TicketBox from "../Ticket/TicketBox";
import TicketFilter from "./Filter/TicketFilter";

const TicketsContain = () => {
	const cards = useSelector((state) => state.Ticket.data.data);

	return (
		<Fragment>
			<Container fluid={true}>
				<Row className="learning-block">
					<Col xl="9" className="xl-80">
						<Row>
							<TicketBox />
						</Row>
					</Col>
					{cards && <TicketFilter />}
				</Row>
			</Container>
		</Fragment>
	);
};
export default TicketsContain;

import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Col } from 'reactstrap';
import { Breadcrumbs, Spinner, H6 } from '../../../AbstractElements';
import ClassPost from '../../../Component/Classes/Class/ClassPost';
import { handleEditClass } from '../../../Component/Classes/Class/ClassUtils';
import { setField, resetState } from '../../../Features/Classes/Class/addClassSlice';
import { fetchClasses } from '../../../Features/Classes/Class/Utils/ClassUtils';


const ClassAdd = () => {
    const { classId } = useParams();
    const addField = useSelector(state => state.addClass)
    const dispatch = useDispatch();
    const filters = useSelector(state => state.Class.filters)
    const isLoading = useSelector(state => state.Class.isLoading)

    useEffect(() => {
        if (!addField.id && classId) {
            const newFilters = {
                ...filters,
                "filter[id]": classId,
                "page": 1,
            };
            dispatch(fetchClasses(newFilters))
                .then((response) => {
                    // Kode yang akan dijalankan setelah fetch sukses
                    handleEditClass(dispatch, response.payload.data[0], setField)
                    // Kode lain yang perlu dijalankan setelah fetch sukses
                })
                .catch((error) => {
                    // Tangani error jika fetch gagal
                    console.error('Error during fetchClasses:', error);
                });
        } else if (!classId) {
            dispatch(resetState());
            // console.log(addField, "addField");
        }
    } , [classId, addField.id, dispatch, filters]);
    
    return (
        <Fragment>
            <Breadcrumbs parent="Kelas" title={classId ? "Update Kelas" : "Tambah Kelas"} />
            {isLoading ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'mb-0 text-center' }}>{'Data Loading...'}</H6>
                </Col>
            ) : (
                <ClassPost />
            )}
        </Fragment>
    );
};
export default ClassAdd;
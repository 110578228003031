import { createSlice } from '@reduxjs/toolkit';
import { attachDetachCourseMemberships } from './Utils/MembershipUtils';

const initialState = {
    id : '',
    courses: [],
    isLoading: false,
};

const attachDetachCourseSlice = createSlice(
    {
        name: 'attachDetachCourse',
        initialState: initialState,
        reducers: {
            setFieldAttachDetachCourse: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetStateAttachDetachCourse: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
            .addCase(attachDetachCourseMemberships.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(attachDetachCourseMemberships.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(attachDetachCourseMemberships.rejected, (state, action) => {
                state.isLoading = false;
                console.log(action.error.message, "error action")
            })
        }
    }
);

export const { setFieldAttachDetachCourse, resetStateAttachDetachCourse } = attachDetachCourseSlice.actions;

export default attachDetachCourseSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';
import { fetchTickets } from './Utils/TicketUtils';

const TicketSlice = createSlice({
    name : 'Tickets',
    initialState : {
        filters: {
            "filter[id]": "",
            "filter[user_id]": "",
            "filter[question_id]": "",
            "filter[title]": "",
            "filter[is_solved]": "",
            "sort": "",
            "page": "",
        },
        data: [],
        isLoading: false,
    },
    reducers : {
        setFilters : (state, action) => {
            state.filters = action.payload;
        }
    },
    extraReducers : (builder) => {
        builder
        .addCase(fetchTickets.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchTickets.fulfilled, (state, action) => {
            state.isLoading = false;
            // console.log('status fulfilled', action.payload)
            state.data = action.payload;
            // console.log('data', state.data)
        })
        .addCase(fetchTickets.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export const { setFilters, setDetail } = TicketSlice.actions;

export default TicketSlice.reducer;

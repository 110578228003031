import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import CoursesContain from '../../../Component/Learning/Courses';

const CourseList = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Course" parent="Membership" title="Daftar Course" />
            <CoursesContain />
        </Fragment>
    );
};
export default CourseList;
import React from "react";
import {
	Button,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";
import {
	Btn,
} from "../../../../AbstractElements";

const AdvanceOrderTryoutCourse = ({
	items,
	setOrder,
	setSaveButtonVisible,
}) => {
	const [modal, setModal] = React.useState(false);
	const toggle = () => setModal(!modal);
	const getItems = () => {
		return items.map((item) => ({
			id: item.id,
			title: item.title,
		}));
	};

	const handleSaveOrderText = () => {
		const order = document.getElementById("order-tryout-course-text").value;
		setOrder(JSON.parse(order).map((item) => item.id));
		setSaveButtonVisible(true);
		toggle();
	};

	return (
		<>
			<Button color="danger" onClick={toggle}
				title="Advance Edit Order"
				size="sm"
			>
				<i className="fa fa-list-ol fa-lg me-2"></i>
			</Button>{" "}
			<Modal isOpen={modal}>
				<ModalHeader onClick={toggle}>Advance Edit Order by Text</ModalHeader>
				<ModalBody>
					<Input
						type="textarea"
						id="order-tryout-course-text"
						style={{ width: "100%" }}
						defaultValue={JSON.stringify(getItems())}
						rows="10"
					/>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
					<Btn attrBtn={{ color: "warning", onClick: handleSaveOrderText }}>
						<i className="fa fa-list-ol fa-lg me-2"></i>Save Text
					</Btn>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default AdvanceOrderTryoutCourse;

import { createSlice } from '@reduxjs/toolkit';
import { importQuestion } from './Utils/QuestionsUtils';

const initialState = {
    file: '',
    countChoices: '',
    isLoading: false,
};

const importQuestionSlice = createSlice(
    {
        name: 'importQuestion',
        initialState: initialState,
        reducers: {
            setFieldImport: (state, action) => {
                const { field, value } = action.payload;
                state[field] = value;
            },
            resetStateImport: (state) => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(importQuestion.pending, (state) => {
                    // Handle loading state
                    state.isLoading = true;
                })
                .addCase(importQuestion.fulfilled, (state, action) => {
                    // Handle success state
                    console.log('Sukses:', action.payload);
                    state.isLoading = false;
                })
                .addCase(importQuestion.rejected, (state, action) => {
                    // Handle error state
                    console.log(action.error.message, "error action")
                    state.isLoading = false;
                });
        }
    }
);


export const { setFieldImport, resetStateImport } = importQuestionSlice.actions;

export default importQuestionSlice.reducer;
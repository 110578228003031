import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Form, FormGroup, Label, Input, Media } from 'reactstrap';
import { PlusCircle, Trash2, X } from 'react-feather';
import { Btn, H5, H6, Image, Spinner } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PostCommon from '../../../Component/Learning/Common/Form/Post';
import AsyncSelectCommon from '../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { fetchMainProgram } from '../../../Features/Learning/MainPrograms/Utils/MainProgramUtils';
import { submitMemberships } from '../../../Features/Learning/Memberships/Utils/MembershipUtils';
import { setField, resetState } from '../../../Features/Learning/Memberships/addMembershipSlice';
import { setDetail, setFilters } from '../../../Features/Learning/Memberships/membershipSlice';
import Swal from 'sweetalert2';

const MembershipPostContain = (props) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const data = useSelector(state => state.addMembership)
    const detail = useSelector(state => state.Membership.detail)
    const startUpload = useSelector(state => state.addMembership.isLoading);
    const filters = useSelector(state => state.Membership.filters)
    const filterMainProgram = useSelector(state => state.MainProgram.filters)
    const [selectedValue, setSelectedValue] = useState(null);

    const handleSuccess = () => {
        dispatch(resetState());
        history(`${process.env.PUBLIC_URL}/membership/membership-list`);
        dispatch(setFilters({
            ...filters,
            'page': '1',
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        let errorMessage = '';
        console.log(data, 'data');
        if(data.title === '') {
            errorMessage = 'Nama Membership belum diisi.';
        } else if (data.price === '') {
            errorMessage = 'Harga Membership belum diisi.';
        } else if (data.duration_days === '') {
            errorMessage = 'Masa Aktif Membership belum diisi.';
        } else if (data.is_to_only === '') {
            errorMessage = 'Membership Hanya Berisi Tryout atau Tidak belum dipilih.';
        } else if (data.is_online === '') {
            errorMessage = 'Jenis Membership belum dipilih.';
        } else if (data.is_guaranteed === '') {
            errorMessage = 'Membership Bergaransi atau Tidak belum dipilih.';
        } else if (data.main_program_id === '') {
            errorMessage = 'Program belum dipilih.';
        } else if (data.points.length === 0) {
            errorMessage = 'Fasilitas Membership ada yang belum diisi.';
        } else if (data.points.length > 0) {
            for (let i = 0; i < data.points.length; i++) {
                if (data.points[i].name === '') {
                    errorMessage = 'Fasilitas Membership ada yang belum diisi.';
                }
            }
        } 

        if (data.title === '' || data.price === '' || data.duration_days === '' || data.is_to_only === '' || data.is_online === '' || data.is_guaranteed === '' || data.main_program_id === '' || data.points.length === 0 || (data.points.length > 0 && data.points.some(point => point.name === ''))) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errorMessage,
            });
        } else {
            dispatch(submitMemberships({ ...data, successCallback: handleSuccess }))
        }
    };
    const handleKeyDown = (e) => {
        // Hanya izinkan input angka (0-9) dan backspace
        const isValidInput = /^[0-9\b]+$/.test(e.key);

        if (!isValidInput && e.key !== 'Backspace') {
            e.preventDefault();
        }
    };
    const handleDiscard = (e) => {
        e.preventDefault();
        dispatch(resetState());
        history(`${process.env.PUBLIC_URL}/membership/membership-list`);
    };
    const handleChanges = (e) => {
        const { name, value } = e.target;
        dispatch(setField({ field: name, value: value }));
        // console.log(name, value);
    };

    const handleAddPoint = () => {
        const updatedPoints = [...data.points];
        updatedPoints.push({ name: '', status: false });
        dispatch(setField({ field: 'points', value: updatedPoints }));
    };

    const handleRemovePoint = (index) => {
        const updatedPoints = [...data.points];
        updatedPoints.splice(index, 1);
        dispatch(setField({ field: 'points', value: updatedPoints }));
    };

    const handleChangePoint = (e, index, field) => {
        const updatedPoints = data.points.map((point, i) => {
            if (i === index) {
                // Membuat salinan objek dan mengubah nilai yang diperlukan
                return { ...point, name: e.target.value };
            }
            return point; // Mengembalikan objek tanpa perubahan untuk indeks selain yang diubah
        });
        dispatch(setField({ field: field, value: updatedPoints }));
    };

    const handleStatus = (e, index) => {
        const { name, checked } = e.target;
        const updatedPoints = data.points.map((point, i) => {
            if (i === index) {
                // Membuat salinan objek dan mengubah nilai yang diperlukan
                return { ...point, status: checked };
            }
            return point; // Mengembalikan objek tanpa perubahan untuk indeks selain yang diubah
        });
        dispatch(setField({ field: 'points', value: updatedPoints }));
        // console.log(name, checked);
        // dispatch(setField({ field: name, value: checked }));
    };

    const handleSelectChange = (selectedOption) => {
        console.log(selectedOption)
        setSelectedValue(selectedOption);
        if (selectedOption === null) {
            dispatch(setField({ field: 'main_program_id', value: '' }));
            setSelectedValue(null);
        } else {
            dispatch(setField({ field: 'main_program_id', value: selectedOption.value}));
            setSelectedValue(selectedOption);
        }
    };

    useEffect(() => {
        const selectedMainProgram = data.main_program_id
            ? [{
                value: detail.main_program.id,
                label: detail.main_program.title,
            }]
            : selectedValue;
        setSelectedValue(selectedMainProgram);
        if (data.points.length === 0) {
            dispatch(setField({ field: 'points', value: [''] }));
        }
    }, [data.id]);

    const promiseOptions = (inputValue, callback) => {
      dispatch(fetchMainProgram({
          ...filterMainProgram,
        'filter[title]': inputValue,
      }))
      .then((response) => {
        const options = response.payload.data.map(item => ({
          value: item.id,
          label: item.title,
        }));
        callback(options);
      })
      .catch((error) => {
        console.error('Error fetching options:', error);
        callback([]);
      });
  };

    return (
        <PostCommon title={props.label} handleSubmit={handleSubmit} handleDiscard={handleDiscard} >
            {startUpload ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses unggah...'}</H6>
                </Col>
            ) : (
                <div>
                    <Form className="row needs-validation">
                        <Col sm="12">
                            <FormGroup className='mb-3'>
                                <Label for="validationName" style={{ fontWeight: 'bold' }}>Nama Membership:</Label>
                                <Input className="form-control" id="validationName" type="text" name="title" placeholder="Masukkan nama membership..." required=""
                                    value={data.title}
                                    onChange={handleChanges} />
                                <div className="valid-feedback">{'Looks good!'}</div>
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <Label for="validationPrice" style={{ fontWeight: 'bold' }}>Harga:</Label>
                                <Input className="form-control" id="validationPrice" type="text" name="price" placeholder="Masukkan harga membership..." required=""
                                    value={data.price}
                                    onChange={handleChanges} 
                                    onKeyDown={handleKeyDown} />
                                <div className="valid-feedback">{'Looks good!'}</div>
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <Label for="validationGimmickPrice" style={{ fontWeight: 'bold' }}>Harga Coret:</Label>
                                <Input className="form-control" id="validationGimmickPrice" type="text" name="gimmick_price" placeholder="Masukkan harga coret dari membership..."
                                    value={data.gimmick_price}
                                    onChange={handleChanges} 
                                    onKeyDown={handleKeyDown} />
                                <div className="valid-feedback">{'Looks good!'}</div>
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <Label style={{ fontWeight: 'bold' }}>Posisi Harga Dibawah?</Label>
                                <div className="m-checkbox-inline">
                                    <Label for="is_price_bottom_false">
                                        <Input className="radio_animated" id="is_price_bottom_false" type="radio" name="is_price_bottom" value="0"
                                            checked={data.is_price_bottom === '0'}
                                            onChange={handleChanges} />
                                            TIDAK
                                    </Label>
                                    <Label for="is_price_bottom_true">
                                        <Input className="radio_animated" id="is_price_bottom_true" type="radio" name="is_price_bottom" value="1"
                                            checked={data.is_price_bottom === '1'}
                                            onChange={handleChanges} />
                                            YA
                                    </Label>
                                </div>
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <Label for="validationSubHeader" style={{ fontWeight: 'bold' }}>Sub Judul:</Label>
                                <Input className="form-control" id="validationSubHeader" type="textarea" name="subheader_desc" placeholder="Apa saja yang didapatkan?"
                                    value={data.subheader_desc || ''}
                                    onChange={handleChanges} />
                                <div className="valid-feedback">{'Looks good!'}</div>
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <Label for="validationDuration" style={{ fontWeight: 'bold' }}>Masa Aktif Membership (Hari):</Label>
                                <Input className="form-control" id="validationDuration" type="text" name="duration_days" placeholder="Masukkan jumlah hari..." required=""
                                    value={data.duration_days}
                                    onChange={handleChanges}
                                    onKeyDown={handleKeyDown} />
                                <div className="valid-feedback">{'Looks good!'}</div>
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <Label style={{ fontWeight: 'bold' }}>Membership Hanya Berisi Tryout?</Label>
                                    {detail && detail.is_to_only === '0' && detail.courses && detail.courses.length > 0 && (
                                    <div className="alert alert-danger" role="alert">
                                        <strong>Perhatian!</strong> Membership tidak dapat diubah menjadi 'hanya berisi tryout' jika sudah memiliki course.
                                    </div>
                                )}
                                <div className="m-checkbox-inline">
                                    <Label for="is_to_only_false">
                                        <Input className="radio_animated" id="is_to_only_false" type="radio" name="is_to_only" value="0"
                                            checked={data.is_to_only === '0'}
                                            onChange={handleChanges}
                                            disabled={detail && detail.is_to_only === '0' && detail.courses && data.courses.length > 0} />
                                            TIDAK
                                    </Label>
                                    <Label for="is_to_only_true">
                                        <Input className="radio_animated" id="is_to_only_true" type="radio" name="is_to_only" value="1"
                                            checked={data.is_to_only === '1'}
                                            onChange={handleChanges}
                                            disabled={detail && detail.is_to_only === '0' && detail.courses && detail.courses.length > 0} />
                                            YA
                                    </Label>
                                </div>
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <Label style={{ fontWeight: 'bold' }}>Jenis Membership:</Label>
                                <div className="m-checkbox-inline">
                                    <Label for="is_online_false">
                                        <Input className="radio_animated" id="is_online_false" type="radio" name="is_online" value="0"
                                            checked={data.is_online === '0'}
                                            onChange={handleChanges} />
                                            Offline
                                    </Label>
                                    <Label for="is_online_true">
                                        <Input className="radio_animated" id="is_online_true" type="radio" name="is_online" value="1"
                                            checked={data.is_online === '1'}
                                            onChange={handleChanges} />
                                            Online
                                    </Label>
                                </div>
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <Label style={{ fontWeight: 'bold' }}>Membership Bergaransi?</Label>
                                <div className="m-checkbox-inline">
                                    <Label for="is_guaranteed_false">
                                        <Input className="radio_animated" id="is_guaranteed_false" type="radio" name="is_guaranteed" value="0"
                                            checked={data.is_guaranteed === '0'}
                                            onChange={handleChanges} />
                                            TIDAK
                                    </Label>
                                    <Label for="is_guaranteed_true">
                                        <Input className="radio_animated" id="is_guaranteed_true" type="radio" name="is_guaranteed" value="1"
                                            checked={data.is_guaranteed === '1'}
                                            onChange={handleChanges} />
                                            YA
                                    </Label>
                                </div>
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <Label style={{ fontWeight: 'bold' }}>Untuk Program Kerja Sama Kampus?</Label>
                                <div className="m-checkbox-inline">
                                    <Label for="is_campus_event_false">
                                        <Input className="radio_animated" id="is_campus_event_false" type="radio" name="is_campus_event" value="0"
                                            checked={data.is_campus_event === '0'}
                                            onChange={handleChanges} />
                                            TIDAK
                                    </Label>
                                    <Label for="is_campus_event_true">
                                        <Input className="radio_animated" id="is_campus_event_true" type="radio" name="is_campus_event" value="1"
                                            checked={data.is_campus_event === '1'}
                                            onChange={handleChanges} />
                                            YA
                                    </Label>
                                </div>
                            </FormGroup>
                            {data.is_campus_event === '1' && (
                                <FormGroup className='mb-3'>
                                    <Label for="validationLink" style={{ fontWeight: 'bold' }}>Link Pendaftaran:</Label>
                                    <Input className="form-control" id="validationLink" type="text" name="link" placeholder="Masukkan link pendaftaran..." required=""
                                        value={data.link}
                                        onChange={handleChanges} />
                                </FormGroup>
                            )}
                            <AsyncSelectCommon label={'Pilih Program'} placeholder={'Silahkan pilih Program...'}
                                isMulti={false}
                                promiseOptions={promiseOptions}
                                handleSelectChange={handleSelectChange}
                                selectedValue={selectedValue}
                            />
                            {data.points.map((point, index) => (
                            <FormGroup key={index} className='mb-3'>
                                <Row>
                                    <Col>
                                        <Label for="validationPoint" style={{ fontWeight: 'bold' }}>Fasilitas Membership {index + 1}:
                                            {index > 0 && (
                                                <Btn
                                                    attrBtn={{
                                                        color: 'link',
                                                        outline: true,
                                                        className: 'p-1',
                                                    onClick:() => handleRemovePoint(index)
                                                    }}
                                                >
                                                    <i className="fa fa-close mx-auto text-danger fa-2x"></i>
                                                </Btn>

                                            )}  
                                        </Label>
                                        <Input className="form-control" id="validationPoint" type="text" name="points" placeholder="Masukkan fasilitas membership..." required=""
                                            value={data.points[index].name || ''}
                                            onChange={(e) => handleChangePoint(e, index, 'points')} />
                                    </Col>
                                    <Col sm="1" className="text-center">
                                        <Label style={{ fontWeight: 'bold' }}>Status</Label>
                                        <Media body className="text-end icon-state">
                                            <Label className="switch">
                                                    <Input type="checkbox" onChange={(e) => handleStatus(e, index)} checked={data.points[index].status || ''} name="status" /><span className="switch-state bg-primary"></span>
                                            </Label>
                                        </Media>
                                    </Col>
                                </Row>
                            </FormGroup>
                            ))}
                            <FormGroup className='mb-3'>
                                <Btn
                                    attrBtn={{
                                        color: 'link',
                                        outline: true,
                                        className: 'p-1',
                                        onClick:() => handleAddPoint()
                                    }}
                                >
                                        <i className="fa fa-plus-circle mx-auto fa-2x" style={{ color:'#3C6F7D' }}></i>
                                </Btn>
                            </FormGroup>

                        </Col>
                    </Form>
                </div>
            )}
        </PostCommon>
    );
};

export default MembershipPostContain;
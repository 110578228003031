import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import ReferrerListContain from '../../../Component/Learning/HistoryReferral/ReferrerList';

const UserHistoryReferralCompact = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Users" title="Daftar Referrer" />
            <ReferrerListContain />
        </Fragment>
    );
};
export default UserHistoryReferralCompact;
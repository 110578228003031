import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Post, Discard } from "../../Constant";
import { Btn, H5, H6, Spinner } from "../../AbstractElements";
import BlogForm from "./BlogForm";
import DropzoneCommon from "../Learning/Common/Upload/Dropzone";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { submitBlog } from "../../Features/Learning/Blog/Utils/BlogUtils";
import {
	resetState,
	setField,
} from "../../Features/Learning/Blog/addBlogSlice";
import Swal from "sweetalert2";

const BlogContain = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const data = useSelector((state) => state.addBlog);
	const filters = useSelector((state) => state.Blog.Loading);
	const isUpdate = useSelector((state) => state.addBlog.isUpdate);
	const startUpload = useSelector((state) => state.addBlog.isLoading);
	const handleSuccess = () => {
		dispatch(resetState());
		history(`${process.env.PUBLIC_URL}/blog-list`);
	};

	const showError = (message) => {
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: message,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let errorMessage = "";
		if (!isUpdate) {
			if (
				data.name === "" ||
				data.description === "" ||
				data.description === "<p><br></p>" ||
				data.image === "" ||
				data.image === null
			) {
				if (data.name === "") {
					errorMessage = "Nama Blog tidak boleh kosong.";
				} else if (
					data.description === "" ||
					data.description === "<p><br></p>"
				) {
					errorMessage = "Deskripsi Blog tidak boleh kosong.";
				} else if (data.image === "" || data.image === null) {
					errorMessage = "Banner Blog tidak boleh kosong.";
				}
				showError(errorMessage);
			} else {
				dispatch(submitBlog({ ...data, successCallback: handleSuccess }));
			}
		} else {
			if (
				data.name === "" ||
				data.description === "" ||
				data.description === "<p><br></p>"
			) {
				if (data.name === "") {
					errorMessage = "Nama Blog tidak boleh kosong.";
				} else if (
					data.description === "" ||
					data.description === "<p><br></p>"
				) {
					errorMessage = "Deskripsi Blog tidak boleh kosong.";
				}
				showError(errorMessage);
			} else {
				dispatch(submitBlog({ ...data, successCallback: handleSuccess }));
			}
		}
	};

	const handleDiscard = (e) => {
		e.preventDefault();
		dispatch(resetState());
		history(`${process.env.PUBLIC_URL}/blog-list`);
	};

	const handleChangeStatus = ({ meta, file }, status) => {
		// console.log(status, meta, file)
		if (status === "removed") {
			dispatch(setField({ field: "thumbnail", value: "" }));
		} else if (status === "done") {
			dispatch(setField({ field: "thumbnail", value: file }));
		}
	};

	return (
		<Fragment>
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						{startUpload ? (
							<Col lg="12">
								<div className="loader-box">
									<Spinner attrSpinner={{ className: "loader-30" }} />
								</div>
								<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
									{"Sedang proses unggah..."}
								</H6>
							</Col>
						) : (
							<Card>
								<CardHeader className="pb-0">
									<H5>{isUpdate ? "" : "Blog Baru"}</H5>
								</CardHeader>
								<CardBody className="add-post">
									<BlogForm />
									<DropzoneCommon
										label={"Banner"}
										inputContent={
											data.imageLink
												? "Ganti gambar..."
												: "Silahkan upload gambar..."
										}
										handleChangeStatus={handleChangeStatus}
										accept="image/*"
									/>
									<div className="btn-showcase text-end">
										<Btn
											attrBtn={{
												color: "primary",
												type: "submit",
												onClick: handleSubmit,
											}}>
											{Post}
										</Btn>
										<Btn
											attrBtn={{
												color: "light",
												type: "reset",
												onClick: handleDiscard,
											}}>
											{Discard}
										</Btn>
									</div>
								</CardBody>
							</Card>
						)}
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default BlogContain;

import { createSlice } from "@reduxjs/toolkit";
import { fetchClasses } from "./Utils/ClassUtils";

const ClassSlice = createSlice({
	name: "Classes",
	initialState: {
		filters: {
			"filter[id]": "",
			"filter[name]": "",
			sort: "",
			page: "",
			include: "members",
		},
		data: [],
		detail: {},
		isLoading: false,
	},
	reducers: {
		setFilters: (state, action) => {
			state.filters = action.payload;
		},
		setDetail: (state, action) => {
			state.detail = action.payload;
		},
	},
	extraReducers: {
		[fetchClasses.pending]: (state) => {
			state.isLoading = true;
		},
		[fetchClasses.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.isLoading = false;
		},
		[fetchClasses.rejected]: (state) => {
			state.isLoading = false;
		},
	},
});

export const { setFilters, setDetail } = ClassSlice.actions;

export default ClassSlice.reducer;

import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import MainProgramContain from '../../../Component/Learning/MainProgram';

const MainProgramPage = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Program Utama" title="Daftar Program" />
            <MainProgramContain />
        </Fragment>
    );
};
export default MainProgramPage;